export class Helper {

	public static getSiteCollectionUrl(): string {
		let baseUrl = window.location.protocol + "//" + window.location.host;
		const pathname = window.location.pathname;
		const siteCollectionDetector = "/sites/";
		if (pathname.indexOf(siteCollectionDetector) >= 0) {
			baseUrl += pathname.substring(0, pathname.indexOf("/", siteCollectionDetector.length));
		}
		return baseUrl;
	}

	public static convertHexToRgb(hex: string): { r: number; b: number; g: number; } {
		let shorthandRegex: any = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
		hex = hex.replace(shorthandRegex, (m, r, g, b) => {
			return r + r + g + g + b + b;
		});
		let result: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
			}
			: null;
	}

	public static getTextColorBasedOnBackgroundColorDarkness(backgroundColor: string, lightReturn: string, darkReturn: string): string {
		let rgb: { r: number; b: number; g: number } = this.convertHexToRgb(backgroundColor);
		if (rgb) {
			let colorValue: number = rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114;
			let threshold: number = 186;
			return colorValue <= threshold ? lightReturn : darkReturn;
		}
		return "#FFFFFF";
	}

	public static getRandomStringKey(): string {
		return Math.random().toString(36).substring(7);
	}

	public static getCookieValue(cookieName): string {
		let ca = document.cookie.split('; ');
		let cookieObjects = ca.filter((cookie) => {
			return cookie.indexOf(cookieName) === 0;
		});
		if (cookieObjects.length != 0) {
			let cookieValue = cookieObjects[0].split("=")[1]
			return cookieValue;
		}
		return undefined;
	}

	public static isYouTubeUrl(url: string): boolean {
		let p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
		if (url.match(p)) {
			return true;
		}
		return false;
	}

	public static getYouTubeThumbnailFromURL(url: string): string {
		let rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
		let youtubeId: string = url.match(rx)[1];
		return "http://img.youtube.com/vi/" + youtubeId + "/mqdefault.jpg";
	}

	public static replaceStreamVideoTag(source: string): string {
		if (source) {
			let doc = new DOMParser().parseFromString(source, "text/html");
			let elements = doc.getElementsByTagName("intraactive");
			for (let i = 0; i < elements.length; i++) {
				let e = elements[i];
				switch ((e as any).dataset["type"]) {
					case "stream":

						let url = (e as any).dataset["url"];
						let placeholder = e.outerHTML.replace("https://", "https&#58;//");
						let streamContent = `<iframe width="640" height="360" src="${url}?autoplay=false&amp;showinfo=true" style="border:none;" allowfullscreen ></iframe>`;
						source = source.replace(placeholder, streamContent);
						break;
					default:
						break;
				}
			}
		}
		return source;
	}

	public static moveArrayItem(array: any[], oldIndex: number, newIndex: number): any[] {
		if (newIndex >= array.length) {
			let k = newIndex - array.length + 1;
			while (k--) {
				array.push(undefined);
			}
		}
		array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
		return array;
	}

	public static sortArray(property) {
		let sortOrder = 1;
		if (property[0] === "-") {
			sortOrder = -1;
			property = property.substr(1);
		}
		return (a, b) => {
			let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
			return result * sortOrder;
		};
	}

	public static cleanContentHTML(source: string, placeholderImage: string): string {
		if (source) {
			let doc = new DOMParser().parseFromString(source, "text/html");
			let elements = doc.getElementsByTagName("intraactive");
			for (let i = 0; i < elements.length; i++) {
				let e = elements[i];
				switch ((e as any).dataset["type"]) {
					case "stream":
            let url = (e as any).dataset["url"];
            url = url.replace("embed/", "");
						let placeholder = e.outerHTML.replace("https://", "https&#58;//");
						let streamContent = `<a href="${url}"><img src="${placeholderImage}"/></a>`;
						source = source.replace(placeholder, streamContent);
						break;
					default:
						break;
				}
			}
		}
		return source;
	}

	public static addImageEndpointToUrl(url: string, imageApiURL: string, tenant: string, token: string): string {
		if (url && imageApiURL && tenant && token) {
			let tenantName = tenant.replace(".onmicrosoft.com", "").replace(".sharepoint.com", "");
			let imageEndpoint = imageApiURL + "/" + tenantName + "/DefaultRendition?token=" + token + "&path=";
			url = imageEndpoint + encodeURIComponent(url);
		}
		return url;
	}

	public static getImageUrl(apiImageUrl: string, tenantName: string, token: string, imageUrl: string, version?: string, format?: string): string {
		if (imageUrl && apiImageUrl && tenantName && token) {
			tenantName = tenantName.replace(".onmicrosoft.com", "").replace(".sharepoint.com", "");
			let imageFormat = format != undefined ? format : "Full";
			let versionNumber = version != undefined ? version : "";

			let pathComparison = `${apiImageUrl}/${tenantName}`;
			let fullPath = `/${imageFormat}?token=${token}&version${versionNumber}=&path=`;

			if (imageUrl.indexOf(pathComparison) === -1) {
				imageUrl = `${pathComparison}${fullPath}${encodeURIComponent(imageUrl)}`;
			}
		}
		return imageUrl;
	}

	public static getProfileImageUrl(apiUrl: string, tenantName: string, token: string, userPrincipalName: string): string {
		let url = "";
		if (apiUrl && tenantName && token && userPrincipalName) {
			if (tenantName.indexOf(".sharepoint.com") == -1) {
				tenantName = `${tenantName}.sharepoint.com`;
			}
			url = `${apiUrl}/api/${tenantName}/image/profile/token/${token}?userPrincipalName=${userPrincipalName}`;
		}
		return url;
  }
  
  public static getProfileImageUrlFromImageAPI(apiUrl: string, tenantName: string, token: string, id: string): string {
		let url = "";
		if (apiUrl && tenantName && token && id) {
			if (tenantName.indexOf(".sharepoint.com") == -1) {
				tenantName = `${tenantName}.sharepoint.com`;
			}
			url = `${apiUrl}/${tenantName}/profile/${id}?authtoken=${token}&ignoreCache=false`;
		}
		return url;
  }

	public static getCurrentAbsoluteSiteUrl(): string {
		if (window) {
			return window.location.protocol + "//" + window.location.host + window.location.pathname;
		}
		return null;
	}

	public static setLocalStorage(key, value, minutes): void {
		let currentDate = new Date().getTime();

		let expiresIn = currentDate + (60000 * minutes);

		localStorage.setItem(key, JSON.stringify({
			"value": value,
			"expires": expiresIn
		}));
	}

	public static getLocalStorage(key): any {
		let itemValue = localStorage[key];
		let currentDate = new Date().getTime();

		if (itemValue !== undefined && itemValue !== null) {
			let current = JSON.parse(itemValue);
			if (current.expires && current.expires <= currentDate) {
				return null;
			}

			return current.value;
		}

		return null;
	}

	public static getTenantName(): string {
		let url = window.location.host;
		url = url.split('.')[0];
		return url;
	}

	public static getTenant(): string {
		return window.location.host;
	}

	public static ensureTenantIsSharePointRef(tenantName: string): string {
		if (!tenantName.endsWith(".sharepoint.com")) {
			return tenantName + ".sharepoint.com";
		}
		return tenantName;
	}

	public static stringShouldHaveNoTrailingSlash(uri: string): string {
		return uri[uri.length - 1] === '/'
			? uri.substr(0, uri.length - 1)
			: uri;
	}

	public static getParameterByName(name: string) {
		let url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	public static removeURLParameter(url: string, parameter: string) {
		//prefer to use l.search if you have a location/link object
		let urlparts = url.split('?');
		if (urlparts.length >= 2) {

			let prefix = encodeURIComponent(parameter) + '=';
			let pars = urlparts[1].split(/[&;]/g);

			//reverse iteration as may be destructive
			for (let i = pars.length; i-- > 0;) {
				//idiom for string.startsWith
				if (pars[i].lastIndexOf(prefix, 0) !== -1) {
					pars.splice(i, 1);
				}
			}

			return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
		}
		return url;
	}

	public static setCookie(name: string, val: string) {
		const date = new Date();
		const value = val;
		// Set it expire in 10 years
		date.setTime(date.getTime() + (10 * 365 * 24 * 60 * 60 * 1000));

		// Set it
		document.cookie = name + "=" + value + "; expires=" + date.toString() + "; path=/; SameSite=None; Secure";
  }
  
  public static removeCookie(name: string): void {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

	public static getUriHash(): string {
		return window.location.hash;
	}
}