import * as React from 'react';
import { Store } from '../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './MediaFiles.css';
import { IAHeader } from '../../../header/Header';
import { IASpinner } from '../../../spinner/Spinner';
import { LocalizationService } from '../../../../services/LocalizationService';
import PlayService from '../../../../services/PlayService';
import { IListElement, IListElementColumn, IMediaFile, IMediaFileTag } from '../../../../interfaces/IPlay';
import { Popup } from '../../reusableComponents/popup/Popup';
import { IAButton } from '../../../button/Button';
import { Helper } from '../../../../Helper';
import { MediaFileUploadPanel } from './mediaFileSelector/mediaFileUploadPanel/MediaFileUploadPanel';
import { PlayHelper } from '../../PlayHelper';
import { IAListView } from '../../../listView/ListView';
import { IAIconDropDown } from '../../../iconDropDown/IconDropDown';
import { IIconDropDown } from '../../../../interfaces/IIconDropDown';
import { IACardViewService } from '../../../messagesCardView/CardView.service';
import { IACard } from '../../../card/Card';
import { ILabel } from '../../../../interfaces/ILabel';
import { IAIcon } from '../../../icon/Icon';
import { IATextField } from '../../../textField/TextField';

export interface IProps {
  showSkeleton: boolean;
  store?: Store;
}

export interface IState {
  isLoadingMediaFiles: boolean;
  isLoadingTags: boolean;
  mediaFiles: IMediaFile[]
  mediaFileToDelete?: string;
  mediaFileTagToDelete?: IMediaFileTag;
  selectedMediaFileId?: string;
  skip: number;
  take: number;
  selectedTags: Set<IMediaFileTag>;
  filterByMimeType: IIconDropDown;
  searchQuery: string;
}

@inject('store')
@observer
export class MediaFiles extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();
  private cardViewService: IACardViewService = new IACardViewService();
  private queryDelay: NodeJS.Timeout;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoadingMediaFiles: false,
      isLoadingTags: false,
      mediaFiles: undefined,
      skip: 0,
      take: PlayHelper.ELEMENTS_PER_PAGE,
      selectedTags: new Set(),
      filterByMimeType: undefined,
      searchQuery: ""
    }
  }

  public componentDidMount(): void {
    this.props.store.mediaFiles = undefined;
    this.props.store.mediaFileTags = [];
    this.getMediaFileTags();
    this.getMediaFiles(true);
  }

  public componentWillReceiveProps(props: IProps): void {
    if (!props.showSkeleton && props.showSkeleton !== this.props.showSkeleton && !this.props.store.mediaFiles) {
      this.getMediaFiles(true);
    } else {
      this.setState({ skip: this.props.store.mediaFiles?.length ? this.props.store.mediaFiles?.length - this.state.take : 0 });
    }
    if (!props.showSkeleton && props.showSkeleton !== this.props.showSkeleton && (!this.props.store.mediaFileTags || this.props.store.mediaFileTags.length === 0)) {
      this.getMediaFileTags();
    }
  }

  public componentWillUnmount(): void {
    this.props.store.mediaFileToEdit = undefined;
  }

  private getMediaFiles(override?: boolean) {
    if (this.props.store.profile?.id) {
      this.setState({ isLoadingMediaFiles: true });
      const tags: string[] = [];
      this.state.selectedTags.forEach(tag => tags.push(tag.id));
      PlayService.getMediaFiles(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, this.state.filterByMimeType?.value, { skip: this.state.skip, take: this.state.take, tags: tags, search: this.state.searchQuery }).then(mediaFiles => {
        const appendedMediaFiles = override ? [] : this.props.store.mediaFiles ?? [];
        appendedMediaFiles.push(...mediaFiles.value);
        this.props.store.mediaFiles = appendedMediaFiles;
        this.props.store.mediaFilesCount = mediaFiles.count;
        this.setState({ mediaFiles: appendedMediaFiles, isLoadingMediaFiles: false });
      }).catch(() => {
        this.setState({ isLoadingMediaFiles: false });
      });
    }
  }

  private getMediaFileTags() {
    if (this.props.store.profile?.id) {
      this.setState({ isLoadingTags: true });
      PlayService.getMediaFileTags(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, {}).then(mediaFileTags => {
        this.props.store.mediaFileTags = mediaFileTags;
        this.setState({ isLoadingTags: false });
      }).catch(() => {
        this.setState({ isLoadingTags: false });
      });
    }
  }

  private onDelete(id: string) {
    PlayService.deleteMediaFile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, id).then(() => {
      this.props.store.mediaFileToEdit = undefined;
      this.props.store.workingOnIt = false;
      this.setState({ mediaFileToDelete: undefined, skip: 0 }, () => this.getMediaFiles(true));
    })
  }

  public render(): JSX.Element {
    const isCardView = localStorage.getItem("IA_mediaFilesViewType") && JSON.parse(localStorage.getItem("IA_mediaFilesViewType")).value === "card";

    const cards: JSX.Element[] = [];
    const listElements: IListElement[] = [];
    // Resizing cards
    const cardMarginRight = 20;
    const [numberOfCardsPerRow, maxCardWidthAvailable] = this.cardViewService.getNumberOfCardsPerRowAndMaxCardWidthAvailable(this.props.store.contentWidth, cardMarginRight, 250, 5);
    let currentRowNumber = 1;

    if (this.state.mediaFiles && this.state.mediaFiles.length > 0) {
      this.state.mediaFiles.forEach((mediaFile: IMediaFile) => {
        let image = mediaFile.url;
        if (mediaFile.mimeType?.indexOf("image") !== -1) {
          image = PlayHelper.excludeImageFromApi(this.props.store.environment, mediaFile.url)
            ? mediaFile.url 
            : PlayHelper.getImageFromApi(this.props.store.environment, this.props.store.tenantId, this.props.store.profile.id, PlayHelper.getStandardImageFormat(500, 250), false, mediaFile.url);
        }

        // LIST VIEW
        const columns: IListElementColumn[] = this.props.store.isMobile ?
          [
            {
              header: this.localizationService.strings.PlayAdmin_Preview?.toUpperCase(),
              type: "preview",
              src: image,
              fileType: mediaFile.mimeType
            },
            {
              header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
              type: "text",
              text: mediaFile.name,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }
          ]
          :
          [
            {
              header: this.localizationService.strings.PlayAdmin_Preview?.toUpperCase(),
              type: "preview",
              src: image,
              fileType: mediaFile.mimeType
            },
            {
              header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
              type: "text",
              text: mediaFile.name,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            },
            {
              header: this.localizationService.strings.PlayAdmin_Type?.toUpperCase(),
              type: "text",
              text: mediaFile.mimeType,
              color: this.props.store.darkMode ? "#ffffff" : "#333333",
              style: { flex: 0.3 }
            },
            {
              header: this.localizationService.strings.PlayAdmin_Tag?.toUpperCase(),
              type: "customElement",
              color: this.props.store.darkMode ? "#ffffff" : "#333333",
              style: { flex: 0.5 },
              element: mediaFile.tags?.map((tag, i) => {
                if (this.props.store.mediaFileTags?.find(t => t.id === tag)) {
                  return <div
                    key={tag + i}
                    style={{
                      margin: "13px 4px 0 0",
                      backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                      color: this.props.store.darkMode ? "#ffffff" : "#333333",
                      fontSize: 12
                    }}
                    className={styles.IA_tagButton}
                  >
                    {tag?.toUpperCase()}
                  </div>
                }
              })
            }
          ];
        listElements.push(
          {
            id: mediaFile.id,
            columns: columns,
            buttons: [
              {
                action: "delete",
                icon: "Trash",
                color: this.props.store.highlightColor
              },
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }
            ]
          }
        );

        // CARD VIEW
        let coverImage;
        let coverVideo;
        if (image && mediaFile.mimeType?.indexOf("image") !== -1) {
          coverImage = image;
        }
        if (image && mediaFile.mimeType?.indexOf("video") !== -1) {
          coverVideo = image;
        }
        const labels: ILabel[] = [];
        mediaFile.tags?.forEach(tag => {
          labels.push(
            {
              text: tag,
              backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
              textColor: this.props.store.darkMode ? "#ffffff" : "#333333",
              borderRadius: 5
            }
          );
        });
        cards.push(
          <IACard
            key={`mediaFile_${mediaFile.id}`}
            id={mediaFile.id}
            showSkeleton={false}
            title={mediaFile.name}
            darkMode={this.props.store.darkMode}
            cover={{
              imageURL: coverImage,
              videoURL: coverVideo
            }}
            width={window.innerWidth < 667 ? window.innerWidth - 56 : maxCardWidthAvailable}
            isMobile={this.props.store.isMobile}
            styles={{
              marginRight: currentRowNumber == numberOfCardsPerRow ? 0 : cardMarginRight,
              marginBottom: 20,
              width: window.innerWidth < 667 ? "100%" : maxCardWidthAvailable,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
            design={{
              highlightColor: this.props.store.highlightColor,
              showDropShadow: true,
              borderRadius: 5,
              backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5",
              hoverBackgroundColor: this.props.store.darkMode ? "#393939" : "#e6e6e6"
            }}
            author={mediaFile.mimeType}
            labels={labels}
            onClickOpen={() => this.props.store.mediaFileToEdit = mediaFile}
            alwaysShowFooter
            footerElements={[
              {
                icon: "Edit",
                color: this.props.store.highlightColor,
                onClick: () => this.props.store.mediaFileToEdit = mediaFile
              },
              {
                icon: "Trash",
                color: this.props.store.highlightColor,
                onClick: () => this.setState({ mediaFileToDelete: mediaFile.id })
              }
            ]}
          />
        );

        if (currentRowNumber == numberOfCardsPerRow) {
          currentRowNumber = 1;
        } else {
          currentRowNumber++;
        }
      });
    }

    return (
      <div>
        <MediaFileUploadPanel
          open={this.props.store.mediaFileToEdit != undefined}
          close={() => {
            this.props.store.mediaFileToEdit = undefined;
          }}
          reloadData={() => this.setState({ skip: 0 }, () => this.getMediaFiles(true))}
          enableMultiple
        />
        <IAHeader
          id="play-media-files-header"
          title={this.localizationService.strings.PlayAdmin_YourMediaFiles}
          description={this.localizationService.strings.PlayAdmin_YourMediaFilesDesc}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor="#91bbcd"
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          actionButton={{
            label: this.localizationService.strings.PlayAdmin_Add,
            onClick: () => {
              this.props.store.mediaFileToEdit = {
                isNew: true
              };
            },
            buttonColor: this.props.store.highlightColor,
            borderRadius: 5,
            textColor: "#ffffff",
            arialable: this.localizationService.strings.PlayAdmin_Add,
            icon: "Add"
          }}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-gray.jpg",
              headline: this.localizationService.strings.PlayAdmin_YourMediaFiles,
              text: this.localizationService.strings.PlayAdmin_YourMediaFilesDesc,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-mediafiles.png"
            }
          ]}
        />
        {!this.props.store.isMobile &&
          <div className={styles.IA_filters}>
            {/* <IAIconDropDown
              options={PlayHelper.getSortingOptions(this.localizationService)}
              selectedOption={localStorage.getItem("IA_mediaFilesSortingOption") ? JSON.parse(localStorage.getItem("IA_mediaFilesSortingOption")) : PlayHelper.getSortingOptions(this.localizationService)[0]}
              disabled
              darkMode={this.props.store.darkMode}
              onChange={(widgetsViewType: IIconDropDown) => {
                localStorage.setItem("IA_mediaFilesSortingOption", JSON.stringify(widgetsViewType));
                this.forceUpdate();
              }}
              style={{
                minWidth: 320,
                float: "left",
                marginRight: 20,
                marginBottom: 20,
                opacity: 0.5
              }}
            /> */}
            <IAIconDropDown
              options={[{ value: "image", label: this.localizationService.strings.PlayAdmin_WidgetTypeImage, icon: "Widgets Image" },
                { value: "video", label: this.localizationService.strings.PlayAdmin_WidgetTypeVideo, icon: "Widgets Video" }]}
              selectedOption={this.state.filterByMimeType}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              placeholder={this.localizationService.strings.PlayAdmin_FilterByMimeType}
              clearable
              style={{
                minWidth: 220,
                marginRight: 20,
                marginBottom: 20,
              }}
              onChange={(filterByMimeType: IIconDropDown) => this.setState({ filterByMimeType, skip: 0 }, () => this.getMediaFiles(true))}
            />
            <IATextField
              style={{
                width: 330,
                marginRight: 20,
                marginTop: 0
              }}
              inputFieldStyle={{
                marginTop: 0,
                height: 38,
                borderColor: "#cccccc",
                fontSize: 15
              }}
              text={this.state.searchQuery}
              placeholder={this.localizationService.strings.PlayAdmin_SearchMediaFiles}
              borderRadius={5}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              onChange={(search: string) => {
                this.setState({
                  skip: 0,
                  searchQuery: search
                })
                clearTimeout(this.queryDelay);
                this.queryDelay = setTimeout(() => {
                  this.props.store.mediaFiles = undefined;
                  this.getMediaFiles(true);
                }, 500);
              }}
            />
            <IAIconDropDown
              options={PlayHelper.getViewTypes()}
              selectedOption={localStorage.getItem("IA_mediaFilesViewType") ? JSON.parse(localStorage.getItem("IA_mediaFilesViewType")) : PlayHelper.getViewTypes()[0]}
              // label={this.localizationService.strings.PlayAdmin_ViewType}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              onChange={(widgetsViewType: IIconDropDown) => {
                localStorage.setItem("IA_mediaFilesViewType", JSON.stringify(widgetsViewType));
                this.forceUpdate();
              }}
              style={{
                minWidth: 70,
                marginBottom: 20,
                marginLeft: "auto"
              }}
            />
          </div>
        }
        {this.props.store.mediaFileTags && this.props.store.mediaFileTags.length > 0 &&
        <div style={{
          marginBottom: 15,
          clear: "both",
          display: "flex",
          flexWrap: "wrap"
        }}>
          <div
            className={styles.IA_tagButton}
            style={{
              backgroundColor: this.state.selectedTags.size === 0 ? this.props.store.highlightColor : this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#eeeeee",
              color: this.state.selectedTags.size === 0 ? "white" : this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
            onClick={() => {
              if (!this.state.isLoadingTags) {
                this.setState({
                  selectedTags: new Set(),
                  skip: 0,
                  take: PlayHelper.ELEMENTS_PER_PAGE
                }, () => {
                  this.getMediaFiles(true);
                });
              }
            }}
          >
            {this.localizationService.strings.PlayAdmin_AllMediaFiles?.toUpperCase()}
          </div>
          {this.props.store.mediaFileTags.map((tag, i) => {
            let backgroundColor = this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#eeeeee";
            let color = this.props.store.darkMode ? "#ffffff" : "#333333";
            const isSelected = this.state.selectedTags.has(tag);
            if (isSelected) {
              backgroundColor = this.props.store.highlightColor;
              color = "white";
            }
            return <div
              key={tag.id + i}
              className={styles.IA_tagButtonWithDelete}
              style={{
                backgroundColor: backgroundColor,
                color: color
              }}
            >
              <div
                className={styles.IA_tagButtonFlexContainer}
                onClick={() => {
                  if (!this.state.isLoadingTags) {
                    this.state.selectedTags.has(tag) ? this.state.selectedTags.delete(tag) : this.state.selectedTags.add(tag);
                    this.setState({
                      selectedTags: this.state.selectedTags,
                      skip: 0,
                      take: PlayHelper.ELEMENTS_PER_PAGE
                    }, () => {
                      this.getMediaFiles(true);
                    });
                  }
                }}  
              >
                <div className={styles.IA_tagButtonTagName}>
                  {tag.id?.toUpperCase()}
                </div>
                <div
                  className={styles.IA_tagButtonIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!this.state.isLoadingTags) {
                      this.setState({ mediaFileTagToDelete: tag });
                    }
                  }}    
                >
                  <IAIcon
                    title="Trash"
                    color={isSelected ? "white" : this.props.store.highlightColor}
                    size={16}
                    style={{
                      marginTop: 4
                    }}
                  />
                </div>
              </div>
            </div>
          })}
        </div>
      }
        <>
          {isCardView && !this.props.store.isMobile ?
            cards
            :
            <IAListView
              id="ListViewOfMediaFiles"
              listElements={listElements}
              backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
              hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
              buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
              darkMode={this.props.store.darkMode}
              defaultAction={"edit"}
              selectedAction={(action: string, id: string) => {
                switch (action) {
                  case "delete":
                    this.setState({ mediaFileToDelete: id });
                    break;
                  case "edit":
                    this.props.store.mediaFiles.forEach((mediaFile: IMediaFile) => {
                      if (mediaFile.id === id) {
                        this.props.store.mediaFileToEdit = mediaFile;
                      }
                    });
                    break;
                  default:
                    break;
                }
              }}
            />
          }
          {PlayHelper.showNextPageButton(this.state.skip, this.state.take, this.props.store.mediaFilesCount) && !this.state.isLoadingMediaFiles &&
            <div className={styles.IA_paginationButtonContainer}>
              {this.state.isLoadingMediaFiles ?
                <IASpinner
                  color={this.props.store.highlightColor}
                  style={{ margin: 0 }}
                />
                :
                <IAButton
                  onClick={() => this.setState({ skip: this.state.skip + this.state.take }, () => { this.getMediaFiles() })}
                  buttonColor={this.props.store.highlightColor}
                  textColor="#ffffff"
                  borderRadius={5}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_LoadMore?.toUpperCase()}
                />
              }
            </div>
          }
          {this.state.isLoadingMediaFiles &&
            <IASpinner
              color={this.props.store.highlightColor}
              style={{
                float: "left",
                margin: "20px 0 0 calc(50% - 20px)"
              }}
            />
          }
        </>
        {this.state.mediaFileToDelete &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_MediaFileConfirmDelete}
            close={() => this.setState({ mediaFileToDelete: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.onDelete(this.state.mediaFileToDelete);
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  darkMode={this.props.store.darkMode}
                  onClick={() => this.setState({ mediaFileToDelete: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.state.mediaFileTagToDelete &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_MediaFileTagConfirmDelete}
            close={() => this.setState({ mediaFileTagToDelete: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.state.selectedTags.delete(this.state.mediaFileTagToDelete);
                    PlayService.deleteMediaFileTag(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, this.state.mediaFileTagToDelete.id).then(() => {
                      this.getMediaFileTags();
                      this.setState({
                        mediaFileTagToDelete: undefined,
                        selectedTags: this.state.selectedTags,
                        skip: 0,
                        take: PlayHelper.ELEMENTS_PER_PAGE
                      }, () => {
                        this.getMediaFiles(true);
                      });
                    });

                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  darkMode={this.props.store.darkMode}
                  onClick={() => this.setState({ mediaFileTagToDelete: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
      </div>
    );
  }

  
}