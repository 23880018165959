import * as React from 'react';
import * as styles from './DropDown.css';
import { IATooltip } from '../tooltip/Tooltip';
import { IAIcon } from '../icon/Icon';
import "../../styles/BasicIA.css";

export interface IDropDownProps {
  label?: string;
  selectedOption: any;
  options: { "key": string, "value": any, "disabled"?: boolean }[];
  style?: React.CSSProperties;
  toolTip?: any;
  disabled?: boolean;
  highlightColor?: string;
  darkMode?: boolean;
  borderRadius?: number;
  labelStyle?: any;
  required?: boolean;
  onChange: (option: any) => void;
}

export interface IDropDownState {
  selectedOptionValue: string;
}


export class IADropDown extends React.Component<IDropDownProps, IDropDownState> {
  private ref;

  constructor(props: IDropDownProps) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      selectedOptionValue: undefined
    }
  }

  public componentDidMount(): void {
    this.getSelectedOptionValue(this.props.selectedOption);
  }

  public componentWillReceiveProps(props: IDropDownProps): void {
    if (props.selectedOption !== this.props.selectedOption) {
      this.setState({ selectedOptionValue: props.selectedOption }, () => {
        this.props.onChange(props.selectedOption)
      });
    }
    if (props.options !== this.props.options) {
      this.render();
    }
    this.getSelectedOptionValue(props.selectedOption);
  }

  private getSelectedOptionValue(key: string): void {
    if (this.props.options) {
      this.props.options.forEach((option: { "key": string, "value": any, "disabled"?: boolean }) => {
        if (option.key == key) {
          this.setState({ selectedOptionValue: option.value }, () => this.forceUpdate());
        }
      });
    }
  }

  public render(): JSX.Element {
    let options: JSX.Element[] = [];
    if (this.props.options) {
      this.props.options.forEach((option: { "key": string, "value": any, "disabled"?: boolean }) => {
        options.push(
          <option
            key={option.key}
            value={option.value}
            disabled={option.disabled != undefined ? option.disabled : false}
          >
            {option.value}
          </option>
        );
      });
    }
    const style = {...this.props.style} ?? {};
    if (!style["--highlight-color"]) {
      style["--highlight-color"] = this.props.highlightColor ?? "black";
    }
    return (
      <div
        className={this.props.darkMode ? styles.IA_dropDownDarkMode : styles.IA_dropDown}
        style={style}
      >
        {this.props.label && <label style={this.props.labelStyle}>{this.props.label}</label>}
        {this.props.toolTip != undefined &&
          <IATooltip
            content="Tooltip"
            type={"bubble"}
            styles={{
              width: 200,
              textAlign: "left",
              color: "#333333",
            }}
            componentContent={this.props.toolTip}
          >
            <IAIcon
              title="Info"
              size={14}
              color={this.props.highlightColor !== undefined ? this.props.highlightColor : "#03787c"}
              style={{
                float: "left",
                cursor: "pointor",
                marginLeft: 4,
                marginTop: 3,
                height: 13
              }}
            />
          </IATooltip>
        }
        {this.props.required &&
          <span style={{ color: "red", paddingLeft: 2 }}>*</span>
        }
        <select
          ref={this.ref}
          className="IA_OnMouseFocusOutline"
          tabIndex={0}
          disabled={this.props.disabled}
          value={this.state.selectedOptionValue}
          onChange={(selectedOption: any) => {
            this.props.options.forEach((option: { "key": string, "value": any, "disabled"?: boolean }) => {
              if (option.value === selectedOption.target.value) {
                this.props.onChange(option.key);
                this.getSelectedOptionValue(option.key);
                return;
              }
            });
          }}
          style={{
            borderRadius: this.props.borderRadius
          }}
        >
          {options}
        </select>
      </div>
    );
  }
}