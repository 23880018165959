import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './PermissionsEditPanel.css';
import { Store } from '../../../../stores';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IAPanel } from '../../../../../panels';
import { IATextField } from '../../../../../textField/TextField';
import { IASpinner } from '../../../../../spinner/Spinner';
import PlayService from '../../../../../../services/PlayService';
import { PermissionsPersona } from './permissionsPersona/PermissionPersona';
import { IADropDown } from '../../../../../dropDown/DropDown';

export interface IProps {
  isAddingUser: boolean;
  profiles?: { key: string, value: any }[];
  store?: Store;
  onClose: () => void;
  onAddUser: (userId: string, profileId?: string) => void;
}

export interface IState {
  searchTerm: string;
  isSearching: boolean;
  users: any[]
  selectedProfile?: string;
}

@inject('store')
@observer
export class PermissionsEditPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();
  private debounceInputTimerId: ReturnType<typeof setTimeout>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      searchTerm: '',
      isSearching: false,
      users: [],
      selectedProfile: this.props.profiles ? this.props.profiles[0].key : undefined
    }
  }

  private async onSearch(searchTerm: string) {
    const currentSearchText = searchTerm;

    this.setState({ searchTerm }, () => {
      clearTimeout(this.debounceInputTimerId);
      this.debounceInputTimerId = setTimeout(async () => {
        if (this.state.searchTerm.length && this.state.searchTerm == currentSearchText) {
          this.setState({ isSearching: true })
          PlayService.getGraphUsers(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.state.searchTerm).then((users) => {
            this.setState({ users });
          })
          this.setState({ isSearching: false });
        }
      }, 500);
    })
  }

  public render(): JSX.Element {
    return (
      <IAPanel
        open={this.props.isAddingUser}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        width={440}
        dataAutomationIdPrefix="permissions-edit-panel"
        darkMode={this.props.store.darkMode}
        close={() => {
          this.setState({ isSearching: false, searchTerm: '', users: [] });
          this.props.onClose();
        }}
      >
        {this.props.isAddingUser &&
          <div
            className={styles.IA_permissionsEditPanel}
            style={{
              height: window.innerHeight - 44,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            <div className={styles.IA_content}>
              {this.props.profiles &&
                <IADropDown
                  label={this.localizationService.strings.PlayAdmin_SelectAProfileToAccess?.toUpperCase()}
                  selectedOption={this.state.selectedProfile}
                  options={this.props.profiles}
                  darkMode={this.props.store.darkMode}
                  highlightColor={this.props.store.highlightColor}
                  onChange={(profile) => this.setState({ selectedProfile: profile })}
                  style={{
                    float: "left",
                    width: 360
                  }}
                  borderRadius={5}
                />
              }
              <IATextField
                label={this.localizationService.strings.PlayAdmin_AddUser}
                text={this.state.searchTerm}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                labelStyle={{
                  textTransform: "uppercase",
                  fontSize: 12
                }}
                placeholder={this.localizationService.strings.PlayAdmin_Search}
                placeholderIcon="Search"
                onChange={async (input: string) => {
                  await this.onSearch(input)
                }}
              />
              {
                this.state.isSearching && <IASpinner
                  color={this.props.store.highlightColor}
                  style={{
                    float: "left",
                    marginLeft: "calc(50% - 20px)"
                  }}
                />
              }

              {
                this.state.users?.length > 0 && <div className={styles.IA_permissionsSearchResult}>
                  {
                    this.state.users.map(user => {
                      return <PermissionsPersona
                        key={user.id}
                        highlightColor={this.props.store.highlightColor}
                        userId={user.id}
                        displayName={user.displayName}
                        userPrincipalName={user.userPrincipalName}
                        onAdd={(id: string) => {
                          this.props.onAddUser(id, this.state.selectedProfile);
                          this.setState({
                            selectedProfile: undefined,
                            users: undefined,
                            isSearching: false,
                            searchTerm: undefined
                          }, () => this.props.onClose());
                        }}
                      />
                    })
                  }
                </div>
              }
            </div>
          </div>
        }
      </IAPanel>
    );
  }

  
}