import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
}

@inject('store')
@observer
export class PdfWidgetSettings extends React.Component<IProps> {
  private readonly localizationService = new LocalizationService()

  render(): React.ReactNode {
    return (
      <MediaFileSelector
        label={this.localizationService.strings.PlayAdmin_PdfUrl?.toUpperCase()}
        url={this.props.store.widgetToEdit.pdf.url}
        placeholder={this.localizationService.strings.PlayAdmin_PdfUrl}
        type={"pdf"}
        onChange={(pdfUrl: string) => {
          this.props.store.widgetToEdit.pdf.url = pdfUrl;
          this.props.forceUpdate();
        }}
      />
    );
  }

  
}