
import { IIcon, IIconType } from '../interfaces/IIcon';

const IconLibrary = require('../components/iconPicker/assets/FluentIcons.json');
const ColoredIconLibrary = require('../components/iconPicker/assets/ColoredIcons.json');
const ExtraIconLibrary = require('../components/iconPicker/assets/ExtraIcons.json');

export default class IconService {

  public static getFluentIcon(url): Promise<string> {
    return new Promise<string>((resolve: (icon: string) => void, reject: (error: any) => void): void => {
      if (url) {
        fetch(url, {
          method: "GET",
          credentials: "same-origin",
        }).then((response) => {
          if (!response.ok) {
            reject(response);
            return;
          }
          return response.text();
        }).then((result) => {
          resolve(result);
        })
          .catch(error => {
            console.log(error)
            reject(null);
          });
      } else {
        reject(null);
      }
    });
  }

  public static getIcons(type: IIconType, search?: string,): IIcon[] {
    if (search) {
      search = search.toLowerCase();
    }
    const icons: IIcon[] = [];
    if (type === "colored") {
      ColoredIconLibrary.icons.forEach((icon: IIcon) => {
        if (search == undefined || icon.title.toLowerCase().indexOf(search) > -1 || icon.tags.toLowerCase().indexOf(search) > -1) {
          icon.type = type;
          icons.push(icon);
        }
      });
    } else if (type === "extra") {
      ExtraIconLibrary.icons.forEach((icon: IIcon) => {
        if (search == undefined || icon.title.toLowerCase().indexOf(search) > -1 || icon.tags.toLowerCase().indexOf(search) > -1) {
          icon.type = type;
          icons.push(icon);
        }
      });
    } else {
      IconLibrary.icons.forEach((icon: IIcon) => {
        if (search == undefined || icon.title.toLowerCase().indexOf(search) > -1 || icon.tags.toLowerCase().indexOf(search) > -1) {
          icon.type = type;
          icons.push(icon);
        }
      });
    }
    return icons;
  }

  public static getIconWithTitle(title: string): IIcon {
    let selectedIcon = undefined;
    IconLibrary.icons.forEach((icon: IIcon) => {
      if (icon.title === title) {
        selectedIcon = icon;
        return selectedIcon;
      }
    });
    ColoredIconLibrary.icons.forEach((icon: IIcon) => {
      if (icon.title === title) {
        selectedIcon = icon;
        selectedIcon.type = "colored";
        return selectedIcon;
      }
    });
    ExtraIconLibrary.icons.forEach((icon: IIcon) => {
      if (icon.title === title) {
        selectedIcon = icon;
        selectedIcon.type = "extra";
        return selectedIcon;
      }
    });
    return selectedIcon;
  }


  public static mapOldIcon(icon: string): IIcon {
    let selectedIcon: IIcon;
    switch (icon) {
      case "Accordion":
        selectedIcon = IconService.getIconWithTitle("Panel Top Expand");
        break;
      case "Address":
        selectedIcon = IconService.getIconWithTitle("Globe Location");
        break;
      case "Address Book":
        selectedIcon = IconService.getIconWithTitle("Book Contacts");
        break;
      case "All items":
        selectedIcon = IconService.getIconWithTitle("Tabs");
        break;
      case "Article":
        selectedIcon = IconService.getIconWithTitle("News");
        break;
      case "Arrow":
        selectedIcon = IconService.getIconWithTitle("iOS Arrow RTL");
        break;
      case "Arrow left":
        selectedIcon = IconService.getIconWithTitle("iOS Arrow LTR");
        break;
      case "Arrow up":
        selectedIcon = IconService.getIconWithTitle("Chevron Up");
        break;
      case "ArrowUp":
        selectedIcon = IconService.getIconWithTitle("Chevron Up");
        break;
      case "Arrow down":
        selectedIcon = IconService.getIconWithTitle("Chevron Down");
        break;
      case "ArrowDown":
        selectedIcon = IconService.getIconWithTitle("Chevron Down");
        break;
      case "Attention":
        selectedIcon = IconService.getIconWithTitle("Warning");
        break;
      case "Audio":
        selectedIcon = IconService.getIconWithTitle("Music Note 2");
        break;
      case "Balloons":
        selectedIcon = IconService.getIconWithTitle("Balloon");
        break;
      case "Bar Chart":
        selectedIcon = IconService.getIconWithTitle("Data Bar Vertical");
        break;
      case "Beer":
        selectedIcon = IconService.getIconWithTitle("Drink Beer");
        break;
      case "Birthday":
        selectedIcon = IconService.getIconWithTitle("Food Cake");
        break;
      case "Box view":
        selectedIcon = IconService.getIconWithTitle("App Folder");
        break;
      case "BurgerMenu":
        selectedIcon = IconService.getIconWithTitle("Line Horizontal 3");
        break;
      case "Butterfly":
        selectedIcon = IconService.getIconWithTitle("Weather Synny");
        break;
      case "Calendar":
        selectedIcon = IconService.getIconWithTitle("Calendar LTR");
        break;
      case "Calendar plus":
        selectedIcon = IconService.getIconWithTitle("Calendar Add");
        break;
      case "Calendar plus 2":
        selectedIcon = IconService.getIconWithTitle("Calendar Add");
        break;
      case "Canteen":
        selectedIcon = IconService.getIconWithTitle("Food");
        break;
      case "Car":
        selectedIcon = IconService.getIconWithTitle("Vehicle Car");
        break;
      case "Car 2":
        selectedIcon = IconService.getIconWithTitle("Vehicle Car Profile LTR");
        break;
      case "Card view":
        selectedIcon = IconService.getIconWithTitle("Card View");
        break;
      case "Cell phone":
        selectedIcon = IconService.getIconWithTitle("Phone");
        break;
      case "Cell phone 2":
        selectedIcon = IconService.getIconWithTitle("Phone");
        break;
      case "Champagne":
        selectedIcon = IconService.getIconWithTitle("Drink Wine");
        break;
      case "ChatVideo":
        selectedIcon = IconService.getIconWithTitle("Chat Video");
        break;
      case "Close":
        selectedIcon = IconService.getIconWithTitle("Dismiss");
        break;
      case "Cocktail":
        selectedIcon = IconService.getIconWithTitle("Drink Margarita");
        break;
      case "Coins":
        selectedIcon = IconService.getIconWithTitle("Money");
        break;
      case "Coffeebreak":
        selectedIcon = IconService.getIconWithTitle("Drink Coffee");
        break;
      case "Compact List":
        selectedIcon = IconService.getIconWithTitle("Compact List View");
        break;
      case "Compass":
        selectedIcon = IconService.getIconWithTitle("Compass Northwest");
        break;
      case "Computer":
        selectedIcon = IconService.getIconWithTitle("Laptop");
        break;
      case "Contact":
        selectedIcon = IconService.getIconWithTitle("Person");
        break;
      case "Content Creator":
        selectedIcon = IconService.getIconWithTitle("Window Edit");
        break;
      case "Content Organizer":
        selectedIcon = IconService.getIconWithTitle("Folder");
        break;
      case "Copy Plus":
        selectedIcon = IconService.getIconWithTitle("Copy Add");
        break;
      case "Credit Card":
        selectedIcon = IconService.getIconWithTitle("Wallet Credit Card");
        break;
      case "Curly Brackets":
        selectedIcon = IconService.getIconWithTitle("Braces");
        break;
      case "CV":
        selectedIcon = IconService.getIconWithTitle("Text Bullet List Square Person");
        break;
      case "Cycle":
        selectedIcon = IconService.getIconWithTitle("Vehicle Bicycle");
        break;
      case "Database02":
        selectedIcon = IconService.getIconWithTitle("Database");
        break;
      case "Design":
        selectedIcon = IconService.getIconWithTitle("Design Ideas");
        break;
      case "Design02":
        selectedIcon = IconService.getIconWithTitle("Design Ideas");
        break;
      case "Designsettings":
        selectedIcon = IconService.getIconWithTitle("Design Ideas");
        break;
      case "Devices":
        selectedIcon = IconService.getIconWithTitle("Phone Laptop");
        break;
      case "Device mobile":
        selectedIcon = IconService.getIconWithTitle("Phone Laptop");
        break;
      case "DiscussionForum":
        selectedIcon = IconService.getIconWithTitle("Tab Desktop Copy");
        break;
      case "Divider part":
        selectedIcon = IconService.getIconWithTitle("Document Page Break");
        break;
      case "Documents":
        selectedIcon = IconService.getIconWithTitle("Document Copy");
        break;
      case "Download":
        selectedIcon = IconService.getIconWithTitle("Cloud Arrow Down");
        break;
      case "Download02":
        selectedIcon = IconService.getIconWithTitle("Cloud Arrow Down");
        break;
      case "Easter rabbit":
        selectedIcon = IconService.getIconWithTitle("Animal Rabbit");
        break;
      case "Easter rabbit":
        selectedIcon = IconService.getIconWithTitle("Animal Rabbit");
        break;
      case "Eat":
        selectedIcon = IconService.getIconWithTitle("Food");
        break;
      case "Editor":
        selectedIcon = IconService.getIconWithTitle("Person Edit");
        break;
      case "Education":
        selectedIcon = IconService.getIconWithTitle("Hat Graduation");
        break;
      case "Email":
        selectedIcon = IconService.getIconWithTitle("Mail");
        break;
      case "Enter":
        selectedIcon = IconService.getIconWithTitle("Door Arrow Right");
        break;
      case "Error":
        selectedIcon = IconService.getIconWithTitle("Warning");
        break;
      case "Evergreen":
        selectedIcon = IconService.getIconWithTitle("Tree Evergreen");
        break;
      case "File":
        selectedIcon = IconService.getIconWithTitle("Document");
        break;
      case "Fit Width":
        selectedIcon = IconService.getIconWithTitle("Auto Fit Width");
        break;
      case "Fit View":
        selectedIcon = IconService.getIconWithTitle("Rotator View");
        break;
      case "FollowFeed":
        selectedIcon = IconService.getIconWithTitle("Feed");
        break;
      case "Forward":
        selectedIcon = IconService.getIconWithTitle("Arrow Left");
        break;
      case "Full Width":
        selectedIcon = IconService.getIconWithTitle("Document Width");
        break;
      case "Globe Earth":
        selectedIcon = IconService.getIconWithTitle("Earth");
        break;
      case "Handbook":
        selectedIcon = IconService.getIconWithTitle("Book");
        break;
      case "Heading":
        selectedIcon = IconService.getIconWithTitle("Text Header 1");
        break;
      case "Heart filled":
        selectedIcon = IconService.getIconWithTitle("Heart");
        selectedIcon.type = "filled"
        break;
      case "HeartFill":
        selectedIcon = IconService.getIconWithTitle("Heart");
        selectedIcon.type = "filled"
        break;
      case "Help":
        selectedIcon = IconService.getIconWithTitle("Question Circle");
        break;
      case "Hide":
        selectedIcon = IconService.getIconWithTitle("Eye Off");
        break;
      case "Idea":
        selectedIcon = IconService.getIconWithTitle("Lightbulb Filament");
        break;
      case "ImageGallery":
        selectedIcon = IconService.getIconWithTitle("Image Multiple");
        break;
      case "ImageGallery2":
        selectedIcon = IconService.getIconWithTitle("Image Multiple");
        break;
      case "Info2":
        selectedIcon = IconService.getIconWithTitle("Info");
        break;
      case "Install":
        selectedIcon = IconService.getIconWithTitle("Plug Disconnected");
        break;
      case "Invoice":
        selectedIcon = IconService.getIconWithTitle("Receipt Money");
        break;
      case "Landingpage":
        selectedIcon = IconService.getIconWithTitle("Content View");
        break;
      case "Landingpageadmin":
        selectedIcon = IconService.getIconWithTitle("Content Settings");
        break;
      case "Language Scandinavian":
        selectedIcon = IconService.getIconWithTitle("Flag");
        break;
      case "Language UK":
        selectedIcon = IconService.getIconWithTitle("Flag");
        break;
      case "Like":
        selectedIcon = IconService.getIconWithTitle("Thumb Like");
        break;
      case "Like filled":
        selectedIcon = IconService.getIconWithTitle("Thumb Like");
        selectedIcon.type = "filled"
        break;
      case "Link2":
        selectedIcon = IconService.getIconWithTitle("Link");
        break;
      case "LinkBroken":
        selectedIcon = IconService.getIconWithTitle("Link Dismiss");
        break;
      case "LinkExternal":
        selectedIcon = IconService.getIconWithTitle("Link");
        break;
      case "LocationFilled":
        selectedIcon = IconService.getIconWithTitle("Location");
        selectedIcon.type = "filled";
        break;
      case "Lock":
        selectedIcon = IconService.getIconWithTitle("Lock Closed");
        break;
      case "LogOut":
        selectedIcon = IconService.getIconWithTitle("Door Arrow Left");
        break;
      case "Mailbox":
        selectedIcon = IconService.getIconWithTitle("Mail Inbox");
        break;
      case "Maintenance":
        selectedIcon = IconService.getIconWithTitle("Window Dev Tools");
        break;
      case "Meeting Room":
        selectedIcon = IconService.getIconWithTitle("People Audience");
        break;
      case "Meeting Room 2":
        selectedIcon = IconService.getIconWithTitle("People Audience");
        break;
      case "Money02":
        selectedIcon = IconService.getIconWithTitle("Currency Dollar Euro");
        break;
      case "Monitor":
        selectedIcon = IconService.getIconWithTitle("TV");
        break;
      case "More":
        selectedIcon = IconService.getIconWithTitle("More Horizontal");
        break;
      case "Move":
        selectedIcon = IconService.getIconWithTitle("Arrow Move");
        break;
      case "Music":
        selectedIcon = IconService.getIconWithTitle("Music Note 2");
        break;
      case "Museum":
        selectedIcon = IconService.getIconWithTitle("Building Bank");
        break;
      case "Museum":
        selectedIcon = IconService.getIconWithTitle("Building Bank");
        break;
      case "Notification":
        selectedIcon = IconService.getIconWithTitle("Alert");
        break;
      case "Org assets folder":
        selectedIcon = IconService.getIconWithTitle("Folder");
        break;
      case "Paragraph":
        selectedIcon = IconService.getIconWithTitle("Text Paragraph");
        break;
      case "Pdf":
        selectedIcon = IconService.getIconWithTitle("Document PDF");
        break;
      case "People fluent":
        selectedIcon = IconService.getIconWithTitle("People");
        break;
      case "People group":
        selectedIcon = IconService.getIconWithTitle("People Community");
        break;
      case "People list":
        selectedIcon = IconService.getIconWithTitle("People List");
        break;
      case "People team":
        selectedIcon = IconService.getIconWithTitle("People Team");
        break;
      case "Phone":
        selectedIcon = IconService.getIconWithTitle("Call");
        break;
      case "Picture":
        selectedIcon = IconService.getIconWithTitle("Image");
        break;
      case "PictureNew":
        selectedIcon = IconService.getIconWithTitle("Image");
        break;
      case "Pie Chart":
        selectedIcon = IconService.getIconWithTitle("Chart Multiple");
        break;
      case "Pizza":
        selectedIcon = IconService.getIconWithTitle("Food Pizza");
        break;
      case "Playlist":
        selectedIcon = IconService.getIconWithTitle("Slide Multiple");
        break;
      case "Plus":
        selectedIcon = IconService.getIconWithTitle("Add Circle");
        break;
      case "Podcast":
        selectedIcon = IconService.getIconWithTitle("Slide Microphone");
        break;
      case "Post":
        selectedIcon = IconService.getIconWithTitle("Send");
        break;
      case "Preview":
        selectedIcon = IconService.getIconWithTitle("Eye");
        break;
      case "Published":
        selectedIcon = IconService.getIconWithTitle("Book Open");
        break;
      case "Puls":
        selectedIcon = IconService.getIconWithTitle("Sound Wave Circle");
        break;
      case "PushNotification":
        selectedIcon = IconService.getIconWithTitle("Dual Screen Closed Alert");
        break;
      case "Quote":
        selectedIcon = IconService.getIconWithTitle("Text Quote");
        break;
      case "Rating":
        selectedIcon = IconService.getIconWithTitle("Star");
        break;
      case "Refresh":
        selectedIcon = IconService.getIconWithTitle("Arrow Sync");
        break;
      case "Reorder":
        selectedIcon = IconService.getIconWithTitle("Arrow Sort");
        break;
      case "Restart":
        selectedIcon = IconService.getIconWithTitle("Arrow Sync");
        break;
      case "Save As":
        selectedIcon = IconService.getIconWithTitle("Save");
        break;
      case "Search Photo":
        selectedIcon = IconService.getIconWithTitle("Image Search");
        break;
      case "SendFile":
        selectedIcon = IconService.getIconWithTitle("Document Arrow Right");
        break;
      case "Services":
        selectedIcon = IconService.getIconWithTitle("Settings");
        break;
      case "Share":
        selectedIcon = IconService.getIconWithTitle("Mail");
        break;
      case "Share02":
        selectedIcon = IconService.getIconWithTitle("Share");
        break;
      case "Shield":
        selectedIcon = IconService.getIconWithTitle("Shield Dismiss");
        break;
      case "Shopping Cart":
        selectedIcon = IconService.getIconWithTitle("Cart");
        break;
      case "Show":
        selectedIcon = IconService.getIconWithTitle("Eye");
        break;
      case "Show Action":
        selectedIcon = IconService.getIconWithTitle("More Vertical");
        break;
      case "Slide":
        selectedIcon = IconService.getIconWithTitle("Rectangle Landscape");
        break;
      case "Slides":
        selectedIcon = IconService.getIconWithTitle("Slide Multiple");
        break;
      case "Sorting AZ":
        selectedIcon = IconService.getIconWithTitle("Text Sort Ascending");
        break;
      case "Sorting ZA":
        selectedIcon = IconService.getIconWithTitle("Text Sort Descending");
        break;
      case "Sorting list down":
        selectedIcon = IconService.getIconWithTitle("Arrow Sort Down Lines");
        break;
      case "Spinner":
        selectedIcon = IconService.getIconWithTitle("Spinner iOS");
        break;
      case "Split view":
        selectedIcon = IconService.getIconWithTitle("Carousel View");
        break;
      case "Snowflake":
        selectedIcon = IconService.getIconWithTitle("Weather Snowflake");
        break;
      case "Star filled":
        selectedIcon = IconService.getIconWithTitle("Star");
        selectedIcon.type = "filled";
        break;
      case "Strategy":
        selectedIcon = IconService.getIconWithTitle("Chess");
        break;
      case "Sun":
        selectedIcon = IconService.getIconWithTitle("Weather Sunny");
        break;
      case "Support":
        selectedIcon = IconService.getIconWithTitle("Wrench");
        break;
      case "Synchronize":
        selectedIcon = IconService.getIconWithTitle("Arrow Sync");
        break;
      case "Tags":
        selectedIcon = IconService.getIconWithTitle("Tag Multiple");
        break;
      case "Template":
        selectedIcon = IconService.getIconWithTitle("Page Fit");
        break;
      case "TextAlignCenter":
        selectedIcon = IconService.getIconWithTitle("Text Align Center");
        break;
      case "TextAlignLeft":
        selectedIcon = IconService.getIconWithTitle("Text Align Left");
        break;
      case "TextAlignRight":
        selectedIcon = IconService.getIconWithTitle("Text Align Right");
        break;
      case "Thumbnail list":
        selectedIcon = IconService.getIconWithTitle("Thumbnail View");
        break;
      case "TilesView":
        selectedIcon = IconService.getIconWithTitle("Tiles View");
        break;
      case "Topic":
        selectedIcon = IconService.getIconWithTitle("Chat");
        break;
      case "TopicPoll":
        selectedIcon = IconService.getIconWithTitle("Poll");
        break;
      case "TopicPopular":
        selectedIcon = IconService.getIconWithTitle("Star");
        break;
      case "Train":
        selectedIcon = IconService.getIconWithTitle("Vehicle Bus");
        break;
      case "Trash":
        selectedIcon = IconService.getIconWithTitle("Delete");
        break;
      case "Travel Card":
        selectedIcon = IconService.getIconWithTitle("Briefcase");
        break;
      case "Unpin":
        selectedIcon = IconService.getIconWithTitle("Pin Off");
        break;
      case "Underline":
        selectedIcon = IconService.getIconWithTitle("Text Baseline");
        break;
      case "Upload":
        selectedIcon = IconService.getIconWithTitle("Cloud Arrow Up");
        break;
      case "Upload02":
        selectedIcon = IconService.getIconWithTitle("Cloud Arrow Up");
        break;
      case "Unlock":
        selectedIcon = IconService.getIconWithTitle("Lock Open");
        break;
      case "User Manual":
        selectedIcon = IconService.getIconWithTitle("Book Information");
        break;
      case "Versions":
        selectedIcon = IconService.getIconWithTitle("Document Copy");
        break;
      case "YouTubePlayButton":
        selectedIcon = IconService.getIconWithTitle("Play Circle");
        break;
      case "Video":
        selectedIcon = IconService.getIconWithTitle("Filmstrip");
        break;
      case "Video02":
        selectedIcon = IconService.getIconWithTitle("Video");
        break;
      case "VideoNew":
        selectedIcon = IconService.getIconWithTitle("Play Circle");
        break;
      case "Volum":
        selectedIcon = IconService.getIconWithTitle("Speaker 2");
        break;
      case "WeatherSunny":
        selectedIcon = IconService.getIconWithTitle("Weather Sunny");
        break;
      case "WeatherCloudy":
        selectedIcon = IconService.getIconWithTitle("Weather Cloudy");
        break;
      case "WeatherPartlyCloudy":
        selectedIcon = IconService.getIconWithTitle("Weather Partly Cloudy Day");
        break;
      case "WeatherMostyCloudyShowers":
        selectedIcon = IconService.getIconWithTitle("Weather Hail Day");
        break;
      case "WeatherRainy":
        selectedIcon = IconService.getIconWithTitle("Weather Rain");
        break;
      case "WeatherSnowy":
        selectedIcon = IconService.getIconWithTitle("Weather Snow Shower Day");
        break;
      case "whistle":
        selectedIcon = IconService.getIconWithTitle("Whistle");
        break;
      case "Wine":
        selectedIcon = IconService.getIconWithTitle("Drink Wine");
        break;
      case "Workstation":
        selectedIcon = IconService.getIconWithTitle("Phone Laptop");
        break;
      case "ZoomIn":
        selectedIcon = IconService.getIconWithTitle("Zoom In");
        break;
      case "ZoomOut":
        selectedIcon = IconService.getIconWithTitle("Zoom Out");
        break;
      default:
        selectedIcon = IconService.getIconWithTitle(icon);
    }
    return selectedIcon;
  }
}