import * as React from 'react';
import * as styles from "./IAAuthors.css";
import { IUser } from '../../../interfaces/IUser';
import { IAPersonaSmall } from '../../personaSmall/PersonaSmall';

export interface IAuthorsProps {
  authors: IUser[];
  date: string;
  highlightColor: string;
  style?: React.CSSProperties;
}
export default class IAAuthors extends React.Component<IAuthorsProps> {

  constructor(props: IAuthorsProps) {
    super(props);
  }

  render() {
    let additionalAuthors = [];
    if (this.props.authors != undefined && this.props.authors.length > 1) {
      for (let i = 1; i < this.props.authors.length; i++) {
        additionalAuthors.push(
          <IAPersonaSmall
            key={`additionalAuthors_${i}`}
            profileImage={this.props.authors[i]?.image}
            name={this.props.authors[i]?.displayName}
            backgroundColor={this.props.highlightColor}
            styles={{
              width: "calc(100% - 50px)",
              float: "left"
            }}
            userLoginName={this.props.authors[i]?.userPrincipalName}
          />
        )
      }
    }
    return (
      <div
        className={styles.IA_authors}
        style={
          this.props.style
        }
      >
        {this.props.authors != undefined && this.props.authors.length > 0 && this.props.authors[0] != undefined &&
          <IAPersonaSmall
            profileImage={this.props.authors[0].image}
            name={this.props.authors[0].displayName}
            metadata={this.props.date}
            backgroundColor={this.props.highlightColor}
            styles={{
              width: "calc(100% - 50px)",
              minWidth: 170,
              float: "left"
            }}
            userLoginName={this.props.authors[0]?.userPrincipalName}
          />
        }
        {additionalAuthors.length > 0 &&
          <div className={styles.IA_additionalAuthors}>
            {additionalAuthors}
          </div>
        }
      </div>
    );
  }
}