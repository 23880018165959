exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_listElementHeader_1CJf1TZT4QyHW3cRbM9kD1 {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  overflow: hidden;\n  font-size: 12px;\n  padding-left: 15px;\n  box-sizing: border-box;\n}\n\n.IA_previewColumn_1gTfo5J85wCaVnIg8RSZw0 {\n  flex: 0.2;\n}\n\n.IA_iconColumn_3lg3hIWokG_GQiGR0KPT-p {\n  flex: 0.4;\n  padding-right: 10px;\n  box-sizing: border-box;\n}\n\n.IA_column_3HCcIhhsQTkGirm9IBr_3n {\n  flex: 1;\n}\n\n.IA_buttons_3SHjz7Z9HII9mrSvbv4j67 {\n  padding-right: 10px;\n}", ""]);

// Exports
exports.locals = {
	"IA_listElementHeader": "IA_listElementHeader_1CJf1TZT4QyHW3cRbM9kD1",
	"IA_previewColumn": "IA_previewColumn_1gTfo5J85wCaVnIg8RSZw0",
	"IA_iconColumn": "IA_iconColumn_3lg3hIWokG_GQiGR0KPT-p",
	"IA_column": "IA_column_3HCcIhhsQTkGirm9IBr_3n",
	"IA_buttons": "IA_buttons_3SHjz7Z9HII9mrSvbv4j67"
};