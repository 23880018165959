import * as React from 'react';
import * as styles from "./NavigationBar.css";
import { IAIcon } from '../../icon/Icon';
import { IUser } from '../../../interfaces/IUser';
import { IAComments } from '../../comments/Comments';
import { IALikes } from '../../likes/Likes';
import { ILike } from '../../../interfaces/ILike';
import { ILanguage } from './../../../interfaces/ILanguage';
import { IADropDownIcon } from './../../dropDownIcon/DropDownIcon';
import { IAMessageBar } from './../../messageBar/MessageBar';
import { IInstance } from '../../../interfaces/IInstance';
import { LocalizationService } from '../../../services/LocalizationService';
import { IAUniqueViews } from '../../uniqueViews/UniqueViews';
import { IATooltip } from '../../tooltip/Tooltip';
import "../../../styles/BasicIA.css";
import { IApprover } from "./../../../interfaces/IApprover";
import { ENTER_KEY, SPACEBAR_KEY } from '../../../global/Constants';
import { Environment } from '../../../services/Environment';
import { Helper } from '../../../Helper';

export interface INavigationBarProps {
  backgroundColor?: string;
  highlightColor?: string;
  navigatorTitle?: string;
  navigatorTitleComponent?: JSX.Element;
  navigationsBarContent?: JSX.Element;
  user?: IUser;
  itemId?: string;
  isDraft?: boolean;
  tenant?: string;
  instance?: IInstance;
  token?: string;
  component?: string;
  environment?: "Development" | "Test" | "Production";
  source?: string;
  isMobile?: boolean;
  commentsEnabled?: boolean;
  likesEnabled?: boolean;
  enableNext?: boolean;
  enablePrevious?: boolean;
  likes?: ILike[];
  commentCount?: number;
  style?: React.CSSProperties;
  disableSaving?: boolean;
  isUpdating?: boolean;
  position?: "relative" | "absolute";
  darkMode?: boolean;
  close?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onCancel?: () => void;
  onSaveAndClose?: () => void;
  onAddAndClose?: () => void;
  onSaveAsDraft?: () => void;
  onReviewReject?: () => void;
  onReviewApprove?: () => void;
  onReviewSave?: () => void;
  previousMessage?: () => void;
  nextMessage?: () => void;
  onClearFilters?: () => void;
  onCopyLink?: () => void;
  onEmailLink?: () => void;
  likesUpdated?: (likes: ILike[]) => void;
  commentsUpdated?: (comments: number) => void;
  commentsClicked?: () => void;
  onTranslate?: (language: string) => void;
  onExportToCalender?: string;
  languages?: ILanguage[];
  enableTranslation?: boolean;
  isTranslating?: boolean;
  dataAutomationIdPrefix?: string;
  showUniqueViews?: boolean;
  uniqueViewsCount?: number;
  isOuterPanel?: boolean;
  approvers?: IApprover[];
  enablePrint?: boolean;
  onPrint?: () => void;
  isContentCreator?: boolean;
}

export interface INavigationBarState {
  likes?: ILike[];
  commentCount?: number;
  linkCopied: boolean;
  languages: ILanguage[];
  selectedLanguages: { [id: string]: string };
  isTranslating: boolean;
}

export default class NavigationBar extends React.Component<INavigationBarProps, INavigationBarState> {

  private localizationService = new LocalizationService();

  constructor(props: INavigationBarProps) {
    super(props);
    this.state = {
      likes: props.likes,
      commentCount: props.commentCount,
      linkCopied: false,
      languages: props.languages,
      selectedLanguages: {},
      isTranslating: props.isTranslating,
    }
  }

  public componentWillReceiveProps(props: INavigationBarProps): void {
    if (props.likes != this.props.likes) {
      this.setState({ likes: props.likes }, () => this.forceUpdate());
    }
    if (props.commentCount != this.props.commentCount) {
      this.setState({ commentCount: props.commentCount }, () => this.forceUpdate());
    }
    if (props.enableTranslation != this.props.enableTranslation) {
      this.forceUpdate();
    }
    if (props.isTranslating != this.props.isTranslating) {
      this.setState({ isTranslating: props.isTranslating }, () => this.forceUpdate());
    }
    this.setState({ linkCopied: false });
  }

  render() {
    let backgroundColor = this.props.darkMode ? Helper.darkModeBackgroundColor : "#ffffff";
    if (this.props.backgroundColor) backgroundColor = this.props.backgroundColor;
    return (
      <div
        className="IA_panelHeader"
        style={{
          position: this.props.position ? this.props.position : "absolute",
          top: 0,
          width: "100%",
        }}>
        <div
          className={this.props.isOuterPanel ? styles.IA_topnav_outer : styles.IA_topnav}
          style={{
            backgroundColor: backgroundColor,
            borderColor: this.props.darkMode ? "#727272" : "#eaeaea",
            ...this.props.style
          }}
        >
          {!!this.props.navigatorTitleComponent && this.props.navigatorTitleComponent}
          {this.props.navigatorTitle != undefined &&
            <div className={styles.IA_navigatorTitle}>
              {this.props.navigatorTitle}
            </div>
          }
          {this.props.previousMessage != undefined && !this.props.isContentCreator &&
            <div
              className={styles.IA_item}
              style={{
                float: "left",
                marginLeft: 0,
                opacity: this.props.isUpdating != undefined && this.props.isUpdating ? 0.5 : 1
              }}
            >
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Arrow%20Left/SVG/ic_fluent_arrow_left_48_regular.svg"
                size={18}
                color={this.props.enablePrevious ? this.props.highlightColor : "lightGray"}
                dataAutomationId={`${this.props.dataAutomationIdPrefix}-forward`}
                style={{
                  cursor: this.props.enablePrevious ? 'pointer' : 'default',
                  paddingTop: 7
                }}
                onClick={this.props.isUpdating != undefined && this.props.isUpdating || !this.props.enablePrevious ? () => { } : () => {
                  this.props.previousMessage();
                }}
              />
            </div>
          }
          {this.props.nextMessage != undefined && !this.props.isContentCreator &&
            <div
              className={styles.IA_item}
              style={{
                float: "left",
              }}
            >
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Arrow%20Right/SVG/ic_fluent_arrow_right_48_regular.svg"
                size={18}
                dataAutomationId={`${this.props.dataAutomationIdPrefix}-next`}
                color={this.props.enableNext ? this.props.highlightColor : "lightGray"}
                style={{
                  cursor: this.props.enableNext ? 'pointer' : 'default',
                  paddingTop: 7,
                  opacity: this.props.isUpdating != undefined && this.props.isUpdating ? 0.5 : 1
                }}
                onClick={this.props.isUpdating != undefined && this.props.isUpdating || !this.props.enableNext ? () => { } : () => {
                  this.props.nextMessage();
                }}
              />
            </div>
          }
          {this.props.close != undefined &&
            <div className={styles.IA_item}>
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Dismiss/SVG/ic_fluent_dismiss_48_regular.svg"
                size={18}
                color="#777777"
                dataAutomationId={`${this.props.dataAutomationIdPrefix}-close`}
                onClick={this.props.isUpdating != undefined && this.props.isUpdating ? () => { } : () => {
                  this.setState({ 
                    selectedLanguages: {}
                  }, () => {
                    this.props.close();
                  });
                }}
                style={{
                  paddingTop: 7,
                  opacity: this.props.isUpdating != undefined && this.props.isUpdating ? 0.5 : 1
                }}
              />
            </div>
          }
          {this.props.navigationsBarContent}

          {this.props.onClearFilters != undefined &&
            <div
              className={styles.IA_item}
              data-automation-id={`${this.props.dataAutomationIdPrefix}-clear-filters`}
              style={{
                opacity: this.props.isUpdating != undefined && this.props.isUpdating ? 0.5 : 1
              }}
            >
              <IATooltip
                showBelow={true}
                content="Tooltip"
                type="bubble"
                styles={{
                  color: this.props.highlightColor,
                }}
                componentContent={
                  <div>
                    {this.localizationService.strings.Panel_NavigatorBar_ClearFilter}
                  </div>
                }
              >
                <IAIcon
                  url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Filter%20Dismiss/SVG/ic_fluent_filter_dismiss_24_regular.svg"
                  onClick={() => {
                    this.setState({
                      selectedLanguages: { ...this.state.selectedLanguages, [this.props.itemId]: undefined}
                    }, () => {
                      this.props.onClearFilters();
                    });
                  }}
                  size={18}
                  color={this.props.highlightColor}
                  style={{
                    cursor: 'pointer',
                    width: 17,
                    float: "left",
                    paddingTop: 7,
                    marginRight: 7
                  }}
                />
              </IATooltip>
            </div>
          }

          {this.props.onEdit != undefined &&
            <div
              className={styles.IA_item}
              data-automation-id={`${this.props.dataAutomationIdPrefix}-edit`}
              style={{
                opacity: this.props.isUpdating != undefined && this.props.isUpdating ? 0.5 : 1
              }}
            >
              <IATooltip
                showBelow={true}
                content="Tooltip"
                type="bubble"
                styles={{
                  color: this.props.highlightColor,
                }}
                componentContent={
                  <div>
                    {this.localizationService.strings.Panel_NavigatorBar_Edit}
                  </div>
                }
              >
                <IAIcon
                  url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Edit/SVG/ic_fluent_edit_48_regular.svg"
                  size={18}
                  color={this.props.highlightColor}
                  style={{
                    cursor: 'pointer',
                    width: 17,
                    float: "left",
                    paddingTop: 7
                  }}
                  onClick={this.props.isUpdating != undefined && this.props.isUpdating ? () => { } : () => {
                    this.setState({
                      selectedLanguages: { ...this.state.selectedLanguages, [this.props.itemId]: undefined}
                    }, () => {
                      this.props.onEdit();
                    });
                  }}
                />
              </IATooltip>
            </div>
          }

          {this.props.onCancel != undefined &&
            <div
              className={styles.IA_item}
              data-automation-id={`${this.props.dataAutomationIdPrefix}-cancel`}
              style={{
                opacity: this.props.isUpdating != undefined && this.props.isUpdating ? 0.5 : 1
              }}
            >
              <IATooltip
                showBelow={true}
                content="Tooltip"
                type="bubble"
                styles={{
                  color: this.props.highlightColor,
                }}
                componentContent={
                  <div>
                    {this.localizationService.strings.Panel_NavigatorBar_Cancel}
                  </div>
                }
              >
                <IAIcon
                  url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Edit%20Arrow%20Back/SVG/ic_fluent_edit_arrow_back_20_regular.svg"
                  size={18}
                  color={this.props.highlightColor}
                  style={{
                    width: 17,
                    float: "left",
                    paddingTop: 7
                  }}
                  onClick={this.props.isUpdating != undefined && this.props.isUpdating ? () => { } : () =>
                    this.props.onCancel()
                  }
                />
              </IATooltip>
            </div>
          }

          {this.props.onSaveAndClose != undefined && this.props.onReviewApprove == undefined && !this.props.isMobile && this.props.component !== "ContentOrganizer" &&
            <div
              className={[styles.IA_item, "IA_OnFocus"].join(" ")}
              tabIndex={0}
              data-automation-id={`${this.props.dataAutomationIdPrefix}-save-and-close`}
              onClick={
                this.props.isUpdating != undefined && this.props.isUpdating || this.props.disableSaving != undefined && this.props.disableSaving ? () => { } : () => {
                  this.props.onSaveAndClose();
                }}
              onKeyDown={(event) => {
                if ((event.key === ENTER_KEY || event.key === SPACEBAR_KEY) && !this.props.isUpdating && !this.props.disableSaving) {
                  this.props.onSaveAndClose();
                }
              }}
              style={{
                cursor: "pointer",
                opacity: this.props.isUpdating != undefined && this.props.isUpdating || this.props.disableSaving != undefined && this.props.disableSaving ? 0.5 : 1
              }}
            >
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/News/SVG/ic_fluent_news_28_regular.svg"
                size={18}
                color={this.props.highlightColor}
                style={{
                  width: 17,
                  float: "left",
                  paddingTop: 7,
                  paddingRight: 2
                }}
              />
              <div
                style={{
                  float: "left",
                  paddingTop: 10,
                  color: this.props.highlightColor
                }}
              >
                {this.publishButtonLabel()}
              </div>
            </div>
          }

          {this.props.onAddAndClose != undefined &&
            <div
              className={[styles.IA_item, "IA_OnFocus"].join(" ")}
              tabIndex={0} data-automation-id={`${this.props.dataAutomationIdPrefix}-add-and-close`}
              onClick={this.props.isUpdating != undefined && this.props.isUpdating || this.props.disableSaving != undefined && this.props.disableSaving ? () => { } : () => this.props.onAddAndClose()}
              onKeyDown={(event) => {
                if ((event.key === ENTER_KEY || event.key === SPACEBAR_KEY) && !this.props.isUpdating && !this.props.disableSaving) {
                  this.props.onAddAndClose();
                }
              }}
              style={{
                cursor: "pointer",
                opacity: this.props.isUpdating != undefined && this.props.isUpdating || this.props.disableSaving != undefined && this.props.disableSaving ? 0.5 : 1
              }}
            >
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Save/SVG/ic_fluent_save_28_regular.svg"
                size={18}
                color={this.props.highlightColor}
                style={{
                  width: 17,
                  float: "left",
                  paddingTop: 7
                }}
              />
              <div
                style={{
                  float: "left",
                  paddingTop: 8,
                  color: this.props.highlightColor
                }}
              >
                {this.localizationService.strings.Panel_NavigatorBar_Save}
              </div>
            </div>
          }
          {this.props.onSaveAsDraft != undefined && !this.props.isMobile && this.props.component !== "ContentOrganizer" &&
            <div
              className={[styles.IA_item, "IA_OnFocus"].join(" ")}
              tabIndex={0}
              data-automation-id={`${this.props.dataAutomationIdPrefix}-save-and-close`}
              onClick={this.props.isUpdating != undefined && this.props.isUpdating || this.props.disableSaving != undefined && this.props.disableSaving ? () => { } : () => this.props.onSaveAsDraft()}
              onKeyDown={(event) => {
                if ((event.key === ENTER_KEY || event.key === SPACEBAR_KEY) && !this.props.isUpdating && !this.props.disableSaving) {
                  this.props.onSaveAsDraft();
                }
              }}
              style={{
                cursor: "pointer",
                opacity: this.props.isUpdating != undefined && this.props.isUpdating || this.props.disableSaving != undefined && this.props.disableSaving ? 0.5 : 1
              }}
            >
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Save/SVG/ic_fluent_save_28_regular.svg"
                size={18}
                color={this.props.highlightColor}
                style={{
                  width: 17,
                  float: "left",
                  paddingTop: 7
                }}
              />
              <div
                style={{
                  float: "left",
                  paddingTop: 8,
                  color: this.props.highlightColor
                }}
              >
                {this.props.itemId == undefined ? this.localizationService.strings.Panel_NavigatorBar_SaveAsDraft : (this.props.isDraft ? this.localizationService.strings.Panel_NavigatorBar_SaveAsDraft : this.localizationService.strings.Panel_NavigatorBar_SaveAndUnpublish)}
              </div>
            </div>
          }

          {this.props.onReviewApprove != undefined &&
            <div
              className={[styles.IA_item, "IA_OnFocus"].join(" ")}
              tabIndex={0}
              data-automation-id={`${this.props.dataAutomationIdPrefix}-review-approve`}
              onClick={this.props.isUpdating != undefined && this.props.isUpdating || this.props.disableSaving != undefined && this.props.disableSaving ? () => { } : () => this.props.onReviewApprove()}
              onKeyDown={(event) => {
                if ((event.key === ENTER_KEY || event.key === SPACEBAR_KEY) && !this.props.isUpdating && !this.props.disableSaving) {
                  this.props.onReviewApprove();
                }
              }}
              style={{
                cursor: "pointer",
                opacity: this.props.isUpdating != undefined && this.props.isUpdating || this.props.disableSaving != undefined && this.props.disableSaving ? 0.5 : 1
              }}
            >
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/News/SVG/ic_fluent_news_28_regular.svg"
                size={18}
                color={this.props.highlightColor}
                style={{
                  width: 17,
                  float: "left",
                  paddingTop: 7,
                  paddingRight: 2
                }}
              />
              <div
                style={{
                  float: "left",
                  paddingTop: 8,
                  color: this.props.highlightColor
                }}
              >
                {this.localizationService.strings.Panel_NavigatorBar_ReviewApprove}
              </div>
            </div>
          }

          {this.props.onReviewReject != undefined &&
            <div
              className={[styles.IA_item, "IA_OnFocus"].join(" ")}
              tabIndex={0}
              data-automation-id={`${this.props.dataAutomationIdPrefix}-review-reject`}
              onClick={this.props.isUpdating != undefined && this.props.isUpdating || this.props.disableSaving != undefined && this.props.disableSaving ? () => { } : () => this.props.onReviewReject()}
              onKeyDown={(event) => {
                if ((event.key === ENTER_KEY || event.key === SPACEBAR_KEY) && !this.props.isUpdating && !this.props.disableSaving) {
                  this.props.onReviewReject();
                }
              }}
              style={{
                cursor: "pointer",
                opacity: this.props.isUpdating != undefined && this.props.isUpdating || this.props.disableSaving != undefined && this.props.disableSaving ? 0.5 : 1
              }}
            >
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Dismiss/SVG/ic_fluent_dismiss_48_regular.svg"
                size={18}
                color={this.props.highlightColor}
                style={{
                  width: 17,
                  float: "left",
                  paddingTop: 7
                }}
              />
              <div
                style={{
                  float: "left",
                  paddingTop: 8,
                  color: this.props.highlightColor
                }}
              >
                {this.localizationService.strings.Panel_NavigatorBar_ReviewReject}
              </div>
            </div>
          }

          {this.props.onReviewSave != undefined &&
            <div
              className={[styles.IA_item, "IA_OnFocus"].join(" ")}
              tabIndex={0}
              data-automation-id={`${this.props.dataAutomationIdPrefix}-review-save`}
              onClick={this.props.isUpdating != undefined && this.props.isUpdating || this.props.disableSaving != undefined && this.props.disableSaving ? () => { } : () => this.props.onReviewSave()}
              onKeyDown={(event) => {
                if ((event.key === ENTER_KEY || event.key === SPACEBAR_KEY) && !this.props.isUpdating && !this.props.disableSaving) {
                  this.props.onReviewSave();
                }
              }}
              style={{
                cursor: "pointer",
                opacity: this.props.isUpdating != undefined && this.props.isUpdating || this.props.disableSaving != undefined && this.props.disableSaving ? 0.5 : 1
              }}
            >
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Save/SVG/ic_fluent_save_28_regular.svg"
                size={18}
                color={this.props.highlightColor}
                style={{
                  width: 17,
                  float: "left",
                  paddingTop: 7
                }}
              />
              <div
                style={{
                  float: "left",
                  paddingTop: 8,
                  color: this.props.highlightColor
                }}
              >
                {this.localizationService.strings.Panel_NavigatorBar_Save}
              </div>
            </div>
          }

          {this.props.showUniqueViews &&
            <div
              className={styles.IA_item}
              style={{ marginLeft: 0 }}
            >
              <IAUniqueViews
                style={{ marginLeft: 10, marginTop: 8 }}
                color={this.props.highlightColor}
                itemId={this.props.itemId}
                user={this.props.user}
                tenant={this.props.tenant}
                instance={this.props.instance.instanceName}
                token={this.props.token}
                environment={this.props.environment}
                source={this.props.source}
                uniqueViews={this.props.uniqueViewsCount}
              />
            </div>
          }
          {this.props.commentsEnabled && this.props.itemId != undefined && !this.props.isContentCreator && this.props.instance &&
            <div className={styles.IA_item}>
              <IAComments
                color={this.props.highlightColor}
                itemId={this.props.itemId}
                user={this.props.user}
                tenant={this.props.tenant}
                component={this.props.component === "Messages" ? "Message" : "Event"}
                instance={this.props.instance?.instanceName}
                token={this.props.token}
                environment={this.props.environment}
                commentCount={this.state.commentCount}
                dataAutomationId={`${this.props.dataAutomationIdPrefix}-comment`}
                commentsUpdated={(comments: number) => this.props.commentsUpdated(comments)}
                commentsClick={
                  () => {
                    if (this.props.commentsClicked) {
                      this.props.commentsClicked();
                    }
                  }
                }
              />
            </div>
          }
          {this.props.likesEnabled && this.props.itemId != undefined && !this.props.isContentCreator && this.props.instance &&
            <div className={styles.IA_item}>
              <IALikes
                color={this.props.highlightColor}
                itemId={this.props.itemId}
                user={this.props.user}
                tenant={this.props.tenant}
                component={this.props.component === "Messages" ? "Message" : "Event"}
                instance={this.props.instance?.instanceName}
                token={this.props.token}
                environment={this.props.environment}
                source={this.props.source}
                likes={this.state.likes}
                likesUpdated={(likes: ILike[]) => this.props.likesUpdated(likes)}
                dataAutomationId={`${this.props.dataAutomationIdPrefix}-like`}
              />
            </div>
          }
          {this.props.enableTranslation && !this.props.isContentCreator &&
            <div
              className={styles.IA_item}
              style={{
                cursor: "pointer",
                opacity: this.props.isUpdating != undefined && this.props.isUpdating ? 0.5 : 1
              }}
            >
              {this.props.isMobile ?
                <IADropDownIcon
                  zIndex={10000000}
                  iconUrl='https://intraactivestorage.blob.core.windows.net/cdn/icons/Translate/SVG/ic_fluent_translate_24_regular.svg'
                  iconSize={18}
                  isLoading={this.state.isTranslating}
                  label=""
                  selectedOption={this.state.selectedLanguages[this.props.itemId]}
                  options={this.state.languages.map((language) => {
                    return { key: language.id, value: language.title }
                  })}
                  onChange={(selectedKey: string) => {
                    this.setState({
                      selectedLanguages: { ...this.state.selectedLanguages, [this.props.itemId]: selectedKey}
                    }, () => {
                      this.props.onTranslate(selectedKey);
                    });

                  }}
                  color={this.props.highlightColor}
                  styles={{
                    cursor: 'pointer',
                    float: "left",
                    paddingTop: 8
                  }}
                />
                :
                <IATooltip
                  showBelow={true}
                  content="Tooltip"
                  type="bubble"
                  styles={{
                    color: this.props.highlightColor,
                  }}
                  componentContent={
                    <div>
                      {this.localizationService.strings.Panel_NavigatorBar_Translate}
                    </div>
                  }
                >
                  <IADropDownIcon
                    zIndex={10000000}
                    iconUrl="https://intraactivestorage.blob.core.windows.net/cdn/icons/Translate/SVG/ic_fluent_translate_24_regular.svg"
                    iconSize={18}
                    isLoading={this.state.isTranslating}
                    label=""
                    selectedOption={this.state.selectedLanguages[this.props.itemId]}
                    options={this.state.languages.map((language) => {
                      return { key: language.id, value: language.title }
                    })}
                    onChange={(selectedKey: string) => {
                      this.setState({
                        selectedLanguages: { ...this.state.selectedLanguages, [this.props.itemId]: selectedKey}
                      }, () => {
                        this.props.onTranslate(selectedKey);
                      });

                    }}
                    color={this.props.highlightColor}
                    styles={{
                      cursor: 'pointer',
                      float: "left",
                      paddingTop: 8
                    }}
                  />
                </IATooltip>
              }
            </div>
          }
          {this.props.onExportToCalender != undefined &&
            <a href={this.props.onExportToCalender} target="_blank">
              <div
                className={styles.IA_item}
                style={{
                  cursor: 'pointer',
                  opacity: this.props.isUpdating != undefined && this.props.isUpdating ? 0.5 : 1,
                }}
              >
                {this.props.isMobile ?
                  <IAIcon
                    url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Arrow%20Download/SVG/ic_fluent_arrow_download_48_regular.svg"
                    size={18}
                    color={this.props.highlightColor}
                    style={{ paddingTop: 7 }}
                  />
                  :
                  <IATooltip
                    showBelow={true}
                    content="Tooltip"
                    type="bubble"
                    styles={{
                      color: this.props.highlightColor,
                    }}
                    componentContent={
                      <div>
                        {this.localizationService.strings.Panel_NavigatorBar_ExportToCalender}
                      </div>
                    }
                  >
                    <IAIcon
                      url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Arrow%20Download/SVG/ic_fluent_arrow_download_48_regular.svg"
                      size={22}
                      color={this.props.highlightColor}
                      style={{ paddingTop: 7 }}
                    />
                  </IATooltip>
                }
              </div>
            </a>
          }
          {this.props.onEmailLink != undefined && !this.props.isMobile &&
            <div
              className={styles.IA_item}
              style={{
                opacity: this.props.isUpdating || this.props.disableSaving ? 0.5 : 1,
                marginRight: 8
              }}
            >
              <IATooltip
                showBelow={true}
                content="Tooltip"
                type="bubble"
                styles={{
                  color: this.props.highlightColor,
                }}
                componentContent={
                  <div>
                    {this.localizationService.strings.Panel_NavigatorBar_EmailLink}
                  </div>
                }
              >
                <IAIcon
                  url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Send/SVG/ic_fluent_send_28_regular.svg"
                  size={18}
                  color={this.props.highlightColor}
                  style={{
                    width: 17,
                    float: "left",
                    paddingTop: 7
                  }}
                  onClick={this.props.isUpdating || this.props.disableSaving ? () => { } : () => this.props.onEmailLink()}
                />
              </IATooltip>
            </div>
          }
          {this.props.onCopyLink != undefined && !this.state.linkCopied && !this.props.isMobile &&
            <div
              className={styles.IA_item}
              style={{
                opacity: this.props.isUpdating || this.props.disableSaving ? 0.5 : 1,
                marginRight: 8
              }}
            >
              <IATooltip
                showBelow={true}
                content="Tooltip"
                type="bubble"
                styles={{
                  color: this.props.highlightColor,
                }}
                componentContent={
                  <div>
                    {this.localizationService.strings.Panel_NavigatorBar_CopyLink}
                  </div>
                }
              >
                <IAIcon
                  url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Copy/SVG/ic_fluent_copy_24_regular.svg"
                  size={18}
                  color={this.props.highlightColor}
                  style={{
                    width: 17,
                    float: "left",
                    paddingTop: 7
                  }}
                  onClick={this.props.isUpdating || this.props.disableSaving ? () => { } : () => {
                    const navigator: any = window.navigator;
                    const url = window.location.href.split('?')[0];
                    let linkToMessage: string;
                    if (this.props.component === "Messages") {
                      if (this.props.instance?.settings?.openMessageLink) {
                        linkToMessage = `${Environment.getEnvironmentForLinkPortal(this.props.environment)}/${this.props.tenant}/${this.props.instance.instanceName}/${this.props.itemId}`;
                      } else {
                        linkToMessage = `${url}?instance=${this.props.instance.instanceName}&messageId=${this.props.itemId}`;
                      }
                    } else {
                      linkToMessage = `${url}?instance=${this.props.instance.instanceName}&eventId=${this.props.itemId}`;
                    }
                    navigator.clipboard.writeText(linkToMessage).then(() => {
                      this.props.onCopyLink();
                      this.setState({ linkCopied: true });
                    }, (err) => {
                      console.error('Async: Could not copy text: ', err);
                    });
                  }}
                />
              </IATooltip>
            </div>
          }
          {this.props.onCopyLink != undefined && this.state.linkCopied && !this.props.isMobile &&
            <div
              className={styles.IA_item}
              style={{
                paddingTop: 14.5,
                color: "#777777",
                marginRight: 8
              }}
            >
              {this.localizationService.strings.Panel_NavigatorBar_LinkCopied}
            </div>
          }
          {this.props.onDelete != undefined && !this.props.isMobile &&
            <div
              className={[styles.IA_item, "IA_OnFocus"].join(" ")}
              tabIndex={0}
              data-automation-id={`${this.props.dataAutomationIdPrefix}-delete`}
              onClick={this.props.isUpdating || this.props.disableSaving ? () => { } : () => this.props.onDelete()}
              onKeyDown={(event) => {
                if ((event.key === ENTER_KEY || event.key === SPACEBAR_KEY) && !this.props.isUpdating) {
                  this.props.onDelete();
                }
              }}
              style={{
                cursor: "pointer",
                opacity: this.props.isUpdating || this.props.disableSaving ? 0.5 : 1,
                marginRight: this.props.isMobile ? 10 : 0
              }}
            >
              <IAIcon
                url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Delete/SVG/ic_fluent_delete_48_regular.svg"
                size={18}
                color={this.props.highlightColor}
                style={{
                  width: 17,
                  float: "left",
                  paddingTop: 7
                }}
              />
              <div
                style={{
                  float: "left",
                  paddingTop: 10,
                  color: this.props.highlightColor
                }}
              >
                {this.localizationService.strings.Panel_NavigatorBar_Delete}
              </div>
            </div>
          }

          {this.props.enablePrint && this.props.onPrint && !this.props.isMobile &&
            <div
              className={styles.IA_item}
              data-automation-id={`${this.props.dataAutomationIdPrefix}-print`}
              style={{
                opacity: this.props.isUpdating ? 0.5 : 1,
                marginRight: 8
              }}
            >
              <IATooltip
                showBelow={true}
                content="Tooltip"
                type="bubble"
                styles={{
                  color: this.props.highlightColor,
                }}
                componentContent={
                  <div>
                    {this.localizationService.strings.Panel_NavigatorBar_Print}
                  </div>
                }
              >
                <IAIcon
                  url="https://intraactivestorage.blob.core.windows.net/cdn/icons/Print/SVG/ic_fluent_print_48_regular.svg"
                  size={18}
                  color={this.props.highlightColor}
                  style={{
                    width: 17,
                    float: "left",
                    paddingTop: 7
                  }}
                  onClick={this.props.isUpdating ? () => { } : () => this.props.onPrint()}
                />
              </IATooltip>
            </div>
          }
          {this.props.dataAutomationIdPrefix === "messages-edit-panel" && this.props.component !== "ContentOrganizer" && this.props.component !== "Event" && this.props.instance?.title &&
            <div className={styles.IA_instanceName}
              style={{
                marginLeft: this.props.isMobile ? 0 : 20,
                marginTop: this.props.isMobile ? 15 : 14
              }}>
              {this.props.instance.title?.toUpperCase()}
            </div>}
        </div>
        {!!this.state.selectedLanguages[this.props.itemId] && !this.state.isTranslating &&
          <div>
            <IAMessageBar
              style={{
                width: "100%"
              }}
              type="information"
              content={`${this.localizationService.strings.Panel_NavigatorBar_TranslatedMessage} ${this.getLanguageNamebyCode(this.state.selectedLanguages[this.props.itemId])}`}
              icon="Info"
              showIcon={true}
              showCloseButton={false}
              dismissContentMessage={this.localizationService.strings.Panel_NavigatorBar_DismissTranslation}
              onDismiss={() => {
                this.setState({
                  selectedLanguages: { ...this.state.selectedLanguages, [this.props.itemId]: undefined},
                  isTranslating: false
                }, () => {
                  this.props.onTranslate("");
                })
              }}
            />
          </div>}
        {/* {this.state.selectedLanguage && this.state.isTranslating && <div>
          <IASpinner />
        </div>} */}
      </div>
    );
  }

  private publishButtonLabel(): string {
    if (this.props.instance?.approvalEnabled) {
      if (this.props.user && this.props.instance.approvers) {
        let isApprover = this.props.approvers?.filter(i => !i.disabled && this.props.user && (i.userPrincipalName?.toLocaleLowerCase() === this.props.user.userPrincipalName?.toLocaleLowerCase() || (i.email && i.email?.toLocaleLowerCase() === this.props.user.email?.toLocaleLowerCase()))).length > 0;
        if (isApprover) {
          return this.localizationService.strings.Panel_NavigatorBar_SaveAndPublish;
        } else {
          return this.localizationService.strings.Panel_NavigatorBar_SubmitForApproval;
        }
      }
      return this.localizationService.strings.Panel_NavigatorBar_SubmitForApproval;
    } else {
      return this.localizationService.strings.Panel_NavigatorBar_SaveAndPublish;
    }
  }

  private getLanguageNamebyCode(language: string) {
    return Helper.getLanguages(this.localizationService).find((l) => {
      if (l.id === language) {
        return true;
      }
    }).name;
  }
}