exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_playlists_3peIzwthcskkdO-gBgprKg {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_1AwIisGvi1iXB1XKxw1Umv {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_3LbFoRdIc6stvrB048d3gp {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_1Gqz-5WYhHdi0vuENQYcQk {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_playlists": "IA_playlists_3peIzwthcskkdO-gBgprKg",
	"IA_filters": "IA_filters_1AwIisGvi1iXB1XKxw1Umv",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_3LbFoRdIc6stvrB048d3gp",
	"IA_paginationPageCount": "IA_paginationPageCount_1Gqz-5WYhHdi0vuENQYcQk"
};