exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_mediaFiles_1mXdJK965u_Of30BavTy3y {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_26_5Tf-g-om1yv7i-CjNSR {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_14LJAhSEBhtyF7l0xypxgL {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_38XWBUra7oN8G9ilnVXRHh {\n  text-align: center;\n}\n\n.IA_tagButton_aRn-WLm4TgQ99tgyLQ3NB {\n  padding: 5px 7px;\n  margin: 5px;\n  border-radius: 5px;\n  cursor: pointer;\n  user-select: none;\n  display: inline-block;\n  font-size: 13px;\n}\n\n.IA_tagButtonWithDelete_2NPUkUjyAdDL17WPmKED7q {\n  margin: 5px;\n  border-radius: 5px;\n  cursor: pointer;\n  user-select: none;\n  display: inline-block;\n  font-size: 13px;\n  overflow: hidden;\n}\n\n.IA_tagButtonFlexContainer_11HIvJ9_1_wVXegx26nMIN {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n}\n\n.IA_tagButtonTagName_MWyKm_KN4LH7g21HZYHcw {\n  padding: 3px 7px 5px;\n}\n\n.IA_tagButtonIcon_2I1az2LTQ3ErEQfwRqii-2 {\n  padding: 3px 3px 5px;\n}\n\n.IA_tagButtonIcon_2I1az2LTQ3ErEQfwRqii-2:hover{\n  background-color: rgba(0,0,0,0.2);\n}", ""]);

// Exports
exports.locals = {
	"IA_mediaFiles": "IA_mediaFiles_1mXdJK965u_Of30BavTy3y",
	"IA_filters": "IA_filters_26_5Tf-g-om1yv7i-CjNSR",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_14LJAhSEBhtyF7l0xypxgL",
	"IA_paginationPageCount": "IA_paginationPageCount_38XWBUra7oN8G9ilnVXRHh",
	"IA_tagButton": "IA_tagButton_aRn-WLm4TgQ99tgyLQ3NB",
	"IA_tagButtonWithDelete": "IA_tagButtonWithDelete_2NPUkUjyAdDL17WPmKED7q",
	"IA_tagButtonFlexContainer": "IA_tagButtonFlexContainer_11HIvJ9_1_wVXegx26nMIN",
	"IA_tagButtonTagName": "IA_tagButtonTagName_MWyKm_KN4LH7g21HZYHcw",
	"IA_tagButtonIcon": "IA_tagButtonIcon_2I1az2LTQ3ErEQfwRqii-2"
};