import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './Integrations.css';
import { Store } from '../../stores';
import { IAHeader } from '../../../header/Header';
import { LocalizationService } from '../../../../services/LocalizationService';
import { IAButton } from '../../../button/Button';
// import { ListView } from '../../dashboard/listView/ListView';
import { Popup } from '../../reusableComponents/popup/Popup';
import { IListElement, IPlayProfile, PlayIntegrationType } from '../../../../interfaces/IPlay';
import { Helper } from '../../../../Helper';
import { IntegrationsEditPanel } from './integrationsEditPanel/IntegrationsEditPanel';
import PlayService from '../../../../services/PlayService';
// import { Group } from '../../dashboard/group/Group';
import { IASpinner } from '../../../spinner/Spinner';
import { IFeed } from '../../../../interfaces/IFeed';
import { Group } from '../../reusableComponents/group/Group';
import { IAListView } from '../../../listView/ListView';

export interface IProps {
  display: boolean;
  store?: Store;
}

export interface IState {
  loading: boolean;
  profiles?: IPlayProfile[];
  availableMessagesFeeds: IFeed[];
  availableEventsFeeds: IFeed[];
  availableSharePointNewsSites: IFeed[];
  integrationToDelete?: any;
  integrationsPerProfile: { [profileId: string]: { messagesFeeds: IFeed[], eventsFeeds: IFeed[], sharePointSites: IFeed[], workplaceAPIKey: string } };
  isEditingExistingIntegration: boolean;
  areEventsConnected: boolean;
  areMessagesConnected: boolean;
  isConsentGrantedForSharePointNewsApp: boolean;
  isConsentGrantedForSharePointNewsSiteManagementApp: boolean;
}


@inject('store')
@observer
export class Integrations extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      availableMessagesFeeds: undefined,
      availableEventsFeeds: undefined,
      availableSharePointNewsSites: undefined,
      integrationsPerProfile: undefined,
      isEditingExistingIntegration: undefined,
      areEventsConnected: undefined,
      areMessagesConnected: undefined,
      isConsentGrantedForSharePointNewsApp: undefined,
      isConsentGrantedForSharePointNewsSiteManagementApp: undefined,
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.display && !this.props.display) {
      if (!this.state.profiles) {
        this.verifyIntranetConnections();
        this.getIntegrations();
        this.getProfiles();
      }
    }
  }
  private async verifyIntranetConnections() {
    const areMessagesConnected = await PlayService.verifyIntranetConnection(this.props.store.environment, this.props.store.token, this.props.store.tenantId, 'messages');
    const areEventsConnected = await PlayService.verifyIntranetConnection(this.props.store.environment, this.props.store.token, this.props.store.tenantId, 'events');
    const isConsentGrantedForSharePointNewsApp = (await PlayService.verifyAppRegistrationConsent(this.props.store.environment, this.props.store.token, this.props.store.tenantId, "SharePointNews")).result;
    const isConsentGrantedForSharePointNewsSiteManagementApp = (await PlayService.verifyAppRegistrationConsent(this.props.store.environment, this.props.store.token, this.props.store.tenantId, "SharePointNewsSiteManagement")).result;

    await this.setState({ areEventsConnected, areMessagesConnected, isConsentGrantedForSharePointNewsApp, isConsentGrantedForSharePointNewsSiteManagementApp });
  }

  private async getIntegrations() {
    const availableMessagesFeeds = await PlayService.getIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, 'messages');
    const availableEventsFeeds = await PlayService.getIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, 'events');
    const availableSharePointNewsSites = await PlayService.getSharePointSitesSelectedAsFeeds(this.props.store.environment, this.props.store.token, this.props.store.tenantId);
    this.setState({ availableEventsFeeds, availableMessagesFeeds, availableSharePointNewsSites });
  }

  private async getProfiles() {
    this.setState({ loading: true });
    const profilesResult = await PlayService.getPlayProfiles(this.props.store.environment, this.props.store.token, this.props.store.tenantId);
    if (profilesResult === 401) {
      this.props.store.showErrorPopup = true;
    }
    else {
      const profiles = Helper.sortAlphabeticalByTitle(profilesResult);
      await this.setState({ profiles })
      this.getIntegrationsPerProfile();
    }
  }

  private async getIntegrationsPerProfile() {
    this.setState({ loading: true })
    let integrationsPerProfile: { [profileId: string]: { messagesFeeds: IFeed[], eventsFeeds: IFeed[], sharePointSites: IFeed[], workplaceAPIKey: string } } = {};
    for (const profile of this.state.profiles) {
      await Promise.all([
        PlayService.getProfileIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id, 'messages'),
        PlayService.getProfileIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id, 'events'),
        PlayService.getSharePointProfileNewsSites(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id),
        PlayService.getWorkplaceAPIKey(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profile.id)
      ])
        .then((values) => {
          integrationsPerProfile[profile.id] = { messagesFeeds: values[0], eventsFeeds: values[1], sharePointSites: values[2], workplaceAPIKey: values[3] };
        })
    }
    this.setState({ integrationsPerProfile, loading: false });
  }

  private onDelete(integrationId: string): void {
    this.props.store.workingOnIt = true;
    console.log(integrationId);
    // PlayService.deleteIntegration(this.props.store.environment, this.props.store.token, this.props.store.tenantId, integrationId).then(() => {
    // 
    // });
  }

  public render(): JSX.Element {

    // const groups = this.state.profiles?.filter(profile => this.state.integrationsPerProfile[profile.id]?.eventsFeeds?.length || this.state.integrationsPerProfile[profile.id]?.messagesFeeds?.length).map(profile => {
    const groups = this.state.profiles?.map(profile => {
      let listElements: IListElement[] = []
      if (this.state.integrationsPerProfile) {
        let listElement: IListElement;
        if (this.state.integrationsPerProfile[profile.id]?.messagesFeeds?.length) {
          listElement = {
            id: "IntraActive-Messages",
            columns: [
              {
                header: this.localizationService.strings?.PlayAdmin_Type?.toUpperCase(),
                type: "tag",
                text: "IntraActive-Messages",
                // icon: feed.application === 'messages' ? 'Widgets Message 2' : 'Widgets Events 2',
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: this.localizationService.strings?.PlayAdmin_Feeds?.toUpperCase(),
                type: "text",
                text: this.state.integrationsPerProfile[profile.id]?.messagesFeeds.map(feed => feed.title).join(', '),
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              },
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }
            ]
          }
          listElements.push(listElement)
        }

        if (this.state.integrationsPerProfile[profile.id]?.eventsFeeds?.length) {
          listElement = {
            id: "IntraActive-Events",
            columns: [
              {
                header: this.localizationService.strings.PlayAdmin_Type?.toUpperCase(),
                type: "tag",
                text: "IntraActive-Events",
                // icon: feed.application === 'messages' ? 'Widgets Message 2' : 'Widgets Events 2',
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: 'Feeds'?.toUpperCase(),
                type: "text",
                text: this.state.integrationsPerProfile[profile.id]?.eventsFeeds.map(feed => feed.title).join(', '),
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              },
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }
            ]
          };
          listElements.push(listElement)
        }

        if (this.state.integrationsPerProfile[profile.id]?.sharePointSites?.length) {
          listElement = {
            id: "SharePointNews",
            columns: [
              {
                header: this.localizationService.strings.PlayAdmin_Type?.toUpperCase(),
                type: "tag",
                text: "SharePoint News",
                // icon: feed.application === 'messages' ? 'Widgets Message 2' : 'Widgets Events 2',
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: 'Sites'?.toUpperCase(),
                type: "text",
                text: this.state.integrationsPerProfile[profile.id]?.sharePointSites.map(feed => feed.title).join(', '),
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              },
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }
            ]
          };
          listElements.push(listElement)
        }
        if (this.state.integrationsPerProfile[profile.id]?.workplaceAPIKey) {
          listElement = {
            id: "WorkplaceFeed",
            columns: [
              {
                header: this.localizationService.strings.PlayAdmin_Type?.toUpperCase(),
                type: "tag",
                text: this.localizationService.strings.PlayAdmin_Workplace,
                // icon: feed.application === 'messages' ? 'Widgets Message 2' : 'Widgets Events 2',
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: 'Sites'?.toUpperCase(),
                type: "text",
                text: this.state.integrationsPerProfile[profile.id]?.workplaceAPIKey,
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              },
            ],
            buttons: [
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              }
            ]
          };
          listElements.push(listElement)
        }
      }

      return <Group
        key={profile.id}
        label={profile.title}
        darkMode={this.props.store.darkMode}
        count={listElements.length}
      >
        <IAListView
          id="ListViewOfIntegrations"
          listElements={listElements}
          backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
          hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
          buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
          darkMode={this.props.store.darkMode}
          defaultAction={"edit"}
          selectedAction={(action: string, integrationType: string) => {
            switch (action) {
              case "edit":
                let type: PlayIntegrationType;
                let feeds: IFeed[] = [];
                let accessToken;

                if (integrationType === "IntraActive-Messages") {
                  type = 'messages';
                  feeds = this.state.integrationsPerProfile[profile.id].messagesFeeds
                } else if (integrationType === "IntraActive-Events") {
                  type = 'events';
                  feeds = this.state.integrationsPerProfile[profile.id].eventsFeeds
                } else if (integrationType === "SharePointNews") {
                  type = 'sharepoint-news';
                  feeds = this.state.integrationsPerProfile[profile.id].sharePointSites
                } else if (integrationType === "WorkplaceFeed") {
                  type = 'workplace-feed';
                  accessToken = this.state.integrationsPerProfile[profile.id].workplaceAPIKey
                }

                this.props.store.integrationToEdit = {
                  type: type,
                  profileId: profile.id,
                  feeds: feeds?.map(feed => feed.id),
                  accessToken: accessToken
                };

                this.setState({ isEditingExistingIntegration: true });
                break;
              default:
                break;
            }
          }}
        />
      </Group>
    })

    return this.props.display ? (
      <div className={styles.IA_integrations}>
        <IntegrationsEditPanel
          show={this.props.store.integrationToEdit != undefined}
          profiles={this.state.profiles}
          availableEventsFeeds={this.state.availableEventsFeeds}
          availableMessagesFeeds={this.state.availableMessagesFeeds}
          availableSharePointNewsSites={this.state.availableSharePointNewsSites}
          integrationsPerProfile={this.state.integrationsPerProfile}
          areMessagesConnected={this.state.areMessagesConnected}
          areEventsConnected={this.state.areEventsConnected}
          isEditingExistingIntegration={this.state.isEditingExistingIntegration}
          isConsentGrantedForSharePointNewsApp={this.state.isConsentGrantedForSharePointNewsApp}
          isConsentGrantedForSharePointNewsSiteManagementApp={this.state.isConsentGrantedForSharePointNewsSiteManagementApp}
          onClose={() => {
            this.props.store.integrationToEdit = undefined;
            this.setState({ isEditingExistingIntegration: undefined })
          }}
          onSave={async () => {
            this.setState({ loading: true });

            switch (this.props.store.integrationToEdit.type) {
              case 'messages':
                await PlayService.connectIntranetApplicationFunction(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, this.props.store.integrationToEdit.type, this.props.store.integrationToEdit.feeds);
                break;
              case 'events':
                await PlayService.connectIntranetApplicationFunction(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, this.props.store.integrationToEdit.type, this.props.store.integrationToEdit.feeds);
                break;
              case 'sharepoint-news':
                await PlayService.updateSharePointSitesForProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, this.props.store.integrationToEdit.feeds);
                break;
              case 'workplace-feed':
                await PlayService.connectWorkplaceFeedFunction(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, this.props.store.integrationToEdit.accessToken);
                break;
              default:
                break;
            }

            PlayService.getPlayProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, localStorage.getItem("IA_profileId")).then((profile: IPlayProfile) => {
              if (profile) {
                this.props.store.profile = profile;
              }
            });

            await this.getIntegrationsPerProfile();
            this.props.store.integrationToEdit = undefined;
            this.setState({ loading: false });
          }}
          onConnecting={async () => {
            await this.verifyIntranetConnections();
            await this.getIntegrations();
          }}
        />
        <IAHeader
          id="play-integrations-header"
          title={this.localizationService.strings.PlayAdmin_Integrations}
          description={this.localizationService.strings.PlayAdmin_LicenseDescription}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor="#666666"
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-black.png",
              headline: this.localizationService.strings.PlayAdmin_Integrations,
              text: this.localizationService.strings.PlayAdmin_IntegrationsDescription,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-integration.png"
            }
          ]}
          actionButton={{
            label: this.localizationService.strings?.PlayAdmin_Add?.toUpperCase(),
            onClick: () => {
              this.props.store.integrationToEdit = {
                feeds: [],
                profileId: undefined,
                type: undefined
              };
              this.setState({ isEditingExistingIntegration: undefined });
            },
            buttonColor: this.props.store.highlightColor,
            borderRadius: 5,
            textColor: "#ffffff",
            arialable: this.localizationService.strings.PlayAdmin_Add,
            icon: "Add",
            disbaled: this.state.loading
          }}
        />
        {this.state.integrationToDelete &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_DeleteProfile}
            close={() => this.setState({ integrationToDelete: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.onDelete(this.state.integrationToDelete.id);
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ integrationToDelete: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.state.loading ?
          <IASpinner
            color={this.props.store.highlightColor}
            style={{
              float: "left",
              marginLeft: "calc(50% - 20px)",
              clear: "both"
            }}
          />
          :
          <>
            {groups}
          </>
        }
      </div>
    )
      :
      (
        <></>
      );
  }


}