import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from '../WidgetEditPanel.css';
import { IATextField } from '../../../../../textField/TextField';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IAColorPicker } from '../../../../../colorPicker/ColorPicker';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
}

export interface IState {
  rssLabelType: "fromfeed" | "customtext" | "image";
}

@inject('store')
@observer
export class RssTickerWidgetSettings extends React.Component<IProps, IState> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    if (this.props.store.widgetToEdit && this.props.store.widgetToEdit && this.props.store.widgetToEdit.rssTicker) {
      let rssLabelType = "fromfeed";
      if (this.props.store.widgetToEdit.rssTicker?.labelText != undefined) {
        rssLabelType = "customtext";
      }
      if (this.props.store.widgetToEdit.rssTicker?.labelLogo != undefined) {
        rssLabelType = "image";
      }
      this.state = {
        rssLabelType: rssLabelType as "fromfeed" | "customtext" | "image"
      }
    };
  }

  render(): React.ReactNode {
    return (
      <>
        <IATextField
          label={this.localizationService.strings.PlayAdmin_RssUrl?.toUpperCase()}
          placeholder={this.localizationService.strings.PlayAdmin_RssUrl}
          text={this.props.store.widgetToEdit.rssTicker ? this.props.store.widgetToEdit.rssTicker.url : undefined}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(url: string) => {
            this.props.store.widgetToEdit.rssTicker.url = url;
            this.props.forceUpdate();
          }}
        />
        {/* <IAToggle
          style={{
            marginTop: 10
          }}
          label={this.localizationService.strings.PlayAdmin_RssShowTime?.toUpperCase()}
          checked={this.props.store.widgetToEdit.rssTicker ? this.props.store.widgetToEdit.rssTicker.showTime : true}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.rssTicker.showTime = !this.props.store.widgetToEdit.rssTicker.showTime;
            this.props.forceUpdate();
          }}
        /> */}
        {/* <IANumberField
          label={this.localizationService.strings.PlayAdmin_RssMaxItems?.toUpperCase()}
          number={this.props.store.widgetToEdit.rssTicker ? this.props.store.widgetToEdit.rssTicker.maxItems : undefined}
          darkMode={this.props.store.darkMode}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(maxItems: number) => {
            this.props.store.widgetToEdit.rssTicker.maxItems = maxItems;
            this.props.forceUpdate();
          }}
          styles={{
            width: "100%",
            clear: "both"
          }}
        /> */}
        <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
        <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>Layout</h3>
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_RssPosition?.toUpperCase()}
          selectedOption={this.props.store.widgetToEdit.rssTicker.position}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          options={[
            {
              key: "bottom",
              value: this.localizationService.strings.PlayAdmin_RssPositionBottom
            },
            {
              key: "top",
              value: this.localizationService.strings.PlayAdmin_RssPositionTop
            },
          ]}
          onChange={(position: any) => {
            this.props.store.widgetToEdit.rssTicker.position = position;
            this.props.forceUpdate();
          }}
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: "100%"
          }}
        />
        <IAColorPicker
          label={this.localizationService.strings.PlayAdmin_BackgroundColor?.toUpperCase()}
          color={this.props.store.widgetToEdit.rssTicker ? this.props.store.widgetToEdit.rssTicker.backgroundColor : undefined}
          darkMode={this.props.store.darkMode}
          inTeams={this.props.store.isTeams}
          swatches={this.props.store.profile?.swatches}
          toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
          allowAddingToSwatches
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(backgroundColor: string) => {
            this.props.store.widgetToEdit.rssTicker.backgroundColor = backgroundColor;
            this.props.forceUpdate();
          }}
          showClearButton={false}
          styles={{
            marginTop: 0,
            marginBottom: 15
          }}
          addSwatch={(color: string) => this.props.store.addSwatch(color)}
        />
        <IAColorPicker
          label={this.localizationService.strings.PlayAdmin_Color?.toUpperCase()}
          color={this.props.store.widgetToEdit.rssTicker ? this.props.store.widgetToEdit.rssTicker.color : undefined}
          darkMode={this.props.store.darkMode}
          inTeams={this.props.store.isTeams}
          swatches={this.props.store.profile?.swatches}
          toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
          allowAddingToSwatches
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(color: string) => {
            this.props.store.widgetToEdit.rssTicker.color = color;
            this.props.forceUpdate();
          }}
          showClearButton={false}
          styles={{
            marginTop: 0,
            marginBottom: 15
          }}
          addSwatch={(color: string) => this.props.store.addSwatch(color)}
        />
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_RssLabelType?.toUpperCase()}
          selectedOption={this.state.rssLabelType}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          options={[
            {
              key: "fromfeed",
              value: this.localizationService.strings.PlayAdmin_RssLabelTypeFromFeed
            },
            {
              key: "customtext",
              value: this.localizationService.strings.PlayAdmin_RssLabelTypeCustomText
            },
            {
              key: "image",
              value: this.localizationService.strings.PlayAdmin_RssLabelTypeImage
            }
          ]}
          onChange={(rssLabelType: any) => {
            this.props.store.widgetToEdit.rssTicker.labelText = rssLabelType === "customtext" ? "" : undefined;
            this.props.store.widgetToEdit.rssTicker.labelLogo = rssLabelType === "image" ? "none" : undefined;
            this.setState({rssLabelType});
          }}
          style={{
            marginTop: 20,
            width: "100%"
          }}
        />
        {this.state.rssLabelType === "customtext" &&
          <IATextField
            label={this.localizationService.strings.PlayAdmin_RssLabelText?.toUpperCase()}
            placeholder={this.localizationService.strings.PlayAdmin_RssLabelText}
            text={this.props.store.widgetToEdit.rssTicker ? this.props.store.widgetToEdit.rssTicker.labelText : undefined}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(labelText: string) => {
              this.props.store.widgetToEdit.rssTicker.labelText = labelText;
              this.props.forceUpdate();
            }}
          />
        }
        {this.state.rssLabelType === "image" &&
          <MediaFileSelector
            url={this.props.store.widgetToEdit.rssTicker.labelLogo}
            label={this.localizationService.strings.PlayAdmin_RssLabelLogo?.toUpperCase()}
            placeholder={this.localizationService.strings.PlayAdmin_ImageUrl}
            type={"image"}
            onChange={(labelLogo: string) => {
              this.props.store.widgetToEdit.rssTicker.labelLogo = labelLogo;
              this.props.forceUpdate();
            }}
          />
        }
        <IAColorPicker
          label={this.localizationService.strings.PlayAdmin_RssLabelBackgroundColor?.toUpperCase()}
          color={this.props.store.widgetToEdit.rssTicker ? this.props.store.widgetToEdit.rssTicker.labelBackgroundColor : undefined}
          darkMode={this.props.store.darkMode}
          inTeams={this.props.store.isTeams}
          swatches={this.props.store.profile?.swatches}
          toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
          allowAddingToSwatches
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(labelBackgroundColor: string) => {
            this.props.store.widgetToEdit.rssTicker.labelBackgroundColor = labelBackgroundColor;
            this.props.forceUpdate();
          }}
          showClearButton={false}
          addSwatch={(color: string) => this.props.store.addSwatch(color)}
        />
        {this.state.rssLabelType !== "image" &&
          <IAColorPicker
            label={this.localizationService.strings.PlayAdmin_RssLabelColor?.toUpperCase()}
            color={this.props.store.widgetToEdit.rssTicker ? this.props.store.widgetToEdit.rssTicker.labelColor : undefined}
            darkMode={this.props.store.darkMode}
            inTeams={this.props.store.isTeams}
            swatches={this.props.store.profile?.swatches}
            toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
            allowAddingToSwatches
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(labelColor: string) => {
              this.props.store.widgetToEdit.rssTicker.labelColor = labelColor;
              this.props.forceUpdate();
            }}
            showClearButton={false}
            addSwatch={(color: string) => this.props.store.addSwatch(color)}
          />
        }
      </>);
  }

  
}