import * as React from 'react';
import { IEnvironment } from '../../../../interfaces/IEnvironment';
import { IPlayBoxStyle, IPlayPoolReplyOption } from '../../../../interfaces/IPlay';
import * as styles from './Poll.css';
import QRCodeService from '../../../../services/QRCodeService';
import PlayService from '../../../../services/PlayService';
import { Environment } from '../../../../services/Environment';

export interface IProps {
  widgetId?: string;
  title?: string;
  replyOptions: IPlayPoolReplyOption[];
  isActive: boolean;
  width?: number;
  height?: number;
  sizeUnit?: number;
  position?: "absolute" | "relative";
  float?: "left" | "right" | "none";
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  boxStyle?: IPlayBoxStyle;
  duration?: number; // seconds
  willRestart: () => void;
}

export interface IState {
  isLoading: boolean;
  replyOptions?: IPlayPoolReplyOption[];
  result?: IPlayPoolReplyOption[];
}

export class Poll extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  public componentDidMount(): void {
    this.start();
  }

  private start(): void {
    const replyOptions = [];
    this.props.replyOptions.forEach(async (replyOption: IPlayPoolReplyOption, index) => {
      try {
        QRCodeService.generateQRCode(`${Environment.getEnvironmentForPlayFrontend(this.props.environment)}?type=poll&tenant=${this.props.tenantId}&profile=${this.props.profileId}&widget=${this.props.widgetId}&name=Poll&optionId=${replyOption.id}`).then((qrCode: string) => {
          if (qrCode) {
            replyOption.qrCode = qrCode;
            replyOptions.push(replyOption)
            if (this.props.replyOptions?.length === index + 1) {
              this.setState({ isLoading: false, replyOptions });
            }
          }
        });
      } catch (error) {
        console.log(`%c${error}`, 'background: red; color: #ffffff')
      }
    });
    setTimeout(() => {
      this.getResult();
    }, (this.props.duration / 3) * 2000);
    setTimeout(() => {
      this.restart();
    }, this.props.duration * 1000);
  }

  private restart(): void {
    this.setState({ result: undefined });
    this.props.willRestart();
    this.start()
  }

  private getResult(): void {
    PlayService.getPollResult(this.props.environment, this.props.tenantId, this.props.profileId, this.props.widgetId).then((results: any) => {
      if (results) {
        const result = [];
        this.state.replyOptions.forEach((replyOption: IPlayPoolReplyOption) => {
          result.push({
            replies: results[replyOption.id],
            ...replyOption
          });
        });
        this.setState({ result });
      }
    })
  }

  private getImageOptions(): JSX.Element {
    const options = [];
    const marginLeft = (this.props.width - (this.props.replyOptions?.length * this.props.sizeUnit * 25)) / 2;
    let optionWidth = this.props.sizeUnit * 15;
    let height;
    if (document.getElementById("IA_PollImageOptions")) {
      height = document.getElementById("IA_PollImageOptions").clientHeight
    } else {
      this.forceUpdate();
    }
    this.state.replyOptions?.forEach((option: IPlayPoolReplyOption) => {
      options.push(
        <div
          className={styles.IA_pollImageOption}
          style={{
            padding: this.props.sizeUnit * 2,
            margin: this.props.sizeUnit * 3
          }}
        >
          {option.image &&
            <div
              className={styles.IA_pollImageOptionImage}
              style={{
                backgroundImage: `url("${option.image}")`,
                width: optionWidth,
                height: optionWidth
              }}
            />
          }
          {option.title && option.showTitle &&
            <div
              className={styles.IA_pollImageOptionTitle}
              style={{
                width: optionWidth,
                marginTop: this.props.sizeUnit * 2,
                fontSize: this.props.sizeUnit * 2
              }}
            >
              {option.title}
            </div>
          }
          <img
            src={option.qrCode}
            className={styles.IA_pollImageOptionQR}
            style={{
              width: optionWidth,
              height: optionWidth
            }}
          />
        </div>
      );
    });
    return (
      <div
        id="IA_PollImageOptions"
        className={styles.IA_pollImageOptions}
        style={{
          opacity: marginLeft && height ? 1 : 0,
          top: (this.props.height - height) / 2,
          left: marginLeft
        }}
      >
        {options}
      </div>
    )
  }

  private getBarResult(): JSX.Element {
    const bars = [];
    const marginLeft = (this.props.width - (this.props.replyOptions?.length * this.props.sizeUnit * 25)) / 2;
    let barWidth = this.props.sizeUnit * 15;
    const titleHeight = this.props.sizeUnit * 2;
    const imageHeight = barWidth;
    const replyOptionTitleHeight = this.props.sizeUnit * 2;
    const replyOptionTitleMarginTop = this.props.sizeUnit * 2;
    const margin = this.props.sizeUnit * 22;
    let barMaxHeight = this.props.height - titleHeight - imageHeight - replyOptionTitleMarginTop - replyOptionTitleHeight - margin;
    let maxReplies = 0;
    this.state.result?.forEach((option: IPlayPoolReplyOption) => {
      if (option.replies > maxReplies) {
        maxReplies = option.replies;
      }
    });
    this.state.result?.forEach((option: IPlayPoolReplyOption) => {
      bars.push(
        <div
          className={styles.IA_pollResultBar}
          style={{
            padding: this.props.sizeUnit * 2,
            margin: this.props.sizeUnit * 3
          }}
        >
          <div
            style={{
              width: this.props.sizeUnit * 3,
              height: barMaxHeight,
              backgroundColor: "transparent",
              marginLeft: `calc(50% - ${this.props.sizeUnit * 1.5}px)`,
              marginBottom: this.props.sizeUnit * 3,
              borderRadius: this.props.sizeUnit
            }}
          >
            <div
              className={styles.IA_pollResultBarBar}
              style={{
                width: this.props.sizeUnit * 3,
                height: (barMaxHeight / maxReplies) * option.replies,
                backgroundColor: "#333333",
                opacity: 0.5,
                borderRadius: this.props.sizeUnit,
                marginTop: (barMaxHeight / maxReplies) * (maxReplies - option.replies),
              }}
            />
          </div>
          {option.image &&
            <div
              className={styles.IA_pollResultBarImage}
              style={{
                backgroundImage: `url("${option.image}")`,
                width: imageHeight,
                height: imageHeight
              }}
            />
          }
          {option.title && option.showTitle &&
            <div
              className={styles.IA_pollResultBarTitle}
              style={{
                width: barWidth,
                height: replyOptionTitleHeight,
                marginTop: replyOptionTitleMarginTop,
                fontSize: this.props.sizeUnit * 2
              }}
            >
              {option.title}
            </div>
          }
        </div>
      );
    });
    return (
      <div
        id="IA_PollReslutBars"
        className={styles.IA_pollResultBars}
        style={{
          bottom: this.props.sizeUnit * 2,
          left: marginLeft
        }}
      >
        {bars}
      </div>
    );
  }

  public render(): JSX.Element {
    return (
      <div className={styles.IA_poll}>
        <div className={styles.IA_pollBg} />
        <div
          className={styles.IA_pollTitle}
          style={{
            fontSize: this.props.sizeUnit * 4,
            padding: `0 ${this.props.sizeUnit * 6}px`,
            marginTop: this.props.sizeUnit * 6,
            color: "#333333"
          }}
        >
          {this.state.result ? "Jeres svar" : this.props.title}
        </div>
        {this.state.result ?
          this.getBarResult()
          :
          this.getImageOptions()
        }
      </div>
    );
  }
}