exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_postcard_2N9MNQF3qofXhCOdkWj4TT {\n  width: 100%;\n  height: 100%;\n}\n\n.IA_postcardContent_XqpogH5d2E9h1nlVX55mo {\n  background-color: #ffffff;\n}\n\n.IA_imageContainer_2LeZFJjcsZxhElP2k56nSd {\n  position: relative;\n  float: left;\n}\n\n.IA_textContainer_3UCq7kZ39rd5Nx8LtKyFMo {\n  position: relative;\n  float: right;\n}\n\n.IA_title_kM-tflnFAkl3wg6FaaPu {\n  float: left;\n  clear: both;\n  position: relative;\n  line-clamp: 2;\n  -webkit-line-clamp: 2;\n  display: -webkit-box;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.IA_header_23mZTThb5TqN2kkYB5bAKD {\n  width: 100%;\n}\n\n.IA_headerImage_239xbYsS_iHEwmdeD8yhbC {\n  background-position: center;\n  background-size: cover;\n  float: left;\n}\n\n.IA_headerTitle_1ZD6cFlsgNEBC-Xf1rZcpx {\n  float: left;\n  font-weight: bold;\n}\n\n.IA_author_b1-O7elOVgilv97xPHzud {\n  float: left;\n  clear: both;\n}\n\n.IA_headerSubtitle_3D18PGiNIGpoU1j7eYqR81 {\n  float: left;\n}\n\n.IA_date_2lYwxRBMZNbGoMZdH6_nRX {\n  float: left;\n  clear: both;\n}", ""]);

// Exports
exports.locals = {
	"IA_postcard": "IA_postcard_2N9MNQF3qofXhCOdkWj4TT",
	"IA_postcardContent": "IA_postcardContent_XqpogH5d2E9h1nlVX55mo",
	"IA_imageContainer": "IA_imageContainer_2LeZFJjcsZxhElP2k56nSd",
	"IA_textContainer": "IA_textContainer_3UCq7kZ39rd5Nx8LtKyFMo",
	"IA_title": "IA_title_kM-tflnFAkl3wg6FaaPu",
	"IA_header": "IA_header_23mZTThb5TqN2kkYB5bAKD",
	"IA_headerImage": "IA_headerImage_239xbYsS_iHEwmdeD8yhbC",
	"IA_headerTitle": "IA_headerTitle_1ZD6cFlsgNEBC-Xf1rZcpx",
	"IA_author": "IA_author_b1-O7elOVgilv97xPHzud",
	"IA_headerSubtitle": "IA_headerSubtitle_3D18PGiNIGpoU1j7eYqR81",
	"IA_date": "IA_date_2lYwxRBMZNbGoMZdH6_nRX"
};