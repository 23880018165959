import * as React from 'react';
import { Store } from '../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './Settings.css';
import { IAHeader } from '../../../header/Header';
import { IAPivot } from '../../../pivot/Pivot';
import { LocalizationService } from '../../../../services/LocalizationService';
import { Profile } from './profile/Profile';
import { Groups } from './groups/Groups';
import { Permissions } from './permissions/Permissions';
import { Helper } from '../../../../Helper';
import { IPlayProfile } from '../../../../interfaces/IPlay';
import PlayService from '../../../../services/PlayService';

export interface IProps {
  showSkeleton: boolean;
  store?: Store;
}

export interface IState {
  selectedPivot: "profile" | "groups" | "permissions";
}

@inject('store')
@observer
export class Settings extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedPivot: "profile"
    }
  }

  public render(): JSX.Element {
    return (
      <div className={styles.IA_settings}>
        <IAHeader
          id="play-settings-header"
          title={this.localizationService.strings.PlayAdmin_Settings}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor="#91bbcd"
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-gray.jpg",
              headline: this.localizationService.strings.PlayAdmin_Settings,
              text: this.localizationService.strings.PlayAdmin_SettingsHeaderText,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-settings.png"
            }
          ]}
        />
        <IAPivot
          highlightColor={this.props.store.highlightColor}
          items={[
            {
              key: "profile",
              text: this.localizationService.strings.PlayAdmin_Profile
            },
            {
              key: "groups",
              text: this.localizationService.strings.PlayAdmin_Groups,
            },
            {
              key: "permissions",
              text: this.localizationService.strings.PlayAdmin_Users,
            }
          ]}
          style={{
            fontSize: "14px",
            color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#333333",
            textTransform: "uppercase"
          }}
          selectedKey={this.state.selectedPivot}
          select={(selectedPivot: "profile" | "groups" | "permissions") => {
            this.setState({ selectedPivot });
            if (this.props.store.profileHasChanged) {
              this.props.store.profileHasChanged = false;
              if (localStorage.getItem("IA_profileId")) {
                PlayService.getPlayProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, localStorage.getItem("IA_profileId")).then((profile: IPlayProfile) => {
                  if (profile) {
                    this.props.store.profile = profile;
                  }
                });
              }
            }
          }}
        />
        <div
          className={styles.IA_pivotContent}
          style={{
            height: this.state.selectedPivot === "profile" ? "auto" : 0
          }}
        >
          <Profile
            showSkeleton={this.props.showSkeleton}
          />
        </div>
        <div
          className={styles.IA_pivotContent}
          style={{
            height: this.state.selectedPivot === "groups" ? "auto" : 0
          }}
        >
          <Groups
            showSkeleton={this.props.showSkeleton}
          />
        </div>

        <div
          className={styles.IA_pivotContent}
          style={{
            height: this.state.selectedPivot === "permissions" ? "auto" : 0
          }}
        >
          <Permissions
            showSkeleton={this.props.showSkeleton}
          />
        </div>
      </div>
    );
  }

  
}