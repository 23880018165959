import { IAadHttpClient } from './Aad/IAadHttpClient';
import { IAadHttpClientFactory } from './Aad/IAadHttpClientFactory';

import "./Global";
import { injectable } from './Containers/Container';

@injectable("SharePointClientService")
export class SharePointClientService {
  public constructor(
    public readonly aadHttpClientFactory: IAadHttpClientFactory,
    public readonly aadClientAppId: string
  ) {
  }

  public getHttpClient(): Promise<IAadHttpClient> {
    const httpClientPromise = new Promise<IAadHttpClient>((resolve, reject) => {
      this.aadHttpClientFactory
        .getClient(this.aadClientAppId)
        .then((client: IAadHttpClient): void => {
          resolve(client);
        })
        .catch(error => {
          reject(error);
        });
    });
    return httpClientPromise;
  }
}