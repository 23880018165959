exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_pivot_3XOAM9OSHpJ4J2_ZY-NrXv {\n  width: 100%;\n  margin-bottom: 20px;\n  font-size: 12px;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.IA_pivot_3XOAM9OSHpJ4J2_ZY-NrXv .IA_pivotItem_1KcqTFJP6liJQmyJnueZE0 {\n  height: 25px;\n  line-height: 25px;\n  width: auto;\n  margin-right: 20px;\n  cursor: pointer;\n}\n\n.IA_tabItem_IUHeQBOVhB_gY8YWCHmV0 {\n  float: left;\n  height: 40px;\n  line-height: 40px;\n  text-align: center;\n  text-transform: uppercase;\n  cursor: pointer;\n  border-right: 1px solid #ffffff;\n  box-sizing: border-box;\n  padding: 0px 10px;\n}\n\n.IA_tabItemNumber_3YrNK41iLNSYdv3EFBNLaV {\n  float: left;\n  width: 20px;\n  height: 20px;\n  border-radius: 20px;\n  margin-top: 10px;\n  line-height: 20px;\n  margin-left: 20px;\n}\n\n.IA_tabItemIcon_JAowBEW8k9u6H67q4Yk7C {\n  float: left;\n  width: 20px;\n  height: 20px;\n  border-radius: 20px;\n  margin-top: 12px;\n  line-height: 20px;\n  margin-left: 20px;\n}\n\n.IA_tabItemText_iCZFLTGv8ZHbYC8xr6fSy {\n  float: left;\n  height: 20px;\n  border-radius: 20px;\n  margin-left: 10px;\n}", ""]);

// Exports
exports.locals = {
	"IA_pivot": "IA_pivot_3XOAM9OSHpJ4J2_ZY-NrXv",
	"IA_pivotItem": "IA_pivotItem_1KcqTFJP6liJQmyJnueZE0",
	"IA_tabItem": "IA_tabItem_IUHeQBOVhB_gY8YWCHmV0",
	"IA_tabItemNumber": "IA_tabItemNumber_3YrNK41iLNSYdv3EFBNLaV",
	"IA_tabItemIcon": "IA_tabItemIcon_JAowBEW8k9u6H67q4Yk7C",
	"IA_tabItemText": "IA_tabItemText_iCZFLTGv8ZHbYC8xr6fSy"
};