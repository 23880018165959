import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Store } from '../../../stores';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IATextField } from '../../../../textField/TextField';
import { MediaFileSelector } from '../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IACard } from '../../../../card/Card';
import { Helper } from '../../../../../Helper';
import { IADropDown } from '../../../../dropDown/DropDown';
import { IAToggle } from '../../../../toggle/Toggle';
import { ArticleText } from './ArticleText';
import { IADateAndTimePicker } from '../../../../dateAndTimePicker/DateAndTimePicker';
import { IAOptionSelector } from '../../../../optionSelector/OptionSelector';
import { IASlider } from '../../../../slider/Slider';
import { IAColorTheme } from '../../../../colorTheme/ColorTheme';

export interface IProps {
  forceUpdate: () => void;
  articleId: string;
  store?: Store;
}

export interface IState {
  newImageUrl: string;
  isTyping: any;
  text: any;
  boxStyleCategory: "box" | "half" | "full" | "onlyImage";
  backgroundTransparency: "transparent" | "solid" | "gradient" | "hidden";
}

@inject('store')
@observer
export class ArticleEditor extends React.Component<IProps, IState> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    let boxStyleCategory: "box" | "half" | "full" | "onlyImage";
    if (props.store.articleToEdit.boxStyle === "box" || props.store.articleToEdit.boxStyle === "boxTransparent") {
      boxStyleCategory = "box";
    } else if (props.store.articleToEdit.boxStyle === "fullSolid" || props.store.articleToEdit.boxStyle === "fullTransparent") {
      boxStyleCategory = "full";
    } else if (props.store.articleToEdit.boxStyle === "onlyImage") {
      boxStyleCategory = "onlyImage";
    } else {
      boxStyleCategory = "half";
    }

    let backgroundTransparency: "transparent" | "solid" | "gradient" = "solid";
    if (props.store.articleToEdit.boxStyle === "boxTransparent" || props.store.articleToEdit.boxStyle === "fullTransparent") {
      backgroundTransparency = "transparent";
    } else if (props.store.articleToEdit.boxStyle === "fullSolid" || props.store.articleToEdit.boxStyle === "solid") {
      backgroundTransparency = "solid";
    } else {
      backgroundTransparency = "gradient";
    }

    this.state = {
      newImageUrl: undefined,
      isTyping: undefined,
      text: undefined,
      boxStyleCategory: boxStyleCategory,
      backgroundTransparency: backgroundTransparency
    }
  }

  private updateBoxStyle(boxStyleCategory: "box" | "half" | "full" | "onlyImage", backgroundTransparency: "transparent" | "solid" | "gradient" | "hidden") {
    if (boxStyleCategory === "box" && backgroundTransparency === "transparent") {
      this.props.store.articleToEdit.boxStyle = "boxTransparent";
    }
    if (boxStyleCategory === "box" && backgroundTransparency === "hidden") {
      this.props.store.articleToEdit.boxStyle = "boxHidden";
    }
    if (boxStyleCategory === "box" && backgroundTransparency === "solid") {
      this.props.store.articleToEdit.boxStyle = "box";
    }
    if (boxStyleCategory === "full" && backgroundTransparency === "transparent") {
      this.props.store.articleToEdit.boxStyle = "fullTransparent";
    }
    if (boxStyleCategory === "full" && backgroundTransparency === "solid") {
      this.props.store.articleToEdit.boxStyle = "fullSolid";
    }
    if (boxStyleCategory === "half" && backgroundTransparency === "transparent") {
      this.props.store.articleToEdit.boxStyle = "transparent";
    }
    if (boxStyleCategory === "half" && backgroundTransparency === "hidden") {
      this.props.store.articleToEdit.boxStyle = "hidden";
    }
    if (boxStyleCategory === "half" && backgroundTransparency === "solid") {
      this.props.store.articleToEdit.boxStyle = "solid";
    }
    if (boxStyleCategory === "half" && backgroundTransparency === "gradient") {
      this.props.store.articleToEdit.boxStyle = "gradient";
    }
    if (boxStyleCategory === "onlyImage") {
      this.props.store.articleToEdit.boxStyle = "onlyImage";
    }
    this.props.forceUpdate();
    this.setState({ boxStyleCategory, backgroundTransparency });
  }

  public render(): JSX.Element {
    // const showLeftRightButtonsForFull = this.props.store.articleToEdit.boxStyle === "fullSolid" && !(this.props.store.articleToEdit.galleryImages?.length > 0 || (this.props.store.articleToEdit.video && this.props.store.articleToEdit.video !== ""));
    return <>
      <h1 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.localizationService.strings.PlayAdmin_ArticleSettings}</h1>
      {/* TITLE */}
      <IATextField
        label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
        placeholder={this.localizationService.strings.PlayAdmin_MissionTitle}
        text={this.props.store.articleToEdit.title}
        darkMode={this.props.store.darkMode}
        highlightColor={this.props.store.highlightColor}
        required
        borderRadius={5}
        labelStyle={{
          fontSize: "12px"
        }}
        onChange={(title: string) => {
          this.props.store.articleToEdit.title = title;
          this.props.forceUpdate();
        }}
      />
      {/* IMAGE */}
      <MediaFileSelector
        url={this.props.store.articleToEdit.imageUrl}
        label={this.localizationService.strings.PlayAdmin_Image?.toUpperCase()}
        placeholder={this.localizationService.strings.PlayAdmin_ImageUrl}
        type={"image"}
        disabled={!((this.props.store.articleToEdit.video == undefined || this.props.store.articleToEdit.video == "") && this.props.store.articleToEdit.galleryImages?.length === 0)}
        onChange={(imageUrl: string) => {
          this.props.store.articleToEdit.imageUrl = imageUrl;
          this.props.forceUpdate();
        }}
      />
      {/* GALLERY */}
      <div style={{
        float: "left",
        width: "100%",
        alignItems: "end",
        opacity: !(this.props.store.articleToEdit.video == undefined || this.props.store.articleToEdit.video == "") ? 0.5 : 1
      }}>
        <MediaFileSelector
          url={this.state.newImageUrl}
          label={this.localizationService.strings.PlayAdmin_GalleryImages?.toUpperCase()}
          placeholder={this.localizationService.strings.PlayAdmin_ImageUrl}
          type={"image"}
          enableMultiple
          onChange={(newImageUrl: string) => {
            try {
              // only add valid urls
              const url = new URL(newImageUrl);
              if (url.protocol === "http:" || url.protocol === "https:") {
                if (this.props.store.articleToEdit.galleryImages) {
                  this.props.store.articleToEdit.galleryImages.push(newImageUrl);
                } else {
                  this.props.store.articleToEdit.galleryImages = [newImageUrl];
                }
                this.setState({ newImageUrl: undefined });
                this.props.forceUpdate();
              }
            } catch (_) {
              return;
            }
          }}
        />
      </div>
      {this.props.store.articleToEdit.galleryImages &&
        <div style={{ paddingTop: this.props.store.articleToEdit.galleryImages?.length > 0 ? 20 : 0, display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: "100%" }}>
          {this.props.store.articleToEdit.galleryImages.map((imageUrl, index) => {
            const isFirstElement = index === 0;
            const isLastElement = index === this.props.store.articleToEdit.galleryImages.length - 1;
            return <IACard
              key={`image${imageUrl}${index}`}
              id={imageUrl}
              darkMode={this.props.store.darkMode}
              cover={{
                imageURL: imageUrl
              }}
              isMobile={this.props.store.isMobile}
              alwaysShowFooter
              footerElements={[
                {
                  color: isLastElement ? this.props.store.highlightColor + "80" : this.props.store.highlightColor,
                  iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Down/SVG/ic_fluent_chevron_down_48_regular.svg",
                  onClick: () => {
                    if (!isLastElement) {
                      this.props.store.articleToEdit.galleryImages.splice(index + 1, 0, this.props.store.articleToEdit.galleryImages.splice(index, 1)[0]);
                      this.props.forceUpdate();
                    }
                  }
                },
                {
                  color: isFirstElement ? this.props.store.highlightColor + "80" : this.props.store.highlightColor,
                  iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Up/SVG/ic_fluent_chevron_up_48_regular.svg",
                  onClick: () => {
                    if (!isFirstElement) {
                      this.props.store.articleToEdit.galleryImages.splice(index - 1, 0, this.props.store.articleToEdit.galleryImages.splice(index, 1)[0]);
                      this.props.forceUpdate();
                    }
                  }
                },
                {
                  color: this.props.store.highlightColor,
                  icon: "Trash",
                  onClick: () => {
                    this.props.store.articleToEdit.galleryImages.splice(index, 1);
                    this.props.forceUpdate();
                  }
                }
              ]}
              width={300}
              styles={{
                marginBottom: 20,
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
                width: 300
              }}
              design={{
                highlightColor: this.props.store.highlightColor,
                showDropShadow: true,
                borderRadius: 5,
                backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"
              }}
            />;
          })}
        </div>
      }
      {/* VIDEO */}
      <MediaFileSelector
        label={this.localizationService.strings.PlayAdmin_VideoUrl?.toUpperCase()}
        url={this.props.store.articleToEdit.video}
        placeholder={this.localizationService.strings.PlayAdmin_VideoUrl}
        type={"video"}
        onChange={(videoUrl: string) => {
          this.props.store.articleToEdit.video = Helper.convertUrlToEmbedUrl(videoUrl);
          this.props.forceUpdate();
        }}
      />
      <IAToggle
        style={{
          marginTop: 20,
          width: "100%",
          clear: 'both',
          float: "left"
        }}
        label={this.localizationService.strings.PlayAdmin_ShowOriginalImage?.toUpperCase()}
        checked={!!this.props.store.articleToEdit.keepOriginalImage}
        color={this.props.store.highlightColor}
        darkMode={this.props.store.darkMode}
        labelStyle={{
          fontSize: 12
        }}
        onChange={() => {
          this.props.store.articleToEdit.keepOriginalImage = !!!this.props.store.articleToEdit.keepOriginalImage;
          this.props.forceUpdate();
        }}
      />
      {/* Content */}
      <div style={{ fontSize: "12px", float: "left", marginTop: 20, marginBottom: 10, color: this.props.store.darkMode ? Helper.darkModeLabelColor : 'rgb(51, 51, 51)' }}>{this.localizationService.strings.PlayAdmin_MenuArea_Content?.toUpperCase()}</div>
      {this.props.store.articleToEdit.textContent.map((text, i) => {
        return <ArticleText
          key={`articletext_${i}`}
          id={`articletext_${i}`}
          text={text}
          hideToolbox={false}
          onChange={(text: string) => {
            this.props.store.articleToEdit.textContent[i] = text;
            if (this.state.isTyping !== undefined) {
              clearTimeout(this.state.isTyping);
            }
            this.setState({
              isTyping: setTimeout(() => {
                this.props.forceUpdate();
                this.setState({ isTyping: undefined });
              }, 500)
            });
          }}
        />
      })}
      {/* Start and end times */}
      <IADateAndTimePicker
        label={this.localizationService.strings.Messages_EditPanel_StartTime?.toUpperCase()}
        selectedDate={new Date(this.props.store.articleToEdit.startDate)}
        showTimeSelector={true}
        highlightColor={this.props.store.highlightColor}
        isClearable={false}
        required={true}
        darkMode={this.props.store.darkMode}
        borderRadius={5}
        onChange={(startDate: Date) => {
          this.props.store.articleToEdit.startDate = startDate.toJSON();
        }}
        style={{
          marginTop: 20,
          width: "100%",
          fontSize: 12
        }}
        inputStyle={{
          color: this.props.store.articleToEdit?.startDate && new Date(this.props.store.articleToEdit?.startDate) > new Date() ? this.props.store.highlightColor : (this.props.store.darkMode ? "#ffffff" : "#000000")
        }}
      />
      <IADateAndTimePicker
        label={this.localizationService.strings.Messages_EditPanel_EndTime?.toUpperCase()}
        selectedDate={this.props.store.articleToEdit.endDate ? new Date(this.props.store.articleToEdit.endDate) : undefined}
        showTimeSelector={true}
        highlightColor={this.props.store.highlightColor}
        isClearable={true}
        darkMode={this.props.store.darkMode}
        borderRadius={5}
        onChange={(endDate: Date | undefined) => {
          this.props.store.articleToEdit.endDate = endDate?.toJSON() ?? null;
        }}
        style={{
          marginTop: 20,
          width: "100%",
          fontSize: 12
        }}
        inputStyle={{
          color: this.props.store.articleToEdit?.endDate && new Date(this.props.store.articleToEdit?.endDate) < new Date() ? "#ee4545" : (this.props.store.darkMode ? "#ffffff" : "#000000")
        }}
      />
      {/* QR link */}
      <IATextField
        label={this.localizationService.strings.PlayAdmin_QRCode?.toUpperCase()}
        placeholder={this.localizationService.strings.PlayAdmin_Link}
        text={this.props.store.articleToEdit.link}
        darkMode={this.props.store.darkMode}
        highlightColor={this.props.store.highlightColor}
        borderRadius={5}
        labelStyle={{
          fontSize: "12px"
        }}
        onChange={(link: string) => {
          this.props.store.articleToEdit.link = link;
          this.props.forceUpdate();
        }}
      />
      <IATextField
        placeholder={this.localizationService.strings.PlayAdmin_LinkText}
        text={this.props.store.articleToEdit.linkText}
        darkMode={this.props.store.darkMode}
        highlightColor={this.props.store.highlightColor}
        borderRadius={5}
        labelStyle={{
          fontSize: "12px"
        }}
        inputFieldStyle={{
          marginTop: 5
        }}
        onChange={(text: string) => {
          this.props.store.articleToEdit.linkText = text;
          this.props.forceUpdate();
        }}
      />
      {/* Content background styling */}
      <IAOptionSelector
        label={this.localizationService.strings.PlayAdmin_ArticleLayoutType?.toUpperCase()}
        options={[
          {
            icon: "Layout Txt Only",
            key: "full",
            text: this.localizationService.strings.PlayAdmin_ArticleLayoutTypeFullText
          },
          {
            icon: "Layout Txt Top Of Img",
            key: "box",
            text: this.localizationService.strings.PlayAdmin_ArticleLayoutTypeTextBox,
            disabled: (this.props.store.articleToEdit?.imageUrl == undefined || this.props.store.articleToEdit?.imageUrl === "") && (this.props.store.articleToEdit?.video == undefined || this.props.store.articleToEdit?.video === "") && (this.props.store.articleToEdit?.galleryImages?.length === 0)
          },
          {
            icon: "Layout Txt Right Img Left",
            key: "half",
            text: this.localizationService.strings.PlayAdmin_ArticleLayoutTypeHalfText,
            disabled: (this.props.store.articleToEdit?.imageUrl == undefined || this.props.store.articleToEdit?.imageUrl === "") && (this.props.store.articleToEdit?.video == undefined || this.props.store.articleToEdit?.video === "") && (this.props.store.articleToEdit?.galleryImages?.length === 0)
          },
          {
            icon: "Layout Img Only",
            key: "onlyImage",
            text: this.localizationService.strings.PlayAdmin_ArticleLayoutTypeOnlyImage,
            disabled: (this.props.store.articleToEdit?.imageUrl == undefined || this.props.store.articleToEdit?.imageUrl === "") && (this.props.store.articleToEdit?.video == undefined || this.props.store.articleToEdit?.video === "") && (this.props.store.articleToEdit?.galleryImages?.length === 0)
          }
        ]}
        selected={this.state.boxStyleCategory}
        highlightColor={this.props.store.highlightColor}
        borderRadius={5}
        labelStyle={{
          fontSize: "12px"
        }}
        compact
        iconSize={30}
        darkMode={this.props.store.darkMode}
        onChange={(boxStyleCategory: any) => {
          this.props.store.articleToEdit.contentPosition = "left";
          this.props.store.articleToEdit.contentVerticalPosition = "top";
          if (boxStyleCategory !== this.state.boxStyleCategory) {
            this.updateBoxStyle(boxStyleCategory, this.state.backgroundTransparency === "gradient" ? "transparent" : this.state.backgroundTransparency);
          }
        }}
        style={{ clear: "none", marginTop: 20 }}
      />
      {this.props.store.articleToEdit.boxStyle !== "onlyImage" &&
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_ContentBackground?.toUpperCase()}
          selectedOption={this.state.backgroundTransparency}
          options={[
            {
              key: "solid",
              value: this.localizationService.strings.PlayAdmin_SolidBackground,
            },
            {
              key: "transparent",
              value: this.localizationService.strings.PlayAdmin_TransparentBackground,
              disabled: !this.props.store.articleToEdit.imageUrl && !this.props.store.articleToEdit.video && this.props.store.articleToEdit.galleryImages?.length < 0
            },
            {
              key: "gradient",
              value: this.localizationService.strings.PlayAdmin_GradientBackground,
              disabled: (!this.props.store.articleToEdit.imageUrl && !this.props.store.articleToEdit.video && this.props.store.articleToEdit.galleryImages?.length < 0) || this.state.boxStyleCategory !== "half"
            },
            {
              key: "hidden",
              value: this.localizationService.strings.PlayAdmin_ClearBackground,
              disabled: (!this.props.store.articleToEdit.imageUrl && !this.props.store.articleToEdit.video && this.props.store.articleToEdit.galleryImages?.length < 0) || !(this.state.boxStyleCategory === "box" || this.state.boxStyleCategory === "half")
            }
          ]}
          borderRadius={5}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          onChange={(backgroundTransparency: any) => {
            if (backgroundTransparency !== this.state.backgroundTransparency) {
              this.updateBoxStyle(this.state.boxStyleCategory, backgroundTransparency);
            }
          }}
          style={{
            clear: "none",
            float: "left",
            width: "100%"
          }}
        />}
      {this.props.store.articleToEdit.boxStyle !== "onlyImage" && this.state.boxStyleCategory === "half" &&
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_TextPosition?.toUpperCase()}
          selectedOption={this.props.store.articleToEdit.contentPosition}
          options={[
            {
              key: "left",
              value: this.localizationService.strings.PlayAdmin_TextPositionLeft,
            },
            {
              key: "right",
              value: this.localizationService.strings.PlayAdmin_TextPositionRight,
            },
            {
              key: "random",
              value: this.localizationService.strings.PlayAdmin_TextPositionRandom,
            }
          ]}
          borderRadius={5}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          onChange={(contentPosition: any) => {
            this.props.store.articleToEdit.contentPosition = contentPosition;
            this.props.forceUpdate();
          }}
          style={{
            clear: "none",
            float: "left",
            width: "100%"
          }}
        />
      }
      {/* {showLeftRightButtonsForFull ||
        (this.props.store.articleToEdit.boxStyle !== "box" &&
          this.props.store.articleToEdit.boxStyle !== "boxTransparent" &&
          this.props.store.articleToEdit.boxStyle !== "onlyImage") &&
        <IAOptionSelector
          label={this.localizationService.strings.PlayAdmin_ArticleLayoutType?.toUpperCase()}
          options={[
            {
              icon: "Layout Txt Left Img Right",
              key: "left",
              text: this.localizationService.strings.PlayAdmin_ArticleLayoutTypeTextLeftImageRight
            },
            {
              icon: "Layout Txt Right Img Left",
              key: "right",
              text: this.localizationService.strings.PlayAdmin_ArticleLayoutTypeTextRightImageLeft
            }
          ]}
          selected={this.props.store.articleToEdit.contentPosition}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          compact
          iconSize={30}
          darkMode={this.props.store.darkMode}
          onChange={(position: "left" | "right") => {
            if (position !== this.props.store.articleToEdit.contentPosition) {
              this.props.store.articleToEdit.contentPosition = position;
              this.props.forceUpdate();
            }
          }}
          style={{ clear: "none", marginTop: 20 }}
        />
      } */}
      {(this.props.store.articleToEdit.boxStyle === "box" ||
        this.props.store.articleToEdit.boxStyle === "boxTransparent" ||
        this.props.store.articleToEdit.boxStyle === "boxHidden") &&
        <IAOptionSelector
          label={this.localizationService.strings.PlayAdmin_ArticleLayoutType?.toUpperCase()}
          options={[
            {
              iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/extra%20icons/ic_layout_txt_left_img_right.svg",
              key: "topLeft",
              text: this.localizationService.strings.PlayAdmin_ArticleLayoutTypeBoxTopLeft
            },
            {
              iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/extra%20icons/ic_layout_text_top_right.svg",
              key: "topRight",
              text: this.localizationService.strings.PlayAdmin_ArticleLayoutTypeBoxTopRight
            },
            {
              iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/extra%20icons/ic_layout_text_bottom_left.svg",
              key: "bottomLeft",
              text: this.localizationService.strings.PlayAdmin_ArticleLayoutTypeBoxBottomLeft
            },
            {
              iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/extra%20icons/ic_layout_text_top_left.svg",
              key: "bottomRight",
              text: this.localizationService.strings.PlayAdmin_ArticleLayoutTypeBoxBottomRight
            }
          ]}
          selected={this.props.store.articleToEdit.contentVerticalPosition + this.props.store.articleToEdit.contentPosition.charAt(0)?.toUpperCase() + this.props.store.articleToEdit.contentPosition.slice(1)}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          compact
          iconSize={30}
          darkMode={this.props.store.darkMode}
          onChange={(position: "topLeft" | "topRight" | "bottomLeft" | "bottomRight") => {
            switch (position) {
              case "topLeft":
                this.props.store.articleToEdit.contentPosition = "left";
                this.props.store.articleToEdit.contentVerticalPosition = "top";
                break;
              case "topRight":
                this.props.store.articleToEdit.contentPosition = "right";
                this.props.store.articleToEdit.contentVerticalPosition = "top";
                break;
              case "bottomLeft":
                this.props.store.articleToEdit.contentPosition = "left";
                this.props.store.articleToEdit.contentVerticalPosition = "bottom";
                break;
              case "bottomRight":
                this.props.store.articleToEdit.contentPosition = "right";
                this.props.store.articleToEdit.contentVerticalPosition = "bottom";
                break;
              default:
                break;
            }
            this.props.forceUpdate();
          }}
          style={{ clear: "none", marginTop: 20 }}
        />
      }
      {/* Width slider (only for box view) */}
      {(this.props.store.articleToEdit.boxStyle === "box" || this.props.store.articleToEdit.boxStyle === "boxTransparent") &&
        <IASlider
          label={this.localizationService.strings.PlayAdmin_ArticleEditorBoxWidth?.toUpperCase()}
          min={30}
          max={90}
          value={`${this.props.store.articleToEdit.contentWidth}`}
          onChange={(value) => {
            this.props.store.articleToEdit.contentWidth = value;
            this.props.forceUpdate();
          }}
          color={this.props.store.highlightColor}
          styles={{ width: "100%" }}
        />
      }
      {/* Colors */}
      <label
        style={{
          color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
          marginTop: 15,
          float: "left"
        }}
      >
        {this.localizationService.strings.PlayAdmin_ColorTheme?.toUpperCase()}
      </label>
      <IAColorTheme
        backgroundColor={this.props.store.articleToEdit.colorTheme.backgroundColor}
        textColor={this.props.store.articleToEdit.colorTheme.textColor}
        highlightColor={this.props.store.highlightColor}
        darkMode={this.props.store.darkMode}
        inTeams
        remove={undefined}
        allowAddingToSwatches={false}
        setBackgroundColor={(backgroundColor) => {
          this.props.store.articleToEdit.colorTheme.backgroundColor = backgroundColor;
          this.props.forceUpdate();
        }}
        setTextColor={(textColor) => {
          this.props.store.articleToEdit.colorTheme.textColor = textColor;
          this.props.forceUpdate();
        }}
      />
      {/* Toggles */}
      {this.props.store.articleToEdit.boxStyle !== "onlyImage" &&
        <>
          <IAToggle
            style={{
              marginTop: 20,
              width: "100%",
              clear: 'both',
              float: "left"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTitle?.toUpperCase()}
            checked={!!this.props.store.articleToEdit.showTitle}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            labelStyle={{
              fontSize: 12
            }}
            onChange={() => {
              this.props.store.articleToEdit.showTitle = !!!this.props.store.articleToEdit.showTitle;
              this.props.forceUpdate();
            }}
          />
          <IAToggle
            style={{
              marginTop: 20,
              width: "100%",
              clear: 'both',
              float: "left"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowDate?.toUpperCase()}
            checked={!!this.props.store.articleToEdit.showDate}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            labelStyle={{
              fontSize: 12
            }}
            onChange={() => {
              this.props.store.articleToEdit.showDate = !!!this.props.store.articleToEdit.showDate;
              this.props.forceUpdate();
            }}
          />
        </>
      }
      {(this.props.store.articleToEdit.boxStyle === "box" || this.props.store.articleToEdit.boxStyle === "boxTransparent") &&
        <>
          <IAToggle
            style={{
              marginTop: 20,
              width: "100%",
              clear: 'both',
              float: "left"
            }}
            label={this.localizationService.strings.PlayAdmin_ArticleEditorBoxRoundCorners?.toUpperCase()}
            checked={!!this.props.store.articleToEdit.boxStyleRoundCorner}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            labelStyle={{
              fontSize: 12
            }}
            onChange={() => {
              this.props.store.articleToEdit.boxStyleRoundCorner = !!!this.props.store.articleToEdit.boxStyleRoundCorner;
              this.props.forceUpdate();
            }}
          />
        </>
      }
    </>;
  }
}