import * as React from 'react';
import * as styles from './Checkbox.css';
import { IAIcon } from '../icon/Icon';
import "../../styles/BasicIA.css";
import { ENTER_KEY_CODE, SPACEBAR_KEY_CODE } from '../../global/Constants';

export interface IACheckboxProps {
  label?: string;
  isChecked?: boolean;
  disabled?: boolean;
  styles?: React.CSSProperties;
  highlightColor: string;
  defaultColor?: string;
  onChange: (isChecked: boolean, name?: string) => void;
  name?: string;
  marked?: boolean;
  id?: string;
  userInteractionDisabled?: boolean;
  borderColor?: string;
}

export interface IACheckboxState {
  isChecked: boolean;
}

export class IACheckbox extends React.Component<IACheckboxProps, IACheckboxState> {

  constructor(props: IACheckboxProps) {
    super(props);
    this.state = {
      isChecked: props.isChecked ? props.isChecked : false
    }
  }

  public render(): JSX.Element {
    let checkmarkColor = "white";
    if (this.props.isChecked) {
      checkmarkColor = this.props.highlightColor;
    }
    let borderColor = !this.props.isChecked && !this.props.marked ? "#cccccc" : this.props.highlightColor;
    if (this.props.borderColor) borderColor = this.props.borderColor;
    
    return (
      <div
        className={styles.IA_Checkbox}
        style={{
          opacity: this.props.disabled == undefined || !this.props.disabled ? 1 : 0.5,
          ...this.props.styles
        }}
      >
        <label
          className={styles.container}
          style={{
            paddingLeft: (this.props.userInteractionDisabled == undefined || !this.props.userInteractionDisabled) ? 25 : 0
          }}
        >
          {(this.props.userInteractionDisabled == undefined || !this.props.userInteractionDisabled) &&
            <input
              id={this.props.id}
              type="Checkbox"
              disabled={this.props.disabled != undefined ? this.props.disabled : false}
              tabIndex={-1}
              className={styles.IA_CheckboxInput}
              checked={this.props.isChecked}
              onChange={() => {
                this.props.onChange(!this.props.isChecked, this.props.name);
              }}
            />
          }
          {(this.props.userInteractionDisabled == undefined || !this.props.userInteractionDisabled) &&
            <span
              tabIndex={0}
              className={[styles.checkmark, "IA_OnFocus"].join(" ")}
              style={{
                backgroundColor: checkmarkColor,
                border: `1px solid ${borderColor}`
              }}
              onKeyPress={(event) => {
                if (event.which === ENTER_KEY_CODE || event.which === SPACEBAR_KEY_CODE) {
                  this.props.onChange(this.state.isChecked, this.props.name);
                }
              }}
            >
              {this.props.isChecked &&
                <IAIcon
                  title="Checkmark"
                  size={15}
                  color="#ffffff"
                  style={{
                    marginLeft: 1,
                    height: 0
                  }}
                />
              }
              {!this.props.isChecked && this.props.marked &&
                <div
                  className={styles.IA_dot}
                  style={{
                    backgroundColor: this.props.highlightColor,
                  }}
                />
              }
            </span>
          }
          <div
            style={{
              marginLeft: 2,
              fontWeight: this.props.marked ? "bold" : "normal"
            }}
          >{this.props.label}
          </div>
        </label>
      </div>
    );
  }
}