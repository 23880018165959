import * as React from 'react';
import { Provider } from 'mobx-react';
import { configure } from 'mobx';
import { Store } from './stores';
import { Froala } from './froala/Froala';

export interface IEditorProps {
  simple?: boolean;
  token?: string;
  tenant?: string;
  environment?: "Development" | "Test" | "Production";
  isMobile: boolean;
  highlightColor: string;
  content: string;
  placeholder?: string;
  style?: React.CSSProperties;
  toolbarClassName?: string;
  quillClassName?: string
  id?: string;
  hideToolbox?: boolean;
  boxStyle?: any;
  darkMode?: boolean;
  hideBorder?: boolean;
  minHeight?: number;
  useImageAPI?: boolean;
  uploadImagesToSharepoint?: boolean;

  fontSizeSupport?: boolean;
  fontStyleSupport?: boolean;
  listsSupport?: boolean;
  textAlignmentSupport?: boolean;
  textColorSupport?: boolean;
  clearStylingSupport?: boolean;
  emojiSupport?: boolean;
  linkSupport?: boolean;
  imageSupport?: boolean;
  videoSupport?: boolean;
  tableSupport?: boolean;
  boxSupport?: boolean;
  htmlSupport?: boolean;
  quoteSupport?: boolean;
  fontFamilySupport?: boolean;
  paragraphStyleSupport?: boolean;
  indentSupport?: boolean;
  fontStyleAdditionalSupport?: boolean;
  lineHeightSupport?: boolean;

  onChange: (html: string) => void;
  onStyleChange?: (style: React.CSSProperties) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

export class IAEditor extends React.Component<IEditorProps> {

  private store: Store;

  constructor(props: IEditorProps) {
    super(props);
    this.store = new Store();
    configure({
      isolateGlobalState: true,
      enforceActions: "never"
    });
    this.updateStore(props);
  }

  public componentWillReceiveProps(props: IEditorProps) {
    this.updateStore(props);
  }

  private updateStore(props: IEditorProps): void {
    this.store.token = props.token;
    this.store.tenant = props.tenant;
    this.store.environment = props.environment;
    this.store.isMobile = props.isMobile;
    this.store.highlightColor = props.highlightColor;
    this.store.style = props.style;
    this.store.toolbarClassName = props.toolbarClassName;
    this.store.quillClassName = props.quillClassName;
    this.store.id = props.id;
    if (props.boxStyle) {
      this.store.boxStyle.backgroundColor = props.boxStyle.backgroundColor ?? this.store.boxStyle.backgroundColor;
      this.store.boxStyle.borderColor = props.boxStyle.borderColor ?? this.store.boxStyle.borderColor;
      this.store.boxStyle.borderRadius = props.boxStyle.borderRadius ?? this.store.boxStyle.borderRadius;
      this.store.boxStyle.borderStyle = props.boxStyle.borderStyle ?? this.store.boxStyle.borderStyle;
      this.store.boxStyle.padding = props.boxStyle.padding ?? this.store.boxStyle.padding;
    }
    this.store.darkMode = props.darkMode;
    this.store.simple = props.simple;
    this.store.minHeight = props.minHeight;
    this.store.useImageAPI = props.useImageAPI;

    this.store.content = props.content;
    this.store.placeholder = props.placeholder;
    this.store.uploadImagesToSharepoint = props.uploadImagesToSharepoint;

    this.store.fontSizeSupport = props.fontSizeSupport;
    this.store.fontStyleSupport = props.fontStyleSupport;
    this.store.listsSupport = props.listsSupport;
    this.store.textAlignmentSupport = props.textAlignmentSupport;
    this.store.textColorSupport = props.textColorSupport;
    this.store.clearStylingSupport = props.clearStylingSupport;
    this.store.emojiSupport = props.emojiSupport;
    this.store.linkSupport = props.linkSupport;
    this.store.imageSupport = props.imageSupport;
    this.store.videoSupport = props.videoSupport;
    this.store.tableSupport = props.tableSupport;
    this.store.boxSupport = props.boxSupport;
    this.store.htmlSupport = props.htmlSupport;
    this.store.quoteSupport = props.quoteSupport;
    this.store.fontFamilySupport = props.fontFamilySupport;
    this.store.paragraphStyleSupport = props.paragraphStyleSupport;
    this.store.indentSupport = props.indentSupport;
    this.store.fontStyleAdditionalSupport = props.fontStyleAdditionalSupport;
    this.store.lineHeightSupport = props.lineHeightSupport;
  }

  public render(): JSX.Element {
    return (
      <Provider store={this.store}>
        <Froala
          onChange={(html: string) => this.props.onChange(html)}
          onBlur={this.props.onBlur ? () => this.props.onBlur() : undefined}
          onFocus={this.props.onFocus ? () => this.props.onFocus() : undefined}
          onStyleChange={this.props.onStyleChange ? (style: any) => this.props.onStyleChange(style) : undefined}
        />
      </Provider>
    );
  }


}