
import { injectable } from "./Containers/Container";
import "./Global";

declare global {
  interface Window { webkit: any; app: any; android: WebAppInterface; platform: string }
  interface WebAppInterface {
    getToken(): string;
  }
}

@injectable("IntraActiveAppClientService")
export class IntraActiveAppClientService {
  public constructor() { }

  public getToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        switch (window.platform) {
          case "ios":
            const payload = { functionName: "getToken" };
            const token: string = prompt(JSON.stringify(payload));
            resolve(token);
          case "android":
            resolve(window.android.getToken());
          default:
            reject("Not supported outside ios and android");
        }
      } catch (error) {
        reject(error);
      }
    });
  }
}