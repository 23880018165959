import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './GettingStartedOverlay.css';
import { Store } from '../../stores';
import { Helper } from '../../../../Helper';
import { IAIcon } from '../../../icon/Icon';

export interface IProps {
  store?: Store;
  height: number;
  top: number;
  videoUrl: string;
  posterUrl: string;
  title: string;
  onHide: () => void;
}

export interface IState {
}

@inject('store')
@observer
export class GettingStartedOverlay extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  public componentDidMount(): void {
  }

  public render(): JSX.Element {
    return this.props.videoUrl === "-" ? (<></>) : (
      <div
        className={styles.IA_gettingStartedOverlay}
        style={{
          height: this.props.height,
          top: this.props.store.isMobile ? 45 : this.props.top,
          backgroundColor: this.props.store.darkMode ? `${Helper.darkModeBackgroundColor}d1` : "#FFFFFFd1",
          padding: this.props.store.isMobile ? 0 : "20px 40px"
        }}
      >
        <div
          className={styles.IA_video}
          style={{
            width: this.props.store.isMobile ? screen.availWidth - 40 : 500,
            height: this.props.store.isMobile ? ((screen.availWidth - 40) / 16) * 9 : 281,
            marginLeft: this.props.store.isMobile ? 0 : "calc(50% - 250px)"
          }}
        >
          <div
            className={styles.IA_close}
            style={{
              backgroundColor: "#00000033"
            }}
          >
            <IAIcon
              title={"Close"}
              size={18}
              color={"#ffffff"}
              style={{
                marginLeft: 1
              }}
              onClick={() => this.props.onHide()}
            />
          </div>
          <video
            width="100%"
            height="100%"
            controls
            poster={this.props.posterUrl}
            style={{
              backgroundColor: this.props.store.highlightColor
            }}
          >
            <source src={this.props.videoUrl} type="video/mp4" />
          </video>
          <div className={styles.IA_title}>{this.props.title}</div>
        </div>
      </div>
    );
  }

  
}