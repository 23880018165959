import { observable, action } from "mobx";
import ReactQuill from "react-quill-with-table";
import { Helper } from "../../../Helper";

export class Store {

  @observable public token: string;
  @observable public tenant: string;
  @observable public environment: "Development" | "Test" | "Production";
  @observable public isMobile: boolean;
  @observable public highlightColor: string;
  @observable public style: React.CSSProperties;
  @observable public toolbarClassName?: string;
  @observable public quillClassName?: string;
  @observable public placeholder: string;
  @observable public id: string;
  @observable public boxStyle: React.CSSProperties = Helper.defaultBoxStyle;
  @observable public darkMode: boolean;
  @observable public simple: boolean;
  @observable public minHeight: number;
  @observable public useImageAPI: boolean;

  // Tool options

  @observable public fontSizeSupport?: boolean;
  @observable public fontStyleSupport?: boolean;
  @observable public listsSupport?: boolean;
  @observable public textAlignmentSupport?: boolean;
  @observable public textColorSupport?: boolean;
  @observable public clearStylingSupport?: boolean;
  @observable public emojiSupport?: boolean;
  @observable public linkSupport?: boolean;
  @observable public imageSupport?: boolean;
  @observable public videoSupport?: boolean;
  @observable public tableSupport?: boolean;
  @observable public boxSupport?: boolean;
  @observable public htmlSupport?: boolean;
  @observable public quoteSupport?: boolean;
  @observable public fontFamilySupport?: boolean;
  @observable public paragraphStyleSupport?: boolean;
  @observable public indentSupport?: boolean;
  @observable public fontStyleAdditionalSupport?: boolean;
  @observable public lineHeightSupport?: boolean;

  // Quill editor
  @observable public content?: string;
  @observable public editorInstanceId: string = Helper.getRandomStringKey();
  @observable public reactQuillRef: ReactQuill;
  @observable public cursorPositionLeft?: number;
  @observable public cursorPositionTop?: number;
  @observable public quillSelection?: { index: number; length: number; };
  @observable public editorSelectionRange?: any;

  // Image tool
  @observable public showImagePicker: boolean;
  @observable public url?: string;
  @observable public isLoadingPreview: boolean;
  @observable public isDragging: boolean;
  @observable public uploadImagesToSharepoint: boolean;

  // Link tool
  @observable public showLinkEditor: boolean;
  @observable public linkEditorTarget?: string = "_blank";
  @observable public linkEditorUrl?: string;
  @observable public linkEditorTitle?: string;
  @observable public linkEditorSelectionHasTrailingSpace?: boolean;
  @observable public textSelection?: any;

  // Emoji tool
  @observable public showEmojiPicker: boolean;

  // Video tool
  @observable public showVideoTool: boolean;

  // Table tool
  @observable public showTableTool: boolean;
  @observable public videoEmbedCode: string;

  // Box tool
  @observable public showBoxTool: boolean;

  // Font color and background color (froala)
  @observable public showFontColorTool: boolean;
  @observable public showFontBackgroundColorTool: boolean;

  // Html tool
  @observable public showHtmlTool: boolean;

  @action public isToolOpen() {
    return (this.showBoxTool
      || this.showEmojiPicker
      || this.showHtmlTool
      || this.showImagePicker
      || this.showLinkEditor
      || this.showTableTool
      || this.showVideoTool);
  }

  @action public closeAllTools() {
    this.showBoxTool = false;
    this.showEmojiPicker = false;
    this.showHtmlTool = false;
    this.showImagePicker = false;
    this.showLinkEditor = false;
    this.showTableTool = false;
    this.showVideoTool = false;
  }

  @action public updateBoxStyle() {
    const editorElement = this.reactQuillRef.getEditor();
    editorElement.root.parentElement.style.backgroundColor = this.boxStyle.backgroundColor;
    editorElement.root.parentElement.style.borderRadius = `${this.boxStyle.borderRadius}px`;
    editorElement.root.parentElement.style.padding = `${this.boxStyle.padding}px`;
    editorElement.root.parentElement.style.border = `1px ${this.boxStyle.borderStyle} ${this.boxStyle.borderColor}`;
    if (this.boxStyle.backgroundColor === "transparent" || this.boxStyle.backgroundColor === "#ffffff") {
      editorElement.root.parentElement.style.padding = "0";
    } else {
      editorElement.root.parentElement.style.padding = "20px";
    }
  }
}