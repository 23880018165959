exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_canteenMenuItem_1OmPerzZ_326f-J9aPcKzm {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n\n.IA_title_1PHNAzhHcPrloANN-cwyxw {\n  float: left;\n  clear: both;\n  position: relative;\n}\n\n.IA_contentWrapper_2EHoGpuPmu98bE9jAuoa-t {\n  float: left;\n  overflow: hidden;\n  width: 100%;\n  position: relative;\n}\n\n.IA_text_2dq4f7zevbDXwy1lQwY4ty {\n  float: left;\n  clear: both;\n}\n\n.IA_text_2dq4f7zevbDXwy1lQwY4ty p,\n.IA_text_2dq4f7zevbDXwy1lQwY4ty ul {\n  margin: 0;\n}\n\n.IA_progressBarBackground_X4wenuCv1W-ILTGZn2dfx {\n  position: relative;\n  float: left;\n  clear: both;\n  position: relative;\n}\n\n.IA_startDate_YFm-Ki6fivch__zvziNeT {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n  font-weight: lighter;\n}\n\n.IA_byline_2GI4KDb5_G11wX4fuGcN5A {\n  float: left;\n  clear: both;\n  position: relative;\n}", ""]);

// Exports
exports.locals = {
	"IA_canteenMenuItem": "IA_canteenMenuItem_1OmPerzZ_326f-J9aPcKzm",
	"IA_title": "IA_title_1PHNAzhHcPrloANN-cwyxw",
	"IA_contentWrapper": "IA_contentWrapper_2EHoGpuPmu98bE9jAuoa-t",
	"IA_text": "IA_text_2dq4f7zevbDXwy1lQwY4ty",
	"IA_progressBarBackground": "IA_progressBarBackground_X4wenuCv1W-ILTGZn2dfx",
	"IA_startDate": "IA_startDate_YFm-Ki6fivch__zvziNeT",
	"IA_byline": "IA_byline_2GI4KDb5_G11wX4fuGcN5A"
};