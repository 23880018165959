exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_personaSmall_1bpX-kAl_uMYuX7JjaKrNL {\n  font-size: 12px;\n  float: left;\n  margin-top: 3px;\n  display: block;\n  width: 100%;\n}\n\n.IA_personaSmall_1bpX-kAl_uMYuX7JjaKrNL .IA_personaSmallProfileImageWrapper_60NQbZeJj-2d7JYUnppTb {\n  height: 30px;\n  width: 30px;\n  float: left;\n  border-radius: 30px;\n  margin-right: 7px;\n  margin-top: 1px;\n  font-size: 15px;\n  text-align: center;\n  line-height: 29px;\n  text-transform: uppercase;\n  font-weight: 200;\n}\n\n.IA_personaSmall_1bpX-kAl_uMYuX7JjaKrNL .IA_personaSmallProfileImage_1GfVQ7BCH1PkpOqiid2Tq6 {\n  height: 32px;\n  width: 32px;\n  border-radius: 30px;\n  background-position: center;\n  margin-top: -32px;\n  left: -1px;\n  position: relative;\n  float: left;\n  background-size: cover;\n}\n\n.IA_personaSmall_1bpX-kAl_uMYuX7JjaKrNL .IA_personaSmallProfileImageText_3PEEq87KAjmioXHBZikDo0 {\n  height: 30px;\n  width: 30px;\n  border-radius: 30px;\n  left: 0px;\n  top: 0px;\n  position: relative;\n  float: left;\n}\n\n.IA_personaSmall_1bpX-kAl_uMYuX7JjaKrNL .IA_personaSmallName_3Q3qOHixav2YJttNt456Xq {\n  float: none;\n  width: calc(100% - 40px);\n  height: 16px;\n  margin-top: -2px;\n  line-clamp: 1;\n  -webkit-line-clamp: 1;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  font-weight: 600;\n  white-space: nowrap;\n}\n\n.IA_personaSmall_1bpX-kAl_uMYuX7JjaKrNL .IA_personaSmallMetadata_3FyxEVxQGm69v6y5diHifm {\n  float: none;\n  width: calc(100% - 40px);\n  margin-top: -2px;\n  line-clamp: 1;\n  -webkit-line-clamp: 1;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  color: #858585;\n  margin-top: 2px;\n}\n\n.IA_personaSmall_1bpX-kAl_uMYuX7JjaKrNL .IA_personaSmallModifiedDate_3mfNGazkElQVnS9YC-AR6q {\n  float: none;\n  height: 12px;\n  width: 100%;\n  margin-top: 1px;\n}", ""]);

// Exports
exports.locals = {
	"IA_personaSmall": "IA_personaSmall_1bpX-kAl_uMYuX7JjaKrNL",
	"IA_personaSmallProfileImageWrapper": "IA_personaSmallProfileImageWrapper_60NQbZeJj-2d7JYUnppTb",
	"IA_personaSmallProfileImage": "IA_personaSmallProfileImage_1GfVQ7BCH1PkpOqiid2Tq6",
	"IA_personaSmallProfileImageText": "IA_personaSmallProfileImageText_3PEEq87KAjmioXHBZikDo0",
	"IA_personaSmallName": "IA_personaSmallName_3Q3qOHixav2YJttNt456Xq",
	"IA_personaSmallMetadata": "IA_personaSmallMetadata_3FyxEVxQGm69v6y5diHifm",
	"IA_personaSmallModifiedDate": "IA_personaSmallModifiedDate_3mfNGazkElQVnS9YC-AR6q"
};