exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_VMr9_-5V8KkXoiFfLNdCm {\n  position: relative;\n  float: right;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_VMr9_-5V8KkXoiFfLNdCm .likes_pTVZikq9NxPF0N-WKDiDC {\n  height: 20px;\n  float: right;\n  position: relative;\n  cursor: pointer;\n}\n\n.likesAndComments_VMr9_-5V8KkXoiFfLNdCm .spinner_2j_ACLekrTEcWaMKw2iuRC {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_urfBVKxy2yti_FI2kB68n 700ms linear infinite;\n  -moz-animation: spin_urfBVKxy2yti_FI2kB68n 700ms linear infinite;\n  animation: spin_urfBVKxy2yti_FI2kB68n 700ms linear infinite;\n}\n\n.likesAndComments_VMr9_-5V8KkXoiFfLNdCm .likesText_1NsmANi_vp98KgTxknixJM {\n  float: left;\n  margin-left: 4px;\n  margin-top: -1px;\n}\n\n.likesAndComments_VMr9_-5V8KkXoiFfLNdCm .likesTextVertical_2IXPhcDTSyX9fYfIcPpqMJ {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n\n@-moz-keyframes spin_urfBVKxy2yti_FI2kB68n {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_urfBVKxy2yti_FI2kB68n {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_urfBVKxy2yti_FI2kB68n {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_VMr9_-5V8KkXoiFfLNdCm",
	"likes": "likes_pTVZikq9NxPF0N-WKDiDC",
	"spinner": "spinner_2j_ACLekrTEcWaMKw2iuRC",
	"spin": "spin_urfBVKxy2yti_FI2kB68n",
	"likesText": "likesText_1NsmANi_vp98KgTxknixJM",
	"likesTextVertical": "likesTextVertical_2IXPhcDTSyX9fYfIcPpqMJ"
};