import * as React from 'react';
import ReactPlayer from 'react-player';

export interface IProps {
  style?: React.CSSProperties;
  mimeType: string;
  previewUrl: string
  onLoad?: (ref?: HTMLImageElement) => void;
}

export class MediaFilePreview extends React.Component<IProps> {
  private imageRef: HTMLImageElement;

  public render(): JSX.Element {
    return (
      <div style={this.props.style}>
        {this.props.mimeType ? 
        <>
          {this.props.mimeType?.indexOf("image") !== -1 &&
            <img
              ref={(ref) => this.imageRef = ref}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain"
              }}
              src={this.props.previewUrl}
              onLoad={() => {
                if (this.props.onLoad) this.props.onLoad(this.imageRef);
              }}
            />
          }
          {this.props.mimeType?.indexOf("video") != -1 &&
            <ReactPlayer
              onStart={() => this.props.onLoad && this.props.onLoad()}
              className='react-player'
              url={this.props.previewUrl}
              width={"100%"}
              height={"100%"}
              muted
              controls
              playing
              loop
            />
          }
          {this.props.mimeType === "application/pdf" &&
            <iframe
              src={`${this.props.previewUrl}#toolbar=0&navpanes=0&scrollbar=0&view=fit`} width="100%" height="100%"
              onLoad={() => this.props.onLoad && this.props.onLoad()}
            />
          }
        </>
      : 
        <></>}
      </div>
    );
  }
}
