import { Container } from "intraactive-sdk-helper";

declare global {
  interface Window {
    globalSdkContainerInstance: Container;
  }
}

export const sdkContainerInstance: Container = window.globalSdkContainerInstance
  ? window.globalSdkContainerInstance
  : (window.globalSdkContainerInstance = new Container());