import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from '../WidgetEditPanel.css';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IAOptionSelector } from '../../../../../optionSelector/OptionSelector';
import { IANumberField } from '../../../../../numberField/NumberField';
import { IAToggle } from '../../../../../toggle/Toggle';
import PlayService from '../../../../../../services/PlayService';
import { IFeed } from '../../../../../../interfaces/IFeed';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { IAButton } from '../../../../../button/Button';
import { Helper } from '../../../../../../Helper';
import { IASpinner } from '../../../../../spinner/Spinner';
import { IAColorPicker } from '../../../../../colorPicker/ColorPicker';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IAColorTheme } from '../../../../../colorTheme/ColorTheme';
import { IATextField } from '../../../../../textField/TextField';
import { IAIcon } from '../../../../../icon/Icon';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
  viewerType: "messageViewer" | "eventViewer" | "sharepointNewsViewer" | "workplaceFeedViewer";
}

export interface IState {
  feeds?: IFeed[];
  loadingFeeds?: boolean;
  colorThemeForPreview?: any;
  viewer: any;
  feedInstances?: string;
  groupToAdd?: string;
}

@inject('store')
@observer
export class MessageViewerWidgetSettings extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      feeds: [],
      loadingFeeds: true,
      viewer: this.getViewer()
    }
  }

  componentDidMount() {
    this.getAvailableInstances();
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.viewerType !== this.props.viewerType) {
      // viewerType has been changed - fetch feeds for new viewerType
      if (this.props.viewerType !== "workplaceFeedViewer") {
        this.getAvailableInstances();
      }
    }
  }

  private getViewer(): any {
    if (this.props.viewerType === "messageViewer") {
      return this.props.store.widgetToEdit.messageViewer;
    }
    if (this.props.viewerType === "eventViewer") {
      return this.props.store.widgetToEdit.eventViewer;
    }
    if (this.props.viewerType === "sharepointNewsViewer") {
      return this.props.store.widgetToEdit.sharepointNewsViewer;
    }
    if (this.props.viewerType === "workplaceFeedViewer") {
      return this.props.store.widgetToEdit.workplaceFeedViewer;
    }
  }

  private async getAvailableInstances() {
    this.setState({ loadingFeeds: true });
    let feeds;
    if (this.props.store.widgetToEdit.messageViewer || this.props.store.widgetToEdit.eventViewer) {
      feeds = await PlayService.getProfileIntegrations(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, this.props.store.widgetToEdit.messageViewer ? 'messages' : 'events')
      this.setState({ feeds, loadingFeeds: false });
    } else {
      feeds = await PlayService.getSharePointProfileNewsSites(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id)
      this.setState({ feeds, loadingFeeds: false });
    }
    if (feeds?.length > 0) {
      this.onFeedChange(this.state.viewer.feedId ? this.state.viewer.feedId : feeds[0].id);
    }
  }

  private async onFeedChange(feedId: string) {
    this.state.viewer.feedId = feedId;
    if (this.props.store.widgetToEdit.messageViewer) {
      this.state.viewer.messages = await PlayService.getFeed(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, 'messages', this.state.viewer.feedId)
    } else if ((this.props.store.widgetToEdit.eventViewer)) {
      let from: Date = this.props.store.widgetToEdit.eventViewer.excludeToday ? new Date(new Date().setDate(new Date().getDate() + 1)) : new Date();
      let to: Date;
      if (this.props.store.widgetToEdit.eventViewer.layoutType === "week") {
        const currentDayInWeek: number = new Date().getDay();
        switch (currentDayInWeek) {
          case 0: // Sunday
            from = new Date(new Date().setDate(new Date().getDate() + 1));
            to = new Date(new Date().setDate(new Date().getDate() + 6));
            break;
          case 1: // Monday
            from = new Date();
            to = new Date(new Date().setDate(new Date().getDate() + 5));
            break;
          case 2: // Tuesday
            from = new Date(new Date().setDate(new Date().getDate() - 1));
            to = new Date(new Date().setDate(new Date().getDate() + 3));
            break;
          case 3: // Wednesday
            from = new Date(new Date().setDate(new Date().getDate() - 2));
            to = new Date(new Date().setDate(new Date().getDate() + 2));
            break;
          case 4: // Thursday
            from = new Date(new Date().setDate(new Date().getDate() - 3));
            to = new Date(new Date().setDate(new Date().getDate() + 1));
            break;
          case 5: // Friday
            from = new Date(new Date().setDate(new Date().getDate() - 4));
            to = new Date();
            break;
          case 6: // Saturday
            from = new Date(new Date().setDate(new Date().getDate() + 2));
            to = new Date(new Date().setDate(new Date().getDate() + 7));
            break;
          default:
            break;
        }
      } else {
        switch (this.props.store.widgetToEdit.eventViewer.timespand) {
          case "today":
            to = new Date();
            break;
          case "week":
            to = new Date(new Date().setDate(new Date().getDate() + 7));
            break;
          case "month":
            to = new Date(new Date().setDate(new Date().getDate() + 30));
            break;
          case "upcomming":
            to = new Date(new Date().setDate(new Date().getDate() + 10000));
            break;
          default:
            break;
        }
      }
      this.state.viewer.events = await PlayService.getFeed(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, 'events', this.state.viewer.feedId, from?.toISOString(), to?.toISOString());
    } else if ((this.props.store.widgetToEdit.sharepointNewsViewer)) {
      this.props.store.widgetToEdit.sharepointNewsViewer.news = await PlayService.getSharepointNews(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, feedId, this.state.viewer.amountToShow);
    }
    this.props.forceUpdate();
    setTimeout(() => {
      this.props.forceUpdate();
    }, 500);
    // Instances on feed
    this.state.feeds?.forEach((feed: IFeed) => {
      if (feed.id === feedId && feed.instances?.length > 0) {
        let feeds = "";
        feed.instances?.forEach((feedInstance: string, index) => {
          feeds += feedInstance;
          if (index < feed.instances.length - 1) {
            feeds += ", ";
          }
        });
        this.setState({ feedInstances: feeds });
      }
    })
  }

  render(): React.ReactNode {
    let availableFeeds = this.state.feeds?.map(feed => {
      return {
        key: feed.id,
        value: feed.title
      }
    });
    const colorThemes = [];
    if (this.state.viewer?.colorThemes?.length > 0) {
      this.state.viewer.colorThemes.forEach((colorTheme, index) => {
        colorThemes.push(
          <IAColorTheme
            key={"colorTheme_" + index}
            backgroundColor={colorTheme.backgroundColor}
            textColor={colorTheme.textColor}
            disableTextColor={this.props.store.widgetToEdit?.workplaceFeedViewer != undefined}
            highlightColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            inTeams
            remove={() => {
              this.state.viewer.colorThemes.splice(index, 1);
              this.props.forceUpdate();
            }}
            allowAddingToSwatches={false}
            setBackgroundColor={(backgroundColor) => {
              colorTheme.backgroundColor = backgroundColor;
              this.props.forceUpdate();
            }}
            setTextColor={(textColor) => {
              colorTheme.textColor = textColor;
              this.props.forceUpdate();
            }}
          />
        )
      });
    }
    const groups: JSX.Element[] = [];
    if (this.props.store.widgetToEdit.workplaceFeedViewer?.groups?.length > 0) {
      this.props.store.widgetToEdit.workplaceFeedViewer.groups.forEach((group: string, index) => {
        groups.push(
          <div className={styles.IA_group}>
            <div className={styles.IA_groupId}>
              {group.toUpperCase()}
            </div>
            <IAIcon
              url='https://intraactivestorage.blob.core.windows.net/cdn/icons/Delete/SVG/ic_fluent_delete_48_regular.svg'
              size={22}
              color={"#333333"}
              style={{
                float: "left",
                marginLeft: 10,
                marginTop: 2
              }}
              onClick={() => {
                this.props.store.widgetToEdit.workplaceFeedViewer.groups.splice(index, 1);
                this.forceUpdate()
                this.setState({ loadingFeeds: true });
                PlayService.getWorkplaceFeed(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, this.props.store.widgetToEdit.workplaceFeedViewer.groups).then((feed) => {
                  this.props.store.widgetToEdit.workplaceFeedViewer.feed = feed;
                  this.setState({ loadingFeeds: false });
                  this.props.forceUpdate();
                  setTimeout(() => {
                    this.props.forceUpdate();
                  }, 500);
                });
              }}
            />
          </div>
        );
      });
    }
    const amountToShowLabel = this.props.store.widgetToEdit.messageViewer
      ? this.localizationService.strings.PlayAdmin_AmountOfMessagesToShow
      : this.props.store.widgetToEdit.eventViewer
        ? this.localizationService.strings.PlayAdmin_AmountOfEventsToShow
        :  this.props.store.widgetToEdit.sharepointNewsViewer
          ? this.localizationService.strings.PlayAdmin_AmountOfSharepointNewsToShow
          : this.localizationService.strings.PlayAdmin_AmountOfWorkplaceFeedItemsToShow;
    return (
      <>
        {this.props.store.widgetToEdit.eventViewer?.layoutType !== "list" && this.props.store.widgetToEdit.eventViewer?.layoutType !== "week" &&
          <IANumberField
            label={this.localizationService.strings.PlayAdmin_MinimumDuration?.toUpperCase()}
            number={this.state.viewer.duration}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            onChange={(duration: number) => {
              this.state.viewer.duration = duration;
              this.props.forceUpdate();
            }}
            styles={{
              width: "100%",
              clear: "both"
            }}
          />
        }
        {this.props.viewerType === "workplaceFeedViewer" &&
          <>
            <IATextField
              label={"GROUPS"}
              required
              placeholder={"Add group..."}
              text={this.state.groupToAdd}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              borderRadius={5}
              labelStyle={{
                fontSize: "12px",
                width: 320
              }}
              style={{
                width: "calc(100% - 80px)",
                float: "left"
              }}
              onChange={(group: string) => {
                this.setState({ groupToAdd: group });
              }}
            />
            <IAIcon
              url='https://intraactivestorage.blob.core.windows.net/cdn/icons/Add/SVG/ic_fluent_add_28_regular.svg'
              size={25}
              color={this.props.store.highlightColor}
              style={{
                float: "left",
                marginTop: 43,
                marginLeft: 10,
                opacity: this.state.groupToAdd?.length > 0 ? 1 : 0.5
              }}
              onClick={() => {
                if (this.state.groupToAdd?.length > 0) {
                  if (this.props.store.widgetToEdit.workplaceFeedViewer.groups == undefined) {
                    this.props.store.widgetToEdit.workplaceFeedViewer.groups = [];
                  }
                  this.props.store.widgetToEdit.workplaceFeedViewer.groups.push(this.state.groupToAdd);
                  this.forceUpdate()
                  this.setState({ loadingFeeds: true });
                  PlayService.getWorkplaceFeed(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, this.props.store.widgetToEdit.workplaceFeedViewer.groups).then((feed) => {
                    this.props.store.widgetToEdit.workplaceFeedViewer.feed = feed;
                    this.setState({ loadingFeeds: false });
                    this.props.forceUpdate();
                    setTimeout(() => {
                      this.props.forceUpdate();
                    }, 500);
                  });
                  this.setState({ groupToAdd: undefined });
                }
              }}
            />
            <div style={{ clear: "both", marginBottom: 10, float: "left" }}>
              {groups}
            </div>
          </>
        }
        {this.props.viewerType !== "workplaceFeedViewer" &&
          <div style={{
            float: "left",
            width: "100%",
            marginTop: 20,
            color: this.props.store.darkMode ? "#a8a8a8" : "#333333"
          }}>
            <label>{this.localizationService.strings.PlayAdmin_SelectFeed?.toUpperCase()}</label>
            {this.state.loadingFeeds ?
              <IASpinner
                color={this.props.store.highlightColor}
                style={{
                  margin: "18px auto"
                }}
              />
              :
              <IADropDown
                selectedOption={this.state.viewer.feedId}
                options={availableFeeds}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                onChange={(id: string) => {
                  this.onFeedChange(id)
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                style={{
                  marginTop: 20,
                  marginBottom: 10,
                  width: "100%"
                }}
              />
            }
            {this.state.viewer?.feedId && this.state.feedInstances &&
              <div
                style={{
                  fontSize: 12,
                  color: "gray",
                  marginBottom: 5
                }}
              >
                {`${this.localizationService.strings.PlayAdmin_InstancesInFeed}: ${this.state.feedInstances}`}
              </div>
            }
          </div>
        }
        {this.props.store.widgetToEdit.eventViewer?.layoutType !== "list" && this.props.store.widgetToEdit.eventViewer?.layoutType !== "week" &&
          <IANumberField
            label={amountToShowLabel?.toUpperCase()}
            number={this.state.viewer.amountToShow}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            labelStyle={{
              fontSize: "12px"
            }}
            min={1}
            max={50}
            onChange={(amountToShow: number) => {
              this.state.viewer.amountToShow = amountToShow;
              this.props.forceUpdate();
            }}
            styles={{
              width: "100%",
              clear: "both"
            }}
          />
        }
        <MediaFileSelector
          label={this.localizationService.strings.PlayAdmin_NoContentImageUrl?.toUpperCase()}
          url={this.state.viewer.noContentImageUrl ? this.state.viewer.noContentImageUrl : this.localizationService.strings.Player_NoContentUrl}
          type={"image"}
          onChange={(noContentImageUrl: string) => {
            this.state.viewer.noContentImageUrl = noContentImageUrl;
            this.forceUpdate();
            this.props.forceUpdate();
          }}
        />

        {this.props.store.widgetToEdit.workplaceFeedViewer &&
          <>
            <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
            <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{"Header"}</h3>
            <MediaFileSelector
              label={"Header image"}
              url={this.props.store.widgetToEdit.headerImage ? this.props.store.widgetToEdit.headerImage : undefined}
              type={"image"}
              onChange={(headerImage: string) => {
                this.props.store.widgetToEdit.headerImage = headerImage;
                this.forceUpdate();
                this.props.forceUpdate();
              }}
            />
            <IATextField
              text={this.props.store.widgetToEdit.headerTitle}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              borderRadius={5}
              label={"Header title"}
              style={{
                marginTop: 10
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              inputFieldStyle={{
                marginBottom: 10
              }}
              onChange={(headerTitle: string) => {
                this.props.store.widgetToEdit.headerTitle = headerTitle;
                this.props.forceUpdate();
              }}
            />
            <IATextField
              text={this.props.store.widgetToEdit.headerSubtitle}
              darkMode={this.props.store.darkMode}
              highlightColor={this.props.store.highlightColor}
              borderRadius={5}
              label={"Header subtitle"}
              style={{
                marginTop: 10
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              inputFieldStyle={{
                marginBottom: 10
              }}
              onChange={(headerSubtitle: string) => {
                this.props.store.widgetToEdit.headerSubtitle = headerSubtitle;
                this.props.forceUpdate();
              }}
            />
          </>
        }

        <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />

        <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.localizationService.strings.PlayAdmin_Layout}</h3>

        {/* View type for events */}
        {this.props.store.widgetToEdit.eventViewer &&
          <>
            <IAOptionSelector
              label={this.localizationService.strings.PlayAdmin_LayoutType?.toUpperCase()}
              options={[
                {
                  icon: "Slides",
                  key: "slide",
                  text: this.localizationService.strings.PlayAdmin_LayoutTypeSlides
                },
                {
                  icon: "Thumbnail list",
                  key: "list",
                  text: this.localizationService.strings.PlayAdmin_LayoutTypeList
                },
                {
                  icon: "Canteen",
                  key: "week",
                  text: this.localizationService.strings.PlayAdmin_LayoutTypeWeek
                },
              ]}
              selected={this.state.viewer.layoutType ? this.state.viewer.layoutType : "slide"}
              highlightColor={this.props.store.highlightColor}
              compact
              iconSize={30}
              darkMode={this.props.store.darkMode}
              borderRadius={5}
              labelStyle={{
                fontSize: "12px"
              }}
              onChange={(layoutType: any) => {
                this.state.viewer.layoutType = layoutType;
                this.onFeedChange(this.state.viewer.feedId);
                this.props.forceUpdate();
              }}
              style={{ clear: "none", marginTop: 20 }}
            />
            {this.props.store.widgetToEdit.eventViewer?.layoutType === "week" &&
              <div className={styles.IA_disclaimer}>
                {this.localizationService.strings.PlayAdmin_CanteenMenuDisclaimer}
              </div>
            }
            {(this.state.viewer.layoutType == undefined || this.state.viewer.layoutType === "slide") &&
              <>
                <IAOptionSelector
                  label={this.localizationService.strings.PlayAdmin_TransitionType?.toUpperCase()}
                  options={[
                    {
                      icon: "Transition Crossfade",
                      key: "crossfade",
                      text: this.localizationService.strings.PlayAdmin_Crossfade
                    },
                    {
                      icon: "Transition Slide",
                      key: "slide",
                      text: this.localizationService.strings.PlayAdmin_Slide
                    },
                  ]}
                  selected={this.state.viewer.transition}
                  highlightColor={this.props.store.highlightColor}
                  compact
                  labelStyle={{
                    fontSize: "12px"
                  }}
                  iconSize={30}
                  darkMode={this.props.store.darkMode}
                  borderRadius={5}
                  onChange={(transitionType: any) => {
                    this.state.viewer.transition = transitionType;
                    this.props.forceUpdate();
                  }}
                  style={{ clear: "none", marginTop: 20 }}
                />
              </>
            }
          </>
        }

        {/* Timespan for events */}
        {this.props.store.widgetToEdit.eventViewer && this.props.store.widgetToEdit.eventViewer.layoutType !== "week" &&
          <>
            <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
            <div style={{
              float: "left",
              width: "100%",
            }}>
              <IADropDown
                label={this.localizationService.strings.PlayAdmin_Timespand?.toUpperCase()}
                selectedOption={this.props.store.widgetToEdit.eventViewer.timespand}
                options={[
                  {
                    key: "today",
                    value: this.localizationService.strings.PlayAdmin_TimespandToday
                  },
                  {
                    key: "week",
                    value: this.localizationService.strings.PlayAdmin_TimespandWeek
                  },
                  {
                    key: "month",
                    value: this.localizationService.strings.PlayAdmin_TimespandMonth
                  },
                  {
                    key: "upcoming",
                    value: this.localizationService.strings.PlayAdmin_TimespandUpcoming
                  }
                ]}
                borderRadius={5}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                onChange={(timespand) => {
                  this.props.store.widgetToEdit.eventViewer.timespand = timespand;
                  this.onFeedChange(this.state.viewer.feedId);
                }}
                style={{
                  clear: "none",
                  float: "left",
                  width: "100%"
                }}
              />
              <IAToggle
                style={{
                  marginTop: 20
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                label={this.localizationService.strings.PlayAdmin_ExcludeToday?.toUpperCase()}
                checked={this.props.store.widgetToEdit.eventViewer.excludeToday}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.props.store.widgetToEdit.eventViewer.excludeToday = !this.props.store.widgetToEdit.eventViewer.excludeToday;
                  this.onFeedChange(this.state.viewer.feedId);
                }}
              />
            </div>
            <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
          </>
        }

        {/* Transition type for rotator */}
        {this.props.store.widgetToEdit.messageViewer &&
          <IADropDown
            label={this.localizationService.strings.PlayAdmin_ContentBackground?.toUpperCase()}
            selectedOption={this.state.viewer.boxStyle}
            options={[
              {
                key: "solid",
                value: this.localizationService.strings.PlayAdmin_SolidBackground
              },
              {
                key: "transparent",
                value: this.localizationService.strings.PlayAdmin_TransparentBackground
              },
              {
                key: "gradient",
                value: this.localizationService.strings.PlayAdmin_GradientBackground
              }
            ]}
            borderRadius={5}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            onChange={(boxStyle) => {
              this.state.viewer.boxStyle = boxStyle;
              this.props.forceUpdate();
            }}
            style={{
              clear: "none",
              float: "left",
              width: "100%"
            }}
          />
        }
        {(this.props.store.widgetToEdit.messageViewer || this.props.store.widgetToEdit.sharepointNewsViewer || this.props.store.widgetToEdit.workplaceFeedViewer || (this.props.store.widgetToEdit.eventViewer && this.props.store.widgetToEdit.eventViewer.layoutType === "slide")) && this.state.viewer.showImage && this.state.viewer.boxStyle !== "postcard" &&
          <IADropDown
            label={this.localizationService.strings.PlayAdmin_TextPosition?.toUpperCase()}
            selectedOption={this.state.viewer.contentPosition}
            options={[
              {
                key: "left",
                value: this.localizationService.strings.PlayAdmin_TextPositionLeft,
              },
              {
                key: "right",
                value: this.localizationService.strings.PlayAdmin_TextPositionRight,
              },
              {
                key: "random",
                value: this.localizationService.strings.PlayAdmin_TextPositionRandom,
              }
            ]}
            borderRadius={5}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            onChange={(contentPosition: any) => {
              if (this.props.store.widgetToEdit.messageViewer) {
                this.props.store.widgetToEdit.messageViewer.contentPosition = contentPosition;
              }
              if (this.props.store.widgetToEdit.eventViewer) {
                this.props.store.widgetToEdit.eventViewer.contentPosition = contentPosition;
              }
              if (this.props.store.widgetToEdit.sharepointNewsViewer) {
                this.props.store.widgetToEdit.sharepointNewsViewer.contentPosition = contentPosition;
              }
              if (this.props.store.widgetToEdit.workplaceFeedViewer) {
                this.props.store.widgetToEdit.workplaceFeedViewer.contentPosition = contentPosition;
              }
              this.props.forceUpdate();
            }}
            style={{
              clear: "none",
              float: "left",
              width: "100%",
              marginBottom: 5
            }}
          />
        }
        <>
          {(this.props.store.widgetToEdit.eventViewer?.layoutType === "list" || this.props.store.widgetToEdit.eventViewer?.layoutType === "week") &&
            <>
              {this.props.store.widgetToEdit.eventViewer.showHeadline && this.props.store.widgetToEdit.eventViewer.layoutType !== "list" &&
                <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
              }
              <IAToggle
                labelStyle={{
                  fontSize: "12px",
                  marginTop: 10
                }}
                label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
                checked={this.props.store.widgetToEdit.eventViewer.showHeadline != undefined ? this.props.store.widgetToEdit.eventViewer.showHeadline : true}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.props.store.widgetToEdit.eventViewer.showHeadline = this.props.store.widgetToEdit.eventViewer.showHeadline != undefined ? !this.props.store.widgetToEdit.eventViewer.showHeadline : false;
                  this.props.forceUpdate();
                }}
              />
              {this.props.store.widgetToEdit.eventViewer && this.props.store.widgetToEdit.eventViewer.showHeadline &&
                <IAColorPicker
                  label={this.localizationService.strings.PlayAdmin_HeadlineColor?.toUpperCase()}
                  color={this.props.store.widgetToEdit.eventViewer.headlineColor}
                  swatches={this.props.store.profile?.swatches}
                  toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
                  inTeams={this.props.store.isTeams}
                  allowAddingToSwatches
                  onChange={(headlineColor: string) => {
                    this.props.store.widgetToEdit.eventViewer.headlineColor = headlineColor;
                    this.props.forceUpdate();
                  }}
                  showClearButton={false}
                  addSwatch={(color: string) => this.props.store.addSwatch(color)}
                />
              }
              {this.props.store.widgetToEdit.eventViewer.showHeadline &&
                <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
              }
            </>
          }
          {this.props.store.widgetToEdit.messageViewer &&
            <IAToggle
              style={{
                marginTop: 10
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowQR?.toUpperCase()}
              checked={this.state.viewer && this.state.viewer.showQRLink != undefined ? this.state.viewer.showQRLink : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.state.viewer.showQRLink = this.state.viewer.showQRLink != undefined ? !this.state.viewer.showQRLink : false;
                this.props.forceUpdate();
              }}
            />
          }
          {this.state.viewer.showQRLink &&
            <>
              <div className={styles.IA_info}>{this.localizationService.strings.PlayAdmin_QRcodeOnMessagesTooltipPart1} <a href="https://docs.intraactive.net/?doc=/intraactive-intranet/messages-manage-messages/#section-header-two-a2dhj" target="_blank">{this.localizationService.strings.PlayAdmin_QRcodeOnMessagesTooltipPart2}</a> {this.localizationService.strings.PlayAdmin_QRcodeOnMessagesTooltipPart3}</div>
              <IATextField
                placeholder={this.localizationService.strings.PlayAdmin_LinkText}
                text={this.props.store.widgetToEdit.messageViewer.linkText}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                label={this.localizationService.strings.PlayAdmin_QRText?.toUpperCase()}
                style={{
                  marginTop: 10
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                inputFieldStyle={{
                  marginBottom: 10
                }}
                onChange={(text: string) => {
                  this.props.store.widgetToEdit.messageViewer.linkText = text;
                  this.props.forceUpdate();
                }}
              />
            </>
          }
          <IAToggle
            style={{
              marginTop: 10
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTitle?.toUpperCase()}
            checked={this.state.viewer && this.state.viewer.showTitle != undefined ? this.state.viewer.showTitle : true}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            onChange={() => {
              this.state.viewer.showTitle = this.state.viewer.showTitle != undefined ? !this.state.viewer.showTitle : false;
              this.props.forceUpdate();
            }}
          />
          <>
            <IAToggle
              style={{
                marginTop: 10
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowText?.toUpperCase()}
              checked={this.state.viewer && this.state.viewer.showText != undefined ? this.state.viewer.showText : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.state.viewer.showText = this.state.viewer.showText != undefined ? !this.state.viewer.showText : false;
                this.props.forceUpdate();
              }}
            />
            {this.state.viewer.boxStyle !== "postcard" &&
              <IAToggle
                style={{
                  marginTop: 10
                }}
                labelStyle={{
                  fontSize: "12px"
                }}
                label={this.localizationService.strings.PlayAdmin_ShowProgressBar?.toUpperCase()}
                checked={this.props.store.widgetToEdit.showProgressBar != undefined ? this.props.store.widgetToEdit.showProgressBar : true}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.props.store.widgetToEdit.showProgressBar = this.props.store.widgetToEdit.showProgressBar != undefined ? !this.props.store.widgetToEdit.showProgressBar : false;
                  this.props.forceUpdate();
                }}
              />
            }
            {this.props.store.widgetToEdit.eventViewer?.layoutType === "list" && (this.state.viewer.showText || this.state.viewer.showText == undefined) &&
              <IANumberField
                label={this.localizationService.strings.PlayAdmin_NumberOfTeaserLines?.toUpperCase()}
                number={this.props.store.widgetToEdit.eventViewer?.textLines}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                labelStyle={{
                  fontSize: "12px"
                }}
                onChange={(textLines: number) => {
                  this.props.store.widgetToEdit.eventViewer.textLines = textLines;
                  this.props.forceUpdate();
                }}
                styles={{
                  width: "100%",
                  clear: "both",
                  marginBottom: 10
                }}
              />
            }
            {(this.props.store.widgetToEdit.eventViewer?.layoutType === "list" || this.props.store.widgetToEdit.eventViewer?.layoutType === "slide") && (this.state.viewer.showText || this.state.viewer.showText == undefined) &&
              <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
            }
          </>
          {(!this.props.store.widgetToEdit.eventViewer && !this.props.store.widgetToEdit.sharepointNewsViewer) &&
            <IAToggle
              style={{
                marginTop: 10
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowAuthor?.toUpperCase()}
              checked={this.state.viewer && this.state.viewer.showAuthor != undefined ? this.state.viewer.showAuthor : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.state.viewer.showAuthor = this.state.viewer.showAuthor != undefined ? !this.state.viewer.showAuthor : false;
                this.props.forceUpdate();
              }}
            />
          }
          {!this.props.store.widgetToEdit.eventViewer &&
            <IAToggle
              style={{
                marginTop: 10
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowDate?.toUpperCase()}
              checked={this.state.viewer && this.state.viewer.showTime != undefined ? this.state.viewer.showTime : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.state.viewer.showTime = this.state.viewer.showTime != undefined ? !this.state.viewer.showTime : false;
                this.props.forceUpdate();
              }}
            />
          }
          {this.props.store.widgetToEdit.eventViewer &&
            <IAToggle
              style={{
                marginTop: 10
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTime?.toUpperCase()}
              checked={this.state.viewer && this.state.viewer.showTime != undefined ? this.state.viewer.showTime : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.state.viewer.showTime = this.state.viewer.showTime != undefined ? !this.state.viewer.showTime : false;
                this.props.forceUpdate();
              }}
            />
          }
          {this.props.store.widgetToEdit.eventViewer &&
            <IAToggle
              style={{
                marginTop: 10
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowLocation?.toUpperCase()}
              checked={this.state.viewer && this.state.viewer.showLocation != undefined ? this.state.viewer.showLocation : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.state.viewer.showLocation = this.state.viewer.showLocation != undefined ? !this.state.viewer.showLocation : false;
                this.props.forceUpdate();
              }}
            />
          }
          {(this.props.store.widgetToEdit.eventViewer?.layoutType !== "list" && this.props.store.widgetToEdit.eventViewer?.layoutType !== "week") &&
            <IAToggle
              style={{
                marginTop: 10
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowImage?.toUpperCase()}
              checked={this.state.viewer && this.state.viewer.showImage != undefined ? this.state.viewer.showImage : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.state.viewer.showImage = this.state.viewer.showImage != undefined ? !this.state.viewer.showImage : false;
                if (!this.state.viewer.showImage) {
                  this.state.viewer.contentPosition = "left";
                }
                this.props.forceUpdate();
              }}
            />
          }
          {this.props.store.widgetToEdit.messageViewer &&
            <IAToggle
              style={{
                marginTop: 10
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowVideo?.toUpperCase()}
              checked={this.state.viewer && this.state.viewer.showVideo != undefined ? this.state.viewer.showVideo : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.state.viewer.showVideo = this.state.viewer.showVideo != undefined ? !this.state.viewer.showVideo : false;
                this.props.forceUpdate();
              }}
            />
          }
          {this.props.store.widgetToEdit.messageViewer &&
            <IAToggle
              style={{
                marginTop: 10
              }}
              labelStyle={{
                fontSize: "12px"
              }}
              label={this.localizationService.strings.PlayAdmin_MessagesViewerShowGallery?.toUpperCase()}
              checked={this.state.viewer && this.state.viewer.showGallery != undefined ? this.state.viewer.showGallery : true}
              color={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onChange={() => {
                this.state.viewer.showGallery = this.state.viewer.showGallery != undefined ? !this.state.viewer.showGallery : false;
                this.props.forceUpdate();
              }}
            />
          }
          {this.props.store.widgetToEdit.eventViewer && this.props.store.widgetToEdit.eventViewer.layoutType !== "week" &&
            <>
              {this.state.viewer.showTimeBox && this.props.store.widgetToEdit.eventViewer?.layoutType !== "slide" &&
                <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
              }
              <IAToggle
                labelStyle={{
                  fontSize: "12px",
                  marginTop: 10
                }}
                label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTimeBox?.toUpperCase()}
                checked={this.state.viewer && this.state.viewer.showTimeBox != undefined ? this.state.viewer.showTimeBox : true}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.state.viewer.showTimeBox = this.state.viewer.showTimeBox != undefined ? !this.state.viewer.showTimeBox : false;
                  this.props.forceUpdate();
                }}
              />
              {this.state.viewer.showTimeBox && this.props.store.widgetToEdit.eventViewer?.layoutType !== "slide" &&
                <>
                  <IAColorPicker
                    label={this.localizationService.strings.PlayAdmin_TimeBatchBackgroundColor?.toUpperCase()}
                    color={this.props.store.widgetToEdit.eventViewer.timeBatchBackgroundColor}
                    swatches={this.props.store.profile?.swatches}
                    toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
                    inTeams={this.props.store.isTeams}
                    allowAddingToSwatches
                    onChange={(timeBatchBackgroundColor: string) => {
                      this.props.store.widgetToEdit.eventViewer.timeBatchBackgroundColor = timeBatchBackgroundColor;
                      this.props.forceUpdate();
                    }}
                    defaultColor="transparent"
                    showClearButton={true}
                    addSwatch={(color: string) => this.props.store.addSwatch(color)}
                  />
                  <IAColorPicker
                    label={this.localizationService.strings.PlayAdmin_TimeBatchTextColor?.toUpperCase()}
                    color={this.props.store.widgetToEdit.eventViewer.timeBatchTextColor}
                    swatches={this.props.store.profile?.swatches}
                    toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
                    inTeams={this.props.store.isTeams}
                    allowAddingToSwatches
                    onChange={(timeBatchTextColor: string) => {
                      this.props.store.widgetToEdit.eventViewer.timeBatchTextColor = timeBatchTextColor;
                      this.props.forceUpdate();
                    }}
                    showClearButton={false}
                    addSwatch={(color: string) => this.props.store.addSwatch(color)}
                  />
                  <IAColorPicker
                    label={this.localizationService.strings.PlayAdmin_TimeBatchBorderColor?.toUpperCase()}
                    color={this.props.store.widgetToEdit.eventViewer.timeBatchBorderColor}
                    swatches={this.props.store.profile?.swatches}
                    inTeams={this.props.store.isTeams}
                    toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
                    allowAddingToSwatches
                    onChange={(timeBatchBorderColor: string) => {
                      this.props.store.widgetToEdit.eventViewer.timeBatchBorderColor = timeBatchBorderColor;
                      this.props.forceUpdate();
                    }}
                    styles={{
                      clear: "both"
                    }}
                    defaultColor="transparent"
                    showClearButton={true}
                    addSwatch={(color: string) => this.props.store.addSwatch(color)}
                  />
                </>
              }
            </>
          }
        </>

        {(this.props.store.widgetToEdit.eventViewer?.layoutType === "list" || this.props.store.widgetToEdit.eventViewer?.layoutType === "week") ?
          <div style={{ marginBottom: 20, float: "left" }}>
            <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
            <label
              style={{
                color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
                marginTop: 10,
                width: "100%"
              }}
            >
              {this.localizationService.strings.PlayAdmin_Colors?.toUpperCase()}
            </label>
            <IAColorPicker
              label={this.localizationService.strings.PlayAdmin_BackgroundColor?.toUpperCase()}
              color={this.props.store.widgetToEdit.eventViewer.backgroundColor}
              inTeams={this.props.store.isTeams}
              swatches={this.props.store.profile?.swatches}
              toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
              allowAddingToSwatches
              onChange={(backgroundColor: string) => {
                this.props.store.widgetToEdit.eventViewer.backgroundColor = backgroundColor;
                this.props.forceUpdate();
              }}
              showClearButton={false}
              addSwatch={(color: string) => this.props.store.addSwatch(color)}
            />
            <IAColorPicker
              label={this.localizationService.strings.PlayAdmin_TextColor?.toUpperCase()}
              color={this.props.store.widgetToEdit.eventViewer.textColor}
              inTeams={this.props.store.isTeams}
              swatches={this.props.store.profile?.swatches}
              toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
              allowAddingToSwatches
              onChange={(textColor: string) => {
                this.props.store.widgetToEdit.eventViewer.textColor = textColor;
                this.props.forceUpdate();
              }}
              showClearButton={false}
              addSwatch={(color: string) => this.props.store.addSwatch(color)}
            />
            <IAColorPicker
              label={this.props.store.widgetToEdit.eventViewer?.layoutType === "week" ? this.localizationService.strings.PlayAdmin_TodayColor?.toUpperCase() : this.localizationService.strings.PlayAdmin_BoxColor?.toUpperCase()}
              color={this.props.store.widgetToEdit.eventViewer.boxColor}
              inTeams={this.props.store.isTeams}
              swatches={this.props.store.profile?.swatches}
              toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
              allowAddingToSwatches
              onChange={(boxColor: string) => {
                this.props.store.widgetToEdit.eventViewer.boxColor = boxColor;
                this.props.forceUpdate();
              }}
              defaultColor="transparent"
              showClearButton={true}
              addSwatch={(color: string) => this.props.store.addSwatch(color)}
            />
          </div>
          :
          <>
            <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
            <label
              style={{
                color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
                marginTop: 20
              }}
            >
              {this.localizationService.strings.PlayAdmin_ColorThemes?.toUpperCase()}
            </label>
            {colorThemes}
            <IAButton
              label={this.localizationService.strings.PlayAdmin_AddColorTheme?.toUpperCase()}
              buttonColor={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onClick={() => {
                if (!this.state.viewer?.colorThemes) {
                  this.state.viewer.colorThemes = [];
                }
                this.state.viewer.colorThemes.push(
                  {
                    textColor: "#333333",
                    backgroundColor: "#ffffff"
                  }
                );
                this.props.forceUpdate();
              }}
              showSpinner={this.props.store.workingOnIt}
              borderRadius={5}
              style={{
                float: "left",
                position: "relative",
                clear: "both",
                marginBottom: 20,
                marginTop: 10
              }}
            />
          </>
        }
        {this.props.store.widgetToEdit.eventViewer?.layoutType === "week" &&
          <>
            <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
            <MediaFileSelector
              label={this.localizationService.strings.PlayAdmin_BackgroundImage?.toUpperCase()}
              url={this.props.store.widgetToEdit.eventViewer?.backgroundImage}
              type={"image"}
              onChange={(backgroundImage: string) => {
                this.props.store.widgetToEdit.eventViewer.backgroundImage = backgroundImage;
                this.forceUpdate();
                this.props.forceUpdate();
              }}
            />
          </>
        }
      </>
    );
  }


}
