import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from '../WidgetEditPanel.css';
import { IATextField } from '../../../../../textField/TextField';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IANumberField } from '../../../../../numberField/NumberField';
import { IADropDown } from '../../../../../dropDown/DropDown';
import { IAToggle } from '../../../../../toggle/Toggle';
import { Helper } from '../../../../../../Helper';
import { IAButton } from '../../../../../button/Button';
import { IAColorTheme } from '../../../../../colorTheme/ColorTheme';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
}

export interface IState {
}

@inject('store')
@observer
export class RssWidgetSettings extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();


  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }


  render(): React.ReactNode {
    const colorThemes = [];
    if (this.props.store.widgetToEdit?.rss?.colorThemes?.length > 0) {
      this.props.store.widgetToEdit.rss.colorThemes.forEach((colorTheme, index) => {
        colorThemes.push(
          <IAColorTheme
            key={"colorTheme_" + index}
            backgroundColor={colorTheme.backgroundColor}
            textColor={colorTheme.textColor}
            highlightColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            inTeams
            remove={() => {
              this.props.store.widgetToEdit.rss.colorThemes.splice(index, 1);
              this.props.forceUpdate();
            }}
            allowAddingToSwatches={false}
            setBackgroundColor={(backgroundColor) => {
              colorTheme.backgroundColor = backgroundColor;
              this.props.forceUpdate();
            }}
            setTextColor={(textColor) => {
              colorTheme.textColor = textColor;
              this.props.forceUpdate();
            }}
          />
        );
      });
    }
    return (
      <>
        <IANumberField
          label={this.localizationService.strings.PlayAdmin_MinimumDuration?.toUpperCase()}
          number={this.props.store.widgetToEdit.rss.duration}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(duration: number) => {
            this.props.store.widgetToEdit.rss.duration = duration;
            this.props.forceUpdate();
          }}
          styles={{
            width: "100%",
            clear: "both"
          }}
        />
        <IATextField
          label={this.localizationService.strings.PlayAdmin_RssUrl?.toUpperCase()}
          placeholder={this.localizationService.strings.PlayAdmin_RssUrl}
          text={this.props.store.widgetToEdit.rss ? this.props.store.widgetToEdit.rss.url : undefined}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(url: string) => {
            this.props.store.widgetToEdit.rss.url = url;
            this.props.forceUpdate();
          }}
        />
        <IANumberField
          label={this.localizationService.strings.PlayAdmin_RssMaxItems?.toUpperCase()}
          number={this.props.store.widgetToEdit.rss?.maxItems ?? undefined}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(maxItems: number) => {
            this.props.store.widgetToEdit.rss.maxItems = maxItems;
            this.props.forceUpdate();
          }}
          styles={{
            width: "100%",
            clear: "both"
          }}
        />
        <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_ContentBackground?.toUpperCase()}
          selectedOption={this.props.store.widgetToEdit.rss.boxStyle}
          options={[
            {
              key: "solid",
              value: this.localizationService.strings.PlayAdmin_SolidBackground
            },
            {
              key: "transparent",
              value: this.localizationService.strings.PlayAdmin_TransparentBackground
            },
            {
              key: "gradient",
              value: this.localizationService.strings.PlayAdmin_GradientBackground
            }
          ]}
          borderRadius={5}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          onChange={(boxStyle) => {
            this.props.store.widgetToEdit.rss.boxStyle = boxStyle;
            this.props.forceUpdate();
          }}
          style={{
            clear: "none",
            float: "left",
            width: "100%"
          }}
        />
        <IADropDown
          label={this.localizationService.strings.PlayAdmin_TextPosition?.toUpperCase()}
          selectedOption={this.props.store.widgetToEdit.rss.contentPosition}
          options={[
            {
              key: "left",
              value: this.localizationService.strings.PlayAdmin_TextPositionLeft,
            },
            {
              key: "right",
              value: this.localizationService.strings.PlayAdmin_TextPositionRight,
            },
            {
              key: "random",
              value: this.localizationService.strings.PlayAdmin_TextPositionRandom,
            }
          ]}
          borderRadius={5}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          onChange={(contentPosition: any) => {
            this.props.store.widgetToEdit.rss.contentPosition = contentPosition;
            this.props.forceUpdate();
          }}
          style={{
            clear: "none",
            float: "left",
            width: "100%",
            marginBottom: 5
          }}
        />
        <IAToggle
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          label={this.localizationService.strings.PlayAdmin_MessagesViewerShowTitle?.toUpperCase()}
          checked={this.props.store.widgetToEdit.rss.showTitle != undefined ? this.props.store.widgetToEdit.rss.showTitle : true}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.rss.showTitle = this.props.store.widgetToEdit.rss.showTitle != undefined ? !this.props.store.widgetToEdit.rss.showTitle : false;
            this.props.forceUpdate();
          }}
        />
        <IAToggle
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          label={this.localizationService.strings.PlayAdmin_MessagesViewerShowText?.toUpperCase()}
          checked={this.props.store.widgetToEdit.rss.showText != undefined ? this.props.store.widgetToEdit.rss.showText : true}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.rss.showText = this.props.store.widgetToEdit.rss.showText != undefined ? !this.props.store.widgetToEdit.rss.showText : false;
            this.props.forceUpdate();
          }}
        />
        <IAToggle
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          label={this.localizationService.strings.PlayAdmin_ShowProgressBar?.toUpperCase()}
          checked={this.props.store.widgetToEdit.showProgressBar != undefined ? this.props.store.widgetToEdit.showProgressBar : true}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.showProgressBar = this.props.store.widgetToEdit.showProgressBar != undefined ? !this.props.store.widgetToEdit.showProgressBar : false;
            this.props.forceUpdate();
          }}
        />
        <IAToggle
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          label={this.localizationService.strings.PlayAdmin_MessagesViewerShowDate?.toUpperCase()}
          checked={this.props.store.widgetToEdit.rss.showDate != undefined ? this.props.store.widgetToEdit.rss.showDate : true}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.rss.showDate = this.props.store.widgetToEdit.rss.showDate != undefined ? !this.props.store.widgetToEdit.rss.showDate : false;
            this.props.forceUpdate();
          }}
        />
        <IAToggle
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          label={this.localizationService.strings.PlayAdmin_MessagesViewerShowQR?.toUpperCase()}
          checked={this.props.store.widgetToEdit.rss.showQRLink != undefined ? this.props.store.widgetToEdit.rss.showQRLink : true}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.rss.showQRLink = this.props.store.widgetToEdit.rss.showQRLink != undefined ? !this.props.store.widgetToEdit.rss.showQRLink : false;
            this.props.forceUpdate();
          }}
        />
        <IATextField
          placeholder={this.localizationService.strings.PlayAdmin_LinkText}
          text={this.props.store.widgetToEdit.rss.linkText}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          label={this.localizationService.strings.PlayAdmin_QRText?.toUpperCase()}
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          inputFieldStyle={{
            marginBottom: 10
          }}
          onChange={(text: string) => {
            this.props.store.widgetToEdit.rss.linkText = text;
            this.props.forceUpdate();
          }}
        />
        <IAToggle
          style={{
            marginTop: 10
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          label={this.localizationService.strings.PlayAdmin_MessagesViewerShowImage?.toUpperCase()}
          checked={this.props.store.widgetToEdit.rss.showImage != undefined ? this.props.store.widgetToEdit.rss.showImage : true}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.rss.showImage = this.props.store.widgetToEdit.rss.showImage != undefined ? !this.props.store.widgetToEdit.rss.showImage : false;
            this.props.forceUpdate();
          }}
        />
        <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
        <label
          style={{
            color: this.props.store.darkMode ? Helper.darkModeLabelColor : '#333333',
            marginTop: 20
          }}
        >
          {this.localizationService.strings.PlayAdmin_ColorThemes?.toUpperCase()}
        </label>
        {colorThemes}
        <IAButton
          label={this.localizationService.strings.PlayAdmin_AddColorTheme?.toUpperCase()}
          buttonColor={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onClick={() => {
            if (!this.props.store.widgetToEdit.rss?.colorThemes) {
              this.props.store.widgetToEdit.rss.colorThemes = [];
            }
            this.props.store.widgetToEdit.rss.colorThemes.push(
              {
                textColor: "#333333",
                backgroundColor: "#ffffff"
              }
            );
            this.props.forceUpdate();
          }}
          showSpinner={this.props.store.workingOnIt}
          borderRadius={5}
          style={{
            float: "left",
            position: "relative",
            clear: "both",
            marginBottom: 20,
            marginTop: 10
          }}
        />
      </>);
  }


}