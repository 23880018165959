import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './Profiles.css';
import { Store } from '../../stores';
import PlayService from '../../../../services/PlayService';
import { IListElement, IPlayProfile } from '../../../../interfaces/IPlay';
import { Helper } from '../../../../Helper';
import { LocalizationService } from '../../../../services/LocalizationService';
import { IAButton } from '../../../button/Button';
import { Popup } from '../../reusableComponents/popup/Popup';
import { IAHeader } from '../../../header/Header';
import { IAListView } from '../../../listView/ListView';
import { IASpinner } from '../../../spinner/Spinner';

export interface IProps {
  display: boolean;
  store?: Store;
}

export interface IState {
  profileList: IListElement[];
  loading: boolean;
  profileToDelete?: string;
}

@inject('store')
@observer
export class Profiles extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      profileList: undefined,
      loading: false
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.display && !this.props.display) {
      this.getProfiles();
    }
  }

  private getProfiles(): void {
    const profileList = [];
    this.setState({ loading: true });
    PlayService.getPlayProfiles(this.props.store.environment, this.props.store.token, this.props.store.tenantId).then(async (profiles: IPlayProfile[] | 401) => {
      if (profiles === 401) {
        this.props.store.showErrorPopup = true;
      } else {
        profiles = Helper.sortAlphabeticalByTitle(profiles);
        if (profiles.length > 0) {
          profiles.forEach(async (profile: IPlayProfile) => {
            if (profile.title) {
              profileList.push({
                object: profile,
                id: profile.id,
                columns: [
                  {
                    header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
                    type: "text",
                    text: profile.title,
                    backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                    color: this.props.store.darkMode ? "#ffffff" : "#333333",
                  }
                ],
                buttons: [
                  {
                    action: "delete",
                    icon: "Trash",
                    color: this.props.store.highlightColor
                  }
                ]
              })
            }
          });
        }
        this.setState({ profileList });
      }
    });
  }

  private onDelete(profileId: string): void {
    this.props.store.workingOnIt = true;
    localStorage.removeItem("IA_profileId");
    PlayService.deletePlayProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, profileId).then(() => {
      location.reload();
    });
  }

  public render(): JSX.Element {
    return this.props.display ? (
      <div className={styles.IA_profiles}>
        <IAHeader
          id="play-profiles-header"
          title={this.localizationService.strings.PlayAdmin_Profiles}
          description={this.localizationService.strings.PlayAdmin_LicenseDescription}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor="#666666"
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-black.png",
              headline: this.localizationService.strings.PlayAdmin_Profiles,
              text: this.localizationService.strings.PlayAdmin_ProfilesDescription,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-profile.png"
            }
          ]}
          actionButton={{
            label: this.localizationService.strings.PlayAdmin_Add?.toUpperCase(),
            onClick: () => {
              const newProfile: IPlayProfile = this.props.store.getDefaultProfile();
              this.props.store.creatingNewProfile = 1;
              this.props.store.isShowingAccountSettings = false;
              PlayService.createPlayProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, newProfile).then((id: string) => {
                newProfile.id = id;
                this.props.store.profile = newProfile;
                this.props.store.profiles = undefined;
                localStorage.setItem("IA_profileId", id);
                this.props.store.creatingNewProfile = 10;
                this.props.store.addDemoContent();
              });
            },
            buttonColor: this.props.store.highlightColor,
            borderRadius: 5,
            textColor: "#ffffff",
            arialable: this.localizationService.strings.PlayAdmin_Add,
            icon: "Add"
          }}
        />
        {this.state.profileList ?
          <IAListView
            id="ListViewOfProfiles"
            listElements={this.state.profileList}
            backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
            hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
            buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
            defaultAction={undefined}
            darkMode={this.props.store.darkMode}
            selectedAction={(action: string, id: string) => {
              switch (action) {
                case "delete":
                  this.setState({ profileToDelete: id });
                  break;
                default:
                  break;
              }
            }}
          />
          :
          <IASpinner
            color={this.props.store.highlightColor}
            style={{
              float: "left",
              marginLeft: "calc(50% - 20px)",
              clear: "both"
            }}
          />

        }
        {this.state.profileToDelete &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_DeleteProfile}
            close={() => this.setState({ profileToDelete: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.props.store.profileHasChanged = false;
                    this.onDelete(this.state.profileToDelete);
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ profileToDelete: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
      </div>
    )
      :
      (<></>);
  }


}