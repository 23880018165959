exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_footer_3k9j834gzyAnX1oEBjim4b {\n  position: absolute;\n  width: 100%;\n  left: 0\n}\n\n.IA_footerWeatherAndClockContainer_34I47h92rFraLI1Llr98mH {\n  height: 100%;\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.IA_logo_2XZV535YBT3Lvq1ql1rQlM {\n  height: 100%;\n  width: 50%;\n  background-repeat: no-repeat;\n  background-size: contain;\n  position: relative;\n  float: left;\n}\n\n.IA_clock_18YuSWh0dWgBb1hAHt7Yh8 {\n  height: 100%;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: flex-end;\n}\n\n.IA_time_Gj2ega-00z1Vf_fVrDtZf {\n  position: relative;\n  float: right;\n  text-align: left;\n  margin-left: 19%;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_timeDivider_2oUJzH9F5ROi2ggRE9-LQa {\n  margin: 0 2px 0 2px;\n}\n\n.IA_date_Jba2DdCrSp02Yo3RSs1if {\n  position: relative;\n  float: right;\n  text-align: right;\n  font-weight: lighter;\n}\n\n\n.IA_weather_2VdjuSEmVDZ739QKGeZKq_ {\n  height: 100%;\n  position: relative;\n  float: right;\n  box-sizing: border-box;\n}\n\n.IA_weatherIcon_oJ6W-umsJoo1Q369vvana {\n  position: relative;\n  text-align: left;\n}\n\n.IA_weatherTemperature_1mB196z7A_Au3i7CNiqTm1 {\n  position: relative;\n  float: right;\n  text-align: left;\n  width: 50%;\n\n}\n\n.IA_weatherArea_T7DFDuZ6Xcy0UjTuXrd6F {\n  position: relative;\n  float: right;\n  text-align: left;\n  font-weight: lighter;\n  width: 50%;\n  clear: right;\n\n}", ""]);

// Exports
exports.locals = {
	"IA_footer": "IA_footer_3k9j834gzyAnX1oEBjim4b",
	"IA_footerWeatherAndClockContainer": "IA_footerWeatherAndClockContainer_34I47h92rFraLI1Llr98mH",
	"IA_logo": "IA_logo_2XZV535YBT3Lvq1ql1rQlM",
	"IA_clock": "IA_clock_18YuSWh0dWgBb1hAHt7Yh8",
	"IA_time": "IA_time_Gj2ega-00z1Vf_fVrDtZf",
	"IA_timeDivider": "IA_timeDivider_2oUJzH9F5ROi2ggRE9-LQa",
	"IA_date": "IA_date_Jba2DdCrSp02Yo3RSs1if",
	"IA_weather": "IA_weather_2VdjuSEmVDZ739QKGeZKq_",
	"IA_weatherIcon": "IA_weatherIcon_oJ6W-umsJoo1Q369vvana",
	"IA_weatherTemperature": "IA_weatherTemperature_1mB196z7A_Au3i7CNiqTm1",
	"IA_weatherArea": "IA_weatherArea_T7DFDuZ6Xcy0UjTuXrd6F"
};