exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_rotator_3pPke5vU2HR_DNpU650VRA {\n  width: 100%;\n  height: 100%;\n  transition: left 500ms ease-in-out;\n  position: absolute;\n}\n\n.IA_dots_vSCs3KgTRzHS9eeVHVpS6 {\n  position: absolute;\n  z-index: 1000;\n  width: 105px;\n  background-color: #ffffff;\n  border-radius: 5px;\n  box-shadow: 0 1px 1px 0 #888888;\n  right: 20px;\n  top: 20px;\n  z-index: 3000000;\n}\n\n.IA_dot_17Vu7PI3nG_wpPYSvjt5Hx {\n  position: relative;\n  float: left;\n  cursor: pointer;\n}\n\n.IA_image_6CdmPItZSHamiAVCCO3m1 {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_imageWithSlidingTransition_WGfqvfx-56liHBrFkFWrz {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  float: left;\n  background-size: cover;\n  background-position: center;\n  overflow: hidden;\n}", ""]);

// Exports
exports.locals = {
	"IA_rotator": "IA_rotator_3pPke5vU2HR_DNpU650VRA",
	"IA_dots": "IA_dots_vSCs3KgTRzHS9eeVHVpS6",
	"IA_dot": "IA_dot_17Vu7PI3nG_wpPYSvjt5Hx",
	"IA_image": "IA_image_6CdmPItZSHamiAVCCO3m1",
	"IA_imageWithSlidingTransition": "IA_imageWithSlidingTransition_WGfqvfx-56liHBrFkFWrz"
};