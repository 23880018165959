/**
 * Hack in support for Function.name for browsers that don't support it.
 * IE, I'm looking at you.
**/
if (Function.prototype.name === undefined && Object.defineProperty !== undefined) {
  Object.defineProperty(Function.prototype, 'name', {
      get: function() {
          var funcNameRegex = /function\s([^(]{1,})\(/;
          var results = (funcNameRegex).exec((this).toString());
          return (results && results.length > 1) ? results[1].trim() : "";
      },
      set: function(value) {}
  });
}

/*
 * Type definition section
 * 
 * We need those declarations because we want to resolve the parameter classes in constructors in compile time.
 * 
 * For example if we didn't have this, where there is `.asSingleton(ExampleClass2, [ExampleClass])`
 *    in code, we could easily mistyped as `.asSingleton(ExampleClass2, [ExampleClass2])` without a compilation warning.
 */
type ConstructorParamsLength<T extends GenericConstructor> = ConstructorParameters<T>['length'];
type GenericConstructor = new (...args: any[]) => any;
type Constructor<T> = new (...args: any[]) => T;
type TypeOfConstructorParameters0<T extends GenericConstructor> = 0 extends ConstructorParamsLength<T> ? 1 extends ConstructorParamsLength<T> ? never : [] : never;
type TypeOfConstructorParameters1<T extends GenericConstructor> = T extends new (args0: infer P0) => any ? (1 extends ConstructorParamsLength<T> ? 2 extends ConstructorParamsLength<T> ? never : [ Constructor<P0> ] : never) : never
type TypeOfConstructorParameters2<T extends GenericConstructor> = T extends new (args0: infer P0, args1: infer P1) => any ? (2 extends ConstructorParamsLength<T> ? 3 extends ConstructorParamsLength<T> ? never : [ Constructor<P0>, Constructor<P1> ] : never) : never;
type TypeOfConstructorParameters3<T extends GenericConstructor> = T extends new (args0: infer P0, args1: infer P1, args2: infer P2) => any ? (3 extends ConstructorParamsLength<T> ? 4 extends ConstructorParamsLength<T> ? never : [ Constructor<P0>, Constructor<P1>, Constructor<P2> ] : never) : never;
type TypeOfConstructorParameters4<T extends GenericConstructor> = T extends new (args0: infer P0, args1: infer P1, args2: infer P2, args3: infer P3) => any ? (4 extends ConstructorParamsLength<T> ? 5 extends ConstructorParamsLength<T> ? never : [ Constructor<P0>, Constructor<P1>, Constructor<P2>, Constructor<P3> ] : never) : never;
type TypeOfConstructorParameters5<T extends GenericConstructor> = T extends new (args0: infer P0, args1: infer P1, args2: infer P2, args3: infer P3, args4: infer P4) => any ? (5 extends ConstructorParamsLength<T> ? 6 extends ConstructorParamsLength<T> ? never : [ Constructor<P0>, Constructor<P1>, Constructor<P2>, Constructor<P3>, Constructor<P4> ] : never) : never;
type TypeOfConstructorParameters6<T extends GenericConstructor> = T extends new (args0: infer P0, args1: infer P1, args2: infer P2, args3: infer P3, args4: infer P4, args5: infer P5) => any ? (6 extends ConstructorParamsLength<T> ? 7 extends ConstructorParamsLength<T> ? never :  [ Constructor<P0>, Constructor<P1>, Constructor<P2>, Constructor<P3>, Constructor<P4>, Constructor<P5> ] : never) : never;
type TypeOfConstructorParameters7<T extends GenericConstructor> = T extends new (args0: infer P0, args1: infer P1, args2: infer P2, args3: infer P3, args4: infer P4, args5: infer P5, args6: infer P6) => any ? (7 extends ConstructorParamsLength<T> ? 8 extends ConstructorParamsLength<T> ? never : [ Constructor<P0>, Constructor<P1>, Constructor<P2>, Constructor<P3>, Constructor<P4>, Constructor<P5>, Constructor<P6> ] : never) : never;
type TypeOfConstructorParameters8<T extends GenericConstructor> = T extends new (args0: infer P0, args1: infer P1, args2: infer P2, args3: infer P3, args4: infer P4, args5: infer P5, args6: infer P6, args7: infer P7) => any ? (8 extends ConstructorParamsLength<T> ? 9 extends ConstructorParamsLength<T> ? never : [ Constructor<P0>, Constructor<P1>, Constructor<P2>, Constructor<P3>, Constructor<P4>, Constructor<P5>, Constructor<P6>, Constructor<P7> ] : never) : never;
type TypeOfConstructorParameters9<T extends GenericConstructor> = T extends new (args0: infer P0, args1: infer P1, args2: infer P2, args3: infer P3, args4: infer P4, args5: infer P5, args6: infer P6, args7: infer P7, args8: infer P8) => any ? (9 extends ConstructorParamsLength<T> ? 10 extends ConstructorParamsLength<T> ? never : [ Constructor<P0>, Constructor<P1>, Constructor<P2>, Constructor<P3>, Constructor<P4>, Constructor<P5>, Constructor<P6>, Constructor<P7>, Constructor<P8> ] : never) : never;
type TypeOfConstructorParameters10<T extends GenericConstructor> = T extends new (args0: infer P0, args1: infer P1, args2: infer P2, args3: infer P3, args4: infer P4, args5: infer P5, args6: infer P6, args7: infer P7, args8: infer P8, args9: infer P9) => any ? (10 extends ConstructorParamsLength<T> ? 11 extends ConstructorParamsLength<T> ? never : [ Constructor<P0>, Constructor<P1>, Constructor<P2>, Constructor<P3>, Constructor<P4>, Constructor<P5>, Constructor<P6>, Constructor<P7>, Constructor<P8>, Constructor<P9> ] : never) : never;

type TypeOfConstructorParameters<T extends GenericConstructor> =
  TypeOfConstructorParameters0<T>
  | TypeOfConstructorParameters1<T>
  | TypeOfConstructorParameters2<T>
  | TypeOfConstructorParameters3<T>
  | TypeOfConstructorParameters4<T>
  | TypeOfConstructorParameters5<T>
  | TypeOfConstructorParameters6<T>
  | TypeOfConstructorParameters7<T>
  | TypeOfConstructorParameters8<T>
  | TypeOfConstructorParameters9<T>
  | TypeOfConstructorParameters10<T>;

enum EntryType {
  Singleton,
  Transient,
}

interface IMetadata {
  [key: string]: IMetadataEntry<any>
}

interface IMetadataEntryBase {
  Type: EntryType;
  Name: string;
}

interface IMetadataEntry<T extends GenericConstructor> extends IMetadataEntryBase {
  SingletonInstance: Object;
  ConstructorTypeArgs: TypeOfConstructorParameters<T>;
  ClassConstructor: Constructor<T>;
}

interface IInjectableConstructor {
  dependencyInjectionKey: string;
}

export function injectable(key: string) {
  return function(constructor: any) {
    constructor.dependencyInjectionKey = key; // This unsafe editing should be ok here
  }
}

/**
 * Service container that allows registration and resolve of services in a type safe way.
 */
export class Container {
  private readonly metadata: IMetadata = {};

  /**
   * Registers an object as singleton
   * @param classConstructor The class that will be registered
   * @param object The instance
   */
  public asSingletonObject<T>(classConstructor: Constructor<T>, object: T): Container {
    this.registerSingletonObject(classConstructor, object);
    return this;
  }

  /**
   * Registers a class as a singleton. The instance will be created when the class is been requested
   * @param classConstructor The class that will be registered
   * @param constructorTypeArgs The constructor parameter classes as a tuple
   */
  public asSingleton<T extends GenericConstructor>(classConstructor: T, constructorTypeArgs: TypeOfConstructorParameters<T>): Container {
    this.registerObject(EntryType.Singleton, classConstructor, constructorTypeArgs);
    return this;
  }

  /**
   * Registers a class as transient. When an object is requested, a new object tree will be created (excluding singleton objects)
   * @param classConstructor The class that will be registered
   * @param constructorTypeArgs The constructor parameter classes as a tuple
   */
  public asTransient<T extends GenericConstructor>(classConstructor: T, constructorTypeArgs: TypeOfConstructorParameters<T>): Container {
    this.registerObject(EntryType.Transient, classConstructor, constructorTypeArgs);
    return this;
  }

  /**
   * Resolves an object tree and returns an instance for it
   * @param classConstructor The class that needs to be resolved
   * @returns An instance of the specified class
   * @throws Error when cannot resolve the item
   */
  public resolve<T>(classConstructor: Constructor<T>): T {
    const constructorName: string = this.getTypeNameUnsafeOrFail(classConstructor);

    if (!!this.metadata[constructorName]) {
      return <T> this.retrieveObject(this.metadata[constructorName]);
    }

    throw new Error(`Type not registered: ${constructorName}`);
  }

  /**
   * Checks class against registry. This method will not find any errors that happen on resolve
   * @param classConstructor The class that needs to be checked
   * @returns True if the class is registered, otherwise false
   */
  public isRegistered<T>(classConstructor: Constructor<T>): boolean {
    const constructorName = this.getTypeNameUnsafeOrFail(classConstructor);
    return !!this.metadata[constructorName];
  }

  private getTypeNameUnsafeOrFail(classConstructor: any): string { // classConstructor is on purpose any here to be able to cast the object
    const injectableConstructor: IInjectableConstructor = classConstructor as IInjectableConstructor;
    if (!injectableConstructor.dependencyInjectionKey) {
      return classConstructor.name;
      // TODO: Change to error when we have moved to the new auth
      // throw new Error(`Type needs to use @inject('${classConstructor.name}') decorator with a unique key`);
    }

    return injectableConstructor.dependencyInjectionKey;
  }

  private registerObject<T extends GenericConstructor>(type: EntryType, classConstructor: T, constructorTypeArgs: TypeOfConstructorParameters<T>) {
    const constructorName = this.getTypeNameUnsafeOrFail(classConstructor);
    const existingEntry = <IMetadataEntry<T>> this.metadata[constructorName];
    
    const newEntry: IMetadataEntry<T> = {
      ClassConstructor: classConstructor,
      ConstructorTypeArgs: constructorTypeArgs || (existingEntry ? existingEntry.ConstructorTypeArgs : null),
      SingletonInstance: null,
      Type: type,
      Name: constructorName,
    };
    this.metadata[constructorName] = newEntry;
  }

  private registerSingletonObject<T extends GenericConstructor>(classConstructor: T, singletonInstance: Object = null) {
    const constructorName = this.getTypeNameUnsafeOrFail(classConstructor);
    const existingEntry = <IMetadataEntry<T>> this.metadata[constructorName];
    
    const newEntry: IMetadataEntry<T> = {
      ClassConstructor: classConstructor,
      ConstructorTypeArgs: null,
      SingletonInstance: singletonInstance || (existingEntry ? existingEntry.SingletonInstance : null),
      Type: EntryType.Singleton,
      Name: constructorName,
    };
    this.metadata[constructorName] = newEntry;
  }

  private retrieveObject<T extends GenericConstructor>(metadataEntry: IMetadataEntry<T>, resolveStack: IMetadataEntryBase[] = [], requestTypeScope: EntryType = metadataEntry.Type): Object {
    // Check if entry exists
    for(const existingEntry of resolveStack) {
      if (existingEntry.Name === metadataEntry.Name) {
        throw new Error(`Circular dependency: (Requested again) ${metadataEntry.Name} -> Stack: ${resolveStack.map(x => x.Name).reduce((x, y) => x + ' => ' + y)} => ${metadataEntry.Name}`);
      }
    }

    // Check for scope consistency
    if (metadataEntry.Type === EntryType.Transient && requestTypeScope === EntryType.Singleton) {
      throw new Error(`Scope inconsistent (Requested Transient in Singleton): ${metadataEntry.Name} -> Stack: ${resolveStack.map(x => x.Name).reduce((x, y) => x + ' => ' + y)}`);
    }
    
    // Try to resolve the type tree
    resolveStack.push(metadataEntry);
    if (metadataEntry.Type === EntryType.Singleton && !!metadataEntry.SingletonInstance) {
      resolveStack.pop();
      return metadataEntry.SingletonInstance;
    }
    
    const constructorArgs: any[] = [];
    for(const item of metadataEntry.ConstructorTypeArgs) {
      const constructorName = this.getTypeNameUnsafeOrFail(item);
      const entry = this.metadata[constructorName];
      if (!entry) {
        throw new Error(`Type not registered: ${constructorName} -> Stack: ${resolveStack.map(x => x.Name).reduce((x, y) => x + ' => ' + y)}`);
      }

      const argObject = this.retrieveObject(entry, resolveStack, requestTypeScope);
      constructorArgs.push(argObject);
    }

    // Create the object
    const instance = new metadataEntry.ClassConstructor(...constructorArgs);

    if (metadataEntry.Type === EntryType.Singleton) {
      metadataEntry.SingletonInstance = instance;
    }

    resolveStack.pop();
    return instance;
  }
}