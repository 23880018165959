exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_cardCover_5gYIT0nwIPEeFCkzj37Ah {\n  background-color: #eeeeee;\n  width: 100%;\n  height: 144px;\n  background-position: center;\n  background-size: cover;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n}\n\n.IA_cardCover_5gYIT0nwIPEeFCkzj37Ah iframe {\n  border: none;\n}\n\n/* Overlay image */\n\n.IA_coverOverlay_2gWySwWaR7mdO0e0x7RGnx {\n  width: 74px;\n  height: 74px;\n  background: rgba(255, 255, 255, 0.8);\n  position: absolute;\n  color: #333333;\n}\n\n.IA_startDate_2wD-qPakfar-TW0gApvvv0 {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n  font-weight: lighter;\n  font-size: 14px;\n}\n\n.IA_startDateendDateDivider_pH35yXhuQSNsvDtavDsgH {\n  float: left;\n  width: 60%;\n  height: 1px;\n  background-color: #999999;\n  margin-left: 20%;\n  margin-top: 10px;\n}\n\n.IA_endDate_B4pgGe2qZv2oE4zb8-ZOc {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 6px; \n  font-weight: lighter;\n  font-size: 14px;\n}\n\n.IA_coverOverlayToday_3fh9QPY-78UTV5CBpC4HqS {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 27px; \n  font-weight: bold;\n  font-size: 14px;\n}\n\n.IA_coverOverlayMonth_AKIyldJJQ0lKQ4Xhl4QLe {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 15px; \n  font-weight: normal;\n  font-size: 14px;\n}\n\n.IA_coverOverlayDay_1zBmuDeaCviUjEefKCkTeG {\n  float: left;\n  width: 100%;\n  text-align: center;\n  font-size: 22px;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_cardCover": "IA_cardCover_5gYIT0nwIPEeFCkzj37Ah",
	"IA_coverOverlay": "IA_coverOverlay_2gWySwWaR7mdO0e0x7RGnx",
	"IA_startDate": "IA_startDate_2wD-qPakfar-TW0gApvvv0",
	"IA_startDateendDateDivider": "IA_startDateendDateDivider_pH35yXhuQSNsvDtavDsgH",
	"IA_endDate": "IA_endDate_B4pgGe2qZv2oE4zb8-ZOc",
	"IA_coverOverlayToday": "IA_coverOverlayToday_3fh9QPY-78UTV5CBpC4HqS",
	"IA_coverOverlayMonth": "IA_coverOverlayMonth_AKIyldJJQ0lKQ4Xhl4QLe",
	"IA_coverOverlayDay": "IA_coverOverlayDay_1zBmuDeaCviUjEefKCkTeG"
};