import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './IntegrationsEditPanel.css';
import { IAPanel } from '../../../../panels/Panel';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IASpinner } from '../../../../spinner/Spinner';
import { Helper } from '../../../../../Helper';
import { PlayHelper } from '../../../PlayHelper';
import { IIconDropDown } from '../../../../../interfaces/IIconDropDown';
import { IACheckbox } from '../../../../checkbox/Checkbox';
import PlayService from '../../../../../services/PlayService';
import { IFeed } from '../../../../../interfaces/IFeed';
import { PlayIntegrationType, IPlayProfile } from '../../../../../interfaces/IPlay';
import { AppRegistrationConsentHelper, IPlayAppRegistration } from '../appRegistrationConsentHelper/AppRegistrationConsentHelper';
import { SharePointSiteManager } from '../sharePointSiteManager/SharePointSiteManager';
import { Popup } from '../../../reusableComponents/popup/Popup';
import { IAIcon } from '../../../../icon/Icon';
import { IAButton } from '../../../../button/Button';
import { IAMessageBar } from '../../../../messageBar/MessageBar';
import { IADropDown } from '../../../../dropDown/DropDown';
import { IATextField } from '../../../../textField/TextField';

export interface IProps {
  show: boolean;
  profiles: IPlayProfile[];
  store?: Store;
  onSave: () => void;
  onClose: () => void;
  onConnecting: () => Promise<void>;
  availableMessagesFeeds: IFeed[];
  availableEventsFeeds: IFeed[];
  availableSharePointNewsSites: IFeed[];
  integrationsPerProfile: { [profileId: string]: { messagesFeeds: IFeed[], eventsFeeds: IFeed[], sharePointSites: IFeed[] } };
  isEditingExistingIntegration: boolean;
  areMessagesConnected: boolean;
  areEventsConnected: boolean;
  isConsentGrantedForSharePointNewsApp: boolean;
  isConsentGrantedForSharePointNewsSiteManagementApp: boolean;
}

export interface IState {
  loading: boolean;
  isCreatingNewFeed: boolean;
  manageMode: boolean;
  availableFeeds: IFeed[];
  requiredAppRegistrations: IPlayAppRegistration[];
  isConnected: boolean;
  isConsentGrantedForSharePointNewsApp: boolean;
  isConsentGrantedForSharePointNewsSiteManagementApp: boolean;
  isDeletingSharepointNewsItem: boolean;
  feedToDelete: IFeed;
  showConfirmDeleteSharepointNewsItem: boolean;
  shouldReloadOnFocus: boolean;
}

@inject('store')
@observer
export class IntegrationsEditPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      isCreatingNewFeed: false,
      manageMode: false,
      availableFeeds: [],
      requiredAppRegistrations: [],
      isConnected: undefined,
      isConsentGrantedForSharePointNewsApp: props.isConsentGrantedForSharePointNewsApp,
      isConsentGrantedForSharePointNewsSiteManagementApp: props.isConsentGrantedForSharePointNewsSiteManagementApp,
      isDeletingSharepointNewsItem: false,
      feedToDelete: undefined,
      showConfirmDeleteSharepointNewsItem: false,
      shouldReloadOnFocus: false
    }
  }

  componentWillMount(): void {
    window.addEventListener("focus", this.handleFocusEvent)
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.handleFocusEvent)
  }

  handleFocusEvent = async () => {
    if (this.state.shouldReloadOnFocus) {
      this.setState({ shouldReloadOnFocus: false }, async () => {
        await this.onFocus(false);
      });
    }
  }

  onFocus = async (force: boolean = false, feedToAddSiteId?: string) => {
    if ((this.props.show &&
      this.props.store.integrationToEdit?.type &&
      (
        this.props.store.integrationToEdit.type === 'messages' ||
        this.props.store.integrationToEdit.type === 'events'
      ) &&
      this.state.isConnected
    ) || force) {
      this.setState({ loading: true })
      await this.props.onConnecting();
      this.onIntegrationTypeChange(this.props.store.integrationToEdit.type);
      this.onProfileChange(this.props.store.integrationToEdit.profileId, feedToAddSiteId);
      this.setState({ loading: false });
      this.state.isCreatingNewFeed && this.setState({ isCreatingNewFeed: false });
    }
  }

  componentWillReceiveProps(nextProps: Readonly<IProps>): void {
    if (nextProps.show && !this.props.show && nextProps.isEditingExistingIntegration) {
      this.onIntegrationTypeChange(this.props.store.integrationToEdit.type);
      this.onProfileChange(this.props.store.integrationToEdit.profileId);
    }

    if (this.props.show) {
      if (!this.props.availableMessagesFeeds && nextProps.availableMessagesFeeds) {
        this.setState({ availableFeeds: nextProps.availableMessagesFeeds });
      }
      if (!this.props.availableEventsFeeds && nextProps.availableEventsFeeds) {
        this.setState({ availableFeeds: nextProps.availableEventsFeeds });
      }
    }

    if (nextProps.isConsentGrantedForSharePointNewsApp !== undefined) {
      this.setState({ isConsentGrantedForSharePointNewsApp: nextProps.isConsentGrantedForSharePointNewsApp });
    }

    if (nextProps.isConsentGrantedForSharePointNewsSiteManagementApp !== undefined) {
      this.setState({ isConsentGrantedForSharePointNewsSiteManagementApp: nextProps.isConsentGrantedForSharePointNewsSiteManagementApp });
    }
  }


  private onProfileChange(id: string, feedToAddSiteId?: string) {
    if (!id) return;
    let currentFeeds: IFeed[];

    switch (this.props.store.integrationToEdit.type) {
      case 'messages':
        currentFeeds = this.props.integrationsPerProfile[id].messagesFeeds;
        break;
      case 'events':
        currentFeeds = this.props.integrationsPerProfile[id].eventsFeeds;
        break;
      case 'sharepoint-news':
        currentFeeds = this.props.integrationsPerProfile[id].sharePointSites;
        break;
      default:
        currentFeeds = [];
        break;
    }

    // newly added sites should be added to integration
    if (feedToAddSiteId) {
      this.state.availableFeeds.forEach(feed => {
        if (feed.meta?.siteId === feedToAddSiteId) {
          this.props.store.integrationToEdit?.feeds.push(feed.id);
          currentFeeds.push(feed);
          return;
        }
      })
    };

    this.props.store.integrationToEdit.profileId = id;
    this.props.store.integrationToEdit.feeds = currentFeeds.map(feed => feed.id);
  }

  private onIntegrationTypeChange(type: string) {
    let availableFeeds: IFeed[];
    let requiredAppRegistrations: IPlayAppRegistration[];
    let isConnected: boolean;

    this.props.store.integrationToEdit.type = type as PlayIntegrationType;

    switch (type) {
      case 'messages':
        availableFeeds = this.props.availableMessagesFeeds;
        isConnected = this.props.areMessagesConnected;
        break;
      case 'events':
        availableFeeds = this.props.availableEventsFeeds;
        isConnected = this.props.areEventsConnected;
        break;
      case 'sharepoint-news':
        availableFeeds = this.props.availableSharePointNewsSites;
        isConnected = this.state.isConsentGrantedForSharePointNewsApp; // **SharePointNewsSiteManagement** app is **optional**
        requiredAppRegistrations = !this.state.isConsentGrantedForSharePointNewsApp ? [
          {
            name: "SharePointNews",
            heading: this.localizationService.strings.PlayAdmin_AppRegistrationSharePointNewsTitle,
            description: this.localizationService.strings.PlayAdmin_AppRegistrationSharePointNewsDescription
          },
          {
            name: "SharePointNewsSiteManagement",
            heading: this.localizationService.strings.PlayAdmin_AppRegistrationSharePointNewsSiteManagementTitle,
            description: this.localizationService.strings.PlayAdmin_AppRegistrationSharePointNewsSiteManagementDescription
          }] : [];
        break;
      default:
        break;
    }

    this.onProfileChange(this.props.store.integrationToEdit.profileId);
    this.setState({ isConnected, availableFeeds, requiredAppRegistrations, manageMode: false });
  }


  public render(): JSX.Element {
    const integrationTypes = [];
    const availabelWidgetTypes = PlayHelper.getIntegrationTypes(this.localizationService);
    availabelWidgetTypes?.forEach((integrationType: IIconDropDown, i: number) => {
      integrationTypes.push(
        <div
          key={i}
          className={styles.IA_widgetType}
          style={{
            opacity: this.props.isEditingExistingIntegration && this.props.store.integrationToEdit?.type === integrationType.value ? 0.8 : (!this.props.store.integrationToEdit?.type || this.props.store.integrationToEdit?.type === integrationType.value) ? 1 : 0.5,
            cursor: this.props.isEditingExistingIntegration ? 'default' : 'cursor'
          }}
          onClick={() => {
            !this.props.isEditingExistingIntegration && this.onIntegrationTypeChange(integrationType.value)
          }}
        >
          <img
            src={`https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/${integrationType.icon}.png`}
            width="100%"
            height="100%"
            style={{
              float: "left",
              width: 65,
              height: 65,
              borderRadius: 10,
              backgroundColor: "#eeeeee",
              border: integrationType.icon === "widget-sharepoint-news" ? "1px solid #e5e5e5" : "1px solid transparent"
            }}
          />
          <div
            className={styles.IA_widgetTypeLabel}
            style={{
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            {integrationType.label}
          </div>
        </div>
      );
    });
    let availableFeeds = [];
    if (this.state.availableFeeds?.length > 0 || this.props.store?.integrationToEdit?.feeds) {
      this.state.availableFeeds?.forEach((feed: IFeed) => {
        const id = Helper.getRandomStringKey();
        availableFeeds.push(
          <div
            id={id}
            className={styles.IA_feed_element}
            onMouseOver={() => {
              document.getElementById(id).style.backgroundColor = this.props.store.darkMode ? "#393939" : "#e6e6e6";
            }}
            onMouseOut={() => {
              document.getElementById(id).style.backgroundColor = "";
            }}
          >
            <IACheckbox
              key={feed.id}
              label={feed.title}
              highlightColor={this.props.store.highlightColor}
              isChecked={this.props.store.integrationToEdit?.feeds.indexOf(feed.id) !== -1}
              onChange={() => {
                const index = this.props.store.integrationToEdit?.feeds.indexOf(feed.id);
                if (index === -1) {
                  this.props.store.integrationToEdit?.feeds.push(feed.id);
                } else {
                  this.props.store.integrationToEdit?.feeds.splice(index, 1);
                }
              }}
              styles={{
                padding: 10
              }}
            />
            {(this.props.store.integrationToEdit?.type === 'messages' || this.props.store.integrationToEdit?.type === 'events') &&
              <>
                <IAIcon
                  title='Edit'
                  size={20}
                  style={{ float: "left", padding: "10px 7px 0 0", marginLeft: "auto" }}
                  color={this.props.store.highlightColor}
                  onClick={() => {
                    this.setState({ shouldReloadOnFocus: true });
                    const sharepointRootSite = this.props.store.environment !== "Development" ? this.props.store.currentUser?.spWebUrl : 'https://intraactivedev.sharepoint.com';
                    window.open(`${sharepointRootSite}/sites/IntraActive-Admin?showFeedWidgetForComponent=${this.props.store.integrationToEdit.type === 'messages' ? 'IntraActive-Messages' : 'IntraActive-Events'}&feedId=${feed.id}&mode=edit`, '_blank')
                  }}
                />
                <IAIcon
                  title='Trash'
                  size={20}
                  style={{ float: "left", padding: "10px 7px 0 0" }}
                  color={this.props.store.highlightColor}
                  onClick={() => {
                    this.setState({ shouldReloadOnFocus: true });
                    const sharepointRootSite = this.props.store.environment !== "Development" ? this.props.store.currentUser?.spWebUrl : 'https://intraactivedev.sharepoint.com';
                    window.open(`${sharepointRootSite}/sites/IntraActive-Admin?showFeedWidgetForComponent=${this.props.store.integrationToEdit.type === 'messages' ? 'IntraActive-Messages' : 'IntraActive-Events'}&feedId=${feed.id}&mode=delete`, '_blank')
                  }}
                />
              </>
            }
            {this.props.store.integrationToEdit?.type == 'sharepoint-news' &&
              <>
                <IAIcon
                  title='Trash'
                  size={20}
                  style={{ float: "left", opacity: this.state.isDeletingSharepointNewsItem ? 0.5 : 1, padding: "10px 7px 0 0", marginLeft: "auto" }}
                  color={this.props.store.highlightColor}
                  onClick={() => {
                    // Bail early if something's already being deleted
                    if (this.state.isDeletingSharepointNewsItem) {
                      return;
                    }
                    this.setState({ showConfirmDeleteSharepointNewsItem: true, feedToDelete: feed });
                  }}
                />
              </>
            }
          </div>
        );
      })
    }
    return (
      <IAPanel
        open={this.props.show}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={false}
        width={440}
        darkMode={this.props.store.darkMode}
        dataAutomationIdPrefix="integrations-edit-panel"
        panelId="integrations-edit-panel"
        isInTeams={this.props.store.isTeams}
        navigationsBarContent={
          <IAButton
            label={this.props.isEditingExistingIntegration ? this.localizationService.strings.PlayAdmin_Update?.toUpperCase() : this.localizationService.strings.PlayAdmin_Save?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            disbaled={!this.state.isConnected || !(this.props.store.integrationToEdit?.feeds?.length || this.props.store.integrationToEdit?.accessToken)}
            darkMode={this.props.store.darkMode}
            onClick={async () => {
              this.setState({ loading: true })
              await this.props.onSave();
              this.setState({ loading: false })
            }}
            borderRadius={5}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7,
              marginRight: 10
            }}
          />
        }
        close={() => {
          this.setState({ availableFeeds: undefined, isConnected: undefined })
          this.props.onClose();
        }}
      >
        {this.state.showConfirmDeleteSharepointNewsItem &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_IntegrationConfirmDeleteSite}
            close={() => this.setState({ showConfirmDeleteSharepointNewsItem: false })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    // Signal that we are currently deleting something
                    this.setState({ isDeletingSharepointNewsItem: true, showConfirmDeleteSharepointNewsItem: false })
                    // Delete the permission for the selected sharepoint site
                    PlayService.deleteSharePointSitePermission(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.state.feedToDelete.meta.siteId).then(() => {
                      this.setState({ isDeletingSharepointNewsItem: false });
                      this.onFocus(true);
                    });
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ showConfirmDeleteSharepointNewsItem: false })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.props.store.integrationToEdit ?
          <div
            className={styles.IA_integrationsEditPanel}
            style={{
              height: window.innerHeight - 44,
              backgroundColor: this.props.store.darkMode ? "#414141" : "#ffffff",
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            <div className={styles.IA_content} style={{ backgroundColor: this.props.store.darkMode ? Helper.darkModeBackgroundColor : '#fff' }}>
              <h1 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.props.store.integrationToEdit?.id ? this.localizationService.strings.PlayAdmin_EditIntegration : this.localizationService.strings.PlayAdmin_AddNewIntegration}</h1>
              <div className={styles.IA_widgetWrapper}>
                {integrationTypes}
                {this.props.store.integrationToEdit.type && this.props.store.integrationToEdit.type !== "workplace-feed" &&
                  <IAMessageBar
                    type={"warning"}
                    style={{
                      marginTop: 20,
                      borderRadius: 5,
                      background: "#eeeeee"
                    }}
                    content={
                      <div>
                        {this.localizationService.strings.PlayAdmin_SharepointIntegrationDisclaimer}
                      </div>
                    }
                    onDismiss={() => { }}
                  />
                }
              </div>
              {this.props.store.integrationToEdit.type === "workplace-feed" ?
                <div>
                  <IADropDown
                    label={this.localizationService.strings.PlayAdmin_SelectAProfileToAccess?.toUpperCase()}
                    selectedOption={this.props.store.integrationToEdit?.profileId}
                    options={[{
                      key: undefined,
                      value: this.localizationService.strings.PlayAdmin_SelectAProfileToAccess,
                      disabled: true
                    },
                    ...this.props.profiles.map(profile => { return { key: profile.id, value: profile.title } })
                    ]}
                    disabled={this.props.isEditingExistingIntegration}
                    darkMode={this.props.store.darkMode}
                    highlightColor={this.props.store.highlightColor}
                    onChange={(profile) => {
                      this.onProfileChange(profile)
                    }}
                    style={{
                      float: "left",
                      width: 340
                    }}
                    borderRadius={5}
                  />
                  <IATextField
                    label={this.localizationService.strings.PlayAdmin_AddWorkplaceAPIKey?.toUpperCase()}
                    text={this.props.store.integrationToEdit.accessToken}
                    disabled={!this.props.store.integrationToEdit.profileId}
                    onChange={(accessToken: string) => {
                      this.props.store.integrationToEdit.accessToken = accessToken;
                      this.setState({ isConnected: true }, () => this.forceUpdate());
                    }}
                  />
                </div>
                :
                (this.props.store.integrationToEdit?.type &&
                  <>
                    <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
                    {this.state.loading ?
                      <div>
                        <div
                          style={{ clear: 'both', paddingTop: 10 }}>
                          <IASpinner
                            color={this.props.store.highlightColor}
                          />
                        </div>
                        {this.state.isCreatingNewFeed &&
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {this.localizationService.strings.PlayAdmin_CreatingNewFeed}
                          </div>
                        }
                      </div>
                      :
                      <div>
                        {this.state.isConnected ?
                          <>
                            {!this.state.manageMode &&
                              <IADropDown
                                label={this.localizationService.strings.PlayAdmin_SelectAProfileToAccess?.toUpperCase()}
                                selectedOption={this.props.store.integrationToEdit?.profileId}
                                options={[{
                                  key: undefined,
                                  value: this.localizationService.strings.PlayAdmin_SelectAProfileToAccess,
                                  disabled: true
                                },
                                ...this.props.profiles.map(profile => { return { key: profile.id, value: profile.title } })
                                ]}
                                disabled={this.props.isEditingExistingIntegration}
                                darkMode={this.props.store.darkMode}
                                highlightColor={this.props.store.highlightColor}
                                onChange={(profile) => {
                                  this.onProfileChange(profile)
                                }}
                                style={{
                                  float: "left",
                                  width: 340
                                }}
                                borderRadius={5}
                              />
                            }
                            {this.props.store.integrationToEdit?.profileId &&
                              <div className={styles.IA_feeds} style={{ marginTop: this.state.manageMode ? 10 : 30 }}>
                                <label>
                                  {(this.props.store.integrationToEdit.type === "messages" || this.props.store.integrationToEdit.type === "events") &&
                                    <>
                                      {this.localizationService.strings.PlayAdmin_SelectAFeed?.toUpperCase()}
                                    </>
                                  }
                                  {this.props.store.integrationToEdit.type === "sharepoint-news" && !this.state.manageMode &&
                                    <>
                                      {this.localizationService.strings.PlayAdmin_SharePointNewsSelectSites?.toUpperCase()}
                                    </>
                                  }
                                </label>
                                <div style={{ margin: '5px 0', clear: "both" }}>
                                  {(this.props.store.integrationToEdit.type === "messages" || this.props.store.integrationToEdit.type === "events") &&
                                    <>
                                      {this.state.availableFeeds ?
                                        <>{availableFeeds}</>
                                        :
                                        <>{this.localizationService.strings.PlayAdmin_NoFeeds}</>
                                      }
                                    </>
                                  }
                                  {this.props.store.integrationToEdit.type === "sharepoint-news" &&
                                    <>
                                      {this.state.manageMode ?
                                        <>
                                          <SharePointSiteManager
                                            onComplete={(id) => {
                                              this.setState({ manageMode: false });
                                              this.onFocus(true, id);
                                            }}
                                          />
                                          <IAButton
                                            label={this.localizationService.strings.PlayAdmin_SharePointNewsSiteManagementBack}
                                            buttonColor={this.props.store.highlightColor}
                                            disbaled={false}
                                            darkMode={this.props.store.darkMode}
                                            onClick={() => {
                                              this.setState({ manageMode: false });
                                            }}
                                            borderRadius={5}
                                            showSpinner={this.props.store.workingOnIt}
                                            style={{ float: "none" }}
                                          />
                                        </>
                                        :
                                        <>
                                          {this.state.availableFeeds ?
                                            <>{availableFeeds}</>
                                            :
                                            <>{this.localizationService.strings.PlayAdmin_SharePointNewsNoFeedsMessage}</>
                                          }
                                        </>
                                      }
                                    </>
                                  }
                                </div>
                                {(this.props.store.integrationToEdit.type === "messages" || this.props.store.integrationToEdit.type === "events") &&
                                  <IAButton
                                    label={this.localizationService.strings.PlayAdmin_AddNewFeed?.toUpperCase()}
                                    buttonColor={this.props.store.highlightColor}
                                    disbaled={false}
                                    darkMode={this.props.store.darkMode}
                                    onClick={() => {
                                      this.setState({ isCreatingNewFeed: true, shouldReloadOnFocus: true })
                                      const sharepointRootSite = this.props.store.environment !== "Development" ? this.props.store.currentUser?.spWebUrl : 'https://intraactivedev.sharepoint.com';
                                      window.open(`${sharepointRootSite}/sites/IntraActive-Admin?showFeedWidgetForComponent=${this.props.store.integrationToEdit.type === 'messages' ? 'IntraActive-Messages' : 'IntraActive-Events'}`, '_blank')
                                    }}
                                    borderRadius={5}
                                    showSpinner={this.props.store.workingOnIt}
                                    style={{ clear: "both", marginTop: 15 }}
                                  />
                                }
                                {this.props.store.integrationToEdit.type === "sharepoint-news" && !this.state.manageMode &&
                                  <IAButton
                                    label={"Add site"}
                                    buttonColor={this.props.store.highlightColor}
                                    disbaled={false}
                                    darkMode={this.props.store.darkMode}
                                    onClick={() => {
                                      this.setState({ manageMode: true });
                                    }}
                                    borderRadius={5}
                                    showSpinner={this.props.store.workingOnIt}
                                    style={{ clear: "both", marginTop: 15 }}
                                  />
                                }
                              </div>}
                          </>
                          :
                          <>
                            {(this.props.store.integrationToEdit.type === "messages" || this.props.store.integrationToEdit.type === "events") &&
                              <div className={styles.IA_feeds}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <IAButton
                                    label={this.localizationService.strings.PlayAdmin_Connect?.toUpperCase()}
                                    buttonColor={this.props.store.highlightColor}
                                    disbaled={false}
                                    darkMode={this.props.store.darkMode}
                                    onClick={async () => {
                                      const integrationTypeToEdit = this.props.store.integrationToEdit.type as "messages" | "events";
                                      PlayService.connectIntranetApplicationFunction(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, integrationTypeToEdit, null).then(async (_) => {
                                        this.setState({ loading: true, isCreatingNewFeed: true, shouldReloadOnFocus: true });
                                        const sharepointRootSite = this.props.store.environment !== "Development" ? this.props.store.currentUser?.spWebUrl : 'https://intraactivedev.sharepoint.com';
                                        window.open(`${sharepointRootSite}/sites/IntraActive-Admin?showFeedWidgetForComponent=${integrationTypeToEdit === 'messages' ? 'IntraActive-Messages' : 'IntraActive-Events'}`, '_blank')
                                        await setTimeout(() => { }, 10000);
                                        const interval = setInterval(() => {
                                          PlayService.connectIntranetApplicationFunction(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.integrationToEdit.profileId, integrationTypeToEdit, null)
                                            .then(async (isConnected) => {
                                              if (isConnected) {
                                                await this.props.onConnecting();
                                                this.onIntegrationTypeChange(this.props.store.integrationToEdit.type);
                                                this.onProfileChange(this.props.store.integrationToEdit.profileId);
                                                this.setState({ loading: false, isCreatingNewFeed: false });
                                                clearInterval(interval);
                                              }
                                            })
                                        }, 5000)
                                      });
                                    }}
                                    borderRadius={5}
                                    showSpinner={this.props.store.workingOnIt}
                                  />
                                </div>
                              </div>
                            }
                            {this.props.store.integrationToEdit.type === "sharepoint-news" &&
                              <AppRegistrationConsentHelper
                                appRegistrations={this.state.requiredAppRegistrations}
                                onConsent={(appRegistration) => {
                                  switch (appRegistration) {
                                    case 'SharePointNews':
                                      this.setState({ isConsentGrantedForSharePointNewsApp: true });
                                      break;
                                    case 'SharePointNewsManagement':
                                      this.setState({ isConsentGrantedForSharePointNewsSiteManagementApp: true });
                                      break;
                                    default:
                                      break;
                                  }
                                }}
                                onSuccess={() => {
                                  this.setState({ isConnected: true });
                                }}
                              />
                            }
                          </>
                        }
                      </div>
                    }
                  </>
                )
              }
            </div>
          </div>
          :
          <div
            className={styles.IA_loading}
            style={{
              backgroundColor: this.props.store.darkMode ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"
            }}
          >
            <IASpinner
              color={this.props.store.highlightColor}
            />
            <div className={styles.IA_weAreWorkingOnIt}>{this.localizationService.strings.Composer_WeAreWorkingOnIt}</div>
          </div>
        }
      </IAPanel >
    );
  }


}