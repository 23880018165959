import * as React from 'react';
import { Store } from '../../../../../stores';
import { inject, observer } from 'mobx-react';
import { IAIcon } from '../../../../../../icon/Icon';
import { IASpinner } from '../../../../../../spinner/Spinner';
import { Helper } from '../../../../../../../Helper';
import PlayService from '../../../../../../../services/PlayService';
import { LocalizationService } from '../../../../../../../services/LocalizationService';
import * as styles from './SocialGalleryDownloader.css';

export interface IProps {
  store?: Store;
}

export interface IState {
  zipStatus: "notStarted" | "generating" | "success" | "failed";
  zipDirectLink: string;
}

@inject('store')
@observer
export class SocialGalleryDownloader extends React.Component<IProps, IState> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      zipStatus: "notStarted",
      zipDirectLink: undefined
    }
  }

  private checkStatus(zipStatusQuery:string){
    PlayService.getSocialGalleryZipStatus(this.props.store.tenantId, zipStatusQuery).then(response => {
      if (response.runtimeStatus === "Completed") {
        this.setState({zipStatus: "success", zipDirectLink: response.output});
      } else if (response.runtimeStatus === "Failed") {
        this.setState({zipStatus: "failed", zipDirectLink: undefined});
      } else {
        setTimeout(() => this.checkStatus(zipStatusQuery), 2000);
      }
    }).catch(() => {
      this.setState({zipStatus: "failed", zipDirectLink: undefined});
    });
  }

  render(): React.ReactNode {
    const backgroundColor = this.props.store.darkMode ? Helper.darkModeInputFieldBackgroundColor : "#f4f4f4";
    let borderColor = backgroundColor;
    if (this.state.zipStatus === "generating") borderColor = this.props.store.highlightColor;
    if (this.state.zipStatus === "success") borderColor = "#4dbd5a";
    if (this.state.zipStatus === "failed") borderColor = "#c44949";
    return (
      <div
        className={styles.IA_SocialGalleryDownloader}
        style={{
          backgroundColor: backgroundColor,
          border: `1px solid ${borderColor}`
        }}
        onClick={() => {
          if (this.state.zipStatus === "notStarted" || this.state.zipStatus === "failed") {
            this.setState({zipStatus: "generating"}, () => {
              PlayService.generateSocialGalleryZip(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, this.props.store.widgetToEdit.id).then(response => {
                console.log(response);
                this.checkStatus(response.statusUri);
              }).catch(() => {
                this.setState({zipStatus: "failed"});
              });
            });
          }

          if (this.state.zipStatus === "success" && this.state.zipDirectLink) {
            window.open(this.state.zipDirectLink, "_blank");
          }
        }}
      >
        {this.state.zipStatus === "notStarted" &&
          <>
            <IAIcon
              title="Download02"
              size={28}
              color={this.props.store.darkMode ? "#ffffff" : "#666666"}
            />
            {this.localizationService.strings.PlayAdmin_DownloadSocialGalleryZip}
          </>
        }
        
        {this.state.zipStatus === "generating" &&
          <>
            <IASpinner
              color={borderColor}
              style={{
                margin: 0,
                height: 28,
                width: 28
              }}
            />
            {this.localizationService.strings.PlayAdmin_GeneratingSocialGalleryZip}
          </>
        }
        
        {this.state.zipStatus === "success" &&
          <>
            <IAIcon
              title="Download02"
              size={28}
              color={borderColor}
            />
            {this.localizationService.strings.PlayAdmin_SuccessCreatingSocialGalleryZip}
          </>
        }
        
        {this.state.zipStatus === "failed" &&
          <>
            <IAIcon
              title="Error"
              size={28}
              color={borderColor}
            />
            {this.localizationService.strings.PlayAdmin_FailedCreatingSocialGalleryZip}
          </>
        }
      </div>
    );
  }
}