import { observable } from "mobx";
import { IEnvironment } from "../../../interfaces/IEnvironment";
import { IPlayer, IPlayerExtended, IPlayWeather, IPlayWeatherData } from "../../../interfaces/IPlay";

export class PlayStore {

  @observable public pendingPlayer: IPlayer;
  @observable public player: IPlayerExtended;
  @observable public environment:  IEnvironment;
  @observable public tenantId: string = "c7392b95-d07b-4653-87a7-6c709f527c37";
  @observable public preview: boolean;

  @observable public loading: boolean = true;

  @observable public slides: JSX.Element[] = [];

  @observable public currentSlideIndex: number = 0;
  @observable public connected: boolean = true;

  @observable public currentWeather: IPlayWeather;
  @observable public weatherData: IPlayWeatherData;
}

