import * as React from 'react';
import { PersonCard } from '@microsoft/mgt-react/dist/es6/spfx'

export interface IMSPersonCardProps {
  userLoginName: string;
  userProfileImage?: string;
}

export interface IMSPersonCardState {
  showPersonCard?: boolean;
  personCardTop?: number;
}

export class MSPersonCard extends React.Component<IMSPersonCardProps, IMSPersonCardState> {
  private timeOutId: NodeJS.Timeout;

  constructor(props: IMSPersonCardProps) {
    super(props);
    this.state = {
      showPersonCard: false
    }
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  private onClickHandler(event: MouseEvent) {
    let element = event.target as HTMLElement;
    if (element.tagName !== "MGT-PERSON-CARD") {
      this.setState({ showPersonCard: false, personCardTop: undefined }, () => {
        window.removeEventListener("mousedown", this.onClickHandler);
      });
    }
  }

  public render(): JSX.Element {
    return (
      <>
        <div
          onMouseOver={(event) => {
            if (!this.state.showPersonCard) {
              const mouseY = event.clientY;
              this.timeOutId = setTimeout(() => {
                let personCardTop = mouseY;
                const cardHeight = 523;
                if (personCardTop + cardHeight > window.innerHeight) {
                  personCardTop = window.innerHeight - cardHeight - 10;
                }
                this.setState({ showPersonCard: true, personCardTop: personCardTop });
                window.addEventListener("mousedown", this.onClickHandler);
              }, 500);
            }
          }}
          onMouseLeave={() => clearTimeout(this.timeOutId)}
        >
          {this.props.children}
        </div>
        {this.state.showPersonCard &&
          <div
            style={{
              position: "fixed",
              zIndex: 1000001,
              top: this.state.personCardTop,
            }}
          >
            <PersonCard
              color="#eb4034"
              personQuery={this.props.userLoginName}
              isExpanded={true}
              personImage={this.props.userProfileImage}
              showPresence={true}
            />
          </div>
        }
      </>
    );
  }
}