import * as React from 'react';
import { Provider } from 'mobx-react';
import { configure } from 'mobx';
import { PlayStore } from './stores';
import { Player } from './Player';
import { IPlayWeatherData, IPlayerExtended } from '../../interfaces/IPlay';
import { IAReplayInteract } from './replayInteract/Index';
import { PlayHelper } from '../playAdmin/PlayHelper';
import { IEnvironment } from '../../interfaces/IEnvironment';

export interface IProps {
  environment: IEnvironment;
  preview?: boolean;
  previewWeather?: IPlayWeatherData;
  player?: IPlayerExtended;
}

export class IAPlayer extends React.Component<IProps> {

  private store: PlayStore;

  constructor(props: IProps) {
    super(props);
    this.store = new PlayStore();
    this.store.environment = props.environment;
    this.store.preview = props.preview;
    if (this.props.preview && this.props.previewWeather) {
      this.store.weatherData = this.props.previewWeather;
    }
    configure({
      isolateGlobalState: true,
      enforceActions: "never"
    });
  }

  public render(): JSX.Element {
    const urlParams = new URLSearchParams(window.location.search);
    const tenantId = urlParams.get("tenant");
    const profileId = urlParams.get("profile");
    const widgetId = urlParams.get("widget");
    const galleryName = urlParams.get("name");
    const orientation = urlParams.get("orientation") as "horizontal" | "vertical";
    const objectFit = urlParams.get("objectFit") as "cover" | "contain";
    const imageSize = urlParams.get("imageSize") as "cover" | "contain"
    return tenantId && profileId && widgetId ?
      (
        <IAReplayInteract
          environment={this.props.environment}
          tenantId={tenantId}
          profileId={profileId}
          widgetId={widgetId}
          galleryName={galleryName ?? "REPLAY SOCIAL GALLERY"}
          orientation={orientation ?? "horizontal"}
          objectFit={objectFit ?? "cover"}
          imageSize={imageSize ?? PlayHelper.getStandardImageFormat(2000, 1000)}
          type={urlParams.get("type")}
          pollOptionId={urlParams.get("optionId")}
        />
      )
      :
      (
        <Provider store={this.store}>
          <Player
            preview={this.props.preview}
            player={this.props.player}
          />
        </Provider>
      );
  }


}