import * as React from 'react';
import * as styles from './Pivot.css';
import "../../styles/BasicIA.css";
import { IAIcon } from '../icon/Icon';

export interface IIAPivotProps {
  items: { key: string, text: string, disabled?: boolean, icon?: string, error?: boolean, unselectedBackgroundColor?: string }[];
  highlightColor: string;
  textColor?: string;
  selectedKey: string;
  style?: React.CSSProperties;
  select: (key: string) => void;
  asTabs?: boolean;
  tabBackgrondColor?: string;
  selectedTabBackgrondColor?: string;
  showNumber?: boolean;
  showIcon?: boolean;
  isMobile?: boolean;
  autoTabWidth?: boolean;
  height?: number;
}

export class IAPivot extends React.Component<IIAPivotProps, {}> {

  constructor(props: IIAPivotProps) {
    super(props);
  }

  public render(): JSX.Element {
    let items = [];
    this.props.items.forEach((pivotItem: { key: string, text: string, disabled?: boolean, icon?: string, error?: boolean, unselectedBackgroundColor?: string }, index) => {
      if (this.props.asTabs) {
        items.push(
          <div
            tabIndex={0}
            key={pivotItem.key}
            className={[styles.IA_tabItem, "IA_OnFocus"].join(" ")}
            onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === "Enter") {
                this.props.select(pivotItem.key);
              }
            }}
            onClick={() => {
              if (!pivotItem.disabled) {
                this.props.select(pivotItem.key);
              }
            }}
            style={{
              width: this.props.autoTabWidth ? "auto" : `${100 / this.props.items.length}%`,
              backgroundColor: this.props.selectedKey === pivotItem.key ? this.props.selectedTabBackgrondColor : (pivotItem.unselectedBackgroundColor ?? this.props.tabBackgrondColor),
              color: pivotItem.disabled ? "#999999" : "#000000",
              borderColor: this.props.selectedTabBackgrondColor,
              borderRight: this.props.autoTabWidth ? "none" : "1px solid #ffffff",
              height: this.props.height ? this.props.height : 40,
              lineHeight: this.props.height ? `${this.props.height}px` : "40px"
            }}
          >
            {this.props.showNumber &&
              <div
                className={styles.IA_tabItemNumber}
                style={{
                  backgroundColor: this.props.selectedKey === pivotItem.key ? this.props.highlightColor : "#ffffff",
                  color: this.props.selectedKey === pivotItem.key ? "#ffffff" : "#000000",
                }}
              >
                {index + 1}
              </div>
            }
            {this.props.showIcon &&
              <div
                className={styles.IA_tabItemIcon}
              >
                <IAIcon
                  title={pivotItem.icon}
                  size={18}
                  color={pivotItem.error ? "red" : this.props.selectedKey === pivotItem.key ? this.props.highlightColor : (this.props.textColor ? this.props.textColor : "#000000")}
                  style={{
                    float: "left"
                  }}
                />
              </div>
            }
            {!this.props.isMobile &&
              <div
                className={styles.IA_tabItemText}
                style={{
                  color: pivotItem.error ? "red" : this.props.selectedKey === pivotItem.key ? this.props.highlightColor : (this.props.textColor ? this.props.textColor : "#000000"),
                  marginLeft: this.props.autoTabWidth ? 0 : 10
                }}
              >
                {pivotItem.text}
              </div>
            }
          </div>
        );
      } else {
        items.push(
          <div
            tabIndex={0}
            key={pivotItem.key}
            className={[styles.IA_pivotItem, "IA_OnFocus"].join(" ")}
            onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
              if (event.key === "Enter") {
                this.props.select(pivotItem.key);
              }
            }}
            onClick={() => {
              if (!pivotItem.disabled) {
                this.props.select(pivotItem.key);
              }
            }}
            style={{
              borderBottom: `2px solid ${this.props.selectedKey === pivotItem.key ? this.props.highlightColor : "transparent"}`,
              opacity: pivotItem.disabled ? 0.5 : 1
            }}
          >
            {pivotItem.text}
          </div>
        );
      }
    });
    return (
      <div
        className={styles.IA_pivot}
        style={this.props.style}
      >
        {items}
      </div>
    );
  }


}