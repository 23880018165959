import { Helper } from "./Helper";
import { AuthenticationRedirectionService } from "./AuthenticationRedirectionService";
import { IAadHttpClientFactory } from "./Aad/IAadHttpClientFactory";
import { IAadHttpClient } from "./Aad/IAadHttpClient";
import { IAadHttpClientConfigurations } from "./Aad/IAadHttpClientConfigurations";
import { IHttpClientResponse } from "./Aad/IHttpClientResponse";
import "./Global";
import { TeamsAuthenticationService } from "./TeamsAuthenticationService";
import { injectable } from "./Containers/Container";

const TOKEN_COOKIE_NAME: string = "token";
const TENANTREF_URL_NAME: string = "tenantRef";
const INTRAACTIVE_AUTHENTICATE_URL_NAME: string = "intraactive-authenticate";
const INTRAACTIVE_AUTHENTICATED_RETURN_URL_NAME: string = "intraactive-authenticated-callback";
const SESSION_STORAGE_REDIR_ID: string = "last-redirect-attempt";
const SESSION_STORAGE_UPN_ID: string = "last-upn-checked";

/**
 * Retrieves a token from the backend systems, using AadHttpClientFactory if in SPFx content.
 * It includes authentication paths for SharePoint mobile app and MS Teams app.
 * @example
 * // With AadHttpClientFactory
 * const service = new IntraActiveTokenService("http://api-url/", aadHttpClientFactory, "app-id-from-config", "authentication functions URI from config");
 * service.getToken();
 * @example
 * // Without AadHttpClientFactory
 * const service = new IntraActiveTokenService("http://api-url/", IntraActiveTokenService.NO_AAD_CLIENT_FACTORY, IntraActiveTokenService.NO_AAD_CLIENT_APPID_OR_AUTHFUNCURI, IntraActiveTokenService.NO_AAD_CLIENT_APPID_OR_AUTHFUNCURI)
 * service.getTokenWithoutAadHttpClientFactory();
 */
@injectable("IntraActiveTokenService")
export class IntraActiveTokenService {
  public static readonly NO_AAD_CLIENT_FACTORY: IAadHttpClientFactory = null;
  public static readonly NO_AAD_CLIENT_APPID_OR_AUTHFUNCURI: string = null;
  private static readonly configurations: IAadHttpClientConfigurations = { v1: {} };

  /**
   * IntraActiveTokenService constructor.
   * @param apiUrl Main API URI
   * @param aadHttpClientFactory SPFx Aad Client Factory (optional - otherwise use IntraActiveTokenService.NO_AAD_CLIENT_FACTORY)
   * @param aadClientAppId Client App ID (optional - otherwise use IntraActiveTokenService.NO_AAD_CLIENT_APPID_OR_AUTHFUNCURI)
   * @param authenticationFunctionsUri Authentication functions URI (optional - otherwise use IntraActiveTokenService.NO_AAD_CLIENT_APPID_OR_AUTHFUNCURI)
   * @param tenantName Tenant name (optional)
   * @param authenticationRedirectionService Authentication redirection service that will be used to find the correct API URIs (optional)
   * @example
   * // With AadHttpClientFactory
   * const service = new IntraActiveTokenService("http://api-url/", aadHttpClientFactory, "app-id-from-config", "authentication functions URI from config");
   * service.getToken();
   * @example
   * // Without AadHttpClientFactory
   * const service = new IntraActiveTokenService("http://api-url/", IntraActiveTokenService.NO_AAD_CLIENT_FACTORY, IntraActiveTokenService.NO_AAD_CLIENT_APPID_OR_AUTHFUNCURI, IntraActiveTokenService.NO_AAD_CLIENT_APPID_OR_AUTHFUNCURI)
   * service.getTokenWithoutAadHttpClientFactory();
   */
  public constructor(
    public readonly apiUrl: string,
    public readonly aadHttpClientFactory: IAadHttpClientFactory,
    public readonly aadClientAppId: string,
    public readonly authenticationFunctionsUri: string,
    public readonly tenantName: string = Helper.getTenantName(),
    public readonly teamsAuthenticationService = new TeamsAuthenticationService(),
    public readonly authenticationRedirectionService = new AuthenticationRedirectionService(apiUrl),
  ) {
  }

  /**
   * Gets a token using the provided AadHttpClientFactory, or API redirects if that fails
   * @returns Promise, but without the token. Retrieve the token from cookies (Helper.getCookieValue(TOKEN_COOKIE_NAME))
   */
  public getToken(): Promise<void> {
    // Centralized authorization
    if (window.IntraActive.Authentication.AuthorizationPromise) {
      return window.IntraActive.Authentication.AuthorizationPromise;
    }

    const authorizationPromise = new Promise<void>((resolve, reject) => {
      const intraActiveAuthenticatedReturnUrlParamToken = Helper.getParameterByName(INTRAACTIVE_AUTHENTICATED_RETURN_URL_NAME);
      const cookieToken = Helper.getCookieValue(TOKEN_COOKIE_NAME);
      const paramToken = Helper.getParameterByName(TOKEN_COOKIE_NAME);

      if (intraActiveAuthenticatedReturnUrlParamToken === INTRAACTIVE_AUTHENTICATED_RETURN_URL_NAME) {
        this.checkLoginTeamsResult(paramToken, reject);
        return;
      }

      if (cookieToken) {
        if (paramToken) {
          this.cleanUpQueryStringVariablesUsingRedirect(paramToken, reject);
          return;
        }

        // Authentication flow ended - it's safe to resume
        resolve();
        return;
      } else if (paramToken) {
        this.cleanUpQueryStringVariablesUsingRedirect(paramToken, reject);
        return;
      }

      const intraActiveAuthenticateParamToken = Helper.getParameterByName(INTRAACTIVE_AUTHENTICATE_URL_NAME);
      if (intraActiveAuthenticateParamToken === INTRAACTIVE_AUTHENTICATE_URL_NAME) {
        this.checkIfTeamsPopupNeedsToTriggerLogin(paramToken, reject);
        return;
      }

      if (this.aadHttpClientFactory === IntraActiveTokenService.NO_AAD_CLIENT_FACTORY) {
        this.requestAuthenticationWithRedirects(paramToken);
        reject();
        return;
      }

      this.aadHttpClientFactory
        .getClient(this.aadClientAppId)
        .then((client: IAadHttpClient): void => {
          // Fix for infinite redirection from IAadHttpClient service without 3rd party cookies
          if (this.hasTriedToRedirectRecently()) {
            this.requestAuthenticationWithRedirects(paramToken);
            reject();
            return;
          }
          this.setLastRedirectAttemptFlag();

          client
            .get(`${this.authenticationFunctionsUri}/api/v2/aad-authorize/${Helper.getTenantName()}`, IntraActiveTokenService.configurations.v1)
            .then((response: IHttpClientResponse): Promise<string> => {
              if (!response.ok) {
                throw new Error("Use redirect authentication");
              }
              return response.text();
            })
            .then((responseData: string): void => {
              Helper.setCookie(TOKEN_COOKIE_NAME, responseData);
              resolve();
            })
            .catch(() => {
              this.initializeTeamsAuthUsingPopups(resolve, paramToken, reject)
                .catch(() => {
                  this.requestAuthenticationWithRedirects(paramToken);
                  reject();
                });
            });
        })
        .catch(() => {
          this.initializeTeamsAuthUsingPopups(resolve, paramToken, reject)
            .catch(() => {
              this.requestAuthenticationWithRedirects(paramToken);
              reject();
            });
        });
    });

    window.IntraActive.Authentication.AuthorizationPromise = authorizationPromise;
    return authorizationPromise;
  }

  /**
   * Gets a token using the API redirects
   * @returns Promise, but without the token. Retrieve the token from cookies (Helper.getCookieValue(TOKEN_COOKIE_NAME))
   */
  public getTokenWithoutAadHttpClientFactory(): Promise<void> {
    // Centralized authorization
    if (window.IntraActive.Authentication.AuthorizationPromise) {
      return window.IntraActive.Authentication.AuthorizationPromise;
    }

    var authorizationPromise = new Promise<void>((resolve, reject) => {
      let cookieToken = Helper.getCookieValue(TOKEN_COOKIE_NAME);
      let paramToken = Helper.getParameterByName(TOKEN_COOKIE_NAME);

      if (cookieToken) {
        if (paramToken) {
          this.cleanUpQueryStringVariablesUsingRedirect(paramToken, reject);
          return;
        }

        // Authentication flow ended - it's safe to resume
        resolve();
        return;
      } else if (paramToken) {
        this.cleanUpQueryStringVariablesUsingRedirect(paramToken, reject);
        return;
      }

      this.requestAuthenticationWithRedirects(paramToken);
      reject();
    });

    window.IntraActive.Authentication.AuthorizationPromise = authorizationPromise;
    return authorizationPromise;
  }

  /**
   * Forgets that the application has authorized - the global promise is disgarded
   */
  public forgetAuthorization(): void {
    window.IntraActive.Authentication.AuthorizationPromise = null;
  }

  /**
   * Forgets the authorization and removes the authentication cookie
   */
  public logout(): void {
    this.forgetAuthorization();
    Helper.removeCookie(TOKEN_COOKIE_NAME)
  }

  /**
   * Will do a background check if the user logged in and the token are not synched properly
   */
  public checkCurrentTokenForSharePointUserOrRedirect() : void {
    fetch(Helper.getSiteCollectionUrl() + "/_api/SP.UserProfiles.PeopleManager/GetMyProperties?$select=accountname",
    {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(
      userResponse => {
        if (!userResponse.ok) {
          return;
        }

        userResponse.json().then(userJson => {
          if (!userJson || !userJson.AccountName) {
            return;
          }

          var cookieToken = Helper.getCookieValue(TOKEN_COOKIE_NAME);
          if (!cookieToken) {
            return;
          }

          var upn = userJson.AccountName.split("|")[2];

          var lastUserCheckedUpn = sessionStorage.getItem(`${SESSION_STORAGE_UPN_ID}`);
          if (lastUserCheckedUpn === upn) {
            return;
          }
          sessionStorage.setItem(`${SESSION_STORAGE_UPN_ID}`, upn);

          this.aadHttpClientFactory
            .getClient(this.aadClientAppId)
            .then((client: IAadHttpClient): void => {

              // Fix for infinite redirection from IAadHttpClient service without 3rd party cookies
              if (this.hasTriedToRedirectRecently()) {
                return;
              }
              this.setLastRedirectAttemptFlag();

              client
                .get(`${this.authenticationFunctionsUri}/api/v2/aad-authorize/${this.tenantName}/user/${upn}/check/${cookieToken}`, IntraActiveTokenService.configurations.v1)
                .then((response: IHttpClientResponse): void => {
                  if (response.status == 401) {
                    // User is not the same, redirect
                    this.logout();
                    this.getTokenWithoutAadHttpClientFactory();
                  }
                })
                .catch(() => {});
            })
            .catch(() => {});
        });
      }
    );
  }

  private cleanUpQueryStringVariablesUsingRedirect(paramToken: string, reject: (error?: any) => void) {
    Helper.setCookie(TOKEN_COOKIE_NAME, paramToken);

    const urlWithoutToken = Helper.removeURLParameter(this.getCurrentUrl(), TOKEN_COOKIE_NAME);
    const urlWithoutTenantRef = Helper.removeURLParameter(urlWithoutToken, TENANTREF_URL_NAME);

    this.redirectTo(urlWithoutTenantRef);
    reject();
    return;
  }

  private requestAuthenticationWithRedirects(paramToken: string, returnUrl?: string, usernameHint?: string): void {
    if (this.isSharepointInAppBrowser()) {
      this.authenticationRedirectionService.authenticateInSharepoint(this.tenantName, returnUrl);
    } else {
      this.authenticationRedirectionService.authenticate(this.tenantName, returnUrl, usernameHint);
    }
  }

  private redirectTo(url: string): void {
    window.location.href = url;
  }

  private getCurrentUrl(): string {
    return window.location.href;
  }

  private getOriginAndPath(): string {
    return `${window.location.origin}${window.location.pathname}`;
  }

  private isSharepointInAppBrowser(): boolean {
    return typeof (window["O365Shell"]) === "undefined"
      && typeof (localStorage.getItem("SPPPLATSuiteNavthemedata")) === "object"
      && !localStorage.getItem("SPPPLATSuiteNavthemedata")
      && !this.teamsAuthenticationService.isInTeamsSoftCheck()
      && Helper.getParameterByName(INTRAACTIVE_AUTHENTICATE_URL_NAME) !== INTRAACTIVE_AUTHENTICATE_URL_NAME;
  }

  private initializeTeamsAuthUsingPopups(resolve, paramToken: string, reject: (error?: any) => void): Promise<void> {
    return this.teamsAuthenticationService.initializeAndGetContext(context => {
      this.teamsAuthenticationService.authenticate({
        url: `${this.getOriginAndPath()}?${INTRAACTIVE_AUTHENTICATE_URL_NAME}=${INTRAACTIVE_AUTHENTICATE_URL_NAME}`,
        successCallback: () => {
          resolve();
        },
        failureCallback: () => {
          reject();
        }
      });
    });
  }

  private checkLoginTeamsResult(paramToken: string, reject: (error?: any) => void): void {
    this.teamsAuthenticationService.initializeAndGetContext(context => {
      const paramTenantRef = Helper.getParameterByName("tenantRef");
      if (!paramToken || !paramTenantRef) {
        this.teamsAuthenticationService.notifyFailure();
        reject();
        return;
      }
      Helper.setCookie("token", paramToken);
      Helper.setCookie("tenant", paramTenantRef);
      this.teamsAuthenticationService.notifySuccess();
      reject();
    });
  }

  private checkIfTeamsPopupNeedsToTriggerLogin(paramToken: string, reject: (error?: any) => void): void {
    this.teamsAuthenticationService.initializeAndGetContext(context => {
      this.requestAuthenticationWithRedirects(paramToken, `${this.getOriginAndPath()}?${INTRAACTIVE_AUTHENTICATED_RETURN_URL_NAME}=${INTRAACTIVE_AUTHENTICATED_RETURN_URL_NAME}`, context.loginHint);
      reject();
    });
  }

  private setLastRedirectAttemptFlag(): void {
    sessionStorage.setItem(`${SESSION_STORAGE_REDIR_ID}`, JSON.stringify(new Date()));
  }

  private hasTriedToRedirectRecently(): boolean {
    const sessionLastRedirectionString = sessionStorage.getItem(`${SESSION_STORAGE_REDIR_ID}`);
    if (sessionLastRedirectionString) {
      const lastRedirectionAttemp: Date = new Date(JSON.parse(sessionLastRedirectionString));
      const oneMinuteAgo = new Date(new Date().setMinutes(new Date().getMinutes() - 1));
      if (oneMinuteAgo < lastRedirectionAttemp) {
        return true;
      }
    }

    return false;
  }
}