import * as React from 'react'
import * as styles from './Tooltip.css';
import { Helper } from '../../Helper';
import { IAPopup } from '../popup/Popup';

export interface IAToolTipProps {
  content: string;
  type: "popup" | "bubble" | "followMouse";
  styles?: React.CSSProperties;
  color?: string;
  backgroundColor?: string;
  rootStyles?: React.CSSProperties;
  componentContent?: JSX.Element;
  showBelow?: boolean;
  darkMode?: boolean;
}

export interface IAToolTipState {
  isVisible: boolean;
  hasFocus: boolean;
  top: number;
  left: number;
  showToolTipPopup: boolean;
}

export class IATooltip extends React.Component<IAToolTipProps, IAToolTipState> {

  private toolTipId: string;

  constructor(props: IAToolTipProps, state: IAToolTipState) {
    super(props, state);
    this.state = {
      isVisible: false,
      hasFocus: false,
      top: undefined,
      left: undefined,
      showToolTipPopup: false
    };
    this.toolTipId = Helper.getRandomStringKey();
  }

  public render(): JSX.Element {

    return (
      <div
        className={`${styles.tooltip} ${this.state.isVisible ? styles.isVisible : styles.isHidden}`}
        id={this.toolTipId}
        onMouseEnter={(event) => {
          if (this.props.type === "bubble") {
            const toolTipContentElement = document.getElementById("toolTipContent-" + this.toolTipId);
            const eventTarget: any = event.target;
            let top;
            let left = event.clientX;
            if (toolTipContentElement && eventTarget) {
              const targetTopPosition = Math.floor(eventTarget?.getBoundingClientRect()?.y);
              const targetBottomPosition = Math.floor(eventTarget?.getBoundingClientRect()?.y + eventTarget?.getBoundingClientRect()?.height);
              if (this.props.showBelow) {
                top = targetBottomPosition;
                // If content overflows window bottom
                if (top > window.innerHeight) {
                  top = targetTopPosition - toolTipContentElement?.getBoundingClientRect()?.height;
                }
              } else {
                top = targetTopPosition - toolTipContentElement?.getBoundingClientRect()?.height;
                // If content overflows window top
                if (top < 0) {
                  top = targetBottomPosition;
                }
              }
              if (left + toolTipContentElement.clientWidth > window.innerWidth) {
                left = window.innerWidth - (toolTipContentElement.clientWidth / 2);
              }
            }
            this.setState({
              isVisible: true,
              top: top,
              left: left
            });
          }
        }}
        onMouseMove={(event) => {
          if (this.props.type == "followMouse") {
            const toolTipContentElement = document.getElementById("toolTipContent-" + this.toolTipId);
            let top = event.clientY - toolTipContentElement.getBoundingClientRect().height - 10;
            let left = event.clientX;
            if (toolTipContentElement) {
              if (top + toolTipContentElement.clientHeight > window.innerHeight) {
                top -= toolTipContentElement.clientHeight + 50;
              }
              if (left + toolTipContentElement.clientWidth > window.innerWidth) {
                left = window.innerWidth - (toolTipContentElement.clientWidth / 2);
              }
            }
            this.setState({
              isVisible: true,
              top: top,
              left: left
            })
          }
        }}
        onMouseLeave={() => {
          if (this.props.type === "bubble" || this.props.type === "followMouse") {
            setTimeout(() => {
              this.setState({
                isVisible: false
              });
            }, 0);
          }
        }}
        onClick={() => {
          if (!this.state.showToolTipPopup && this.props.type === "popup") {
            this.setState({ showToolTipPopup: true }, () => {
              this.forceUpdate();
            });
          }
        }}
        style={{
          ...this.props.rootStyles
        }}
      >
        <div
          className={this.props.darkMode ? styles.tooltipLabelDarkMode : styles.tooltipLabel}
          id={"toolTipContent-" + this.toolTipId}
          onMouseEnter={() => {
            this.setState({
              hasFocus: true
            });
          }}
          style={{
            top: this.state.top,
            left: this.state.left,
            ...this.props.styles
          }}
        >
          <div
            className={styles.scrollBar}
            style={{
              color: this.props.darkMode ? "#ffffff" : "#333333"
            }}
          >
            {this.props.componentContent}
          </div>
        </div>
        {this.props.children}
        {this.state.showToolTipPopup &&
          <IAPopup
            content={
              <div>
                <div
                  style={{
                    clear: "both",
                    float: "left",
                    maxHeight: 200,
                    overflow: "auto",
                    width: "100%"
                  }}
                >
                  {this.props.componentContent}
                </div>
              </div>
            }
            close={() => {
              this.setState({ showToolTipPopup: false });
            }}
          />
        }
      </div>
    );
  }
}