import { Context, authentication } from "@microsoft/teams-js";
import * as microsoftTeams from "@microsoft/teams-js";

/**
 * Abstracts the teams global tooling layer so we can test the service flow.
 */
export class TeamsAuthenticationService {
  public initializeAndGetContext(callback: (context: Context) => void): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const timeoutHandler = setTimeout(() => reject(), 200);
      microsoftTeams.initialize(() => {
        microsoftTeams.getContext(context => {
          clearTimeout(timeoutHandler);
          callback(context);
          resolve();
        });
      });
    });
  }

  public authenticate(authenticateParameters?: authentication.AuthenticateParameters): void {
    authentication.authenticate(authenticateParameters);
  }

  public notifyFailure(): void {
    authentication.notifyFailure();
  }

  public notifySuccess(): void {
    authentication.notifySuccess();
  }

  public isInTeamsSoftCheck(): boolean {
    /**
     * This is not completely foolproof, but there is no other way to get synchronous bool if we are in teams.
     * And this is the reason we are not using it everywhere
     */
    return window.parent !== window.self;
  }
}