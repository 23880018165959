exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_articles_1mNStD149QgRPuL3D05IEn {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_3SlmHPzTJ9B6l8xbzYvpzL {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_3Rk4KCPnivfVCt07e3zkBK {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_2NipNQl1UipW6OXes74eym {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_articles": "IA_articles_1mNStD149QgRPuL3D05IEn",
	"IA_filters": "IA_filters_3SlmHPzTJ9B6l8xbzYvpzL",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_3Rk4KCPnivfVCt07e3zkBK",
	"IA_paginationPageCount": "IA_paginationPageCount_2NipNQl1UipW6OXes74eym"
};