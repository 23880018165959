import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { IAToggle } from '../../../../../toggle/Toggle';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
}

@inject('store')
@observer
export class ImageWidgetSettings extends React.Component<IProps> {

  private readonly localizationService = new LocalizationService()

  render(): React.ReactNode {
    return (
      <div>
        <MediaFileSelector
          url={this.props.store.widgetToEdit.image.imageUrl}
          label={this.localizationService.strings.PlayAdmin_ImageUrl?.toUpperCase()}
          placeholder={this.localizationService.strings.PlayAdmin_ImageUrl}
          type={"image"}
          onChange={(imageUrl: string) => {
            this.props.store.widgetToEdit.image.imageUrl = imageUrl;
            this.props.forceUpdate();
          }}
        />
        <IAToggle
          style={{
            marginTop: 20,
            width: "50%",
            float: "left"
          }}
          label={this.localizationService.strings.PlayAdmin_ShowOriginalImage?.toUpperCase()}
          checked={!!this.props.store.widgetToEdit?.image?.keepOriginal}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.image.keepOriginal = !this.props.store.widgetToEdit.image.keepOriginal;
            this.props.forceUpdate();
          }}
        />
      </div>
    );
  }
}