import * as React from 'react';
import * as styles from './Toggle.css';
import { IAIcon } from '../icon/Icon';
import { IATooltip } from '../tooltip/Tooltip';
import "../../styles/BasicIA.css";
import { ENTER_KEY_CODE, SPACEBAR_KEY_CODE } from '../../global/Constants';
import { Helper } from '../../Helper';

export interface IIAToggleProps {
  label?: string;
  checked: boolean;
  color: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  toolTip?: any;
  darkMode?: boolean;
  onChange: () => void;
}

export class IAToggle extends React.Component<IIAToggleProps, {}> {

  constructor(props: IIAToggleProps) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <div
        className={styles.IA_switch}
        style={{
          ...this.props.style
        }}>
        {this.props.label &&
          <label
            style={{
              color: this.props.darkMode ? Helper.darkModeLabelColor : '#000',
              opacity: this.props.disabled == undefined || !this.props.disabled ? 1 : 0.5,
              ...this.props.labelStyle
            }}
          >
            {this.props.label}</label>
        }
        {this.props.toolTip != undefined && this.props.toolTip !== "" &&
          <IATooltip
            content="Tooltip"
            type={"bubble"}
            darkMode={this.props.darkMode}
            styles={{
              maxWidth: 400,
              textAlign: "left",
              color: this.props.darkMode ? "#ffffff" : "#333333",
            }}
            componentContent={this.props.toolTip}
          >
            <IAIcon
              title="Info"
              size={14}
              color={this.props.color}
              style={{
                float: "left",
                cursor: "pointor",
                marginLeft: 4
              }}
            />
          </IATooltip>
        }
        <label className={styles.IA_switchLabel}>
          <div
            style={{
              position: "relative",
              opacity: this.props.disabled == undefined || !this.props.disabled ? 1 : 0.5,
            }}
            className="IA_OnFocus"
            tabIndex={0}
            onKeyPress={(event) => {
              if (event.which === ENTER_KEY_CODE || event.which === SPACEBAR_KEY_CODE) {
                this.props.onChange();
              }
            }}
            role="switch"
            aria-checked={this.props.checked}
          >
            <input
              disabled={this.props.disabled != undefined ? this.props.disabled : false}
              type="checkbox"
              tabIndex={-1}
              checked={this.props.checked}
              onChange={() => this.props.onChange()}
            />
            <span
              tabIndex={-1}
              className={styles.IA_switchSlider}
              style={{
                boxShadow: this.props.checked ? `0 0 1px ${this.props.color}` : "none",
                background: this.props.checked ? this.props.color : "#cccccc"
              }}
            ></span>
          </div>
        </label>
      </div>
    );
  }
}