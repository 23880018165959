import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './CompanyInformation.css';
import { Store } from '../../../stores';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAButton } from '../../../../button/Button';
import { CompanyInformationForm } from './CompanyInformationForm';

export interface IProps {
  display: boolean;
  store?: Store;
}

export interface IState {
  loading: boolean;
}

@inject('store')
@observer
export class CompanyInformation extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false
    }
  }

  public render(): JSX.Element {
    return this.props.display ? (
      <div className={styles.IA_companyInformation}>
        <CompanyInformationForm
          onSaved={() => { }}
        />
        <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
        <h2>{this.localizationService.strings.PlayAdmin_BillingOption}</h2>
        <div className={styles.IA_billingInfo}>{this.localizationService.strings.PlayAdmin_BillingOptionInfo}</div>
        <IAButton
          label={this.localizationService.strings.PlayAdmin_AddACreditCard?.toUpperCase()}
          buttonColor={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onClick={() => window.open(`${this.props.store.subscription?.hostedPageLinks?.paymentInfo}`)}
          borderRadius={5}
          showSpinner={this.state.loading}
          style={{
            float: "left",
            position: "relative",
            marginTop: 20
          }}
        />
      </div>
    )
      :
      (
        <></>
      );
  }

  
}