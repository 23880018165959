import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './WidgetEditPanel.css';
import { IAPanel } from '../../../../panels/Panel';
import { LocalizationService } from '../../../../../services/LocalizationService';
import PlayService from '../../../../../services/PlayService';
import { IPlaySlideExtended, IPlaySlideLayout, IPlayWidgetExtended, IPlayWidgetType } from '../../../../../interfaces/IPlay';
import { IASpinner } from '../../../../spinner/Spinner';
import { Helper } from '../../../../../Helper';
import { IATextField } from '../../../../textField/TextField';
import { IAButton } from '../../../../button/Button';
import { RssTicker } from '../../../../player/rssTicker/RssTicker';
import { Slide } from '../../../../player/slide/Slide';
import { ImageWidgetSettings } from './widgetSettings/ImageWidgetSettings';
import { RssWidgetSettings } from './widgetSettings/RssWidgetSettings';
import { RssTickerWidgetSettings } from './widgetSettings/RssTickerWidgetSettings';
import { GalleryWidgetSettings } from './widgetSettings/GalleryWidgetSettings';
import { ArticleViewerWidgetSettings } from './widgetSettings/ArticleViewerWidgetSettings';
import { IIconDropDown } from '../../../../../interfaces/IIconDropDown';
import { EmbedWidgetSettings } from './widgetSettings/EmbedWidgetSettings';
import { PdfWidgetSettings } from './widgetSettings/PdfWidgetSettings';
import { VideoWidgetSettings } from './widgetSettings/VideoWidgetSettings';
import { MessageViewerWidgetSettings } from './widgetSettings/MessageViewerWidgetSettings';
import { IColorTheme } from '../../../../../interfaces/IColorTheme';
import { Popup } from '../../../reusableComponents/popup/Popup';
import { PlayHelper } from '../../../PlayHelper';
import { Footer } from '../../../../player/footer/Footer';
import { IANumberField } from '../../../../numberField/NumberField';
import { IAToggle } from '../../../../toggle/Toggle';
import { PreviewSettings } from '../../../reusableComponents/previewSettings/PreviewSettings';
import { WeatherWidgetSettings } from './widgetSettings/WeatherWidgetSettings';
import { IAMessageBar } from '../../../../messageBar/MessageBar';
import { InstagramWidgetSettings } from './widgetSettings/InstagramWidgetSettings';

export interface IProps {
  widgetId: string;
  store?: Store;
  close: () => void;
  onSave: () => void;
  availableTypes?: IIconDropDown[];
  preSelectedType?: IPlayWidgetType;
}

export interface IState {
  isSaving: boolean;
  displayLayout: IPlaySlideLayout;
  previewColorTheme?: IColorTheme;
  showConfirmClosePopup: boolean;
  displayFormat: "landscape" | "portrait";
  showFooter?: boolean;
  showRssTicker?: boolean;
  playersMissingLocationWarning?: string[];
}

@inject('store')
@observer
export class WidgetEditPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isSaving: false,
      displayLayout: "oneColumn",
      showConfirmClosePopup: false,
      displayFormat: "landscape",
      showFooter: false,
      showRssTicker: false,
      playersMissingLocationWarning: []
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.widgetId !== this.props.widgetId && props.widgetId) {
      PlayService.getWidgetById(props.store.environment, this.props.store.token, props.store.tenantId, this.props.store.profile?.id, props.widgetId).then((widget: IPlayWidgetExtended) => {
        if (widget) {
          this.props.store.widgetToEditBeforeChanges = JSON.stringify(widget);
          this.props.store.widgetToEdit = widget;
          if (this.props.store.widgetToEdit.type === "messageViewer") {
            this.setState({ previewColorTheme: this.props.store.widgetToEdit.messageViewer.colorThemes[0] });
          }
          if (this.props.store.widgetToEdit?.video?.showTitle) {
            this.props.store.widgetToEdit.showTitle = true;
            this.props.store.widgetToEdit.video.showTitle = undefined;
          }
          if (this.props.store.widgetToEdit?.gallery?.showTitle) {
            this.props.store.widgetToEdit.showTitle = true;
            this.props.store.widgetToEdit.gallery.showTitle = undefined;
          }
          this.forceUpdate();
          setTimeout(() => {
            this.forceUpdate();
          }, 2000);
        }
      });
    }
  }

  private getWidgetSettings(type: IPlayWidgetType): JSX.Element {
    switch (type) {
      case "image":
        return <ImageWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "rssTicker":
        return <RssTickerWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "rss":
        return <RssWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "instagram":
        return <InstagramWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "gallery":
        return <GalleryWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "articleViewer":
        return <ArticleViewerWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "embed":
        return <EmbedWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "pdf":
        return <PdfWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "video":
        return <VideoWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "youtube":
        return <VideoWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "vimeo":
        return <VideoWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "video23":
        return <VideoWidgetSettings
          forceUpdate={() => this.forceUpdate()}
        />;
      case "messageViewer":
        return <MessageViewerWidgetSettings
          forceUpdate={() => this.forceUpdate()}
          viewerType="messageViewer"
        />;
      case "eventViewer":
        return <MessageViewerWidgetSettings
          forceUpdate={() => this.forceUpdate()}
          viewerType="eventViewer"
        />;
      case "sharepointNewsViewer":
        return <MessageViewerWidgetSettings
          forceUpdate={() => this.forceUpdate()}
          viewerType="sharepointNewsViewer"
        />;
      case "workplaceFeedViewer":
        return <MessageViewerWidgetSettings
          forceUpdate={() => this.forceUpdate()}
          viewerType="workplaceFeedViewer"
        />;
      case "weather":
        return <WeatherWidgetSettings
          onPlayersMissingLocation={playerNames => this.setState({ playersMissingLocationWarning: playerNames })}
          forceUpdate={() => this.forceUpdate()}
        />;
      default:
        return <></>;
    }
  }

  public render(): JSX.Element {
    // Landscape mode
    let previewWidth = this.props.store.windowWidth - 480;
    let previewHeight = (previewWidth / 16) * 9;
    // resize if the window height is smaller than the preview height
    if (previewHeight > window.innerHeight - 84) {
      previewHeight = window.innerHeight - 84 - 80;
      previewWidth = (previewHeight / 9) * 16;
    }
    // Portrait mode
    if (this.state.displayLayout === "portraitOneRow" || this.state.displayLayout === "portraitTwoRows") {
      previewHeight = window.innerHeight - 84 - 80;
      previewWidth = (previewHeight / 16) * 9;
    }
    const previewSlide: IPlaySlideExtended = {
      id: "widgetEditPanelPreviewSlide",
      showFooter: this.state.showFooter,
      showRssTicker: this.state.showRssTicker,
      startDate: new Date().toJSON(),
      widgets: [
        this.props.store.widgetToEdit,
        this.props.store.widgetToEdit,
        this.props.store.widgetToEdit,
        this.props.store.widgetToEdit
      ],
      layout: this.state.displayLayout
    }

    if (this.props.store.loadingPreviewContent) {
      setTimeout(() => {
        this.forceUpdate();
      }, 0);
    }
    return (
      <IAPanel
        open={this.props.store.widgetToEdit != undefined || this.props.widgetId != undefined}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={this.state.isSaving}
        width={this.props.store.windowWidth}
        dataAutomationIdPrefix="widgets-edit-panel"
        panelId="widgets-edit-panel"
        darkMode={this.props.store.darkMode}
        isInTeams={this.props.store.isTeams}
        navigationsBarContent={
          <IAButton
            label={this.props.store.widgetToEdit?.id && this.props.store.widgetToEdit?.id !== "new" ? this.localizationService.strings.PlayAdmin_Update?.toUpperCase() : this.localizationService.strings.PlayAdmin_Save?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            disbaled={(!this.props.store.widgetToEdit?.title || !this.props.store.widgetToEdit.type)}
            onClick={() => {
              this.props.onSave();
            }}
            borderRadius={5}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7,
              marginRight: 10
            }}
          />
        }
        close={() => {
          if (this.props.store.widgetToEditBeforeChanges !== JSON.stringify(this.props.store.widgetToEdit)) {
            this.setState({ showConfirmClosePopup: true });
          } else {
            this.setState({ showConfirmClosePopup: false });
            this.props.close();
          }
        }}
      >
        <>
          {this.state.showConfirmClosePopup &&
            <Popup
              headline={this.localizationService.strings.Messages_CancelPopup_Headline}
              close={() => this.setState({ showConfirmClosePopup: false })}
              darkMode={this.props.store.darkMode}
              content={
                <>
                  <IAButton
                    buttonColor={this.props.store.highlightColor}
                    darkMode={this.props.store.darkMode}
                    label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                    onClick={() => {
                      this.setState({ showConfirmClosePopup: false });
                      this.props.close();
                    }}
                    borderRadius={5}
                    style={{
                      float: "left"
                    }}
                  />
                  <IAButton
                    textColor={this.props.store.highlightColor}
                    darkMode={this.props.store.darkMode}
                    label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                    onClick={() => this.setState({ showConfirmClosePopup: false })}
                    style={{
                      float: "left",
                      marginLeft: 30
                    }}
                  />
                </>
              }
            />
          }
          {this.props.store.widgetToEdit ?
            <div
              className={styles.IA_widgetsEditPanel}
              style={{
                height: window.innerHeight - 45,
                backgroundColor: this.props.store.darkMode ? "#414141" : "#777777",
                color: this.props.store.darkMode ? "#ffffff" : "#333333"
              }}
            >
              <div className={styles.IA_content} style={{ backgroundColor: this.props.store.darkMode ? Helper.darkModeBackgroundColor : '#fff' }}>
                <h1 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{PlayHelper.getTitleForWidgetType(this.props.store.widgetToEdit.type as IPlayWidgetType, this.localizationService)}</h1>
                <div
                  style={{
                    float: "left",
                    width: "100%",
                    height: 100,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: 5,
                    backgroundImage: `url("https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/${PlayHelper.getIconForWidgetType(this.props.store.widgetToEdit.type as IPlayWidgetType)}-placeholder.png")`
                  }}
                />
                {this.props.store.widgetToEdit?.type === "weather" && this.state.playersMissingLocationWarning?.length > 0 &&
                  <IAMessageBar
                    type="warning"
                    onDismiss={undefined}
                    content={<div
                      style={{
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <p style={{ marginBottom: 0 }}>{this.localizationService.strings.PlayAdmin_PlayersMissingLocation}</p>
                      <ul style={{ marginBottom: 0 }}>
                        {this.state.playersMissingLocationWarning.map(name => <li>{name}</li>)}
                      </ul>
                    </div>}
                    style={{
                      marginTop: 10,
                      borderRadius: 5
                    }}
                  />
                }
                <IATextField
                  label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
                  required
                  placeholder={this.localizationService.strings.PlayAdmin_Title}
                  text={this.props.store.widgetToEdit.title}
                  darkMode={this.props.store.darkMode}
                  highlightColor={this.props.store.highlightColor}
                  borderRadius={5}
                  labelStyle={{
                    fontSize: "12px"
                  }}
                  onChange={(title: string) => {
                    this.props.store.widgetToEdit.title = title;
                    this.forceUpdate();
                  }}
                />
                <IAToggle
                  style={{
                    marginTop: 10,
                    fontSize: 12
                  }}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_ShowWidgetTitle?.toUpperCase()}
                  checked={this.props.store.widgetToEdit.showTitle}
                  color={this.props.store.highlightColor}
                  onChange={() => {
                    this.props.store.widgetToEdit.showTitle = this.props.store.widgetToEdit.showTitle != undefined ? !this.props.store.widgetToEdit.showTitle : true;
                    this.forceUpdate();
                  }}
                />
                {
                  this.props.store.widgetToEdit.type !== "youtube" &&
                  this.props.store.widgetToEdit.type !== "vimeo" &&
                  this.props.store.widgetToEdit.type !== "video" &&
                  this.props.store.widgetToEdit.type !== "articleViewer" &&
                  this.props.store.widgetToEdit.type !== "messageViewer" &&
                  this.props.store.widgetToEdit.type !== "gallery" &&
                  this.props.store.widgetToEdit.type !== "rss" &&
                  this.props.store.widgetToEdit.type !== "sharepointNewsViewer" &&
                  this.props.store.widgetToEdit.type !== "workplaceFeedViewer" &&
                  <IANumberField
                    label={this.localizationService.strings.PlayAdmin_Duration?.toUpperCase()}
                    number={this.props.store.widgetToEdit?.duration && this.props.store.widgetToEdit?.duration !== 0 ? this.props.store.widgetToEdit?.duration : 30}
                    onChange={(duration: number) => {
                      this.props.store.widgetToEdit.duration = duration;
                      this.forceUpdate();
                    }}
                    min={0}
                    darkMode={this.props.store.darkMode}
                    highlightColor={this.props.store.highlightColor}
                    borderRadius={5}
                    styles={{
                      width: "100%",
                      clear: "both"
                    }}
                    labelStyle={{
                      fontSize: "12px"
                    }}
                  />
                }
                {this.getWidgetSettings(this.props.store.widgetToEdit.type)}
              </div>
              <div className={styles.IA_previewArea}>
                {this.props.store.widgetToEdit.type !== "rssTicker" &&
                  <PreviewSettings
                    displayLayout={this.state.displayLayout}
                    previewColorTheme={this.state.previewColorTheme}
                    showFooter={this.state.showFooter}
                    showRssTicker={this.state.showRssTicker}
                    onLayoutChange={(layout: IPlaySlideLayout) => this.setState({ displayLayout: layout }, () => this.forceUpdate())}
                    onColorThemeChange={(colorTheme: IColorTheme) => this.setState({ previewColorTheme: colorTheme }, () => this.forceUpdate())}
                    onShowFooterChange={() => this.setState({ showFooter: !this.state.showFooter }, () => this.forceUpdate())}
                    onShowRSSChange={() => this.setState({ showRssTicker: !this.state.showRssTicker }, () => this.forceUpdate())}
                  />
                }
                <div
                  className={styles.IA_preview}
                  style={{
                    width: previewWidth,
                    height: previewHeight,
                    paddingLeft: this.state.displayLayout === "portraitOneRow" || this.state.displayLayout === "portraitTwoRows" ? `calc(50% - ${previewWidth / 2}px)` : 40
                  }}
                >
                  {
                    this.props.store.widgetToEdit.type === "rssTicker" || this.props.preSelectedType === "rssTicker" ?
                      <div
                        className={styles.IA_previewRssTicker}
                        style={{
                          height: previewHeight,
                          width: previewWidth
                        }}
                      >
                        <RssTicker
                          rssTicker={this.props.store.widgetToEdit}
                          // slide={undefined}
                          sizeUnit={(previewHeight > previewWidth ? previewWidth : previewHeight) / 100}
                          playerWidth={previewWidth}
                          bottom={undefined}
                          isActive={true}
                        />
                      </div>
                      :
                      <div
                        className={styles.IA_previewScreen}
                        style={{
                          height: previewHeight,
                          width: previewWidth
                        }}
                      >
                        {this.state.showRssTicker &&
                          <RssTicker
                            rssTicker={{}}
                            editMode={true}
                            isActive={true}
                            sizeUnit={(previewHeight > previewWidth ? previewWidth : previewHeight) / 100}
                            playerWidth={previewWidth}
                            bottom={this.state.showFooter ? ((previewHeight > previewWidth ? previewWidth : previewHeight) / 100) * 8 : 0}

                          />
                        }
                        {this.state.showFooter &&
                          <Footer
                            footer={this.props.store.profile?.footer}
                            sizeUnit={(previewHeight > previewWidth ? previewWidth : previewHeight) / 100}
                            city={this.props.store.city}
                            currentWeather={this.props.store.currentWeather}
                            editMode={true}
                          />
                        }
                        {!this.props.store.loadingPreviewContent &&
                          <Slide
                            index={0}
                            slide={previewSlide}
                            isActive={true}
                            height={previewHeight}
                            width={previewWidth}
                            left={0}
                            footer={undefined}
                            city={this.props.store.city}
                            currentWeather={this.props.store.currentWeather}
                            environment={this.props.store.environment}
                            tenantId={this.props.store.tenantId}
                            profileId={this.props.store.profile.id}
                            highlightColor={this.props.store.highlightColor}
                            editPreviewMode
                            previewColorTheme={this.state.previewColorTheme}
                            standalone
                            forecast={this.props.store.weatherData}
                            nextSlide={() => { }}
                            rssTicker={this.state.showRssTicker ? {} : undefined}
                            isHardcodedWeatherData={this.props.store.isUsingHardCodedWeather}
                          />
                        }
                      </div>
                  }
                </div>
              </div>
            </div>
            :
            <div
              className={styles.IA_loading}
              style={{
                backgroundColor: this.props.store.darkMode ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"
              }}
            >
              <IASpinner
                key={Helper.getRandomStringKey()}
                color={this.props.store.highlightColor}
              />
              <div className={styles.IA_weAreWorkingOnIt}>{this.localizationService.strings.Composer_WeAreWorkingOnIt}</div>
            </div>
          }
        </>
      </IAPanel >
    );
  }


}