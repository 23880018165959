
import * as QRCode from 'qrcode';

export default class QRCodeService {

  public static generateQRCode(text: string, backgroundColor?: string, textColor?: string): Promise<string> {
    return new Promise<string>((resolve: (qrCode: string) => void): void => {
      const options = backgroundColor && textColor ? {
        color: {
          dark: textColor,
          light: backgroundColor
        }
      } : undefined;
      QRCode.toDataURL(text, options).then((qrCode: string) => {
        resolve(qrCode);
      }).catch(() => {
        resolve(undefined);
      });
    });
  }
}