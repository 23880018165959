import { Environment } from './Environment';
import { IUserResult } from '../interfaces/IUserResult';
import { IUserRole } from '../interfaces/IUserRole';

import { ICombinedUserResult } from '../interfaces/ICombinedUserResult';
import { FetchProxy } from 'intraactive-sdk-helper';
import { sdkContainerInstance } from '../global/Container';
import { IHttpStatus } from '../interfaces/IHttpStatus';
import DateTimeService from './DateTimeService';

export default class UserService {

  public static getUserRole(apiUrl: string, tenant: string, instanceId: number, token: string, component: string): Promise<IUserRole> {
    tenant = tenant.replace(".sharepoint.com", "");
    return new Promise((resolve) => {
      if (apiUrl && tenant && token && instanceId && component) {
        return new FetchProxy(`${apiUrl}/api/${tenant}.sharepoint.com/users/my/role?site=${component}&instanceId=${instanceId}`, {
          credentials: "same-origin",
          mode: "cors",
          headers: {
            "AuthToken": token
          }
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response.status);
            } else {
              return response.json();
            }
          }).then(response => {
            resolve(response);
          });
      } else {
        resolve(null);
      }
    });
  }

  public static getUserRoleForMessages(apiUrl: string, tenant: string, instanceId: number, token: string, component: string, ignoreCache?: boolean): Promise<{ httpStatus: IHttpStatus, value?: IUserRole }> {
    tenant = tenant.replace(".sharepoint.com", "");
    return new Promise((resolve) => {
      if (apiUrl && tenant && token && instanceId && component) {
        const userRole = localStorage.getItem(`IA_userRole_${component}_${instanceId}`);
        if (!ignoreCache && userRole && new DateTimeService().getDatePlusOneHour(new Date(JSON.parse(userRole).date)) > new Date()) {
          console.log("Using cached getUserRole");
          resolve(JSON.parse(userRole).value);
        } else {
          console.log("Fetching getUserRole from server");
          return new FetchProxy(`${apiUrl}/api/permissions/${tenant}.sharepoint.com/${component}/userrole?list=Instances&id=${instanceId}`, {
            credentials: "same-origin",
            mode: "cors",
            headers: {
              "AuthToken": token
            }
          })
            .withContainer(sdkContainerInstance)
            .fetch()
            .then((response) => {
              if (!response.ok) {
                resolve({
                  httpStatus: {
                    statusCode: response.status,
                    message: response.statusText
                  }
                });
                return;
              } else {
                return response.json();
              }
            }).then(result => {
              let highestRole: IUserRole;
              result.forEach((role: IUserRole) => {
                switch (role.title.toString()) {
                  case "Administrator":
                    highestRole = role;
                    break;
                  case "Editor":
                    if (highestRole?.title === "Administrator") {
                      highestRole = highestRole;
                    } else {
                      highestRole = role;
                    }
                    break;
                  case "Reader":
                    if (highestRole?.title === "Editor" || highestRole?.title === "Administrator") {
                      highestRole = highestRole;
                    } else {
                      highestRole = role;
                    }
                    break;
                  default:
                    break;
                }
              });
              localStorage.setItem(`IA_userRole_${component}_${instanceId}`, JSON.stringify({
                date: new Date(),
                value: {
                  httpStatus: {
                    statusCode: 200,
                  },
                  value: highestRole
                }
              }));
              resolve({
                httpStatus: {
                  statusCode: 200,
                },
                value: highestRole
              });
            })
            .catch(error => {
              console.log(error);
              resolve({
                httpStatus: {
                  statusCode: error.status,
                  message: error.statusText
                }
              });
            });
        }
      } else {
        resolve({
          httpStatus: {
            statusCode: 400,
            message: "Missing parameter"
          }
        });
      }
    });
  }

  public static getUsers(environment: "Development" | "Test" | "Production", token: string, tenant: string, searchQuery: string, showGroups?: boolean, top?: number): Promise<IUserResult> {
    return new Promise<IUserResult>((resolve: (result: IUserResult) => void, reject: (error: any) => void): void => {
      if (environment && tenant && token && searchQuery) {
        let apiUrl = Environment.getEnvironmentForMainAPI(environment) + `/api/${tenant}/users/search?searchQuery=${encodeURIComponent(searchQuery)}`;
        if (showGroups) {
          apiUrl = Environment.getEnvironmentForMainAPI(environment) + `/api/${tenant}/users/groups/search?searchQuery=${encodeURIComponent(searchQuery)}`;
        }
        if (top) {
          apiUrl += `?top=${top}`;
        }

        new FetchProxy(apiUrl, {
          method: "GET",
          headers: {
            'AuthToken': token,
            'Content-Type': 'application/json'
          }
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              reject(response);
              return;
            }
            return response.json().then((jsonResponse: any) => {
              const userResult: IUserResult = {
                searchQuery: jsonResponse.searchQuery,
                users: jsonResponse.users
              };
              resolve(userResult);
            });
          })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        reject(null);
      }
    });
  }

  public static getSharePointAndGraphCombinedUsers(environment: "Development" | "Test" | "Production", token: string, tenant: string, siteName: string, searchQuery: string, top?: number): Promise<ICombinedUserResult> {
    return new Promise<ICombinedUserResult>((resolve: (result: ICombinedUserResult) => void, reject: (error: any) => void): void => {
      if (environment && tenant && token && searchQuery) {
        let apiUrl = Environment.getEnvironmentForMainAPI(environment) + `/api/${tenant}/users/component/${siteName}/search?searchQuery=${encodeURIComponent(searchQuery)}`;
        if (top) {
          apiUrl += `?top=${top}`;
        }

        new FetchProxy(apiUrl, {
          method: "GET",
          headers: {
            'AuthToken': token,
            'Content-Type': 'application/json'
          }
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              reject(response);
              return;
            }
            return response.json().then((jsonResponse: any) => {
              const userResult: ICombinedUserResult = {
                searchQuery: jsonResponse.searchQuery,
                users: jsonResponse.users
              };
              resolve(userResult);
            });
          })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        reject(null);
      }
    });
  }

  public static isSiteOwner(environment: "Development" | "Test" | "Production", tenant: string, token: string, siteName: string): Promise<boolean> {
    return new Promise<boolean>((resolve: (isSiteOwner: boolean) => void, reject: (error: any) => void): void => {
      if (environment && tenant && token && siteName) {
        const apiUrl = Environment.getEnvironmentForMainAPI(environment) + `/api/${tenant}.sharepoint.com/users/issiteowner?site=${siteName}`;
        new FetchProxy(apiUrl, { method: "GET", credentials: "same-origin", headers: { 'AuthToken': token } })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              reject(response);
              return;
            }
            return response.json().then((jsonResponse) => resolve(jsonResponse));
          })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        reject(null);
      }
    });
  }


  public static getCurrentUser(apiUrl: string, tenant: string, token: string): Promise<any> {
    return new Promise<any>((resolve: (profile: any) => void, reject: (error: any) => void): void => {
      if (apiUrl && tenant && token) {
        const currentUser = localStorage.getItem(`IA_currentUser`);
        if (currentUser && new DateTimeService().getDatePlusOneHour(new Date(JSON.parse(currentUser).date)) > new Date()) {
          console.log("Using cached currentUser");
          resolve(JSON.parse(currentUser).value);
        } else {
          console.log("Fetching currentUser from server");
          const endpointUrl = apiUrl + `/api/${tenant}/users/currentUser`;
          new FetchProxy(endpointUrl, { method: "GET", credentials: "same-origin", headers: { 'AuthToken': token } })
            .withContainer(sdkContainerInstance)
            .fetch()
            .then((response) => {
              if (!response.ok) {
                reject(response);
                return;
              }
              return response.json().then((jsonResponse) => {
                localStorage.setItem(`IA_currentUser`, JSON.stringify({
                  date: new Date(),
                  value: jsonResponse
                }));
                resolve(jsonResponse);
              });
            })
            .catch(error => {
              console.log(error);
              reject(null);
            });
        }
      } else {
        reject(null);
      }
    });
  }

  public static getUPNByLookupId(environment: "Development" | "Test" | "Production", token: string, tenant: string, component: string, instance: string, lookupId: string): Promise<string> {
    console.log(instance);
    return new Promise<string>((resolve: (status: string) => void, reject: (error: any) => void): void => {
      if (environment && token && tenant && component && lookupId) {
        const tenantRef = `${tenant}.sharepoint.com`;
        const siteName = component === 'Messages' ? "IntraActive-Messages" : "IntraActive-Events";
        const apiUrl = `${Environment.getEnvironmentForMainAPI(environment)}/api/${tenantRef}/users/${siteName}/${lookupId}`;
        new FetchProxy(apiUrl, { method: "GET", headers: { 'Content-Type': 'application/json', 'AuthToken': token } })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              reject(response);
              return;
            }
            return response.json().then((jsonResponse) => resolve(jsonResponse));
          })
          .catch(error => {
            console.log(error);
            reject(null);
          });
      } else {
        reject(null);
      }
    });
  }
}