import * as React from 'react';
import { Store } from '../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './Playlists.css';
import { LocalizationService } from '../../../../services/LocalizationService';
import { PlaylistEditPanel } from './playlistEditPanel/PlaylistEditPanel';
import PlayService from '../../../../services/PlayService';
import { IAHeader } from '../../../header/Header';
import { IPlaylist } from '../../../../interfaces/IPlay';
import { Popup } from '../../reusableComponents/popup/Popup';
import { IAButton } from '../../../button/Button';
import { PlaylistsContent } from './PlaylistsContent';
import { PlayHelper } from '../../PlayHelper';
import { GettingStartedOverlay } from '../gettingStartedOverlay/GettingStartedOverlay';

export interface IProps {
  showSkeleton: boolean;
  store?: Store;
}

export interface IState {
  isLoading: boolean;
  selectedPlaylistId?: string;
  playlistToDelete?: string;
  skip: number;
  take: number;
  searchQuery: string;
}

@inject('store')
@observer
export class Playlists extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      skip: this.props.store.playlists?.length ?? 0,
      take: PlayHelper.ELEMENTS_PER_PAGE,
      searchQuery: ""
    }
  }

  public componentDidMount(): void {
    this.props.store.playlists = undefined;
    this.getPlaylists(true);
  }

  public componentWillUnmount(): void {
    this.props.store.playlists = undefined;
    this.props.store.playlistsCount = undefined;
  }

  public componentWillReceiveProps(props: IProps): void {
    if (!props.showSkeleton && props.showSkeleton !== this.props.showSkeleton && !this.props.store.playlists) {
      this.setState({ isLoading: true });
      this.getPlaylists(true);
    }
  }

  private getPlaylists(override?: boolean) {
    if (this.props.store.profile?.id) {
      this.setState({ isLoading: true });
      PlayService.getPlaylists(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, { skip: this.state.skip, take: this.state.take, search: this.state.searchQuery }).then(playlists => {
        const appendedPlaylists = override ? [] : this.props.store.playlists ?? [];
        appendedPlaylists.push(...playlists.value);
        this.props.store.playlists = appendedPlaylists;
        this.props.store.playlistsCount = playlists.count;
        this.setState({ isLoading: false });
        playlists.value.forEach(playlist => {
          if (playlist?.slides?.length > 0) {
            PlayService.getSlide(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, playlist?.slides[0]).then(slide => {
              playlist.firstSlide = slide;
            });
          }
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
    }
  }

  private onDelete(id: string) {
    this.props.store.workingOnIt = true;
    PlayService.deletePlaylist(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, id).then(() => {
      this.props.store.playlistToEdit = undefined;
      this.props.store.workingOnIt = false;
      this.setState({ selectedPlaylistId: undefined, playlistToDelete: undefined, skip: 0 }, () => this.getPlaylists(true));
    });
  }

  private onClosePanel() {
    this.props.store.playlistToEdit = undefined;
    this.props.store.workingOnIt = false;
    this.props.store.slides = undefined;
    this.props.store.slidesCount = undefined;
  }

  private onSave(): void {
    this.props.store.workingOnIt = true;
    const slidesIds = this.props.store.playlistToEdit.slides?.map(slide => slide.id);
    if (this.props.store.playlistToEdit.omittedSlides?.length > 0) {
      this.props.store.playlistToEdit.omittedSlides.forEach((id: string) => {
        slidesIds.push(id);
      })
    }
    const playlistModel: IPlaylist = {
      ...this.props.store.playlistToEdit,
      slides: slidesIds
    }

    if (this.props.store.playlistToEdit.id) {
      PlayService.updatePlaylist(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, playlistModel).then(() => {
        console.log(`Updated playlist with id: ${this.props.store.playlistToEdit.id}`)
        this.onClosePanel();

        this.setState({ selectedPlaylistId: undefined, skip: 0 }, () => this.getPlaylists(true));
      })
    } else {
      PlayService.createPlaylist(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, playlistModel).then((id) => {
        console.log(`Created playlist with id: ${id}`)
        this.onClosePanel();
        this.setState({ selectedPlaylistId: undefined, skip: 0 }, () => this.getPlaylists(true));
      })
    }
    this.setState({ selectedPlaylistId: undefined });
  }

  private removeGettingStarted(): void {
    localStorage.setItem("IA_GettingStarted_Playlists", "hide");
    this.forceUpdate();
  }

  public render(): JSX.Element {
    return (
      <div
        className={styles.IA_playlists}
      >
        {this.props.store.playlists &&
          <PlaylistEditPanel
            playlistId={this.state.selectedPlaylistId}
            onSave={() => this.onSave()}
            onClose={() => {
              this.onClosePanel();
              this.setState({ selectedPlaylistId: undefined })
            }
            }
          />
        }
        <IAHeader
          id="play-playlists-header"
          title={this.localizationService.strings.PlayAdmin_YourPlaylists}
          description={this.localizationService.strings.PlayAdmin_YourPlaylistsDesc}
          expandButtonColor={this.props.store.highlightColor}
          navigationButtonColor="#91bbcd"
          navigationButtonIconColor="#ffffff"
          isMobile={this.props.store.isMobile}
          darkMode={this.props.store.darkMode}
          actionButton={{
            label: this.localizationService.strings.PlayAdmin_Add?.toUpperCase(),
            onClick: () => {
              this.removeGettingStarted();
              this.setState({ selectedPlaylistId: "new" });
            },
            buttonColor: this.props.store.highlightColor,
            borderRadius: 5,
            textColor: "#ffffff",
            arialable: this.localizationService.strings.PlayAdmin_Add,
            icon: "Add"
          }}
          slides={[
            {
              backgroundImage: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-bg-gray.jpg",
              headline: this.localizationService.strings.PlayAdmin_YourPlaylists,
              text: this.localizationService.strings.PlayAdmin_YourPlaylistsDesc,
              image: "https://intraactivestorage.blob.core.windows.net/cdn/SDK%20Images/play/banner-illustration-playlist.png"
            }
          ]}
          onCollapseOrExpand={() => this.forceUpdate()}
        />

        <PlaylistsContent
          getPlaylists={(searchQuery) => {
            this.setState({ searchQuery }, () => {
              this.props.store.playlists = undefined;
              this.getPlaylists(true);
            });
          }}
          nextPage={PlayHelper.showNextPageButton(this.state.skip, this.state.take, this.props.store.playlistsCount)
            ? () => this.setState({ skip: this.state.skip + this.state.take }, () => {
              this.getPlaylists();
            })
            : undefined
          }
          isLoading={this.state.isLoading}
          elementActions={[
            // {
            //   action: () => alert("TODO"),
            //   actionName: "follow",
            //   icon: "Star",
            //   color: this.props.store.highlightColor
            // },
            {
              action: (id) => this.setState({ playlistToDelete: id }),
              actionName: "delete",
              icon: "Trash",
              color: this.props.store.highlightColor
            },
            {
              action: (id) => this.setState({ selectedPlaylistId: id }),
              actionName: "edit",
              icon: "Edit",
              color: this.props.store.highlightColor
            },
          ]}
        />
        {!localStorage.getItem("IA_GettingStarted_Playlists") &&
          <GettingStartedOverlay
            onHide={() => this.removeGettingStarted()}
            height={localStorage.getItem("play-playlists-header") ? window.innerHeight - 210 : window.innerHeight - 390}
            top={localStorage.getItem("play-playlists-header") ? 126 : 300}
            videoUrl={this.localizationService.strings.PlayAdmin_GettingStartedPlaylistsVideoUrl}
            posterUrl={this.localizationService.strings.PlayAdmin_GettingStartedPlaylistsPosterUrl}
            title={this.localizationService.strings.PlayAdmin_GettingStartedPlaylistsTitle?.toUpperCase()}
          />
        }
        {this.state.playlistToDelete &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_DeletePlaylist}
            close={() => this.setState({ playlistToDelete: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  label={this.localizationService.strings.DeletePopup_Yes}
                  darkMode={this.props.store.darkMode}
                  onClick={() => {
                    this.onDelete(this.state.playlistToDelete);
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  label={this.localizationService.strings.DeletePopup_No}
                  onClick={() => this.setState({ playlistToDelete: undefined })}
                  darkMode={this.props.store.darkMode}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
      </div>
    );
  }

  
}