import * as React from 'react';
import * as styles from './Header.css';
import { IPlayHeaderSlide } from '../../interfaces/IPlayHeader';
import { IAIcon } from '../icon/Icon';
import { IAButton, IIAButtonProps } from '../button/Button';

export interface IHeaderProps {
  id: string;
  title: string;
  description?: string;
  expandButtonColor: string;
  navigationButtonColor: string;
  navigationButtonIconColor: string;
  slides: IPlayHeaderSlide[];
  actionButton?: IIAButtonProps;
  isMobile: boolean;
  darkMode: boolean;
  onCollapseOrExpand?: () => void;
}

export interface IHeaderState {
  index: number;
  collapsed: boolean;
}

export class IAHeader extends React.Component<IHeaderProps, IHeaderState> {

  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      index: 0,
      collapsed: this.props.isMobile || localStorage.getItem(this.props.id) ? true : false
    }
  }

  public componentDidMount(): void {
  }

  private previous(): void {
    this.setState({ index: this.state.index - 1 });
  }

  private next(): void {
    this.setState({ index: this.state.index + 1 });
  }

  private minimize(): void {
    localStorage.setItem(this.props.id, "collapsed");
    this.setState({ index: 0, collapsed: true });
    if (this.props.onCollapseOrExpand) {
      this.props.onCollapseOrExpand ();
    }
  }

  private expand(): void {
    localStorage.removeItem(this.props.id);
    this.setState({ index: 0, collapsed: false });
    if (this.props.onCollapseOrExpand) {
      this.props.onCollapseOrExpand ();
    }
  }

  public render(): JSX.Element {
    let width = 0;
    if (document.getElementById(`IA_Header_${this.props.id}`)) {
      const header = document.getElementById(`IA_Header_${this.props.id}`);
      width = header.clientWidth;
    } else {
      setTimeout(() => {
        this.forceUpdate();
      }, 0);
    }
    const slides = [];
    if (!this.state.collapsed) {
      this.props.slides.forEach((slide: IPlayHeaderSlide, i: number) => {
        slides.push(
          <div
            className={styles.IA_slide}
            style={{
              backgroundImage: `url(${slide.backgroundImage})`,
              width: `${100 / this.props.slides.length}%`,
              color: slide.textColor ? slide.textColor : "#333333"
            }}
            key={i}
          >
            <div className={styles.IA_textArea}>
              <h1>{slide.headline}</h1>
              <div className={styles.IA_text}>{slide.text}</div>
            </div>
            <div
              className={styles.IA_illustration}
              style={{
                backgroundImage: `url(${slide.image})`
              }}
            />
          </div>
        );
      });
    }
    return this.state.collapsed ? (
      <div className={styles.IA_headerCollapsed}>
        <div className={styles.IA_collapsedTitle}>
          {this.props.title}
        </div>
        {!this.props.isMobile &&
          <div
            className={styles.IA_expand}
          >
            <IAIcon
              title={"Info"}
              size={18}
              color={this.props.expandButtonColor}
              style={{
                marginLeft: 1
              }}
              onClick={() => this.expand()}
            />
          </div>
        }
        {
          this.props.actionButton &&
          <IAButton
            label={this.props.actionButton.label}
            darkMode={this.props.darkMode}
            onClick={this.props.actionButton.onClick}
            buttonColor={this.props.actionButton.buttonColor}
            borderRadius={this.props.actionButton.borderRadius}
            textColor={this.props.actionButton.textColor}
            arialable={this.props.actionButton.arialable}
            icon={this.props.actionButton.icon}
            disbaled={this.props.actionButton.disbaled}
            style={{
              position: "relative",
              float: "right",
              marginTop: 5
            }}
          />
        }
      </div >
    ) : (
      <div
        id={`IA_Header_${this.props.id}`}
        className={styles.IA_header}
      >
        <div
          className={styles.IA_slideWrapper}
          style={{
            width: `${this.props.slides.length * 100}%`,
            left: -width * this.state.index
          }}
        >
          {slides}
        </div>
        <div className={styles.IA_navigation}>
          <div
            className={styles.IA_close}
            style={{
              backgroundColor: this.props.navigationButtonColor
            }}
          >
            <IAIcon
              title={"Close"}
              size={18}
              color={this.props.navigationButtonIconColor}
              style={{
                marginLeft: 1
              }}
              onClick={() => this.minimize()}
            />
          </div>
          {this.props.slides.length > 1 &&
            <div
              className={styles.IA_next}
              style={{
                backgroundColor: this.props.navigationButtonColor,
                opacity: this.state.index === this.props.slides.length - 1 ? 0.5 : 1
              }}
            >
              <IAIcon
                title={"iOS Arrow RTL"}
                size={18}
                color={this.props.navigationButtonIconColor}
                onClick={this.state.index === this.props.slides.length - 1 ? undefined : () => this.next()}
              />
            </div>
          }
          {this.props.slides.length > 1 &&
            <div
              className={styles.IA_previous}
              style={{
                backgroundColor: this.props.navigationButtonColor,
                opacity: this.state.index === 0 ? 0.5 : 1
              }}
            >
              <IAIcon
                title={"iOS Arrow LTR"}
                size={18}
                color={this.props.navigationButtonIconColor}
                onClick={this.state.index === 0 ? undefined : () => this.previous()}
              />
            </div>
          }
        </div>
        {this.props.actionButton &&
          <IAButton
            label={this.props.actionButton.label}
            onClick={this.props.actionButton.onClick}
            buttonColor={this.props.actionButton.buttonColor}
            darkMode={this.props.darkMode}
            borderRadius={this.props.actionButton.borderRadius}
            textColor={this.props.actionButton.textColor}
            arialable={this.props.actionButton.arialable}
            icon={this.props.actionButton.icon}
            disbaled={this.props.actionButton.disbaled}
            style={{
              position: "absolute",
              bottom: 15,
              right: 20
            }}
          />
        }
      </div>
    );
  }

  
}