export const ENTER_KEY_CODE = 13;
export const SPACEBAR_KEY_CODE = 32;
export const ESC_KEY_CODE = 27;
export const LEFTARROW_KEY_CODE = 37;
export const UPARROW_KEY_CODE = 38;
export const RIGHTARROW_KEY_CODE = 39;
export const DOWNARROW_KEY_CODE = 40;
export const ENTER_KEY = "Enter";
export const SPACEBAR_KEY = " ";
export const ESC_KEY = "Escape";
export const LEFTARROW_KEY = "ArrowLeft";
export const UPARROW_KEY = "ArrowUp";
export const RIGHTARROW_KEY = "ArrowRight";
export const DOWNARROW_KEY = "ArrowDown";
export const REMOVE_FROM_TABORDER = -1;
export const DEFAULT_IMAGE_ASPECT_RATIO = 16 / 9;
export const MAX_FILE_SIZE_BYTES = 29360128; // 28 MB