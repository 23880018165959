
export interface IIntraActive {
  Authentication: IAuthentication;
}

export interface IAuthentication {
  AuthorizationPromise?: Promise<void>;
  LiteTenantRequestPromise?: Promise<boolean>;
}

declare global {
  interface Window {
    IntraActive: IIntraActive
  }
}

window.IntraActive = window.IntraActive || { Authentication: {} };
window.IntraActive.Authentication = window.IntraActive.Authentication || {};