exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_listItem_1buHwJGZIoV5qM7DnzWrM1 {\n  position: relative;\n  width: 100%;\n  float: left;\n  box-sizing: border-box;\n}\n\n.IA_title_33OjC_C1zg4C-U_hwZx_ee {\n  float: left;\n}\n\n.IA_teaser_1YC3hnFaWGn5ywxbSR9YmX {\n  float: left;\n  display: -webkit-box;\n  box-orient: vertical;\n  -webkit-box-orient: vertical;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.IA_startDate_12cZpXw5zvylYxFAluKYqZ {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 10px; \n}\n\n.IA_now_3_wHoV9EQ1nnRv4mUpOOjn {\n  float: left;\n  width: 100%;\n  text-align: center;\n  font-weight: bold;\n}\n\n.IA_startDateendDateDivider_1PaLUeXatzVfwlbpR5s8bK {\n  float: left;\n  width: 60%;\n  height: 1px;\n  background-color: #999999;\n  margin-left: 20%;\n}\n\n.IA_endDate_1XS1bSsNJxs19TohTNdcHG {\n  float: left;\n  width: 100%;\n  text-align: center;\n}\n\n.IA_coverOverlayToday_3Y8NE84jlNl5PoPv4oM02M {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 27px; \n  font-weight: bold;\n}\n\n.IA_coverOverlayMonth_3YQa_fKCvXdJtS9uNF9IV6 {\n  float: left;\n  width: 100%;\n  text-align: center;\n  margin-top: 15px; \n  font-weight: normal;\n}\n\n.IA_coverOverlayDay_1URNn01CYbsFVMB45dr6J1 {\n  float: left;\n  width: 100%;\n  text-align: center;\n}\n\n.IA_byline_pzbL6sLaJrtUggZWsP_tL {\n  float: left;\n  clear: both;\n  position: relative;\n}\n", ""]);

// Exports
exports.locals = {
	"IA_listItem": "IA_listItem_1buHwJGZIoV5qM7DnzWrM1",
	"IA_title": "IA_title_33OjC_C1zg4C-U_hwZx_ee",
	"IA_teaser": "IA_teaser_1YC3hnFaWGn5ywxbSR9YmX",
	"IA_startDate": "IA_startDate_12cZpXw5zvylYxFAluKYqZ",
	"IA_now": "IA_now_3_wHoV9EQ1nnRv4mUpOOjn",
	"IA_startDateendDateDivider": "IA_startDateendDateDivider_1PaLUeXatzVfwlbpR5s8bK",
	"IA_endDate": "IA_endDate_1XS1bSsNJxs19TohTNdcHG",
	"IA_coverOverlayToday": "IA_coverOverlayToday_3Y8NE84jlNl5PoPv4oM02M",
	"IA_coverOverlayMonth": "IA_coverOverlayMonth_3YQa_fKCvXdJtS9uNF9IV6",
	"IA_coverOverlayDay": "IA_coverOverlayDay_1URNn01CYbsFVMB45dr6J1",
	"IA_byline": "IA_byline_pzbL6sLaJrtUggZWsP_tL"
};