import { Helper } from 'intraactive-sdk-helper';
import * as React from 'react';
import { IAIcon } from '../../../../../../icon/Icon';
import * as styles from './PermissionsPersona.css';

export interface IProps {
  displayName: string;
  userPrincipalName: string;
  userId: string;
  highlightColor: string
  onAdd: (id: string) => void;
}

export class PermissionsPersona extends React.Component<IProps> {

  public render(): JSX.Element {
    let firstNameChar =
      this.props.displayName != undefined
        ? this.props.displayName.split(" ")[0].charAt(0)
        : "";
    let lastNameChar =
      this.props.displayName != undefined
        ? this.props.displayName
          .split(" ")
        [this.props.displayName.split(" ").length - 1].charAt(0)
        : "";

    return <>
      <div
        className={styles.IA_personaCard}
      >
        <div className={styles.IA_personaWrapper}>
          <div
            className={styles.IA_personaProfileImageWrapper}
            style={{
              backgroundColor: this.props.highlightColor,
              color: Helper.getTextColorBasedOnBackgroundColorDarkness(this.props.highlightColor, "#ffffff", "#333333")
            }}
          >
            <div className={styles.IA_personaProfileImageText}>
              {firstNameChar + lastNameChar}
            </div>

          </div>
          <div>{this.props.displayName}</div>
          <div className={styles.IA_personaUserPrincipalName}>{this.props.userPrincipalName}</div>
        </div>
        <div>
          <IAIcon title='Add' color={this.props.highlightColor} size={20} style={{ display: 'pointer' }} onClick={() => this.props.onAdd(this.props.userId)} />
        </div>
      </div>
    </>
  }
}
