import { LOCALIZATION } from '../localization/localization';
import { Environment } from "./Environment";

const DB_VERSION: number = 1;
declare global {
  interface Window { language: string; localizedStrings: any, localizedStringsIsRequested: boolean }
}

export class LocalizationService {

  public strings = LOCALIZATION;
  public constructor(forceLanguage?: string) {
    this.setLocalization(forceLanguage);
  }

  private setLocalization(forceLanguage?: string): void {
    if (window.localizedStrings) {
      this.setLocalizedStrings();
    } else {
      const _this = this;
      const request = indexedDB.open("IA_localization", DB_VERSION);
      
      request.onerror = function () { 
        console.error("Error creating/accessing IndexedDB database");
      };

      request.onsuccess = function () {
        const db = request.result;
        
        db.onerror = function () {
          console.error("Error creating/accessing IndexedDB database");
        };

        if (db.objectStoreNames.contains("localizationStrings")) {
          const getTransaction = db.transaction(["localizationStrings"], "readonly");
          const getFileRequest = getTransaction.objectStore("localizationStrings").get("strings");
          getFileRequest.onsuccess = function () {
            window.localizedStrings = getFileRequest.result;
          }
          getFileRequest.onerror = function () {
            console.error("Error getting localization strings");
          }
        } else {
          setTimeout(() => {
            _this.setLocalization(forceLanguage);
          }, 500);
        }
        if (!window.localizedStringsIsRequested) {
          window.localizedStringsIsRequested = true;
          _this.getLocalizedKeysForLanguage(forceLanguage ? forceLanguage : _this.getLanguageCode()).then((localizedStrings) => {
            window.localizedStrings = localizedStrings;
            const putTransaction = db.transaction(["localizationStrings"], "readwrite");
            putTransaction.objectStore("localizationStrings").put(localizedStrings, "strings");
            _this.setLocalizedStrings();
          });
        }
      }
      
      request.onupgradeneeded = function () {
        const db = request.result;
        db.createObjectStore('localizationStrings');
      };
    }
  }

  private setLocalizedStrings(): void {
    for (const key in this.strings) {
      if (this.strings.hasOwnProperty(key)) {
        if (window.localizedStrings[key]) {
          this.strings[key] = window.localizedStrings[key];
        }
      }
    }
  }

  public getLanguageFromNavigator(forceLanguage?: string): 'Danish' | 'English' | 'Swedish' | 'German' | 'Norwegian' {
    switch (forceLanguage ?? navigator.language.substring(0, 2)) {
      case 'en':
        return 'English';
      case 'da':
        return 'Danish';
      case 'sv':
        return 'Swedish';
      case 'de':
        return 'German';
      case 'nb':
        return 'Norwegian';
      default:
        return 'English';
    }
  }

  public getLanguageCode() {
    let language = this.getLanguageFromWindowOrCache();
    let defaultLanguage = this.getLanguageFromNavigator();
    switch (language ?? defaultLanguage) {
      case 'English':
        return 'en-US';
      case 'Danish':
        return 'da-DK';
      case 'Swedish':
        return 'sv-SE';
      case 'Norwegian':
        return 'nb-NO';
      case 'German':
        return 'de-DE';
      default:
        return 'en-US';
    }
  }
  
  public getFromString(key: string): string {
    if (window.localizedStrings && window.localizedStrings[key]) {
      return window.localizedStrings[key];
    } else {
      return key;
    }
  }

  private getLanguageFromWindowOrCache() {
    if (window.language) {
      return window.language;
    } else {
      if (window.localStorage && !!window.localStorage.getItem("IA_language")) {
        return window.localStorage.getItem("IA_language");
      }
    }
  }

  private getLocalizedKeysForLanguage(language: string): Promise<any> {
    return new Promise<any>((resolve) => {
      fetch(`${Environment.getEnvironmentForTranslationAPI("Production")}/translations/${language}`, {
        method: "GET"
      })
        .then((response) => {
          if (!response.ok) {
            return;
          }
          return response.json();
        })
        .then((keys) => {
          resolve(keys);
        })
        .catch(error => {
          console.log(error);
          resolve([]);
        });
    });
  }
}