import * as React from 'react';
import { Store } from '../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './Dashboard.css';
import { Players } from './players/Players';
import { Playlists } from './playlists/Playlists';
import { Slides } from './slides/Slides';
import { Widgets } from './widgets/Widgets';
import { Articles } from './articles/Articles';
import { MediaFiles } from './mediaFiles/MediaFiles';
import { Settings } from './settings/Settings';
import { Helper } from '../../../Helper';
import { IAMobileMenu } from '../../mobileMenu/MobileMenu';
import { IAMenu } from '../../menu/Menu';
import { GettingStarted } from './gettingStated/GettingStarted';
import { LocalizationService } from '../../../services/LocalizationService';

export interface IProps {
  showSkeleton: boolean;
  getProfiles: () => void;
  store?: Store;
}

export interface IState {
}

@inject('store')
@observer
export class Dashboard extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
  }

  public render(): JSX.Element {
    let content;
    switch (this.props.store.selectedMenuItem) {
      case "players":
        content = (
          <Players
            showSkeleton={this.props.showSkeleton}
          />
        );
        break;
      case "playlists":
        content = (
          <Playlists
            showSkeleton={this.props.showSkeleton}
          />
        );
        break;
      case "slides":
        content = (
          <Slides
            showSkeleton={this.props.showSkeleton}
          />
        );
        break;
      case "widgets":
        content = (
          <Widgets
            showSkeleton={this.props.showSkeleton}
          />
        );
        break;
      case "articles":
        content = (
          <Articles
            showSkeleton={this.props.showSkeleton}
          />
        );
        break;
      case "mediaFiles":
        content = (
          <MediaFiles
            showSkeleton={this.props.showSkeleton}
          />
        );
        break;
      case "profileSettings":
        content = (
          <Settings
            showSkeleton={this.props.showSkeleton}
          />
        );
        break;
      case "gettingStarted":
        content = (
          <GettingStarted />
        );
        break;
      default:
        break;
    }
    const bottomItems = [];
    if (this.localizationService.strings.PlayAdmin_GettingStartedPlayersVideoUrl !== "-") {
      bottomItems.push({
        key: "gettingStarted",
        label: this.localizationService.strings.PlayAdmin_MenuArea_GettingStarted,
        icon: "User Manual"
      });
    }
    bottomItems.push({
      key: "profileSettings",
      label: this.localizationService.strings.PlayAdmin_MenuArea_Settings,
      icon: "Settings"
    });
    if (this.props.store.isAdmin && !this.props.store.isMobile) {
      bottomItems.push({
        key: "accountSettings",
        label: this.localizationService.strings.PlayAdmin_MenuArea_AccountSettingsaSettings,
        icon: "Landingpageadmin"
      });
    }
    return (
      <>
        {this.props.store.isMobile &&
          <IAMobileMenu
            showMobileMenu={this.props.store.showMobileMenu}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            toogleMobileMenu={() => this.props.store.showMobileMenu = !this.props.store.showMobileMenu}
          />
        }
        <IAMenu
          profiles={this.props.store.profiles}
          showProfile
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          isMobile={this.props.store.isMobile}
          showMobileMenu={this.props.store.showMobileMenu}
          isTeams={this.props.store.isTeams}
          items={[
            {
              key: "players",
              label: this.localizationService.strings.PlayAdmin_MenuArea_Players,
              icon: "Monitor"
            },
            {
              key: "playlists",
              label: this.localizationService.strings.PlayAdmin_MenuArea_Playlists,
              icon: "Playlists"
            },
            {
              key: "slides",
              label: this.localizationService.strings.PlayAdmin_MenuArea_Slides,
              icon: "Slides"
            },
            {
              key: "widgets",
              label: this.localizationService.strings.PlayAdmin_MenuArea_Widgets,
              icon: "Widgets Menu"
            },
            {
              key: "articles",
              label: this.localizationService.strings.PlayAdmin_MenuArea_Articles,
              icon: "Article"
            },
            {
              key: "mediaFiles",
              label: this.localizationService.strings.PlayAdmin_MenuArea_MediaFiles,
              icon: "Folder"
            }
          ]}
          bottomItems={bottomItems}
          selectedItem={this.props.store.selectedMenuItem}
          showCollapseButton={false}
          profile={this.props.store.profile}
          collapsed={this.props.store.menuCollapsed}
          onSelect={(selectedMenuItem: string) => {
            if (selectedMenuItem === "accountSettings") {
              this.props.store.isShowingAccountSettings = true;
            } else {
              this.props.store.selectedMenuItem = selectedMenuItem;
              this.props.store.showMobileMenu = false;
              localStorage.setItem("intraactive-play-menu-item", selectedMenuItem);
              if (this.props.store.profileHasChanged) {
                this.props.store.profileHasChanged = false;
                this.props.getProfiles();
              }
            }
          }}
          onCollapseToggle={() => {
            this.props.store.menuCollapsed = !this.props.store.menuCollapsed;
            if (this.props.store.menuCollapsed) {
              localStorage.setItem("intraactive-play-menu-collapsed", "collapsed");
            } else {
              localStorage.removeItem("intraactive-play-menu-collapsed");
            }
          }}
          setProfile={(profile: any) => this.props.store.profile = profile}
          resetProfile={() => this.props.store.resetProfile()}
          style={{
            height: this.props.store.isMobile ? (this.props.store.showMobileMenu ? "calc(100% - 51px)" : 0) : "calc(100% - 1px)"
          }}
        />
        <div
          className={styles.IA_dashboard}
          style={{
            width: this.props.store.isMobile ? "100%" : (this.props.store.menuCollapsed ? "calc(100% - 50px)" : "calc(100% - 300px)"),
            transition: "width 300ms ease-in-out",
            backgroundColor: this.props.store.darkMode ? Helper.darkModeBackgroundColor : "#ffffff",
          }}
        >
          <div
            id="IA_Content"
            className={styles.IA_content}
            style={{
              backgroundColor: this.props.store.darkMode ? Helper.darkModeBackgroundColor : "#ffffff",
              color: this.props.store.darkMode ? Helper.darkModeLabelColor : "#333333"
            }}
          >
            {content}
          </div>
        </div>
      </>
    );
  }

  
}
