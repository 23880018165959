import * as React from 'react';
import { IPlayWeatherForecast } from '../../../../../interfaces/IPlay';
import * as styles from '../Weather.css';
import { IColorTheme } from '../../../../../interfaces/IColorTheme';
import { ColorTranslator } from 'colortranslator';

export interface IProps {
  widgetId: string;
  forecast: IPlayWeatherForecast[];
  width: number;
  height: number;
  sizeUnit: number;
  colorTheme: IColorTheme;
  getDayString?: (index: number, time: string) => string;
}

export interface IState {
}

export class WeekVerticalWeather extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    };
  }

  public render(): JSX.Element {
    const colortranslator = new ColorTranslator(this.props.colorTheme.textColor.toLocaleLowerCase());
    const borderColor = `rgba(${colortranslator.R}, ${colortranslator.G}, ${colortranslator.B}, .2)`;
    
    return (
      <div
        className={styles.IA_weatherWidgetContainerVertical}
        style={{
          backgroundColor: this.props.colorTheme.backgroundColor
        }}
      >
        {this.props.forecast && this.props.forecast.slice(0, -1).map((weather, i) => {
          return <div
            className={styles.IA_weatherDayVertical}
            style={{
              marginTop: i === 0 ? this.props.sizeUnit * 5 : undefined,
              marginBottom: i === 4 ? this.props.sizeUnit * 5 : undefined,
              borderBottom: i !== 4 ? `${this.props.sizeUnit / 10}px solid ${borderColor}` : undefined,
              borderTop: i !== 0 ? `${this.props.sizeUnit / 10}px solid ${borderColor}` : undefined,
            }}
          >
            <p
              style={{
                fontSize: this.props.sizeUnit * 3,
                marginLeft: this.props.sizeUnit * 8,
                marginBottom: 0,
                color: this.props.colorTheme.textColor
              }}
            >
              {this.props.getDayString(i, weather.time)}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                alignItems: "center",
                marginLeft: "auto",
                marginRight: this.props.sizeUnit * 6
              }}
            >
              <p
                className={styles.IA_precipation}
                style={{
                  fontSize: this.props.sizeUnit * 2,
                  minWidth: this.props.sizeUnit * 6,
                  marginRight: this.props.sizeUnit * 3,
                  marginTop: 0,
                  marginBottom: 0
                }}
              >
                {weather.precipitationAmount !== 0 ?`${Math.ceil(weather.precipitationAmount)} mm` : " "}
              </p>
              <p
                style={{
                  fontSize: this.props.sizeUnit * 5,
                  marginTop: 0,
                  marginBottom: 0,
                  color: this.props.colorTheme.textColor,
                  marginRight: this.props.sizeUnit * 3
                }}
              >
                {`${weather.airTemperatureHigh.toFixed(0)}°`}
              </p>
              <img
                src={weather.iconUrl}
                style={{
                  width: this.props.height / 10
                }}
              />
            </div>
          </div>
        })}
      </div>
    );
  }
}