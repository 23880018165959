import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './SlideEditPanel.css';
import { IAPanel } from '../../../../panels/Panel';
import { IATextField } from '../../../../textField/TextField';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAButton } from '../../../../button/Button';
import { IADateAndTimePicker } from '../../../../dateAndTimePicker/DateAndTimePicker';
import { IAOptionSelector } from '../../../../optionSelector/OptionSelector';
import { IAToggle } from '../../../../toggle/Toggle';
import { Slide } from '../../../../player/slide/Slide';
import PlayService from '../../../../../services/PlayService';
import { IPlaylist, IPlayRssTickerWidget, IPlaySlideExtended, IPlaySlideLayout, IPlayWidget, IPlayWidgetExtended, IPlayWidgetType } from '../../../../../interfaces/IPlay';
import { IASpinner } from '../../../../spinner/Spinner';
import { Helper } from '../../../../../Helper';
import { PlayHelper } from '../../../PlayHelper';
import { WidgetEditPanel } from '../../widgets/widgetEditPanel/WidgetEditPanel';
import { WidgetPopup } from '../../widgetPopup/WidgetPopup';
import { IACheckbox } from '../../../../checkbox/Checkbox';
import { Popup } from '../../../reusableComponents/popup/Popup';
import { RssTicker } from '../../../../player/rssTicker/RssTicker';
import { Footer } from '../../../../player/footer/Footer';

export interface IProps {
  slideId: string;
  store?: Store;
  showPlaylistSelector?: boolean;
  isOpen: boolean;
  close: () => void;
  onSave: () => void;
}

export interface IState {
  isSaving: boolean;
  selectedWidgetIndex: number;
  selectedWidgetType: IPlayWidgetType;
  selectedRssTickerPosition: string;
  selectedRssTicker: IPlayRssTickerWidget;
  availableWidgets: IPlayWidget[];
  showRssTickerPopup: boolean;
  playlists: IPlaylist[];
  playlistsCount: number;
  isLoadingMore: boolean;
  showConfirmClosePopup: boolean;
}

@inject('store')
@observer
export class SlideEditPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();
  private readonly perPage: number = 5;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isSaving: false,
      selectedWidgetIndex: undefined,
      selectedWidgetType: undefined,
      selectedRssTickerPosition: undefined,
      selectedRssTicker: undefined,
      availableWidgets: undefined,
      showRssTickerPopup: false,
      isLoadingMore: false,
      playlists: [],
      playlistsCount: undefined,
      showConfirmClosePopup: false
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    this.props.showPlaylistSelector && this.state.playlists?.length < this.perPage && props.isOpen && this.loadPlaylists();
    if (props.slideId !== this.props.slideId && props.slideId) {
      PlayService.getSlide(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, props.slideId).then((slide: IPlaySlideExtended) => {
        this.props.store.slideToEdit = { ...slide, playlistsToAddTo: [], playlistsToRemoveFrom: [] };
        this.props.store.slideToEditBeforeChanges = JSON.stringify(this.props.store.slideToEdit);
        if (slide?.rssTicker?.rssTicker) {
          this.setState({ selectedRssTicker: slide?.rssTicker?.rssTicker });
        }
        this.forceUpdate();
      });
    }
  }

  private getWidgetsOfType(type: IPlayWidgetType): void {
    let availableWidgets: IPlayWidget[];
    this.setState({ availableWidgets });
    if (type) {
      PlayService.getWidgets(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, type).then(widgets => {
        availableWidgets = [];
        widgets.value.forEach((widget: IPlayWidget) => {
          availableWidgets.push(widget);
        });
        this.setState({ availableWidgets });
      });
    }
  }

  private loadPlaylists() {
    this.setState({ isLoadingMore: true })
    const currentPage = Math.ceil(this.state.playlists?.length / this.perPage) || 0;
    PlayService.getPlaylists(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id,
      {
        skip: currentPage * this.perPage,
        take: this.perPage
      })
      .then(result => {
        if (!this.state.playlistsCount) {
          this.setState({ playlistsCount: result.count })
        }
        this.setState({ playlists: [...this.state.playlists, ...result.value], isLoadingMore: false })
      })
  }

  public render(): JSX.Element {
    let previewWidth = this.props.store.windowWidth - 480;
    let previewHeight = (previewWidth / 16) * 9;

    // resize if the window height is smaller than the preview height
    if (previewHeight > window.innerHeight - 84) {
      previewHeight = window.innerHeight - 84 - 80;
      previewWidth = (previewHeight / 9) * 16;
    }
    if (this.props.store.isMobile) {
      previewWidth = window.innerWidth - 20;
      previewHeight = (previewWidth / 16) * 9;
    }
    // Portrait mode
    if (this.props.store.slideToEdit?.layout === "portraitOneRow" || this.props.store.slideToEdit?.layout === "portraitTwoRows") {
      previewHeight = window.innerHeight - 84 - 80;
      previewWidth = (previewHeight / 16) * 9;
    }
    return (
      <IAPanel
        open={(this.props.store.slideToEdit != undefined || this.props.slideId != undefined) && !this.props.store.widgetToEdit}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={this.state.isSaving}
        width={this.props.store.windowWidth}
        darkMode={this.props.store.darkMode}
        dataAutomationIdPrefix="slide-edit-panel"
        panelId="slide-edit-panel"
        navigationsBarContent={
          <IAButton
            label={this.props.store.slideToEdit?.id ? this.localizationService.strings.PlayAdmin_Update?.toUpperCase() : this.localizationService.strings.PlayAdmin_Save?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            disbaled={(!this.props.store.slideToEdit?.title)}
            darkMode={this.props.store.darkMode}
            onClick={() => {
              this.setState({ playlists: [], playlistsCount: undefined })
              this.props.onSave();
            }}
            borderRadius={5}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7,
              marginRight: 10
            }}
          />
        }
        close={() => {
          if (this.props.store.slideToEditBeforeChanges === JSON.stringify(this.props.store.slideToEdit)) {
            this.setState({ selectedRssTicker: undefined, playlists: [], playlistsCount: undefined, showConfirmClosePopup: false })
            this.props.close();
          } else {
            this.setState({ showConfirmClosePopup: true });
          }
        }}
        isInTeams={this.props.store.isTeams}
      >
        {this.state.showConfirmClosePopup &&
          <Popup
            headline={this.localizationService.strings.Messages_CancelPopup_Headline}
            close={() => this.setState({ showConfirmClosePopup: false })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.setState({ selectedRssTicker: undefined, playlists: [], playlistsCount: undefined, showConfirmClosePopup: false })
                    this.props.close();
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ showConfirmClosePopup: false })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.state.selectedWidgetIndex != undefined && this.props.store.widgetToEdit == undefined &&
          <WidgetPopup
            selectedWidgetIndex={this.state.selectedWidgetIndex}
            availableWidgets={this.state.availableWidgets}
            setSelectedWidgetType={(selectedWidgetType: IPlayWidgetType) => this.getWidgetsOfType(selectedWidgetType)}
            close={() => this.setState({ selectedWidgetIndex: undefined, selectedWidgetType: undefined })}
          />
        }
        {this.props.store.slideToEdit ?
          <div
            className={styles.IA_slidesEditPanel}
            style={{
              height: window.innerHeight - 45,
              backgroundColor: this.props.store.darkMode ? "#414141" : "#777777",
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            <div className={styles.IA_content} style={{ backgroundColor: this.props.store.darkMode ? Helper.darkModeBackgroundColor : '#fff' }} >
              <h1 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.localizationService.strings.PlayAdmin_MenuArea_SlideSettings}</h1>
              <IATextField
                label={this.localizationService.strings.PlayAdmin_Title?.toUpperCase()}
                text={this.props.store.slideToEdit.title}
                required
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                onChange={(title: string) => {
                  this.props.store.slideToEdit.title = title;
                  this.forceUpdate();
                }}
              />
              <IADateAndTimePicker
                label={this.localizationService.strings.Messages_EditPanel_StartTime?.toUpperCase()}
                selectedDate={new Date(this.props.store.slideToEdit.startDate)}
                showTimeSelector={true}
                highlightColor={this.props.store.highlightColor}
                isClearable={false}
                required={true}
                darkMode={this.props.store.darkMode}
                borderRadius={5}
                onChange={(startDate: Date) => {
                  this.props.store.slideToEdit.startDate = startDate.toJSON();
                }}
                style={{
                  marginTop: 20,
                  width: "100%"
                }}
              />
              <IADateAndTimePicker
                label={this.localizationService.strings.Messages_EditPanel_EndTime?.toUpperCase()}
                selectedDate={this.props.store.slideToEdit.endDate ? new Date(this.props.store.slideToEdit.endDate) : undefined}
                showTimeSelector={true}
                highlightColor={this.props.store.highlightColor}
                isClearable={true}
                darkMode={this.props.store.darkMode}
                borderRadius={5}
                onChange={(endDate: Date | undefined) => {
                  this.props.store.slideToEdit.endDate = endDate?.toJSON() ?? null;
                }}
                style={{
                  marginTop: 20,
                  width: "100%"
                }}
              />
              <IAOptionSelector
                label={this.localizationService.strings.PlayAdmin_SlideLayout?.toUpperCase()}
                options={[
                  {
                    icon: PlayHelper.getIconForSlideLayout("oneColumn"),
                    key: "oneColumn",
                    text: this.localizationService.strings.PlayAdmin_LayoutOneColumn,
                    disabled: this.props.store.slideToEdit.id != undefined && (this.props.store.slideToEdit.layout === "portraitOneRow" || this.props.store.slideToEdit.layout === "portraitTwoRows")
                  },
                  {
                    icon: PlayHelper.getIconForSlideLayout("twoColumns"),
                    key: "twoColumns",
                    text: this.localizationService.strings.PlayAdmin_LayoutTwoColumns,
                    disabled: this.props.store.slideToEdit.id != undefined && (this.props.store.slideToEdit.layout === "portraitOneRow" || this.props.store.slideToEdit.layout === "portraitTwoRows")
                  },
                  {
                    icon: PlayHelper.getIconForSlideLayout("rightSideTwoRows"),
                    key: "rightSideTwoRows",
                    text: this.localizationService.strings.PlayAdmin_LayoutRightSideTwoRows,
                    disabled: this.props.store.slideToEdit.id != undefined && (this.props.store.slideToEdit.layout === "portraitOneRow" || this.props.store.slideToEdit.layout === "portraitTwoRows")
                  },
                  {
                    icon: PlayHelper.getIconForSlideLayout("leftAndRightSideTwoRows"),
                    key: "leftAndRightSideTwoRows",
                    text: this.localizationService.strings.PlayAdmin_LayoutLeftAndRightSideTwoRows,
                    disabled: this.props.store.slideToEdit.id != undefined && (this.props.store.slideToEdit.layout === "portraitOneRow" || this.props.store.slideToEdit.layout === "portraitTwoRows")
                  },
                  {
                    icon: PlayHelper.getIconForSlideLayout("portraitOneRow"),
                    key: "portraitOneRow",
                    text: this.localizationService.strings.PlayAdmin_LayoutPortraitOneRow,
                    disabled: this.props.store.slideToEdit.id != undefined && (this.props.store.slideToEdit.layout === "oneColumn" || this.props.store.slideToEdit.layout === "twoColumns" || this.props.store.slideToEdit.layout === "rightSideTwoRows" || this.props.store.slideToEdit.layout === "leftAndRightSideTwoRows")
                  },
                  {
                    icon: PlayHelper.getIconForSlideLayout("portraitTwoRows"),
                    key: "portraitTwoRows",
                    text: this.localizationService.strings.PlayAdmin_LayoutPortraitTwoRows,
                    disabled: this.props.store.slideToEdit.id != undefined && (this.props.store.slideToEdit.layout === "oneColumn" || this.props.store.slideToEdit.layout === "twoColumns" || this.props.store.slideToEdit.layout === "rightSideTwoRows" || this.props.store.slideToEdit.layout === "leftAndRightSideTwoRows")
                  }
                ]}
                selected={this.props.store.slideToEdit.layout}
                highlightColor={this.props.store.highlightColor}
                list
                iconSize={30}
                darkMode={this.props.store.darkMode}
                borderRadius={5}
                onChange={(layout: IPlaySlideLayout) => {
                  this.props.store.slideToEdit.layout = layout;
                  const numberOfWidgetsForLayout = PlayHelper.getNumberOfWidgetsForLayout(layout);
                  const widgetsToSave = [];
                  if (this.props.store.slideToEdit?.widgets?.length > 0) {
                    this.props.store.slideToEdit.widgets.forEach((widget: IPlayWidgetExtended, index) => {
                      if (index < numberOfWidgetsForLayout) {
                        widgetsToSave.push(widget);
                      }
                    });
                    this.props.store.slideToEdit.widgets = widgetsToSave;
                  }
                  this.forceUpdate();
                  setTimeout(() => {
                    this.forceUpdate();
                  }, 0);
                }}
                style={{ clear: "none", marginTop: 20 }}
              />
              <IAToggle
                style={{
                  marginTop: 20,
                  width: "50%",
                  float: "left"
                }}
                label={this.localizationService.strings.PlayAdmin_ShowFooter?.toUpperCase()}
                checked={this.props.store.slideToEdit.showFooter}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.props.store.slideToEdit.showFooter = !this.props.store.slideToEdit.showFooter;
                }}
              />
              <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
              <IAToggle
                style={{
                  marginTop: 20,
                  width: "50%",
                  float: "left",
                  clear: "both"
                }}
                label={this.localizationService.strings.PlayAdmin_ShowRssTicker?.toUpperCase()}
                checked={!!this.props.store.slideToEdit.showRssTicker}
                color={this.props.store.highlightColor}
                darkMode={this.props.store.darkMode}
                onChange={() => {
                  this.props.store.slideToEdit.showRssTicker = !!!this.props.store.slideToEdit.showRssTicker;
                  this.forceUpdate();
                }}
              />
              {/* RSS TICKER */}
              {this.state.selectedRssTickerPosition != undefined &&
                <div
                  style={{
                    clear: "both",
                    float: "left",
                    width: "100%"
                  }}
                >
                </div>
              }
              {
                this.props.showPlaylistSelector && this.props.store.slideToEdit && <div className={`${styles.IA_playlistsSelector} ${this.props.store.darkMode ? styles.dark : ''}`}>
                  <h4>{this.localizationService.strings.PlayAdmin_PublishToPlaylist}</h4>
                  {
                    this.state.playlists?.map((playlist) => {
                      if (
                        (playlist.screenFormat === "portrait" && this.props.store.slideToEdit.layout === "portraitOneRow" || this.props.store.slideToEdit.layout === "portraitTwoRows") ||
                        ((playlist.screenFormat == undefined || playlist.screenFormat === "landscape") && (this.props.store.slideToEdit.layout !== "portraitOneRow" && this.props.store.slideToEdit.layout !== "portraitTwoRows"))
                      ) {
                        return <div key={playlist.id} className={styles.IA_playlistsSelectorRow}>
                          <div>
                            {playlist.title}
                          </div>
                          <IACheckbox
                            highlightColor={this.props.store.highlightColor}
                            isChecked={(playlist?.slides.some(slideId => slideId === this.props.store.slideToEdit.id) || this.props.store.slideToEdit.playlistsToAddTo.some(playlistId => playlist.id === playlistId)) && this.props.store.slideToEdit.playlistsToRemoveFrom.every(playlistId => playlistId !== playlist.id)}
                            onChange={(isChecked) => {
                              if (isChecked) {
                                playlist?.slides.every(slideId => slideId != this.props.store.slideToEdit.id) && this.props.store.slideToEdit.playlistsToAddTo.push(playlist.id);
                                this.props.store.slideToEdit.playlistsToRemoveFrom = [...this.props.store.slideToEdit.playlistsToRemoveFrom.filter(playlistId => playlistId !== playlist.id)];
                              }
                              else {
                                playlist?.slides.some(slideId => slideId === this.props.store.slideToEdit.id) && this.props.store.slideToEdit.playlistsToRemoveFrom.push(playlist.id);
                                this.props.store.slideToEdit.playlistsToAddTo = [...this.props.store.slideToEdit.playlistsToAddTo.filter(playlistId => playlistId !== playlist.id)];
                              }
                            }}
                          />
                        </div>
                      }
                    })
                  }
                  {
                    this.state.isLoadingMore && <div>
                      <IASpinner color={this.props.store.highlightColor} />
                    </div>
                  }
                  {
                    this.state.playlists?.length < this.state.playlistsCount && <div className={styles.IA_playlistsSelectorLoadMore}>
                      <IAButton
                        label='Load more'
                        buttonColor={this.props.store.highlightColor}
                        onClick={() => this.loadPlaylists()}
                        darkMode={this.props.store.darkMode}
                      />
                    </div>
                  }

                </div>
              }
            </div>

            <div className={styles.IA_previewArea}>
              <div
                className={styles.IA_preview}
                style={{
                  width: this.props.store.isMobile ? "100%" : previewWidth,
                  height: previewHeight,
                  paddingLeft: this.props.store.slideToEdit?.layout === "portraitOneRow" || this.props.store.slideToEdit?.layout === "portraitTwoRows" ? `calc(50% - ${previewWidth / 2}px)` : 40
                }}
              >
                <div
                  className={styles.IA_previewScreen}
                  style={{
                    height: previewHeight,
                    width: previewWidth
                  }}
                >
                  {this.props.store.slideToEdit?.showRssTicker &&
                    <RssTicker
                      rssTicker={{}}
                      editMode={true}
                      isActive={true}
                      sizeUnit={(previewHeight > previewWidth ? previewWidth : previewHeight) / 100}
                      playerWidth={previewWidth}
                      bottom={this.props.store.slideToEdit.showFooter ? ((previewHeight > previewWidth ? previewWidth : previewHeight) / 100) * 8 : 0}

                    />
                  }
                  {this.props.store.slideToEdit?.showFooter &&
                    <Footer
                      footer={this.props.store.profile?.footer}
                      sizeUnit={(previewHeight > previewWidth ? previewWidth : previewHeight) / 100}
                      city={this.props.store.city}
                      currentWeather={this.props.store.currentWeather}
                      editMode={true}
                    />
                  }
                  <Slide
                    index={0}
                    slide={this.props.store.slideToEdit}
                    isActive={true}
                    height={previewHeight}
                    width={previewWidth}
                    left={0}
                    footer={this.props.store.slideToEdit.showFooter ? this.props.store.profile.footer : undefined}
                    city={this.props.store.city}
                    currentWeather={this.props.store.currentWeather}
                    editMode
                    highlightColor={this.props.store.highlightColor}
                    editWidgetIndex={(index: number) => this.setState({ selectedWidgetIndex: index, selectedRssTickerPosition: undefined })}
                    environment={this.props.store.environment}
                    tenantId={this.props.store.tenantId}
                    profileId={this.props.store.profile.id}
                    showActivityLabel
                    standalone
                    forecast={this.props.store.weatherData}
                    isHardcodedWeatherData={this.props.store.isUsingHardCodedWeather}
                    nextSlide={() => { }}
                  />
                </div>
              </div>
            </div>
          </div>
          :
          <div
            className={styles.IA_loading}
            style={{
              backgroundColor: this.props.store.darkMode ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"
            }}
          >
            <IASpinner
              key={Helper.getRandomStringKey()}
              color={this.props.store.highlightColor}
            />
            <div className={styles.IA_weAreWorkingOnIt}>{this.localizationService.strings.Composer_WeAreWorkingOnIt}</div>
          </div>
        }
        <WidgetEditPanel
          widgetId={undefined}
          close={() => {
            this.props.store.widgetToEdit = undefined;
          }}
          onSave={() => {
            this.props.store.workingOnIt = true;
            const widgetModel: IPlayWidget = {
              ...this.props.store.widgetToEdit, articleViewer: { articles: this.props.store.widgetToEdit.articleViewer?.articles?.map(article => article.id) }
            }
            PlayService.createWidget(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, widgetModel).then((id) => {
              console.log(`Created widget with id: ${id}`)
              this.props.store.widgetToEdit = undefined;
              PlayService.getWidgetById(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, id).then((widget) => {
                this.props.store.slideToEdit.widgets[this.state.selectedWidgetIndex] = widget;
                this.props.store.workingOnIt = false;
                this.setState({ selectedWidgetIndex: undefined });
              });
            });
          }}
          availableTypes={PlayHelper.getAvailableWidgets(this.localizationService, this.props.store.environment, this.props.store.isConnectedToMessages(), this.props.store.isConnectedToEvents(), this.props.store.isConnectedToSharepointNews())}
          preSelectedType={this.state.selectedWidgetType}
        />
      </IAPanel >
    );
  }


}