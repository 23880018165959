import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './Groups.css';
import { IListElement, IPlayerGroup } from '../../../../../interfaces/IPlay';
import { IAButton } from '../../../../button/Button';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { GroupEditPanel } from './groupEditPanel/GroupEditPanel';
import { Popup } from '../../../reusableComponents/popup/Popup';
import PlayService from '../../../../../services/PlayService';
import { Helper } from '../../../../../Helper';
import { IAListView } from '../../../../listView/ListView';

export interface IProps {
  showSkeleton: boolean;
  store?: Store;
}

export interface IState {
  selectedGroupId: string;
  groupToDelete: string;
}

@inject('store')
@observer
export class Groups extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedGroupId: undefined,
      groupToDelete: undefined
    }
  }

  public componentDidMount(): void {
    if (!this.props.showSkeleton) {
      this.getGroups();
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (!props.showSkeleton && props.showSkeleton !== this.props.showSkeleton) {
      this.getGroups();
    }
  }

  private getGroups(): void {
    this.props.store.workingOnIt = true;
    PlayService.getGroups(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id).then(groups => {
      this.props.store.groups = groups.value;
      this.props.store.groupsCount = groups.count;
      this.props.store.workingOnIt = false;
    });
  }

  private onDelete(id: string) {
    this.props.store.workingOnIt = true;
    PlayService.deleteGroup(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, id).then(() => {
      this.props.store.groupToEdit = undefined;
      this.props.store.workingOnIt = false;
      this.setState({ selectedGroupId: undefined, groupToDelete: undefined });
      this.getGroups();
    });
  }

  public render(): JSX.Element {
    const listElements: IListElement[] = [];
    if (this.props.store.groups?.length > 0) {
      this.props.store.groups.forEach((group: IPlayerGroup) => {
        listElements.push(
          {
            id: group.id,
            columns: [
              {
                header: this.localizationService.strings.PlayAdmin_Groups?.toUpperCase(),
                type: "tag",
                text: group.title,
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              }
            ],
            buttons: [
              {
                action: "delete",
                icon: "Trash",
                color: this.props.store.highlightColor
              },
              {
                action: "edit",
                icon: "Edit",
                color: this.props.store.highlightColor
              },
            ]
          }
        );
      });
    }
    return (
      <div className={styles.IA_groups}>
        <GroupEditPanel
          id={this.state.selectedGroupId}
          onClose={() => {
            this.getGroups();
            this.setState({ selectedGroupId: undefined });
          }}
        />
        <IAButton
          label={this.localizationService.strings.PlayAdmin_Add?.toUpperCase()}
          buttonColor={this.props.store.highlightColor}
          onClick={() => {
            this.props.store.groupToEdit = {};
          }}
          darkMode={this.props.store.darkMode}
          borderRadius={5}
          showSpinner={this.props.store.workingOnIt}
          style={{
            float: "right",
            position: "relative",
            marginTop: 7
          }}
        />
        <IAListView
          id="ListViewOfGroups"
          listElements={listElements}
          backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
          hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
          buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
          defaultAction={"edit"}
          darkMode={this.props.store.darkMode}
          selectedAction={(action: string, id: string) => {
            switch (action) {
              case "edit":
                this.setState({ selectedGroupId: id });
                break;
              case "delete":
                this.setState({ groupToDelete: id });
                break;
              default:
                break;
            }
          }}
        />
        {this.state.groupToDelete &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_DeleteGroup}
            close={() => this.setState({ groupToDelete: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  label={this.localizationService.strings.DeletePopup_Yes}
                  onClick={() => {
                    this.onDelete(this.state.groupToDelete);
                  }}
                  borderRadius={5}
                  darkMode={this.props.store.darkMode}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  label={this.localizationService.strings.DeletePopup_No}
                  darkMode={this.props.store.darkMode}
                  onClick={() => this.setState({ groupToDelete: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
      </div>
    );
  }

  
}