import { Helper } from "./Helper";

/**
 * Generic helper class that helps with feature flags.
 * @ Use TenantSettings when there is tenant specific flag/setting.
 */
export class FeatureFlagsService<T> {

  public constructor() {}

  /**
   * Gets settings from URI hash. This is been used in case we do not know the tenant.
   * The settings must be in format #setting1&setting2&... and it supports onlt boolean properties/fields.
   * @param defaultSettings The default settings that will take place when no setting is set
   */
  public getSettingsFromUriHash(defaultSettings: T): T {
    const hash = Helper.getUriHash().substr(1);
    const hashFragments = hash.split('&');
    let settings: T = defaultSettings;
    hashFragments.forEach(x => {
      settings[x] = true;
    });

    return settings;
  }
}