import * as React from 'react';
import { IPlayNewsItem, IPlayWidgetAreaType } from '../../../../interfaces/IPlay';
import DateTimeService from '../../../../services/DateTimeService';
import * as styles from './CanteenMenu.css';
import { CanteenMenuItem } from './canteenMenuItem/CanteenMenuItem';

export interface IProps {
  widgetId: string;
  index?: number;
  newsItems: IPlayNewsItem[];
  width?: number | string;
  sizeUnit?: number;
  headlineColor?: string;
  backgroundColor?: string;
  boxColor?: string;
  borderColor?: string;
  textColor?: string;
  timeBatchBackgroundColor?: string;
  timeBatchTextColor?: string;
  timeBatchBorderColor?: string;
  showTitle?: boolean;
  showText?: boolean;
  showDate?: boolean;
  showTime?: boolean;
  showLocation?: boolean;
  showTimeBox?: boolean;
  areaType?: IPlayWidgetAreaType;
  textLines?: number;
  headline?: string;
  backgroundImage?: string;
  language?: string;
}

export interface IState {
  isLoading: boolean;
}

export class CanteenMenu extends React.Component<IProps, IState> {

  private readonly dateTimeService: DateTimeService = new DateTimeService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  public render(): JSX.Element {
    const columns = [];
    try {
      for (let dayIndex = 1; dayIndex <= 5; dayIndex++) {
        let newsItemOfTheDay;
        let dateOfDay: Date;
        switch (new Date().getDay()) {
          case 0: // Sunday
            switch (dayIndex) {
              case 1:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 1));
                break;
              case 2:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 2));
                break;
              case 3:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 3));
                break;
              case 4:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 4));
                break;
              case 5:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 5));
                break;
            }
            break;
          case 1: // Monday
            switch (dayIndex) {
              case 1:
                dateOfDay = new Date();
                break;
              case 2:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 1));
                break;
              case 3:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 2));
                break;
              case 4:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 3));
                break;
              case 5:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 4));
                break;
            }
            break;
          case 2: // Tuesday
            switch (dayIndex) {
              case 1:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() - 1));
                break;
              case 2:
                dateOfDay = new Date();
                break;
              case 3:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 1));
                break;
              case 4:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 2));
                break;
              case 5:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 3));
                break;
            }
            break;
          case 3: // Wednesday
            switch (dayIndex) {
              case 1:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() - 2));
                break;
              case 2:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() - 1));
                break;
              case 3:
                dateOfDay = new Date();
                break;
              case 4:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 1));
                break;
              case 5:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 2));
                break;
            }
            break;
          case 4: // Thursday
            switch (dayIndex) {
              case 1:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() - 3));
                break;
              case 2:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() - 2));
                break;
              case 3:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() - 1));
                break;
              case 4:
                dateOfDay = new Date();
                break;
              case 5:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 1));
                break;
            }
            break;
          case 5: // Friday
            switch (dayIndex) {
              case 1:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() - 4));
                break;
              case 2:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() - 3));
                break;
              case 3:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() - 2));
                break;
              case 4:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() - 1));
                break;
              case 5:
                dateOfDay = new Date();
                break;
            }
            break;
          case 6: // Saturday
            switch (dayIndex) {
              case 1:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 2));
                break;
              case 2:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 3));
                break;
              case 3:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 4));
                break;
              case 4:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 5));
                break;
              case 5:
                dateOfDay = new Date(new Date().setDate(new Date().getDate() + 6));
                break;
            }
            break;
          default:
            break;
        }
        if (this.props.newsItems?.length > 0) {
          this.props.newsItems.forEach((newsItem: IPlayNewsItem) => {
            if (new Date(newsItem.startDate).getDay() === dayIndex) {
              newsItemOfTheDay = newsItem;
            }
          })
        }
        columns.push(
          <div
            key={`column_${dayIndex}`}
            className={styles.IA_column}
            style={{
              width: "20%",
              padding: this.props.sizeUnit * 2,
              borderRight: dayIndex !== 5 && `1px solid ${this.props.textColor}2e`,
              backgroundColor: new Date().getDay() === dayIndex && (this.props.backgroundImage ? `${this.props.boxColor}e6` : this.props.boxColor),
              height: this.props.headline ? `calc(100% - ${this.props.sizeUnit * 6.5}px)` : "100%"
            }}
          >
            <CanteenMenuItem
              sizeUnit={this.props.sizeUnit}
              textColor={this.props.textColor}
              title={this.props.showTitle && newsItemOfTheDay?.title}
              content={this.props.showText && newsItemOfTheDay?.content}
              startDate={this.props.showTime && newsItemOfTheDay?.startDate}
              endDate={this.props.showTime && newsItemOfTheDay?.endDate}
              location={this.props.showLocation && newsItemOfTheDay?.location}
              dayInMonth={this.dateTimeService.getDayInMonth(dateOfDay)}
              dayOfTheWeek={this.dateTimeService.getWeekday(dateOfDay, this.props.language)}
            />
          </div>
        );
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
    return (
      <div
        id={`IA_List_${this.props.widgetId}_${this.props.index}`}
        className={styles.IA_canteenMenu}
        style={{
          backgroundColor: `${this.props.backgroundColor}`,
          backgroundImage: `url(${this.props.backgroundImage})`
        }}
      >
        <div
          className={styles.IA_canteenMenuOverlay}
          style={{
            backgroundColor: `${this.props.backgroundColor}`,
            opacity: 0.9
          }}
        />
        <div
          style={{
            padding: this.props.sizeUnit * 3,
            height: this.props.headline ? `calc(100% - ${this.props.sizeUnit * 3}px)` : "100%",
            boxSizing: "border-box",
            overflow: "hidden"
          }}
        >
          {this.props.headline &&
            <div
              id={`IA_Headline`}
              className={styles.IA_headline}
              style={{
                fontSize: this.props.sizeUnit * 4,
                marginBottom: this.props.sizeUnit * 2.5,
                color: this.props.headlineColor
              }}
            >
              {this.props.headline}
            </div>
          }
          {columns}
        </div>
      </div >
    );
  }


}