import * as React from 'react';
import ReactQuill from 'react-quill-with-table';
import * as styles from './Reader.css';
import '../../styles/BasicIA.css';
import { Helper } from '../../Helper';
import { IEnvironment } from '../../interfaces/IEnvironment';
import '../editor/froala/FroalaCustomStyling.css';

export interface IReaderProps {
  id?: string;
  content: string;
  useNewEditor: boolean;
  environment?: IEnvironment;
  tenant?: string;
  token?: string;
  style?: React.CSSProperties;
  highlightColor?: string;
}

export class IAReader extends React.Component<IReaderProps> {

  private id: string;

  constructor(props: IReaderProps) {
    super(props);
    this.state = {
    };
    this.id = Helper.getRandomStringKey();
  }

  private addTargetBlankToLinks() {
    const quill = document.getElementById(`reactQuill_${this.id}`);
    if (quill) {
      const links = quill?.getElementsByTagName("a");
      for (let i = 0; i < links?.length; i++) {
        const link = links[i];
        if (link.classList.length === 0 || !link.className?.startsWith("ql-")) {
          link.setAttribute("target", "_blank");
          link.setAttribute("data-interception", "off");
        }
      }
    }
  }

  public render(): JSX.Element {
    const style = this.props.style ?? {};
    return (
      <div
        id={this.props.id}
        className={styles.IA_reader}        
        onMouseOver={() => this.addTargetBlankToLinks()}
      >
        {this.props.useNewEditor ?
          <div
            id={`reactQuill_${this.id}`}
            className={[styles.IA_froala, "fr-wrapper", "fr-element", "fr-view"].join(" ")}
            style={{
              ...style,
              borderWidth: 2,
              ['--highlight-color' as any]: this.props.highlightColor
            }}
            dangerouslySetInnerHTML={{ __html: this.props.content }}
          />
          :
          <ReactQuill
            id={`reactQuill_${this.id}`}
            style={{ ...this.props.style, borderWidth: 1 }}
            className={["IA_quillReadOnlyContainer"].join(" ")}
            value={this.props.content}
            readOnly
            modules={{
              toolbar: false
            }}
          />
        }
      </div >
    );
  }
}