exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_pk7t_aRMMP7_2Y9cfPkPI {\n  position: relative;\n  float: right;\n  height: 20px;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.likesAndComments_pk7t_aRMMP7_2Y9cfPkPI .likes_18n3Kx5K3P5oyS3TB0pDA6 {\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-right: 5px;\n  cursor: pointer;\n}\n\n.likesAndComments_pk7t_aRMMP7_2Y9cfPkPI .spinner_3A3Id8zA1QCer12wTH3F62 {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_sCVUdCTsMkV9VZWZwCF-b 700ms linear infinite;\n  -moz-animation: spin_sCVUdCTsMkV9VZWZwCF-b 700ms linear infinite;\n  animation: spin_sCVUdCTsMkV9VZWZwCF-b 700ms linear infinite;\n}\n\n.likesAndComments_pk7t_aRMMP7_2Y9cfPkPI .comments_14gp9f2w-No-5hsn_02pjc {\n  height: 20px;\n  float: right;\n  position: relative;\n  margin-right: 3px;\n}\n\n.likesAndComments_pk7t_aRMMP7_2Y9cfPkPI .likesText_2qaF8VpjFCheqoXkenek-f {\n  float: left;\n  margin-left: 5px;\n  margin-top: -1px;\n}\n\n.likesAndComments_pk7t_aRMMP7_2Y9cfPkPI .likesTextVertical_3jchtRHdQJCCq-VnN4sYgn {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n\n.likesAndComments_pk7t_aRMMP7_2Y9cfPkPI .commentText_3oiBsKh7OHKuy4iA8GZBb {\n  float: left;\n  margin-left: 4px;\n  margin-top: -2px;\n}\n\n@-moz-keyframes spin_sCVUdCTsMkV9VZWZwCF-b {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_sCVUdCTsMkV9VZWZwCF-b {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_sCVUdCTsMkV9VZWZwCF-b {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_pk7t_aRMMP7_2Y9cfPkPI",
	"likes": "likes_18n3Kx5K3P5oyS3TB0pDA6",
	"spinner": "spinner_3A3Id8zA1QCer12wTH3F62",
	"spin": "spin_sCVUdCTsMkV9VZWZwCF-b",
	"comments": "comments_14gp9f2w-No-5hsn_02pjc",
	"likesText": "likesText_2qaF8VpjFCheqoXkenek-f",
	"likesTextVertical": "likesTextVertical_3jchtRHdQJCCq-VnN4sYgn",
	"commentText": "commentText_3oiBsKh7OHKuy4iA8GZBb"
};