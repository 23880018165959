import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './Profile.css';
import { IATextField } from '../../../../textField/TextField';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAColorPicker } from '../../../../colorPicker/ColorPicker';
import { Footer } from '../../../../player/footer/Footer';
import { IAToggle } from '../../../../toggle/Toggle';
import { IAButton } from '../../../../button/Button';
import PlayService from '../../../../../services/PlayService';
import { MediaFileSelector } from '../../mediaFiles/mediaFileSelector/MediaFileSelector';
import { PlayHelper } from '../../../PlayHelper';
import { IAIcon } from '../../../../icon/Icon';
import { ProfileRssTickerSettings } from './ProfileRssTickerSettings';

export interface IProps {
  showSkeleton: boolean;
  store?: Store;
}

export interface IState {
  width: number;
  profileToDelete: string;
  rssTickerIndexToEdit: number;
}

@inject('store')
@observer
export class Profile extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      width: undefined,
      profileToDelete: undefined,
      rssTickerIndexToEdit: undefined
    }
  }

  public componentDidMount(): void {
    const progressBarElement = document.getElementById("IA_Profile");
    if (progressBarElement) {
      this.setState({ width: progressBarElement.clientWidth });
    }
  }

  public render(): JSX.Element {
    const rssTickers = [];
    this.props.store.profile?.rssTickers?.forEach((ticker, i) => {
      rssTickers.push(
        <div
          key={i}
          className={styles.IA_widgetType}
          style={{
            opacity: this.state.rssTickerIndexToEdit === undefined || this.state.rssTickerIndexToEdit === i ? 1 : 0.5
          }}
          onClick={() => {
            if (this.state.rssTickerIndexToEdit === i) {
              this.setState({ rssTickerIndexToEdit: undefined });
            } else {
              this.setState({ rssTickerIndexToEdit: i });
            }
          }}
        >
          {ticker.labelLogo && ticker.labelLogo !== "none" ?
            <div
              style={{
                height: 65,
                width: 65,
                backgroundColor: ticker.labelBackgroundColor,
                borderRadius: 10,
                overflow: "hidden"
              }}
            >
              <img
                style={{
                  objectFit: "contain",
                  width: "100%"
                }}
                src={ticker.labelLogo}
              />
            </div>
            :
            <IAIcon
              url="https://intraactivestorage.blob.core.windows.net/cdn/icons/RSS/SVG/ic_fluent_rss_24_regular.svg"
              size={41}
              color={ticker.labelColor}
              style={{
                float: "left",
                backgroundColor: ticker.labelBackgroundColor,
                padding: "10px 12px",
                borderRadius: 10
              }}
            />
          }
          <div
            className={styles.IA_widgetTypeLabel}
            style={{
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            {ticker.title}
          </div>
        </div>
      );
    });
    const swatches = [];
    if (this.props.store.profile?.swatches?.length > 0) {
      this.props.store.profile.swatches.forEach((swatch: string, index) => {
        swatches.push(
          <div key={"swatch_" + swatch}>
            <IAColorPicker
              color={swatch}
              defaultColor="#00ffff"
              showClearButton={false}
              onChange={(color: string) => {
                this.props.store.profile.swatches[index] = color;
              }}
              removeColor={() => {
                this.props.store.profile.swatches.splice(index, 1);
              }}
              styles={{
                width: 70,
              }}
            />
          </div>
        );
      });
    }
    return (
      <div 
      className={styles.IA_profile}
      id="IA_Profile"
      >
        <IAButton
          label={this.localizationService.strings.PlayAdmin_Update?.toUpperCase()}
          buttonColor={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onClick={() => {
            this.props.store.workingOnIt = true;
            PlayService.updatePlayProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile).then(() => {
              this.props.store.workingOnIt = false;
              this.props.store.profileHasChanged = false;
              location.reload();
            });
          }}
          borderRadius={5}
          showSpinner={this.props.store.workingOnIt}
          style={{
            float: "right",
            position: "relative",
            marginTop: 7
          }}
        />
        <IATextField
          label={this.localizationService.strings.PlayAdmin_ProfileTitle}
          placeholder={this.localizationService.strings.PlayAdmin_ProfileTitlePlaceholder}
          text={this.props.store.profile?.title}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          labelStyle={{
            textTransform: "uppercase",
            fontSize: 12
          }}
          onChange={(title: string) => {
            this.props.store.profile.title = title;
            this.props.store.profileHasChanged = true;
            this.forceUpdate();
          }}
        />
        <MediaFileSelector
          url={this.props.store.profile?.logoUrl}
          label={this.localizationService.strings.PlayAdmin_ProfileIcon?.toUpperCase()}
          type={"image"}
          onChange={(logoUrl: string) => {
            this.props.store.profile.logoUrl = logoUrl;
            this.props.store.profileHasChanged = true;
            this.forceUpdate();
          }}
        />
        <div
          className={styles.IA_divider}
          style={{
            backgroundColor: this.props.store.darkMode ? "#727272" : "#eeeeee"
          }}
        />
        <>
          <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.localizationService.strings.PlayAdmin_Footer}</h3>
          {this.state.width && this.props.store.profile?.footer &&
            <div style={{
              float: "left",
              clear: "both",
              position: "relative",
              width: "100%",
              height: (this.state.width / 16 * 9) / 12,
              marginTop: 10,
              marginBottom: 20
            }}>
              <Footer
                footer={this.props.store.profile?.footer}
                sizeUnit={(this.state.width / 16 * 9) / 100}
                city={this.props.store.city}
                currentWeather={this.props.store.currentWeather}
                editMode={true}
              />
            </div>
          }
          <IAColorPicker
            label={this.localizationService.strings.PlayAdmin_FooterBackgroundColor}
            color={this.props.store.profile?.footer?.backgroundColor}
            swatches={this.props.store.profile?.swatches}
            toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
            inTeams={this.props.store.isTeams}
            allowAddingToSwatches
            onChange={(backgroundColor: string) => {
              this.props.store.profile.footer.backgroundColor = backgroundColor;
              this.props.store.profileHasChanged = true;
              this.forceUpdate();
            }}
            styles={{
              width: "auto",
              marginRight: 40,
              float: "left",
              clear: "both"
            }}
            labelStyle={{
              textTransform: "uppercase",
              fontSize: 12
            }}
            showClearButton={false}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            addSwatch={(color: string) => this.props.store.addSwatch(color)}
          />
          <IAColorPicker
            label={this.localizationService.strings.PlayAdmin_FooterColor}
            color={this.props.store.profile?.footer?.color}
            swatches={this.props.store.profile?.swatches}
            toolTip={<div>{this.localizationService.strings.PlayAdmin_EditSwatches}</div>}
            inTeams={this.props.store.isTeams}
            allowAddingToSwatches
            onChange={(color: string) => {
              this.props.store.profile.footer.color = color;
              this.props.store.profileHasChanged = true;
              this.forceUpdate();
            }}
            styles={{
              width: "auto"
            }}
            labelStyle={{
              textTransform: "uppercase",
              fontSize: 12
            }}
            showClearButton={false}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            addSwatch={(color: string) => this.props.store.addSwatch(color)}
          />
          <IAToggle
            label={this.localizationService.strings.PlayAdmin_FooterShowLogo}
            labelStyle={{
              textTransform: "uppercase",
              fontSize: 12
            }}
            checked={this.props.store.profile?.footer?.showLogo}
            darkMode={this.props.store.darkMode}
            color={this.props.store.highlightColor}
            style={{
              marginTop: 20,
              width: "100%"
            }}
            onChange={() => {
              this.props.store.profile.footer.showLogo = !this.props.store.profile.footer.showLogo;
              this.props.store.profileHasChanged = true;
            }}
          />
          {this.props.store.profile?.footer?.showLogo &&
            <MediaFileSelector
              url={this.props.store.profile?.footer?.logoUrl}
              label={this.localizationService.strings.PlayAdmin_FooterLogo?.toUpperCase()}
              type={"image"}
              onChange={(logoUrl: string) => {
                // for some reason we have to encode "(" and ")" manually  
                this.props.store.profile.footer.logoUrl = logoUrl ? logoUrl.replace(/\(/g, '%28').replace(/\)/g, '%29') : logoUrl;
                this.props.store.profileHasChanged = true;
                this.forceUpdate();
              }}
            />
          }
          <IAToggle
            label={this.localizationService.strings.PlayAdmin_FooterShowWeather}
            checked={this.props.store.profile?.footer?.showWeather}
            color={this.props.store.highlightColor}
            labelStyle={{
              textTransform: "uppercase",
              fontSize: 12
            }}
            darkMode={this.props.store.darkMode}
            style={{
              marginTop: 20,
              width: "100%"
            }}
            onChange={() => {
              this.props.store.profile.footer.showWeather = !this.props.store.profile.footer.showWeather;
              this.props.store.profileHasChanged = true;
            }}
          />
          <IAToggle
            label={this.localizationService.strings.PlayAdmin_FooterShowClock}
            checked={this.props.store.profile?.footer?.showClock}
            color={this.props.store.highlightColor}
            darkMode={this.props.store.darkMode}
            labelStyle={{
              textTransform: "uppercase",
              fontSize: 12
            }}
            style={{
              marginTop: 20,
              width: "100%"
            }}
            onChange={() => {
              this.props.store.profile.footer.showClock = !this.props.store.profile.footer.showClock;
              this.props.store.profileHasChanged = true;
            }}
          />
        </>
        <div
          className={styles.IA_divider}
          style={{
            backgroundColor: this.props.store.darkMode ? "#727272" : "#eeeeee"
          }}
        />
        <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>{this.localizationService.strings.PlayAdmin_ProfileColors}</h3>
        <IAButton
          label={this.localizationService.strings.PlayAdmin_AddSwatch?.toUpperCase()}
          buttonColor={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onClick={() => {
            if (!this.props.store.profile?.swatches) {
              this.props.store.profile.swatches = [];
            }
            this.props.store.profile?.swatches.push(this.props.store.highlightColor);
          }}
          borderRadius={5}
          style={{
            clear: "both"
          }}
        />
        <div className={styles.IA_swatches}>
          {swatches}
        </div>
        <div
          className={styles.IA_divider}
          style={{
            backgroundColor: this.props.store.darkMode ? "#727272" : "#eeeeee"
          }}
        />
        <h3 style={{ color: this.props.store.darkMode ? "#ffffff" : '#333333' }}>RSS Tickers</h3>
        <IAButton
          label={this.localizationService.strings.PlayAdmin_ProfileAddTicker?.toUpperCase()}
          buttonColor={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onClick={() => {
            const newTicker = PlayHelper.getEmptyRssTicker();
            if (!this.props.store.profile.rssTickers) {
              this.props.store.profile.rssTickers = [newTicker];
            } else {
              this.props.store.profile.rssTickers.push(newTicker);
            }
            this.setState({ rssTickerIndexToEdit: this.props.store.profile.rssTickers.length - 1 });
          }}
          borderRadius={5}
          style={{
            clear: "both"
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            clear: "both",
            float: "left",
            marginTop: 20
          }}
        >
          {rssTickers}
        </div>
        {this.state.rssTickerIndexToEdit !== undefined &&
          <div
            style={{
              clear: "both",
              float: "left"
            }}
          >
            <ProfileRssTickerSettings
              forceUpdate={() => this.forceUpdate()}
              rssTickerIndexToEdit={this.state.rssTickerIndexToEdit}
            />
            <IAButton
              icon='Trash'
              iconColor={this.props.store.highlightColor}
              label={this.localizationService.strings.PlayAdmin_ProfileDeleteTicker?.toUpperCase()}
              textColor={this.props.store.highlightColor}
              darkMode={this.props.store.darkMode}
              onClick={() => {
                this.props.store.profile.rssTickers.splice(this.state.rssTickerIndexToEdit, 1);
                this.setState({ rssTickerIndexToEdit: undefined });
              }}
              borderRadius={5}
              style={{
                marginTop: 15,
                clear: "both"
              }}
            />
          </div>
        }
      </div>
    );
  }

  componentWillUnmount(): void {
    if (this.props.store.profile?.id) {
      PlayService.getPlayProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id).then((profile) => {
        if (profile !== 401 && profile !== 404) {
          this.props.store.profile = profile;
        }
      });
    }
  }

  
}