exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_weatherWidgetContainer_2Im3mTdMg64Rliis6DYngj {\n  height:100%;\n  width:100%;\n  display:flex;\n  flex-direction:row;\n}\n\n.IA_weatherDay_2-7C3GjpwVfU7DyABA66V4 {\n  flex:1;\n  display:flex;\n  flex-direction:column;\n  align-items:center;\n}\n\n.IA_iconAndPrecipationContainer_bjm94gqNRPGQxgNGYjxn3 {\n  width:100%;\n  display:flex;\n  flex-direction:column;\n  align-items:center;\n}\n\n.IA_weatherIcon_2tDSY9frmJGiNVhZm6NZar {\n  width: 45%;\n}\n\n.IA_precipation_2lwSo_lT8xfFp2FdNcXsXh {\n  color:#0062bf;\n  font-weight:bold;\n}\n\n.IA_graphContainer_2L0o8vB0mXNW9DxInrKnhr {\n  width:100%;\n  height:30%;\n  position:absolute;\n  bottom:0;\n  left:0;\n}\n\n.IA_weatherWidgetContainerVertical_31vvIwAf60CNuI5arFDd5a {\n  height:100%;\n  width:100%;\n  background-color:#f0f2f4;\n  display:flex;\n  flex-direction: column;\n}\n\n.IA_weatherDayVertical_46QkqKeuk95klRoGnZzLP {\n  flex:1;\n  display:flex;\n  flex-direction:row;\n  align-items:center;\n  justify-content: space-around;\n}\n\n.IA_exampleWeatherLabel_qH0SpamDP-wnqpkm5-LoY {\n  background-color: #ffffff;\n  color: #333333;\n  position: absolute;\n  text-align: center;\n  transform: rotate(-45deg);\n  box-shadow: rgba(0, 0, 0, 0.2) 0 0 5px 0;\n}", ""]);

// Exports
exports.locals = {
	"IA_weatherWidgetContainer": "IA_weatherWidgetContainer_2Im3mTdMg64Rliis6DYngj",
	"IA_weatherDay": "IA_weatherDay_2-7C3GjpwVfU7DyABA66V4",
	"IA_iconAndPrecipationContainer": "IA_iconAndPrecipationContainer_bjm94gqNRPGQxgNGYjxn3",
	"IA_weatherIcon": "IA_weatherIcon_2tDSY9frmJGiNVhZm6NZar",
	"IA_precipation": "IA_precipation_2lwSo_lT8xfFp2FdNcXsXh",
	"IA_graphContainer": "IA_graphContainer_2L0o8vB0mXNW9DxInrKnhr",
	"IA_weatherWidgetContainerVertical": "IA_weatherWidgetContainerVertical_31vvIwAf60CNuI5arFDd5a",
	"IA_weatherDayVertical": "IA_weatherDayVertical_46QkqKeuk95klRoGnZzLP",
	"IA_exampleWeatherLabel": "IA_exampleWeatherLabel_qH0SpamDP-wnqpkm5-LoY"
};