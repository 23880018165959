import * as React from 'react';
import * as styles from "./NewComment.css";
import "../../../styles/BasicIA.css";
import { Helper } from './../../../Helper';

import { IReply } from '../../../interfaces/IReply';
import { IUser } from '../../../interfaces/IUser';
import { LocalizationService } from '../../../services/LocalizationService';
import { Environment } from '../../../services/Environment';
import { IAEditor } from '../../editor/Index';
import { IAButton } from '../../button/Button';

export interface INewCommentProps {
  useNewEditor: boolean;
  token: string;
  tenant: string;
  environment: "Development" | "Test" | "Production";
  imageUploadUrl: string;
  buttonColor: string;
  reply: IReply;
  addComment: boolean;
  isMobile: boolean;
  dataAutomationIdPrefix?: string;
  comment: (comment: string, mentions: IUser[], reply?: IReply) => Promise<void>;
  cancelReply: () => void;
  newCommentPopupIsShowing: (isShowing: boolean) => void;
  isWriting: () => void;
  user?: any;
  highlightColor: string;
}

export interface INewCommentState {
  editor: any;
  textSelection?: any;
  showLinkEditor: boolean;
  linkEditorRange?: any;
  linkEditorTarget?: any;
  linkEditorUrl?: any;
  linkEditorTitle?: any;
  currentRange?: any;
  showEmojiPicker: boolean;
  showImagePicker: boolean;
  cursorPositionLeft?: number;
  cursorPositionTop?: number;
  url?: string;
  isLoadingPreview: boolean;
  isDragging: boolean;
  toolbarId: string;
}

export class IANewComment extends React.Component<INewCommentProps, INewCommentState> {
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: INewCommentProps) {
    super(props);
    this.state = {
      editor: undefined,
      showLinkEditor: false,
      showEmojiPicker: false,
      showImagePicker: false,
      isLoadingPreview: false,
      isDragging: false,
      toolbarId: Helper.getRandomStringKey()
    };
  }

  public componentWillReceiveProps(props: INewCommentProps): void {
    if (!props.addComment) {
      if (props.reply != undefined && props.reply != this.props.reply) {
        var div = document.getElementById('newComment');
        setTimeout(() => {
          if (div) {
            div.focus();
          };
        }, 0);
      }
      if (props.addComment != undefined && props.addComment != this.props.addComment) {
        var div = document.getElementById('newComment');
        setTimeout(() => {
          if (div) {
            div.focus();
          }
        }, 0);
      }
    }
  }

  public render(): JSX.Element {
    let firstNameChar = this.props.user?.displayName != undefined ? this.props.user?.displayName?.split(" ")[0].charAt(0) : "";
    let lastNameChar = this.props.user?.displayName != undefined ? this.props.user?.displayName?.split(" ")[this.props.user?.displayName?.split(" ").length - 1].charAt(0) : "";
    let toolTipContentElement = document.getElementById("IA_EmojiPicker");
    if (!toolTipContentElement) {
      toolTipContentElement = document.getElementById("IA_LinkEditor");
    }
    if (!toolTipContentElement) {
      toolTipContentElement = document.getElementById("IA_ImagePicker");
    }
    let cursorPositionTop = this.state.cursorPositionTop;
    let cursorPositionLeft = this.state.cursorPositionLeft;
    if (toolTipContentElement) {
      if (cursorPositionTop + toolTipContentElement.clientHeight > window.innerHeight) {
        cursorPositionTop -= toolTipContentElement.clientHeight + 20;
      }
      if (cursorPositionLeft + toolTipContentElement.clientWidth > window.innerWidth) {
        cursorPositionLeft = window.innerWidth - (toolTipContentElement.clientWidth / 2);
      }
    }
    return (
      <div
        className={styles.IA_newComment}
        style={{
          height: "auto",
          marginBottom: this.props.isMobile ? 100 : 20
        }}
      >
        <div>
          <div id="newComment">
            {this.props.reply &&
              <div
                style={{
                  marginBottom: 10
                }}
              >
                {`${this.localizationService.strings.LikesAndComments_ReplyTo} ${this.props.reply.user?.displayName}:`}
              </div>
            }
            <div style={{ display: this.props.addComment ? "" : "none" }}>
              <div>
                <div
                  className={styles.IA_commentProfileImageWrapper}
                  style={{
                    backgroundColor: this.props.highlightColor != undefined ? this.props.highlightColor : "#333333",
                    color: this.props.highlightColor != undefined ? Helper.getTextColorBasedOnBackgroundColorDarkness(this.props.highlightColor, "#ffffff", "#333333") : "#ffffff"
                  }}
                >
                  <div className={styles.IA_commentProfileImageText}>
                    {firstNameChar + lastNameChar}
                  </div>
                  <div
                    className={styles.IA_commentProfileImagePicture}
                    style={{
                      backgroundImage: `url('${Helper.getProfileImageUrlFromImageAPI(Environment.getEnvironmentForImageAPI(this.props.environment),
                        this.props.tenant, this.props.token, this.props.user?.userPrincipalName)}')`
                    }}
                  />
                </div>
                <div style={{
                  float: "left",
                  width: "calc(100% - 45px)",
                  padding: "5px 0px 5px 5px",
                  backgroundColor: "#ffffff",
                  marginTop: this.props.useNewEditor ? 0 : 8,
                  borderRadius: 10
                }}
                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    event.stopPropagation();
                  }}
                >
                  <IAEditor
                    useImageAPI
                    token={this.props.token}
                    tenant={this.props.tenant}
                    environment={this.props.environment}
                    highlightColor={this.props.highlightColor}
                    isMobile={this.props.isMobile}
                    content={this.state.editor}
                    onChange={(editor) => this.setState({ editor })}
                    placeholder={this.localizationService.strings.CommentsAndLikes_AddComment}
                    toolbarClassName={styles.IA_newCommentToolbar}
                    quillClassName={styles.IA_newCommentQuill}
                    simple
                    hideToolbox
                    fontSizeSupport={false}
                    fontStyleSupport={false}
                    listsSupport={false}
                    textAlignmentSupport={false}
                    textColorSupport={false}
                    clearStylingSupport={false}
                    emojiSupport
                    linkSupport={false}
                    imageSupport={true}
                    videoSupport={false}
                    tableSupport={false}
                    boxSupport={false}
                    htmlSupport={false}
                    quoteSupport={false}
                    fontFamilySupport={false}
                    paragraphStyleSupport={false}
                    indentSupport={false}
                    fontStyleAdditionalSupport={false}
                    uploadImagesToSharepoint
                  />
                </div>
              </div>
            </div>
            <div className={styles.IA_postBtn}>
              <IAButton
                label={this.localizationService.strings.LikesAndComments_NewComment_AddComment}
                buttonColor={this.props.buttonColor}
                textColor={Helper.getTextColorBasedOnBackgroundColorDarkness(this.props.buttonColor, "#ffffff", "#333333")}
                disbaled={!this.state.editor || this.state.editor === ""}
                data-automation-id={`${this.props.dataAutomationIdPrefix}-new`}
                borderRadius={5}
                onClick={() => {
                  // Mentions
                  let mentions: IUser[] = [];
                  var contentDiv: any = document.createElement('div');
                  contentDiv.innerHTML = this.state.editor;
                  contentDiv.innerHTML = contentDiv.innerHTML.replace(new RegExp(`"`, 'g'), `'`);
                  contentDiv.innerHTML = contentDiv.innerHTML.replace(new RegExp(`//www.y`, 'g'), `https://www.y`);
                  let mentionElements = contentDiv.getElementsByClassName("ia_mention");
                  for (let i = 0; i < mentionElements.length; i++) {
                    mentions.push(
                      {
                        userPrincipalName: mentionElements[i].getAttribute("id").toLowerCase(),
                        displayName: mentionElements[i].innerText
                      }
                    );
                  }
                  // Images
                  let images = contentDiv.getElementsByTagName("img");
                  for (let i = 0; i < images.length; i++) {
                    let src = images[i].src;
                    if (src.indexOf("intraactive-image-functions") != -1) {
                      let sharepointImageUrl = decodeURIComponent(src.split("path=")[1]);
                      contentDiv.getElementsByTagName("img")[i].src = sharepointImageUrl;
                    }
                  }
                  let activeElement: any = document.activeElement;
                  activeElement.blur();
                  this.props.newCommentPopupIsShowing(false);
                  this.props.comment(contentDiv.innerHTML, mentions, this.props.reply).then(() => {
                    this.setState({ editor: "" })
                  });
                }}
              />
            </div>
            {this.props.addComment &&
              <div className={styles.IA_cancelBtn}>
                {(this.state.editor || this.props.reply) &&
                  <IAButton
                    label={this.localizationService.strings.LikesAndComments_NewComment_Cancel}
                    buttonColor={"gray"}
                    textColor={"white"}
                    data-automation-id={`${this.props.dataAutomationIdPrefix}-cancel`}
                    onClick={() => {
                      this.setState({ editor: "" });
                      this.props.newCommentPopupIsShowing(false);
                      let activeElement: any = document.activeElement;
                      activeElement.blur();
                      this.props.cancelReply();
                    }}
                    borderRadius={5}
                  />
                }
              </div>
            }
          </div>
        </div>
      </div >
    );
  }
}