import { Helper } from "../Helper";
import { IEventTelemetry, IExceptionTelemetry, ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Environment } from "./Environment";
import '../global';
const PACKAGE = require("../../package.json");

export class LoggingService {

  public static init (environment: "Development" | "Test" | "Production"): void {
    window.AppInsights = new ApplicationInsights({
      config: {
        instrumentationKey: Environment.getApplicationInsightsKey(environment),
        loggingLevelConsole: 0,
        loggingLevelTelemetry: 0,
        disableAjaxTracking: true,
        disableExceptionTracking: true,
        disableFetchTracking: true
      }
    });
    window.AppInsights.loadAppInsights();
    if (environment === "Development") {
      window.AppInsights.addTelemetryInitializer((item) => {
        console.log("addTelemetryInitializer", item);
        true;
      });
      window.AppInsights.addDependencyInitializer((item) => {
        console.log("addDependencyInitializer", item);
        true;
      });
      window.AppInsights.addDependencyListener((item) => {
        console.log("addDependencyListener", item);
      });
    }
  }

  public static trackException(component: string, action: string, error: Error, instanceName?: string): void {
    const exception: IExceptionTelemetry = {
      error: error,
      exception: error,
      properties: {
        Component: component,
        ActionType: action,
        Tenant: location.host,
        Instance: instanceName,
        UserId: Helper.getCookieValue("token")
      }
    };
    if (window.AppInsights) {
      window.AppInsights.trackException(exception);
    }
    console.error(error);
  }

  public static trackEvent(component: string, properties: any): void {
    const event: IEventTelemetry = {
      name: component,
      properties: { ...properties, SdkUIVersion: PACKAGE?.version }
    };
    window.AppInsights?.trackEvent(event);
  }
}