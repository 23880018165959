import * as React from 'react';
import { Helper } from '../../../../Helper';
import { IEnvironment } from '../../../../interfaces/IEnvironment';
import { IPlayBoxStyle, IPlayNewsItem, IPlayWidgetAreaType, IPlayTransitionType, IPlayWidgetType } from '../../../../interfaces/IPlay';
import { LocalizationService } from '../../../../services/LocalizationService';
import { IAButton } from '../../../button/Button';
import * as styles from './Rotator.css';
import { RotatorItem } from './rotatorItem/RotatorItem';
import { PlayHelper } from '../../../playAdmin/PlayHelper';

export interface IProps {
  widgetId: string;
  widgetType: IPlayWidgetType;
  width: number;
  height: number;
  playerWidth: number;
  playerHeight: number;
  linkPortalURL?: string;
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  transition: IPlayTransitionType;
  newsItems: IPlayNewsItem[];
  duration?: number;
  boxStyle?: IPlayBoxStyle;
  colorThemes?: { textColor: string, backgroundColor: string }[];
  editPreviewMode?: boolean;
  areaType: IPlayWidgetAreaType;
  showQRLink?: boolean;
  showTitle?: boolean;
  showText?: boolean;
  showAuthor?: boolean;
  showDate?: boolean;
  showProgressBar?: boolean;
  showImage?: boolean;
  showVideo?: boolean;
  showGallery?: boolean;
  showTime?: boolean;
  showLocation?: boolean;
  showTimeBox?: boolean;
  isMessages?: boolean;
  language?: string;
  articleEditMode?: boolean;
  noContentImage?: string;
  excludeImagesFromImageAPI?: boolean;
  header?: string;
  widgetTitleIsShowing?: boolean;
  headerTitle?: string;
  headerSubtitle?: string;
  headerImage?: string;
  willRestart: () => void;
}

export interface IState {
  activeIndex: number;
  marginLeft: number;
  stopAutoRun: boolean;
  contentPositions: any[];
  colorThemes: { textColor: string, backgroundColor: string }[];
  visible: boolean;
}

export class Rotator extends React.Component<IProps, IState> {

  private _isMounted: boolean;
  private readonly localizationService: LocalizationService;

  constructor(props: IProps) {
    super(props);
    this.state = {
      activeIndex: 0,
      marginLeft: 0,
      stopAutoRun: false,
      contentPositions: [],
      colorThemes: undefined,
      visible: true
    };
    this.localizationService = new LocalizationService(PlayHelper.getLocalizerLanguageFromPlayerLanguage(this.props.language));
  }

  public componentDidMount(): void {
    this._isMounted = true;
    this.updateDesign();
    if (this.props.newsItems == undefined || this.props.newsItems?.length === 0) {
      setTimeout(() => {
        this.props.willRestart();
      }, 5000);
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.editPreviewMode || this.state.colorThemes == undefined) {
      this.updateDesign();
    }
    if (props.width != this.props.width) {
      this.forceUpdate();
    }
  }

  private nextRotatorItem(): void {
    try {
      if (this._isMounted && !this.state.stopAutoRun) {
        if (this.props.newsItems) {
          if (this.state.activeIndex < this.props.newsItems.length - 1) {
            this.setState({ activeIndex: this.state.activeIndex + 1 });
          } else {
            this.props.willRestart();
            this.setState({ activeIndex: 0, marginLeft: 0 });
          }
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private updateDesign(): void {
    try {
      const contentPositions = [];
      const colorThemes: { textColor: string, backgroundColor: string }[] = [];
      if (this.props.newsItems?.length > 0) {
        this.props.newsItems.forEach(() => {
          if (this.props.editPreviewMode) {
            contentPositions.push("right");
          } else {
            contentPositions.push(Helper.getRandomNumberBetween(1, 2) === 1 ? "left" : "right");
          }
          const colorTheme: { textColor: string, backgroundColor: string } = this.props.colorThemes?.length > 0 && this.props.colorThemes[Helper.getRandomNumberBetween(0, this.props.colorThemes?.length)];
          colorThemes.push(colorTheme);
        })
        this.setState({ contentPositions, colorThemes: colorThemes });
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  public render(): JSX.Element {
    const sizeUnit = (this.props.playerHeight < this.props.playerWidth ? this.props.playerHeight : this.props.playerWidth) / 100;
    const newsItems: JSX.Element[] = [];
    let newsItemsCount: number;
    let showNoContentImage;
    let noContentImage;
    try {
      if (this.props.newsItems?.length > 0) {
        newsItemsCount = this.props.newsItems.length;
      }

      // Add news items if there are any
      if (this.props.newsItems?.length > 0) {
        this.props.newsItems.forEach((newsItem: IPlayNewsItem, index) => {
          if (newsItem) {
            // Removing inline style
            let content = newsItem.content;
            if (!content) {
              content = "";
            }
            let div = document.createElement("div");
            div.innerHTML = content;
            const childNodes = div?.childNodes;
            if (childNodes?.length > 0) {
              for (var i = 0; i < childNodes.length; i++) {
                let child: any = childNodes[i];
                try {
                  child.removeAttribute("style");
                } catch (error) {
                }
                if (child?.childNodes?.length > 0) {
                  for (var j = 0; j < child?.childNodes?.length; j++) {
                    let grandChild: any = child?.childNodes[j];
                    if (grandChild?.attributes?.length > 0) {
                      try {
                        if (!grandChild?.className?.includes("fr-emoticon")) {
                          grandChild.removeAttribute("style");
                        } else {
                          grandChild.style.minWidth = `${sizeUnit * 2.9}px`;
                          grandChild.style.minHeight = `${sizeUnit * 2.9}px`;
                          grandChild.style.backgroundRepeat = `no-repeat`;
                          grandChild.style.display = `inline-block`;
                          grandChild.style.backgroundPosition = `center`;
                          grandChild.style.backgroundSize = `contain`;
                        }
                      } catch (error) {
                      }
                    }
                  }
                }
              }
            }
            content = div.innerHTML;
            let boxStyle = newsItem.boxStyle ? newsItem.boxStyle : this.props.boxStyle;
            if (boxStyle === "onlyImage" || boxStyle === "onlyVideo") {
              boxStyle = "onlyMedia";
            }
            if (!newsItem.content && !newsItem.title) {
              boxStyle = "onlyMedia";
            }
            newsItems.push(
              <div
                key={`newsItem_${index}`}
                className={this.props.transition === "slide" ? styles.IA_imageWithSlidingTransition : styles.IA_image}
                style={{
                  opacity: this.props.transition === "slide" || index === this.state.activeIndex ? 1 : 0,
                  transition: this.props.transition === "crossfade" ? "opacity 500ms ease-in-out" : "none",
                  width: this.props.transition === "slide" ? `${100 / newsItemsCount}%` : "100%",
                  willChange: this.props.transition === "slide" ? "width" : "opacity"
                }}
              >
                <RotatorItem
                  sizeUnit={sizeUnit}
                  title={this.props.showTitle && newsItem.title}
                  text={this.props.showText ? content : undefined}
                  image={this.props.showImage && newsItem.image ? newsItem.image : undefined}
                  width={this.props.width}
                  height={this.props.height}
                  playerWidth={this.props.playerWidth}
                  playerHeight={this.props.playerHeight}
                  active={index === this.state.activeIndex}
                  standalone={this.props.newsItems?.length === 1}
                  date={this.props.showDate && newsItem.date}
                  startDate={newsItem.startDate}
                  endDate={newsItem.endDate}
                  isAllDayEvent={newsItem.isAllDayEvent}
                  location={this.props.showLocation && newsItem.location}
                  author={this.props.showAuthor && newsItem.author ? newsItem.author : undefined}
                  boxStyle={boxStyle}
                  colorTheme={newsItem.colorTheme ? newsItem.colorTheme : this.state.colorThemes && this.state.colorThemes[index]}
                  video={this.props.showVideo && newsItem.video != undefined ? newsItem.video : undefined}
                  gallery={this.props.showGallery && newsItem.gallery?.length > 0 ? newsItem.gallery : undefined}
                  contentVerticalPosition={newsItem.contentVerticalPosition}
                  link={this.props.showQRLink ? newsItem.link : undefined}
                  linkText={this.props.showQRLink ? newsItem.linkText : undefined}
                  duration={this.props.duration}
                  areaType={this.props.areaType}
                  showTimeBox={this.props.showTimeBox}
                  showTime={this.props.showTime}
                  showProgressBar={this.props.showProgressBar}
                  widgetTitleIsShowing={this.props.widgetTitleIsShowing}
                  headerImage={this.props.headerImage ? this.props.headerImage : `https://intraactivestorage.blob.core.windows.net/cdn/play/widgets/${PlayHelper.getIconForWidgetType(this.props.widgetType)}.png`}
                  headerTitle={this.props.headerTitle}
                  headerSubtitle={newsItem.metaData ? newsItem.metaData : this.props.headerSubtitle}
                  boxStyleWidth={newsItem.boxStyleWidth}
                  boxStyleRoundCorner={newsItem.boxStyleRoundCorner}
                  environment={this.props.environment}
                  profileId={this.props.profileId}
                  tenantId={this.props.tenantId}
                  language={this.props.language}
                  keepOriginalImage={newsItem.keepOriginalImage}
                  articleEditMode={this.props.articleEditMode}
                  excludeImageFromImageAPI={this.props.excludeImagesFromImageAPI}
                  contentPosition={newsItem.contentPosition}
                  nextRotatorItem={() => this.nextRotatorItem()}
                  willRestart={() => this.props.willRestart()}
                />
              </div>
            );
          }
        });
      }
      showNoContentImage = this.props.newsItems == undefined || this.props.newsItems?.length === 0;
      noContentImage = this.props.noContentImage ? this.props.noContentImage : this.localizationService.strings.Player_NoContentUrl
      if (!this.props.noContentImage && this.props.editPreviewMode && this.props.isMessages) {
        noContentImage = "https://intraactivestorage.blob.core.windows.net/cdn/play/No_messages_to_display_yet_EN.jpg";
        if (showNoContentImage) {
          if (this.localizationService.getLanguageCode() === "da-DK") {
            noContentImage = "https://intraactivestorage.blob.core.windows.net/cdn/play/No_messages_to_display_yet_DA.jpg";
          }
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
    return this.state.visible ? (
      <>
        <div
          id="IA_NewsViewer"
          className={styles.IA_rotator}
          style={{
            width: this.props.transition === "slide" ? `${newsItemsCount * 100}%` : "100%",
            left: this.props.transition === "slide" ? `${-100 * this.state.activeIndex}%` : 0
          }}
        >
          {showNoContentImage === true ?
            <img
              style={{
                width: this.props.playerWidth,
                height: this.props.playerHeight,
                objectFit: "cover"
              }}
              src={noContentImage}
            />
            :
            newsItems
          }

        </div>
        {this.props.newsItems?.length && this.props.editPreviewMode && this.props.newsItems.length > 1 &&
          <div
            id={`IA_dots_${this.props.widgetId}`}
            className={styles.IA_dots}
          >
            <IAButton
              iconColor={"#fc8a00"}
              iconUrl={"https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Left/SVG/ic_fluent_chevron_left_48_regular.svg"}
              style={{
                marginTop: 2,
                float: "left",
                opacity: this.state.activeIndex === 0 ? 0.3 : 1
              }}
              onClick={() => {
                if (this.state.activeIndex !== 0) {
                  this.setState({ activeIndex: this.state.activeIndex - 1, stopAutoRun: true });
                }
              }}
            />
            <div
              style={{
                fontSize: "12px",
                float: "left",
                textAlign: "center",
                width: 40,
                lineHeight: "30px"
              }}
            >
              {`${this.state.activeIndex + 1} / ${this.props.newsItems.length}`}
            </div>
            <IAButton
              iconColor={"#fc8a00"}
              iconUrl="https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Right/SVG/ic_fluent_chevron_right_48_regular.svg"
              style={{
                marginTop: 2,
                float: "right",
                opacity: this.state.activeIndex === this.props.newsItems?.length - 1 ? 0.3 : 1
              }}
              onClick={() => {
                if (this.state.activeIndex !== this.props.newsItems?.length - 1) {
                  this.setState({ activeIndex: this.state.activeIndex + 1, stopAutoRun: true });
                }
              }}
            />
            {/* } */}
          </div>
        }
      </>
    ) : (<></>);
  }

  public componentWillUnmount(): void {
    this._isMounted = false;
  }
}