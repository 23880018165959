import { Helper } from "./Helper";

const requestAzureAuthApiPath: string = '/api/Authentication/requestazureauth';
const authenticateApiPath: string = '/api/authentication/authenticate';
const sharepointAppAuthApiPath: string = '/api/authentication/sharepoint-authenticate';

export class AuthenticationRedirectionService {

  public constructor(private readonly apiUrl: string) {
    apiUrl = Helper.stringShouldHaveNoTrailingSlash(apiUrl);
  }

  public generateUrl(tenantName: string, returnUrl: string, usernameHint: string): string {
    const loginHint = usernameHint ? '&loginHint=' + encodeURIComponent(usernameHint) : '';
    return `${this.apiUrl}${requestAzureAuthApiPath}?tenantRef=${Helper.ensureTenantIsSharePointRef(tenantName)}&returnUrl=${this.apiUrl}${authenticateApiPath}?returnUrl=${encodeURIComponent(returnUrl)}&authtype=userconsent${loginHint}`;
  }

  public generateSharepointAppAuthUrl(tenantName: string, returnUrl: string): string {
    return `${this.apiUrl}${sharepointAppAuthApiPath}?tenantRef=${Helper.ensureTenantIsSharePointRef(tenantName)}&returnUrl=${encodeURIComponent(returnUrl)}`;
  }

  public authenticate(tenantName?: string, returnUrl?: string, usernameHint?: string): void {
    window.location.href = this.generateUrl(tenantName || Helper.getTenant(), returnUrl || window.location.href, usernameHint || '');
  }

  public authenticateInSharepoint(tenantName?: string, returnUrl?: string): void {
    window.location.href = this.generateSharepointAppAuthUrl(tenantName || Helper.getTenant(), returnUrl || window.location.href);
  }
}