import * as React from 'react';
import * as styles from './Postcard.css';
import { Image } from '../../../image/Image';
import { IEnvironment } from '../../../../../../interfaces/IEnvironment';
import { IAReader } from '../../../../../reader/Reader';
import { Video } from '../../../video/Video';
import { PlayHelper } from '../../../../../playAdmin/PlayHelper';
import { QRCode } from '../QRCode/QRCode';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import Moment from 'react-moment';

export interface IProps {
  title: string;
  text: string;
  image: string;
  video: string;
  link: string;
  linkText: string;
  date?: string;
  colorTheme: { textColor: string, backgroundColor: string };
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  active: boolean;
  excludeImageFromImageAPI: boolean;
  width: number;
  height: number;
  sizeUnit: number;
  language?: string;
  headerImage?: string;
  headerTitle?: string;
  headerSubtitle?: string;
  author?: string;
  isVertical?: boolean;
}

export interface IState {
  imageHasFailed?: boolean;
}

export class Postcard extends React.Component<IProps, IState> {


  private localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public componentDidMount(): void {
  }

  public render(): JSX.Element {
    const padding = this.props.isVertical ? this.props.sizeUnit * 3 : this.props.sizeUnit * 2.5;
    return (
      <div
        className={styles.IA_postcard}
        style={{
          backgroundColor: this.props.colorTheme.backgroundColor,
          padding: padding,
        }}
      >
        <div
          className={styles.IA_postcardContent}
          style={{
            width: (this.props.width - (padding * 2)),
            height: (this.props.height - (padding * 2)),
            borderRadius: `${this.props.sizeUnit * 2}px`
          }}
        >
          {!this.props.isVertical ?
            <>
              <div
                className={styles.IA_imageContainer}
                style={{
                  width: ((this.props.width - (padding * 2)) * 0.6) - padding,
                  height: (this.props.height - (padding * 2)) * 0.9,
                  marginTop: padding,
                  marginLeft: padding
                }}
              >
                {this.props.image &&
                  <Image
                    imageUrl={this.props.image}
                    height="100%"
                    width="100%"
                    environment={this.props.environment}
                    profileId={this.props.profileId}
                    tenantId={this.props.tenantId}
                    keepOriginal={true}
                    isActive={this.props.active}
                    excludeFromImageAPI={this.props.excludeImageFromImageAPI}
                    onError={() => {
                      console.log(`${this.props.image} failed loading`);
                      this.setState({ imageHasFailed: true });
                    }}
                    onLoad={() => {
                      this.setState({ imageHasFailed: false });
                    }}
                  />
                }
                {this.props.video &&
                  <Video
                    type={"video"}
                    videoUrl={PlayHelper.getVideoFromIntranetApiIfVideoIsFromSharepoint(this.props.environment, this.props.video)}
                    width={"100%"}
                    height={"100%"}
                    marginLeft={0}
                    marginTop={0}
                    playerHeight={this.props.height}
                    playerWidth={this.props.width}
                    showTitle={false}
                    title={undefined}
                    environment={this.props.environment}
                    profileId={this.props.profileId}
                    tenantId={this.props.tenantId}
                    willRestart={() => { }}
                  // onDuration={(duration: number) => {
                  //   const textDuration = this.getTextDuration();
                  //   if (duration > textDuration) {
                  //     this.setState({ duration });
                  //   } else {
                  //     this.setState({ duration: textDuration });
                  //   }
                  //   this.startIfReady();
                  //   setTimeout(() => {
                  //     this.nextRotatorItem();
                  //   }, this.state.duration * 1000);
                  // }}
                  />
                }
              </div>
              <div
                className={styles.IA_textContainer}
                style={{
                  width: ((this.props.width - (padding * 2)) * 0.4) - (padding * 2),
                  height: (this.props.height - (padding * 2)) * 0.9,
                  marginTop: padding,
                  marginRight: padding,
                  marginLeft: padding
                }}
              >
                <div
                  className={styles.IA_header}
                  style={{
                    marginBottom: this.props.sizeUnit * 6,
                    marginTop: this.props.sizeUnit * 2
                  }}
                >
                  <div
                    className={styles.IA_headerImage}
                    style={{
                      width: padding * 1.3,
                      height: padding * 1.3,
                      borderRadius: padding * 1.3,
                      backgroundColor: this.props.colorTheme.backgroundColor,
                      backgroundImage: this.props.headerImage ? `url("${this.props.headerImage}")` : undefined
                    }}
                  />
                  <div
                    className={styles.IA_headerTitle}
                    style={{
                      marginLeft: this.props.sizeUnit,
                      fontSize: `${this.props.sizeUnit * 1.4}px`,
                      width: `calc(100% - ${(this.props.sizeUnit) + (padding * 1.3)}px)`,
                      marginTop: this.props.headerSubtitle ? this.props.sizeUnit * 0.03 : this.props.sizeUnit * 0.8
                    }}
                  >
                    {this.props.headerTitle}
                  </div>
                  <div
                    className={styles.IA_headerSubtitle}
                    style={{
                      marginLeft: this.props.sizeUnit * 1,
                      fontSize: `${this.props.sizeUnit * 1.4}px`,
                      width: `calc(100% - ${(this.props.sizeUnit * 1) + (padding * 1.3)}px)`
                    }}
                  >
                    {this.props.headerSubtitle}
                  </div>
                </div>
                <div
                  className={styles.IA_title}
                  style={{
                    fontSize: `${this.props.sizeUnit * 2}px`,
                    marginTop: this.props.sizeUnit * 2,
                  }}
                >
                  {this.props.title}
                </div>
                {(this.props.author || this.props.date) &&
                  <>
                    <div
                      className={styles.IA_author}
                      style={{
                        fontSize: `${this.props.sizeUnit * 1.2}px`,
                        marginTop: this.props.sizeUnit * 1.4,
                        opacity: 0.7
                      }}
                    >
                      {this.props.author ? `${this.localizationService.strings.Player_By} ${this.props.author}` : ""}
                      {this.props.date &&
                        <>
                          {this.props.author &&
                            <span style={{
                              paddingRight: this.props.sizeUnit * 1.2,
                              paddingLeft: this.props.sizeUnit * 1.2
                            }}
                            >
                              |
                            </span>
                          }
                          <Moment fromNow locale={PlayHelper.getMomentJSLanguageCode(this.props.language)}>{this.props.date}</Moment>
                        </>
                      }
                    </div>
                  </>
                }
                <IAReader
                  useNewEditor
                  content={this.props.text ? this.props.text : ""}
                  style={{
                    position: "relative",
                    fontSize: `${this.props.sizeUnit * 1.4}px`,
                    lineHeight: `${this.props.sizeUnit * 2}px`,
                    borderWidth: 1,
                    marginTop: this.props.sizeUnit * 1.4,
                  }}
                />
                {this.props.link &&
                  <QRCode
                    link={this.props.link}
                    linkText={this.props.linkText}
                    sizeUnit={this.props.sizeUnit}
                    language={this.props.language}
                    backgroundColor={"#ffffff"}
                    textColor={"#333333"}
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#333333",
                      border: `1px solid ${this.props.colorTheme.backgroundColor}`,
                      bottom: 0,
                      right: 0
                    }}
                  />
                }
              </div>
            </>
            :
            <>
              <div
                className={styles.IA_imageContainer}
                style={{
                  width: (this.props.width - (padding * 2)) - (2 * padding),
                  height: (this.props.height - (padding * 2)) * 0.5,
                  marginTop: padding,
                  marginLeft: padding
                }}
              >
                {this.props.image &&
                  <Image
                    imageUrl={this.props.image}
                    height="100%"
                    width="100%"
                    environment={this.props.environment}
                    profileId={this.props.profileId}
                    tenantId={this.props.tenantId}
                    keepOriginal={true}
                    isActive={this.props.active}
                    excludeFromImageAPI={this.props.excludeImageFromImageAPI}
                    onError={() => {
                      console.log(`${this.props.image} failed loading`);
                      this.setState({ imageHasFailed: true });
                    }}
                    onLoad={() => {
                      this.setState({ imageHasFailed: false });
                    }}
                  />
                }
                {this.props.video &&
                  <Video
                    type={"video"}
                    videoUrl={PlayHelper.getVideoFromIntranetApiIfVideoIsFromSharepoint(this.props.environment, this.props.video)}
                    width={"100%"}
                    height={"100%"}
                    marginLeft={0}
                    marginTop={0}
                    playerHeight={this.props.height}
                    playerWidth={this.props.width}
                    showTitle={false}
                    title={undefined}
                    environment={this.props.environment}
                    profileId={this.props.profileId}
                    tenantId={this.props.tenantId}
                    willRestart={() => { }}
                  // onDuration={(duration: number) => {
                  //   const textDuration = this.getTextDuration();
                  //   if (duration > textDuration) {
                  //     this.setState({ duration });
                  //   } else {
                  //     this.setState({ duration: textDuration });
                  //   }
                  //   this.startIfReady();
                  //   setTimeout(() => {
                  //     this.nextRotatorItem();
                  //   }, this.state.duration * 1000);
                  // }}
                  />
                }
              </div>
              <div
                className={styles.IA_textContainer}
                style={{
                  width: ((this.props.width - (padding * 2))) - (padding * 2),
                  height: (this.props.height - (padding * 2)) * 0.4,
                  marginTop: padding,
                  marginRight: padding,
                  marginLeft: padding
                }}
              >
                <div
                  className={styles.IA_header}
                  style={{
                    marginBottom: this.props.sizeUnit * 6,
                    marginTop: this.props.sizeUnit * 2
                  }}
                >
                  <div
                    className={styles.IA_headerImage}
                    style={{
                      width: padding * 2.2,
                      height: padding * 2.2,
                      borderRadius: padding * 2.2,
                      backgroundColor: this.props.colorTheme.backgroundColor,
                      backgroundImage: this.props.headerImage ? `url("${this.props.headerImage}")` : undefined
                    }}
                  />
                  <div
                    className={styles.IA_headerTitle}
                    style={{
                      marginLeft: this.props.sizeUnit,
                      fontSize: `${this.props.sizeUnit * 2.4}px`,
                      width: `calc(100% - ${(this.props.sizeUnit) + (padding * 2.2)}px)`,
                      marginTop: this.props.headerSubtitle ? this.props.sizeUnit * 0.03 : this.props.sizeUnit * 0.8
                    }}
                  >
                    {this.props.headerTitle}
                  </div>
                  <div
                    className={styles.IA_headerSubtitle}
                    style={{
                      marginLeft: this.props.sizeUnit * 1,
                      fontSize: `${this.props.sizeUnit * 2.4}px`,
                      width: `calc(100% - ${(this.props.sizeUnit * 1) + (padding * 2.2)}px)`
                    }}
                  >
                    {this.props.headerSubtitle}
                  </div>
                </div>
                <div
                  className={styles.IA_title}
                  style={{
                    fontSize: `${this.props.sizeUnit * 3.8}px`,
                    marginTop: this.props.sizeUnit * 2,
                  }}
                >
                  {this.props.title}
                </div>
                {(this.props.author || this.props.date) &&
                  <>
                    <div
                      className={styles.IA_author}
                      style={{
                        fontSize: `${this.props.sizeUnit * 2.2}px`,
                        marginTop: this.props.sizeUnit * 1.3,
                        opacity: 0.7
                      }}
                    >
                      {this.props.author ? `${this.localizationService.strings.Player_By} ${this.props.author}` : ""}
                      {this.props.date &&
                        <>
                          {this.props.author &&
                            <span style={{
                              paddingRight: this.props.sizeUnit * 1.2,
                              paddingLeft: this.props.sizeUnit * 1.2
                            }}
                            >
                              |
                            </span>
                          }
                          <Moment fromNow locale={PlayHelper.getMomentJSLanguageCode(this.props.language)}>{this.props.date}</Moment>
                        </>
                      }
                    </div>
                  </>
                }
                <IAReader
                  useNewEditor
                  content={this.props.text ? this.props.text : ""}
                  style={{
                    position: "relative",
                    fontSize: `${this.props.sizeUnit * 2.4}px`,
                    lineHeight: `${this.props.sizeUnit * 3.3}px`,
                    borderWidth: 1,
                    marginTop: this.props.sizeUnit * 2,
                  }}
                />
                {this.props.link &&
                  <QRCode
                    link={this.props.link}
                    linkText={this.props.linkText}
                    sizeUnit={this.props.sizeUnit * 1.5}
                    language={this.props.language}
                    backgroundColor={"#ffffff"}
                    textColor={"#333333"}
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#333333",
                      border: `1px solid ${this.props.colorTheme.backgroundColor}`,
                      top: -padding * 6,
                      right: 0
                    }}
                  />
                }
              </div>
            </>
          }
        </div>
      </div >
    );
  }

}