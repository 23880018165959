
import { FetchPromise } from "./FetchPromise";
import { Container } from '../Containers/Container';
import { SharePointClientService } from '../SharePointClientService';
import { IAadHttpClientConfigurations } from '../Aad/IAadHttpClientConfigurations';
import { MsalClientService } from '../MsalClientService';
import { IntraActiveAppClientService } from '../IntraActiveAppClientService';
import { IntraActiveTokenService } from "../IntraActiveTokenService";
import { Helper } from "../Helper";

const AUTH_VERSION_HEADER: string = "x-intraactive-auth-version";
const AUTH_VERSION_HEADER_VALUE: string = "v1.0";
const INTRAACTIVE_TOKEN_HEADER: string = "authtoken";
const TOKEN_COOKIE_NAME: string = "token";

export type onKnownHttpErrorMethodType = ((errorText: string, response: Response) => void);

export interface IFetchOptions {
  container?: Container;
  onReAuthentication?: ((value: Response) => void);
  onKnownError?: onKnownHttpErrorMethodType;
  isRequestRetried?: boolean;
}

export class Fetch {
  private readonly promise: Promise<Response>;
  private wellKnownHttpError: () => Promise<any>;
  private static readonly configurations: IAadHttpClientConfigurations = { v1: {} };
  public constructor(input: RequestInfo, init?: RequestInit, public readonly options?: IFetchOptions) {
    const isRecentVersionOfSdkHelper: boolean = !!options.container.isRegistered; // Add incremental API behind a flag to make sure we can use it
    
    // SharePoint authentication
    if(isRecentVersionOfSdkHelper && options.container.isRegistered(SharePointClientService)){
      this.promise = new Promise<Response>((resolve, reject) => {
        const spClientService = options.container.resolve(SharePointClientService);

        let headers = new Headers(init.headers);
        headers.append(AUTH_VERSION_HEADER, AUTH_VERSION_HEADER_VALUE);
        headers.delete(INTRAACTIVE_TOKEN_HEADER);
        init.headers = headers;

        spClientService.getHttpClient()
          .then((client) => {
            client
              .fetch(input as string, Fetch.configurations.v1, init)
              .then((response: Response) => {
                resolve(response);
              })
              .catch(error => {
                reject(error);
              });
          })
          .catch((error) => {
            if ((error?.errorCode?.indexOf("AcquireToken_In_Progress") ?? -1) > -1) {
              // SPFx will just redirect, do nothing
              return;
            }

            reject(error);
            return;
          })
        });
      return;
    }

    // MSAL authentication
    if(isRecentVersionOfSdkHelper && options.container.isRegistered(MsalClientService)){
      this.promise = new Promise<Response>((resolve, reject) => {
        const msalClientService = options.container.resolve(MsalClientService);
        msalClientService.getToken()
          .then(token => {
            // add token as Bearer and remove old auth token
            let headers = new Headers(init.headers);
            headers.append("Authorization", `Bearer ${token}`);
            headers.append(AUTH_VERSION_HEADER, AUTH_VERSION_HEADER_VALUE);
            headers.delete(INTRAACTIVE_TOKEN_HEADER);
            init.headers = headers;

            fetch(input, init)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
                return;
              });
          })
          .catch(error => {
            reject(error);
            return;
          });
      });
      return;
    }

    // Mobile authentication
    if(isRecentVersionOfSdkHelper && options.container.isRegistered(IntraActiveAppClientService)){
      this.promise = new Promise<Response>((resolve, reject) => {
        const intraActiveAppClientService = options.container.resolve(IntraActiveAppClientService);
        intraActiveAppClientService.getToken()
          .then(token => {
            // add token as Bearer and remove old auth token
            let headers = new Headers(init.headers);
            headers.append("Authorization", `Bearer ${token}`);
            headers.append(AUTH_VERSION_HEADER, AUTH_VERSION_HEADER_VALUE);
            headers.delete(INTRAACTIVE_TOKEN_HEADER);
            init.headers = headers;

            fetch(input, init)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
                return;
              });
          })
          .catch(error => {
            reject(error);
            return;
          });
      });
      return;
    }

    // Fallback for modules that cannot support access tokens
    if(options.container.isRegistered(IntraActiveTokenService)){
      this.promise = new Promise<Response>((resolve, reject) => {
        const intraActiveTokenService = options.container.resolve(IntraActiveTokenService);
        intraActiveTokenService.getTokenWithoutAadHttpClientFactory()
          .then(() => {
            var token = Helper.getCookieValue(TOKEN_COOKIE_NAME);
            let headers = new Headers(init.headers);
            headers.delete(INTRAACTIVE_TOKEN_HEADER);
            headers.append(INTRAACTIVE_TOKEN_HEADER, token);
            init.headers = headers;

            fetch(input, init)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
                return;
              });
          });
      });
      return;
    }

    this.promise = Promise.reject("Could not use the new authentication. Make sure you have the correct services registered.");
  }

  public then<TResult1 = Response, TResult2 = never>(onfulfilled?: ((value: Response) => TResult1 | PromiseLike<TResult1>) | undefined | null, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null): FetchPromise<TResult1 | TResult2> {
    const newPromise = this.promise.then(onfulfilled, onrejected);
    return new FetchPromise<TResult1 | TResult2>(newPromise, () => this.wellKnownHttpError);
  }
  
  public catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): FetchPromise<Response | TResult | Promise<any>> {
    const newPromise = this.promise.catch((reason) => {
      if (this.wellKnownHttpError) {
        return this.wellKnownHttpError();
      } else {
        return onrejected(reason);
      }
    });
    return new FetchPromise<Response | TResult | Promise<any>>(newPromise, () => this.wellKnownHttpError);
  }
}

