import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IAToggle } from '../../../../../toggle/Toggle';
import { IANumberField } from '../../../../../numberField/NumberField';
import { IAOptionSelector } from '../../../../../optionSelector/OptionSelector';
import { IAButton } from '../../../../../button/Button';
import { IACard } from '../../../../../card/Card';
import { Helper } from '../../../../../../Helper';
import { MediaFileSelector } from '../../../mediaFiles/mediaFileSelector/MediaFileSelector';
import * as styles from '../WidgetEditPanel.css';
import { PlayHelper } from '../../../../PlayHelper';
import { SocialGalleryDownloader } from './socialGalleryDownloader/SocialGalleryDownloader';
import { IATextField } from '../../../../../textField/TextField';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
}

export interface IState {
  newImageUrl?: string;
}

@inject('store')
@observer
export class GalleryWidgetSettings extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {}
  }

  private hasSocialGalleryImages(): boolean {
    var hasSocialGalleryImages = false;
    if (this.props.store.widgetToEdit?.gallery?.images
        && this.props.store.widgetToEdit.id
        && this.props.store.profile.id
        && this.props.store.tenantId
      ) {
      this.props.store.widgetToEdit.gallery.images.forEach(image => {
        if (this.isImageSocialGallery(image)) {
          hasSocialGalleryImages = true;
        }
      });
    }
    return hasSocialGalleryImages;
  }

  private isImageSocialGallery(image: string) {
    var socialGalleryUrl = `${this.props.store.tenantId}/${this.props.store.profile.id}/social-gallery/${this.props.store.widgetToEdit.id}/`;
    return image.indexOf(socialGalleryUrl) !== -1;
  }

  render(): React.ReactNode {
    return (
      <>
        <IANumberField
          label={this.localizationService.strings.PlayAdmin_Duration?.toUpperCase()}
          number={this.props.store.widgetToEdit.gallery.duration}
          onChange={(duration: number) => {
            this.props.store.widgetToEdit.gallery.duration = duration;
            this.props.forceUpdate();
          }}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          styles={{
            width: "100%",
            clear: "both"
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          min={3}
        />
        <IAToggle
          color={this.props.store.highlightColor}
          checked={this.props.store.widgetToEdit.gallery.showThumbnails}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.gallery.showThumbnails = !this.props.store.widgetToEdit.gallery.showThumbnails;
            this.props.forceUpdate();
          }}
          labelStyle={{
            fontSize: "12px"
          }}
          label={this.localizationService.strings.PlayAdmin_ShowThumbnail?.toUpperCase()}
          style={{ marginTop: 20 }}
        />
        <IAToggle
          style={{
            marginTop: 20,
            width: "50%",
            float: "left"
          }}
          label={this.localizationService.strings.PlayAdmin_AllowExternalUpload?.toUpperCase()}
          checked={this.props.store.widgetToEdit.gallery.showSocialGallery}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.gallery.showSocialGallery = !this.props.store.widgetToEdit.gallery.showSocialGallery;
            this.props.store.widgetToEdit.gallery.linkText = undefined;
            this.props.forceUpdate();
          }}
        />
        {this.props.store.widgetToEdit.gallery?.showSocialGallery && 
          <IATextField
            placeholder={this.localizationService.strings.PlayAdmin_LinkText}
            text={this.props.store.widgetToEdit.gallery.linkText}
            darkMode={this.props.store.darkMode}
            highlightColor={this.props.store.highlightColor}
            borderRadius={5}
            label={this.localizationService.strings.PlayAdmin_QRText?.toUpperCase()}
            style={{
              marginTop: 10
            }}
            labelStyle={{
              fontSize: "12px"
            }}
            inputFieldStyle={{
              marginBottom: 10
            }}
            onChange={(text: string) => {
              this.props.store.widgetToEdit.gallery.linkText = text;
              this.props.forceUpdate();
            }}
          />
        }
        <div className={this.props.store.darkMode ? styles.IA_dividerDarkMode : styles.IA_divider} />
        <IAOptionSelector
          label={this.localizationService.strings.PlayAdmin_TransitionType?.toUpperCase()}
          options={[
            {
              icon: "Transition Crossfade",
              key: "crossfade",
              text: this.localizationService.strings.PlayAdmin_Crossfade
            },
            {
              icon: "Transition Slide",
              key: "slide",
              text: this.localizationService.strings.PlayAdmin_Slide
            },
          ]}
          selected={this.props.store.widgetToEdit.gallery.transition}
          highlightColor={this.props.store.highlightColor}
          compact
          iconSize={30}
          darkMode={this.props.store.darkMode}
          borderRadius={5}
          labelStyle={{
            fontSize: "12px"
          }}
          onChange={(transitionType: any) => {
            this.props.store.widgetToEdit.gallery.transition = transitionType;
            this.props.forceUpdate();
          }}
          style={{ clear: "none", marginTop: 20 }}
        />
        <IAToggle
          style={{
            marginTop: 20,
            width: "50%",
            float: "left"
          }}
          label={this.localizationService.strings.PlayAdmin_ShowOriginalImage?.toUpperCase()}
          checked={this.props.store.widgetToEdit.gallery.keepOriginal}
          color={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onChange={() => {
            this.props.store.widgetToEdit.gallery.keepOriginal = !this.props.store.widgetToEdit.gallery.keepOriginal;
            this.props.forceUpdate();
          }}
        />
        <div style={{
          float: "left",
          width: "100%",
          alignItems: "end"
        }}>
          <MediaFileSelector
            url={this.state.newImageUrl}
            label={this.localizationService.strings.PlayAdmin_GalleryImages?.toUpperCase()}
            placeholder={this.localizationService.strings.PlayAdmin_ImageUrl}
            type={"image"}
            enableMultiple
            onChange={(newImageUrl: string) => {
              this.props.store.widgetToEdit.gallery.images.push(newImageUrl);
              this.setState({ newImageUrl: undefined });
              this.props.forceUpdate();
            }}
          />
          <IAButton
            buttonColor={this.props.store.highlightColor}
            disbaled={!this.state.newImageUrl}
            label={this.localizationService.strings.PlayAdmin_Add}
            darkMode={this.props.store.darkMode}
            borderRadius={5}
            onClick={() => {
              this.props.store.widgetToEdit.gallery.images.push(this.state.newImageUrl);
              this.setState({ newImageUrl: undefined });
              this.props.forceUpdate();
            }}
            style={{
              marginBottom: 5,
              marginTop: 10,
              float: "left",
              clear: "both"
            }}
          />
        </div>
        {this.hasSocialGalleryImages() &&
          <div
            style={{
              float: "left",
              width: "100%",
              marginTop: 20
            }}
          >
            <SocialGalleryDownloader />
          </div>
        }
        <div style={{ paddingTop: 20, display: "flex", flexWrap: "wrap", justifyContent: "space-between", width: "100%" }}>
          {this.props.store.widgetToEdit.gallery.images.map((imageUrl, index) => {
            const isFirstElement = index === 0;
            const isLastElement = index === this.props.store.widgetToEdit.gallery.images.length - 1;
            const id = `image${imageUrl}${index}`;
            const cardImage =  PlayHelper.excludeImageFromApi(this.props.store.environment, imageUrl)
                ? imageUrl 
                : PlayHelper.getImageFromApi(this.props.store.environment, this.props.store.tenantId, this.props.store.profile.id, PlayHelper.getStandardImageFormat(500, 250), false, imageUrl);  
            return <IACard
              key={id}
              id={id}
              darkMode={this.props.store.darkMode}
              cover={{ imageURL: cardImage }}
              isMobile={this.props.store.isMobile}
              alwaysShowFooter
              footerLeftText={this.isImageSocialGallery(imageUrl) ? this.localizationService.strings.PlayAdmin_SocialGalleryImageUploadedFromQR : undefined}
              footerElements={[
                {
                  color: isLastElement ? this.props.store.highlightColor + "80" : this.props.store.highlightColor,
                  iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Down/SVG/ic_fluent_chevron_down_48_regular.svg",
                  onClick: () => {
                    if (!isLastElement) {
                      this.props.store.widgetToEdit.gallery.images.splice(index + 1, 0, this.props.store.widgetToEdit.gallery.images.splice(index, 1)[0]);
                      this.props.forceUpdate();
                    }
                  }
                },
                {
                  color: isFirstElement ? this.props.store.highlightColor + "80" : this.props.store.highlightColor,
                  iconUrl: "https://intraactivestorage.blob.core.windows.net/cdn/icons/Chevron%20Up/SVG/ic_fluent_chevron_up_48_regular.svg",
                  onClick: () => {
                    if (!isFirstElement) {
                      this.props.store.widgetToEdit.gallery.images.splice(index - 1, 0, this.props.store.widgetToEdit.gallery.images.splice(index, 1)[0]);
                      this.props.forceUpdate();
                    }
                  }
                },
                {
                  color: this.props.store.highlightColor,
                  icon: "Trash",
                  onClick: () => {
                    this.props.store.widgetToEdit.gallery.images.splice(index, 1);
                    this.props.forceUpdate();
                  }
                }
              ]}
              width={300}
              styles={{
                marginBottom: 20,
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
                width: 300
              }}
              design={{
                highlightColor: this.props.store.highlightColor,
                showDropShadow: true,
                borderRadius: 5,
                backgroundColor: this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"
              }}
            />;
          })}
        </div>
      </>);
  }

  
}