import * as React from 'react';
import { IColorTheme } from '../../../interfaces/IColorTheme';
import { IComposerImage } from '../../../interfaces/IComposerImage';
import { IComposerComponent } from '../../../interfaces/IComposerTool';
import { IPlayArticle, IPlayEvent, IPlayMessage, IPlayNewsItem, IPlayWidgetAreaType, IPlaySharepointNewsItem, IPlayWidgetExtended, IPlayBoxStyle, IPlayWeatherData, IPlayWorkplaceFeedItem } from '../../../interfaces/IPlay';
import { Environment } from '../../../services/Environment';
import { Gallery } from './gallery/Gallery';
import { Image } from './image/Image';
import { List } from './list/List';
import { Rotator } from './rotator/Rotator';
import { Rss } from './rss/Rss';
import { Video } from './video/Video';
import { CanteenMenu } from './canteenMenu/CanteenMenu';
import { IEnvironment } from '../../../interfaces/IEnvironment';
import * as styles from './Slide.css';
import { Poll } from './poll/Poll';
import { Weather } from './weather/Weather';
import { Instagram } from './instagram/Instagram';
import { Helper } from '../../../Helper';

export interface IProps {
  widget: IPlayWidgetExtended;
  isOnActiveSlide: boolean;
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  index: number;
  height?: number;
  width?: number;
  editPreviewMode?: boolean;
  showActivityLabel?: boolean;
  highlightColor: string;
  previewColorTheme?: IColorTheme;
  areaType?: IPlayWidgetAreaType;
  language?: string;
  forecast?: IPlayWeatherData;
  isHardcodedWeatherData?: boolean;
  widgetContentWillRestart: () => void;
}

export interface IState {
}

export class Widget extends React.Component<IProps, IState> {

  public componentDidMount(): void {
    if (
      this.props.widget.type !== "articleViewer" &&
      this.props.widget.type !== "messageViewer" &&
      this.props.widget.type !== "sharepointNewsViewer" &&
      this.props.widget.type !== 'workplaceFeedViewer' &&
      this.props.widget.type !== "video" &&
      this.props.widget.type !== "youtube" &&
      this.props.widget.type !== "vimeo" &&
      this.props.widget.type !== "gallery" &&
      this.props.widget.type !== "rss"
    ) {
      try {
        setTimeout(() => {
          this.props.widgetContentWillRestart();
        }, this.props.widget.duration && this.props.widget.duration !== 0 ? (this.props.widget.duration * 1000) : (30 * 1000));
      } catch (error) {
        console.log(`%c${error}`, 'background: red; color: #ffffff')
      }
    }
  }


  private prepareText(htmlText: string): string {
    const maxCharacters = 280; // characters
    let originalText: string = Helper.stripHtml(htmlText);
    originalText = originalText
    const words = originalText.split(" ");
    let text = "";
    if (htmlText) {
      words.forEach((word: string) => {
        if (text.length < maxCharacters) {
          text += word + " ";
        }
      });
      if (originalText?.length > maxCharacters) {
        if (text?.substring(text?.length - 1) === " ") {
          text = text?.slice(0, -1);
        }
        if (text?.substring(text?.length - 1) === ".") {
          text = text?.slice(0, -1);
        }
        text = `${text}...`;
      }
    }
    return text;
  }

  public render(): JSX.Element {
    let areaWidth;
    let areaHeight;
    let content: React.ReactElement;
    let newsItems: IPlayNewsItem[] = [];
    const sizeUnit = (this.props.height < this.props.width ? this.props.height : this.props.width) / 100;
    try {
      if (document.getElementById(`widget_${this.props.widget?.id}_${this.props.index}`)) {
        const element = document.getElementById(`widget_${this.props.widget?.id}_${this.props.index}`);
        if (element) {
          areaWidth = element.clientWidth;
          areaHeight = element.clientHeight;
        }
      }
      if (areaWidth == undefined) {
        setTimeout(() => {
          this.forceUpdate();
        }, 0);
      }
      console.log("areaWidth: " + areaWidth);
      switch (this.props.widget?.type) {
        case "image":
          if (!this.props.widget.image) break;
          content = <Image
            imageUrl={this.props.widget?.image?.imageUrl}
            environment={this.props.environment}
            tenantId={this.props.tenantId}
            profileId={this.props.profileId}
            keepOriginal={this.props.widget.image?.keepOriginal}
            isActive={true}
          />;
          break;
        case "gallery":
          if (!this.props.widget.gallery) break;
          if (this.props.isOnActiveSlide) {
            content = <Gallery
              title={this.props.widget.title}
              gallery={this.props.widget?.gallery}
              playerHeight={this.props.height}
              playerWidth={this.props.width}
              areaWidth={areaWidth}
              duration={this.props.widget?.gallery?.duration}
              transition={this.props.widget?.gallery?.transition}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              keepOriginal={this.props.widget?.gallery?.keepOriginal}
              widgetId={this.props.widget?.id}
              language={this.props.language}
              willRestart={() => this.props.widgetContentWillRestart()}
            />
          }
          break;
        case "rss":
          if (!this.props.widget.rss) break;
          if (this.props.isOnActiveSlide) {
            content = <Rss
              widget={this.props.widget}
              width={areaWidth}
              height={areaHeight}
              playerHeight={this.props.height}
              playerWidth={this.props.width}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              editPreviewMode={this.props.editPreviewMode}
              areaType={this.props.areaType}
              language={this.props.language}
              previewColorTheme={this.props.previewColorTheme}
              willRestart={() => this.props.widgetContentWillRestart()}
            />;
          }
          break;
        case "instagram":
          if (!this.props.widget.instagram) break;
          if (this.props.isOnActiveSlide) {
            content = <Instagram
              widget={this.props.widget}
              width={areaWidth}
              height={areaHeight}
              playerHeight={this.props.height}
              playerWidth={this.props.width}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              editPreviewMode={this.props.editPreviewMode}
              areaType={this.props.areaType}
              language={this.props.language}
              previewColorTheme={this.props.previewColorTheme}
              willRestart={() => this.props.widgetContentWillRestart()}
            />;
          }
          break;
        case "video":
        case "vimeo":
        case "video23":
        case "youtube":
          if (!this.props.widget.video) break;
          let marginLeft = 0;
          let marginTop = 0;
          if (this.props.isOnActiveSlide) {
            if (this.props.widget?.video?.fitToView) {
              const newAreaWidth = (areaHeight / 9) * 16;
              if (newAreaWidth < areaWidth) {
                const newAreaHeight = areaWidth / 16 * 9
                marginTop = (areaHeight - newAreaHeight) / 2;
                areaHeight = newAreaHeight;
              } else {
                marginLeft = (areaWidth - newAreaWidth) / 2;
                areaWidth = newAreaWidth;
              }
            }
            content = <Video
              type={this.props.widget.type}
              videoUrl={this.props.widget?.video?.videoUrl}
              width={areaWidth}
              height={areaHeight}
              marginLeft={marginLeft}
              marginTop={marginTop}
              playerHeight={this.props.height}
              playerWidth={this.props.width}
              showTitle={this.props.widget?.video.showTitle}
              title={this.props.widget?.title}
              environment={this.props.environment}
              profileId={this.props.profileId}
              tenantId={this.props.tenantId}
              willRestart={() => this.props.widgetContentWillRestart()}
            />;
          }
          break;
        case "embed":
          if (!this.props.widget.embed) break;
          if (this.props.widget?.embed?.url && this.props.widget?.embed.url !== "") {
            content = <iframe src={this.props.isOnActiveSlide ? this.props.widget?.embed?.url : ""} width="100%" height="100%" />
          }
          break;
        case "pdf":
          if (!this.props.widget.pdf) break;
          if (this.props.widget?.pdf?.url) {
            content = <object data={this.props.widget.pdf.url} type="application/pdf" width="100%" height="100%" >
              <iframe src={`${this.props.widget?.pdf.url}#toolbar=0&navpanes=0&scrollbar=0&view=fit`} width="100%" height="100%" />
            </object>
          }
          break;
        case "articleViewer":
          if (!this.props.widget.articleViewer) break;
          if (this.props.widget?.articleViewer?.articles) {
            this.props.widget?.articleViewer.articles.forEach((article: IPlayArticle) => {
              let text = "";
              if (article?.textContent?.length > 0) {
                article?.textContent?.forEach((t) => {
                  if (t !== undefined && t !== "") {
                    text += t;
                  }
                });
              }
              const newsItem: IPlayNewsItem = {
                id: article.id,
                title: article.showTitle && article.title,
                content: text,
                date: article.showDate && article.startDate,
                author: article.createdBy?.displayName,
                image: article.imageUrl,
                video: article.video,
                gallery: article.galleryImages,
                link: article.link,
                linkText: article.linkText,
                colorTheme: article.colorTheme,
                boxStyle: article.boxStyle,
                contentPosition: article.contentPosition,
                contentVerticalPosition: article.contentVerticalPosition,
                boxStyleWidth: article.contentWidth,
                boxStyleRoundCorner: article.boxStyleRoundCorner,
                keepOriginalImage: article.keepOriginalImage,
              };
              newsItems.push(newsItem);
            });
          }
          content = (
            <Rotator
              widgetType="articleViewer"
              newsItems={newsItems}
              widgetId={this.props.widget?.id}
              linkPortalURL={Environment.getEnvironmentForLinkPortal(this.props.environment)}
              width={areaWidth}
              height={areaHeight}
              playerHeight={this.props.height}
              playerWidth={this.props.width}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              transition={this.props.widget.articleViewer.transition}
              duration={this.props.widget.articleViewer.duration}
              editPreviewMode={this.props.editPreviewMode}
              areaType={this.props.areaType}
              showQRLink={true}
              showTitle={true}
              showText={true}
              showProgressBar={this.props.widget?.showProgressBar != undefined ? this.props.widget?.showProgressBar : true}
              showAuthor={false} // always hide author for articles
              showDate={true}
              showImage={true}
              showVideo={true}
              showGallery={true}
              language={this.props.language}
              widgetTitleIsShowing={this.props.widget?.showTitle}
              headerTitle={this.props.widget?.title}
              articleEditMode={this.props.widget.articleViewer.articleEditMode}
              noContentImage={this.props.widget.articleViewer.noContentImageUrl}
              willRestart={() => this.props.widgetContentWillRestart()}
            />
          );
          break;
        case "messageViewer":
          if (!this.props.widget.messageViewer) break;
          const ampuntToShow = this.props.widget.messageViewer?.amountToShow != undefined ? this.props.widget.messageViewer?.amountToShow : 5;
          this.props.widget.messageViewer.messages?.forEach((message: IPlayMessage, index: number) => {
            let gallery;
            let text = "";
            if (message?.configuration?.composerComponents?.length > 0) {
              message?.configuration?.composerComponents.forEach((component: IComposerComponent) => {
                switch (component.type) {
                  case "text":
                    if (component.text !== undefined && component.text !== "") {
                      text += component.text;
                    }
                    break;
                  case "gallery":
                    if (component.gallery?.images?.length > 0) {
                      gallery = [];
                      component.gallery.images.forEach((image: IComposerImage) => {
                        gallery.push(
                          image.url
                        );
                      });
                    }
                    break;
                }
              });
            }

            // The content showed is prioritized like this:
            // Feed text > teaser > message content
            let content = text;
            if (message.teaser && message.teaser !== "") content = message.teaser;
            if (message.configuration?.replay?.content && message.configuration?.replay?.content !== "") content = message.configuration?.replay?.content;

            const newsItem: IPlayNewsItem = {
              id: message.id,
              title: message.title,
              content: content,
              date: message.startTime,
              author: message.contacts && message.contacts.length > 0 ? message.contacts[0].name : message.author?.name,
              image: message.configuration?.replay?.boxStyle === "onlyVideo" ? undefined : message.featuredImage,
              video: message.configuration?.replay?.boxStyle === "onlyImage" ? undefined : message.featuredVideo,
              gallery: message.configuration?.replay?.boxStyle === "onlyImage" || message.configuration?.replay?.boxStyle === "onlyVideo" ? undefined : gallery,
              link: message.link,
              linkText: this.props.widget.messageViewer?.linkText,
              keepOriginalImage: message.featuredVideo ? true : false,
              boxStyle: message.configuration?.replay?.boxStyle as IPlayBoxStyle,
              contentPosition: this.props.widget.messageViewer.contentPosition
            };
            if (index < ampuntToShow) {
              newsItems.push(newsItem);
            }
          });
          content = (
            <Rotator
              widgetType="messageViewer"
              newsItems={newsItems}
              widgetId={this.props.widget?.id}
              linkPortalURL={Environment.getEnvironmentForLinkPortal(this.props.environment)}
              width={areaWidth}
              height={areaHeight}
              playerHeight={this.props.height}
              playerWidth={this.props.width}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              transition={this.props.widget.messageViewer.transition}
              duration={this.props.widget.messageViewer.duration}
              boxStyle={this.props.widget.messageViewer.boxStyle}
              editPreviewMode={this.props.editPreviewMode}
              colorThemes={this.props.previewColorTheme ? [this.props.previewColorTheme] : this.props.widget.messageViewer.colorThemes}
              areaType={this.props.areaType}
              showQRLink={this.props.widget.messageViewer.showQRLink != undefined ? this.props.widget.messageViewer.showQRLink : true}
              showTitle={this.props.widget.messageViewer.showTitle != undefined ? this.props.widget.messageViewer.showTitle : true}
              showText={this.props.widget.messageViewer.showText != undefined ? this.props.widget.messageViewer.showText : true}
              showAuthor={this.props.widget.messageViewer.showAuthor != undefined ? this.props.widget.messageViewer.showAuthor : true}
              showDate={this.props.widget.messageViewer.showTime != undefined ? this.props.widget.messageViewer.showTime : true}
              showProgressBar={this.props.widget?.showProgressBar != undefined ? this.props.widget?.showProgressBar : true}
              showImage={this.props.widget.messageViewer.showImage != undefined ? this.props.widget.messageViewer.showImage : true}
              showVideo={this.props.widget.messageViewer.showVideo != undefined ? this.props.widget.messageViewer.showVideo : true}
              showGallery={this.props.widget.messageViewer.showGallery != undefined ? this.props.widget.messageViewer.showGallery : true}
              isMessages={true}
              widgetTitleIsShowing={this.props.widget?.showTitle}
              headerTitle={this.props.widget.messageViewer?.feedTitle ? this.props.widget.messageViewer?.feedTitle : this.props.widget?.title}
              language={this.props.language}
              noContentImage={this.props.widget.messageViewer.noContentImageUrl}
              willRestart={() => this.props.widgetContentWillRestart()}
            />
          );
          break;
        case "eventViewer":
          if (!this.props.widget.eventViewer) break;
          this.props.widget.eventViewer.events?.forEach((event: IPlayEvent) => {
            const newsItem: IPlayNewsItem = {
              id: event.id,
              title: event.title,
              content: event.configuration?.replay?.content && event.configuration?.replay?.content !== "" ? event.configuration?.replay?.content : event.content,
              startDate: event.startTime,
              endDate: event.endTime,
              isAllDayEvent: event.allDayEvent,
              location: event.location,
              author: event.author?.name,
              image: event.featuredImage,
              contentPosition: this.props.widget.eventViewer.contentPosition
            };
            newsItems.push(newsItem);
          });
          switch (this.props.widget.eventViewer.layoutType) {
            case "list":
              content = (
                <List
                  widgetId={this.props.widget?.id}
                  index={this.props.index}
                  newsItems={newsItems}
                  playerHeight={this.props.height}
                  playerWidth={this.props.width}
                  sizeUnit={sizeUnit}
                  headlineColor={this.props.widget.eventViewer.headlineColor}
                  backgroundColor={this.props.widget.eventViewer.backgroundColor}
                  boxColor={this.props.widget.eventViewer.boxColor}
                  borderColor={this.props.widget.eventViewer.borderColor}
                  textColor={this.props.widget.eventViewer.textColor}
                  timeBatchBackgroundColor={this.props.widget.eventViewer.timeBatchBackgroundColor}
                  timeBatchTextColor={this.props.widget.eventViewer.timeBatchTextColor}
                  timeBatchBorderColor={this.props.widget.eventViewer.timeBatchBorderColor}
                  showTitle={this.props.widget.eventViewer.showTitle != undefined ? this.props.widget.eventViewer.showTitle : true}
                  showText={this.props.widget.eventViewer.showText != undefined ? this.props.widget.eventViewer.showText : true}
                  showTime={this.props.widget.eventViewer.showTime != undefined ? this.props.widget.eventViewer.showTime : true}
                  showLocation={this.props.widget.eventViewer.showLocation != undefined ? this.props.widget.eventViewer.showLocation : true}
                  showTimeBox={this.props.widget.eventViewer.showTimeBox != undefined ? this.props.widget.eventViewer.showTimeBox : true}
                  areaType={this.props.areaType}
                  textLines={this.props.widget.eventViewer.textLines}
                  headline={this.props.widget.eventViewer.showHeadline && this.props.widget.title}
                  noContentImage={this.props.widget.eventViewer.noContentImageUrl}
                  language={this.props.language}
                />
              );
              break;
            case "week":
              content = (
                <CanteenMenu
                  widgetId={this.props.widget?.id}
                  index={this.props.index}
                  newsItems={newsItems}
                  sizeUnit={this.props.height / 100}
                  headlineColor={this.props.widget.eventViewer.headlineColor}
                  backgroundColor={this.props.widget.eventViewer.backgroundColor}
                  boxColor={this.props.widget.eventViewer.boxColor}
                  borderColor={"lightgray"}
                  textColor={this.props.widget.eventViewer.textColor}
                  timeBatchBackgroundColor={this.props.widget.eventViewer.timeBatchBackgroundColor}
                  timeBatchTextColor={this.props.widget.eventViewer.timeBatchTextColor}
                  timeBatchBorderColor={this.props.widget.eventViewer.timeBatchBorderColor}
                  showTitle={this.props.widget.eventViewer.showTitle != undefined ? this.props.widget.eventViewer.showTitle : true}
                  showText={this.props.widget.eventViewer.showText != undefined ? this.props.widget.eventViewer.showText : true}
                  showTime={this.props.widget.eventViewer.showTime != undefined ? this.props.widget.eventViewer.showTime : true}
                  showLocation={this.props.widget.eventViewer.showLocation != undefined ? this.props.widget.eventViewer.showLocation : true}
                  showTimeBox={this.props.widget.eventViewer.showTimeBox != undefined ? this.props.widget.eventViewer.showTimeBox : true}
                  areaType={this.props.areaType}
                  textLines={this.props.widget.eventViewer.textLines}
                  headline={this.props.widget.eventViewer.showHeadline && this.props.widget.title}
                  backgroundImage={this.props.widget.eventViewer.backgroundImage}
                  language={this.props.language}
                />
              );
              break;
            default:
            case "slide":
              content = (
                <Rotator
                  widgetType="eventViewer"
                  newsItems={newsItems}
                  widgetId={this.props.widget?.id}
                  linkPortalURL={Environment.getEnvironmentForLinkPortal(this.props.environment)}
                  width={areaWidth}
                  height={areaHeight}
                  playerHeight={this.props.height}
                  playerWidth={this.props.width}
                  environment={this.props.environment}
                  tenantId={this.props.tenantId}
                  profileId={this.props.profileId}
                  transition={this.props.widget.eventViewer.transition}
                  duration={this.props.widget.eventViewer.duration}
                  boxStyle={this.props.widget.eventViewer.boxStyle}
                  editPreviewMode={this.props.editPreviewMode}
                  colorThemes={this.props.previewColorTheme ? [this.props.previewColorTheme] : this.props.widget.eventViewer.colorThemes}
                  areaType={this.props.areaType}
                  showTitle={this.props.widget.eventViewer.showTitle != undefined ? this.props.widget.eventViewer.showTitle : true}
                  showText={this.props.widget.eventViewer.showText != undefined ? this.props.widget.eventViewer.showText : true}
                  showAuthor={this.props.widget.eventViewer.showAuthor != undefined ? this.props.widget.eventViewer.showAuthor : true}
                  showImage={this.props.widget.eventViewer.showImage != undefined ? this.props.widget.eventViewer.showImage : true}
                  showTime={this.props.widget.eventViewer.showTime != undefined ? this.props.widget.eventViewer.showTime : true}
                  showProgressBar={this.props.widget?.showProgressBar != undefined ? this.props.widget?.showProgressBar : true}
                  showLocation={this.props.widget.eventViewer.showLocation != undefined ? this.props.widget.eventViewer.showLocation : true}
                  showTimeBox={this.props.widget.eventViewer.showTimeBox != undefined ? this.props.widget.eventViewer.showTimeBox : true}
                  noContentImage={this.props.widget.eventViewer.noContentImageUrl}
                  language={this.props.language}
                  widgetTitleIsShowing={this.props.widget?.showTitle}
                  headerTitle={this.props.widget?.title}
                  willRestart={() => this.props.widgetContentWillRestart()}
                />
              );
              break;
          }
          break;
        case "sharepointNewsViewer":
          if (!this.props.widget.sharepointNewsViewer) break;
          this.props.widget.sharepointNewsViewer.news?.forEach((sharepointNewsItem: IPlaySharepointNewsItem) => {
            const newsItem: IPlayNewsItem = {
              title: sharepointNewsItem.title,
              content: sharepointNewsItem.description,
              date: sharepointNewsItem.modified,
              image: sharepointNewsItem.featuredImage ?? sharepointNewsItem.bannerImageUrl?.url,
              contentPosition: this.props.widget.sharepointNewsViewer.contentPosition
            };
            newsItems.push(newsItem);
          });
          content =
            <Rotator
              widgetType="sharepointNewsViewer"
              newsItems={newsItems}
              widgetId={this.props.widget?.id}
              linkPortalURL={Environment.getEnvironmentForLinkPortal(this.props.environment)}
              width={areaWidth}
              height={areaHeight}
              playerHeight={this.props.height}
              playerWidth={this.props.width}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              transition={this.props.widget.sharepointNewsViewer.transition}
              duration={this.props.widget.sharepointNewsViewer.duration}
              boxStyle={this.props.widget.sharepointNewsViewer.boxStyle}
              editPreviewMode={this.props.editPreviewMode}
              colorThemes={this.props.previewColorTheme ? [this.props.previewColorTheme] : this.props.widget.sharepointNewsViewer.colorThemes}
              areaType={this.props.areaType}
              showTitle={this.props.widget.sharepointNewsViewer.showTitle != undefined ? this.props.widget.sharepointNewsViewer.showTitle : true}
              showText={this.props.widget.sharepointNewsViewer.showText != undefined ? this.props.widget.sharepointNewsViewer.showText : true}
              showDate={this.props.widget.sharepointNewsViewer.showTime != undefined ? this.props.widget.sharepointNewsViewer.showTime : true}
              showImage={this.props.widget.sharepointNewsViewer.showImage != undefined ? this.props.widget.sharepointNewsViewer.showImage : true}
              showProgressBar={this.props.widget?.showProgressBar != undefined ? this.props.widget?.showProgressBar : true}
              noContentImage={this.props.widget.sharepointNewsViewer.noContentImageUrl}
              language={this.props.language}
              widgetTitleIsShowing={this.props.widget?.showTitle}
              headerTitle={this.props.widget?.title}
              willRestart={() => this.props.widgetContentWillRestart()}
            />;
          break;
        case "workplaceFeedViewer":
          if (!this.props.widget.workplaceFeedViewer) break;
          this.props.widget.workplaceFeedViewer.feed?.forEach((workplaceFeedItem: IPlayWorkplaceFeedItem, index) => {
            if (index < this.props.widget.workplaceFeedViewer.amountToShow) {
              const content = workplaceFeedItem.message?.split("\n\n");
              const title = content?.length > 0 ? content[0] : undefined;
              let description = workplaceFeedItem.message?.replace(title, "");
              if (description) {
                description = this.prepareText(description);
              }
              const newsItem: IPlayNewsItem = {
                title: title?.replace("# ", ""),
                content: description,
                date: workplaceFeedItem.created_time,
                image: workplaceFeedItem.picture,
                author: workplaceFeedItem.from?.name,
                contentPosition: this.props.widget.workplaceFeedViewer.contentPosition ?? "left",
              };
              newsItems.push(newsItem);
            }
          });
          content =
            <Rotator
              widgetType="workplaceFeedViewer"
              newsItems={newsItems}
              widgetId={this.props.widget?.id}
              linkPortalURL={Environment.getEnvironmentForLinkPortal(this.props.environment)}
              width={areaWidth}
              height={areaHeight}
              playerHeight={this.props.height}
              playerWidth={this.props.width}
              environment={this.props.environment}
              tenantId={this.props.tenantId}
              profileId={this.props.profileId}
              transition={this.props.widget.workplaceFeedViewer.transition}
              duration={this.props.widget.workplaceFeedViewer.duration}
              boxStyle={this.props.widget.workplaceFeedViewer.boxStyle}
              editPreviewMode={this.props.editPreviewMode}
              colorThemes={this.props.previewColorTheme ? [this.props.previewColorTheme] : this.props.widget.workplaceFeedViewer.colorThemes}
              areaType={this.props.areaType}
              showAuthor={this.props.widget.workplaceFeedViewer.showAuthor != undefined ? this.props.widget.workplaceFeedViewer.showAuthor : true}
              showTitle={this.props.widget.workplaceFeedViewer.showTitle != undefined ? this.props.widget.workplaceFeedViewer.showTitle : true}
              showText={this.props.widget.workplaceFeedViewer.showText != undefined ? this.props.widget.workplaceFeedViewer.showText : true}
              showDate={this.props.widget.workplaceFeedViewer.showTime != undefined ? this.props.widget.workplaceFeedViewer.showTime : true}
              showImage={this.props.widget.workplaceFeedViewer.showImage != undefined ? this.props.widget.workplaceFeedViewer.showImage : true}
              showProgressBar={this.props.widget?.workplaceFeedViewer != undefined ? this.props.widget?.showProgressBar : true}
              noContentImage={this.props.widget.workplaceFeedViewer.noContentImageUrl}
              language={this.props.language}
              widgetTitleIsShowing={this.props.widget?.showTitle}
              headerTitle={this.props.widget?.headerTitle}
              headerSubtitle={this.props.widget?.headerSubtitle}
              headerImage={this.props.widget?.headerImage}
              willRestart={() => {
                this.props.widgetContentWillRestart();
              }}
            />;
          break;
        case "poll":
          if (!this.props.widget.poll) break;
          content = <Poll
            widgetId={this.props.widget.id}
            environment={this.props.environment}
            tenantId={this.props.tenantId}
            profileId={this.props.profileId}
            title={this.props.widget.poll.title}
            replyOptions={this.props.widget.poll.replyOptions}
            sizeUnit={sizeUnit}
            isActive={true}
            width={areaWidth}
            height={areaHeight}
            duration={this.props.widget.duration && this.props.widget.duration !== 0 ? this.props.widget.duration : 20}
            willRestart={() => this.props.widgetContentWillRestart()}
          />;
          break;
        case "weather":
          if (!this.props.widget.weather) break;
          content = <Weather
            widgetId={`${this.props.widget?.id}_${this.props.index}`}
            forecast={this.props.forecast}
            width={areaWidth}
            height={areaHeight}
            sizeUnit={sizeUnit}
            language={this.props.language}
            areaType={this.props.areaType}
            colorTheme={this.props.widget.weather.colorTheme}
            isHardcodedWeatherData={this.props.isHardcodedWeatherData}
            displayMode={this.props.widget.weather?.weatherType ?? "week"}
          />;
          break;
        default:
          content = <></>;
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }

    return <div
      key={`widget_${this.props.widget?.id}_${this.props.index}`}
      id={`widget_${this.props.widget?.id}_${this.props.index}`}
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        zIndex: 1,
        position: "relative"
      }}
    >
      {content}

      {(this.props.widget.gallery?.showTitle || this.props.widget.video?.showTitle || this.props.widget.showTitle) && this.props.widget.title &&
        <div
          className={styles.IA_title}
          style={{
            padding: `${sizeUnit}px ${sizeUnit * 3}px ${sizeUnit}px ${sizeUnit * 2}px`,
            left: 0,
            bottom: this.props.widget?.gallery?.showThumbnails && this.props.widget?.gallery?.images?.length > 1 ? this.props.height / 10 : 0,
            fontSize: sizeUnit * 1.5,
            borderRadius: `0px ${sizeUnit * 1.5}px 0px 0px`
          }}
        >
          {this.props.widget.title?.toUpperCase()}
        </div>
      }
    </div>;
  }
}
