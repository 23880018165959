import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './ArticleEditPanel.css';
import { IAPanel } from '../../../../panels/Panel';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IPlayArticle, IPlaySlideExtended, IPlaySlideLayout, IPlayWidget, IPlayWidgetExtended } from '../../../../../interfaces/IPlay';
import PlayService from '../../../../../services/PlayService';
import { IASpinner } from '../../../../spinner/Spinner';
import { Slide } from '../../../../player/slide/Slide';
import { IAButton } from '../../../../button/Button';
import { Helper } from '../../../../../Helper';
import { IACheckbox } from '../../../../checkbox/Checkbox';
import { ArticleEditor } from './ArticleEditor';
import { PlayHelper } from '../../../PlayHelper';
import { Popup } from '../../../reusableComponents/popup/Popup';
import { RssTicker } from '../../../../player/rssTicker/RssTicker';
import { Footer } from '../../../../player/footer/Footer';
import { PreviewSettings } from '../../../reusableComponents/previewSettings/PreviewSettings';

export interface IProps {
  id?: string;
  articleId: string;
  store?: Store;
  showWidgetsSelector?: boolean;
  isOpen: boolean;
  close: () => void;
  onSave: () => void;
}

export interface IState {
  isSaving: boolean;
  displayLayout: IPlaySlideLayout;
  isLoadingMore: boolean;
  widgets: IPlayWidget[];
  widgetsCount: number;
  showConfirmClosePopup: boolean;
  showFooter?: boolean;
  showRssTicker?: boolean;
}

@inject('store')
@observer
export class ArticleEditPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();
  private readonly perPage: number = 5;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isSaving: false,
      displayLayout: "oneColumn",
      isLoadingMore: false,
      widgets: [],
      widgetsCount: undefined,
      showConfirmClosePopup: false
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    this.props.showWidgetsSelector && this.state.widgets?.length < this.perPage && props.isOpen && this.loadArticleViewers();
    if (this.props.articleId !== props.articleId && props.articleId != undefined && props.articleId !== "new") {
      PlayService.getArticle(props.store.environment, this.props.store.token, props.store.tenantId, this.props.store.profile?.id, props.articleId).then((article: IPlayArticle) => {
        this.props.store.articleToEdit = { ...article, widgetsToAddTo: [], widgetsToRemoveFrom: [] };
        this.props.store.articleToEditBeforeChanged = JSON.stringify(this.props.store.articleToEdit);
        this.setState({ displayLayout: "oneColumn" })
        this.forceUpdate();
      });
    }
  }

  public componentWillUnmount(): void {
    this.props.store.widgetsCount = undefined;
    this.props.store.widgets = undefined;
  }

  private loadArticleViewers() {
    this.setState({ isLoadingMore: true })
    const currentPage = Math.ceil(this.state.widgets?.length / this.perPage) || 0;
    PlayService.getWidgets(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, "articleViewer",
      {
        skip: currentPage * this.perPage,
        take: this.perPage
      })
      .then(result => {
        if (!this.state.widgetsCount) {
          this.setState({ widgetsCount: result.count })
        }
        this.setState({ widgets: [...this.state.widgets, ...result.value], isLoadingMore: false })
      })
  }

  public render(): JSX.Element {
    let previewWidth = this.props.store.windowWidth - 480;
    let previewHeight = (previewWidth / 16) * 9;

    // resize if the window height is smaller than the preview height
    if (previewHeight > window.innerHeight - 84) {
      previewHeight = window.innerHeight - 84 - 80;
      previewWidth = (previewHeight / 9) * 16;
    }
    // Portrait mode
    if (this.state.displayLayout === "portraitOneRow" || this.state.displayLayout === "portraitTwoRows") {
      previewHeight = window.innerHeight - 84 - 80;
      previewWidth = (previewHeight / 16) * 9;

    }

    const articleViewer: IPlayWidgetExtended = {
      id: this.props.store.articleToEdit?.id,
      type: "articleViewer",
      articleViewer: {
        articles: [this.props.store.articleToEdit],
        duration: 30,
        transition: "none",
        articleEditMode: true
      }
    }

    const previewSlide: IPlaySlideExtended = {
      id: "articleEditPanelPreviewSlide",
      showFooter: this.state.showFooter,
      showRssTicker: this.state.showRssTicker,
      startDate: new Date().toJSON(),
      widgets: [
        articleViewer,
        articleViewer,
        articleViewer,
        articleViewer
      ],
      layout: this.state.displayLayout
    }

    return (
      <IAPanel
        open={this.props.isOpen}
        darkMode={this.props.store.darkMode}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={this.state.isSaving}
        width={this.props.store.windowWidth}
        dataAutomationIdPrefix="article-edit-panel"
        panelId={`article-edit-panel-from-${this.props.id}`}
        isInTeams={this.props.store.isTeams}
        navigationsBarContent={
          <IAButton
            darkMode={this.props.store.darkMode}
            label={this.props.store.articleToEdit?.id && this.props.store.articleToEdit?.id !== "new" ? this.localizationService.strings.PlayAdmin_Update?.toUpperCase() : this.localizationService.strings.PlayAdmin_Save?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            disbaled={(!this.props.store.articleToEdit?.title)}
            onClick={() => {
              this.setState({ widgets: [], widgetsCount: undefined })
              this.props.onSave();
            }}
            borderRadius={5}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7,
              marginRight: 10
            }}
          />
        }
        close={() => {
          if (this.props.store.articleToEditBeforeChanged === JSON.stringify(this.props.store.articleToEdit)) {
            this.setState({ widgets: [], widgetsCount: undefined, showConfirmClosePopup: false });
            this.props.close();
          } else {
            this.setState({ showConfirmClosePopup: true });
          }
        }}
      >
        {this.state.showConfirmClosePopup &&
          <Popup
            headline={this.localizationService.strings.Messages_CancelPopup_Headline}
            close={() => this.setState({ showConfirmClosePopup: false })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_Yes?.toUpperCase()}
                  onClick={() => {
                    this.setState({ widgets: [], widgetsCount: undefined, showConfirmClosePopup: false });
                    this.props.close();
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.PlayAdmin_DeletePopup_No?.toUpperCase()}
                  onClick={() => this.setState({ showConfirmClosePopup: false })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
        {this.props.store.articleToEdit ?
          <div
            className={styles.IA_articleEditPanel}
            style={{
              height: window.innerHeight - 45,
              backgroundColor: this.props.store.darkMode ? "#414141" : "#777777",
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            <div className={styles.IA_content} style={{ backgroundColor: this.props.store.darkMode ? Helper.darkModeBackgroundColor : '#fff' }}>
              {this.props.store.articleToEdit.boxStyle &&
                <ArticleEditor
                  forceUpdate={() => {
                    setTimeout(() => {
                      this.forceUpdate()
                    }, 10);
                  }}
                  articleId={this.props.articleId}
                />
              }
              {this.props.showWidgetsSelector && this.props.store.articleToEdit && <div className={`${styles.IA_widgetsSelector} ${this.props.store.darkMode ? styles.dark : ''}`}>
                <h4>{this.localizationService.strings.PlayAdmin_PublishToArticleViewer}</h4>
                {
                  this.state.widgets?.map((widget) => {
                    // disable the checkbox if we are editing an article with the new layout,
                    // and the article viewer is from before the launch of the new article layout (because of no backwards compatibility)
                    const disableForOld = !this.props.store.articleToEdit.boxStyle && widget.created && new Date(widget.created) > PlayHelper.NEW_ARTICLES_LAUNCH_DATE;
                    if (!disableForOld) {
                      return <div
                        key={widget.id}
                        className={styles.IA_widgetsSelectorRow}
                      >
                        <div>
                          {widget.title}
                        </div>
                        <div
                          style={{ display: "flex" }}
                        >
                          <IACheckbox
                            highlightColor={this.props.store.highlightColor}
                            isChecked={(widget.articleViewer?.articles?.some(articleId => articleId === this.props.store.articleToEdit.id) || this.props.store.articleToEdit.widgetsToAddTo.some(widgetId => widget.id === widgetId)) && this.props.store.articleToEdit.widgetsToRemoveFrom.every(widgetId => widgetId !== widget.id)}
                            onChange={(isChecked) => {
                              if (isChecked) {
                                widget.articleViewer.articles.every(widgetId => widgetId != this.props.store.articleToEdit.id) && this.props.store.articleToEdit.widgetsToAddTo.push(widget.id);
                                this.props.store.articleToEdit.widgetsToRemoveFrom = [...this.props.store.articleToEdit.widgetsToRemoveFrom.filter(widgetId => widgetId !== widget.id)];
                              }
                              else {
                                widget.articleViewer.articles.some(articleId => articleId === this.props.store.articleToEdit.id) && this.props.store.articleToEdit.widgetsToRemoveFrom.push(widget.id);
                                this.props.store.articleToEdit.widgetsToAddTo = [...this.props.store.articleToEdit.widgetsToAddTo.filter(widgetId => widgetId !== widget.id)];
                              }
                            }}
                          />
                        </div>
                      </div>
                    }
                  })
                }
                {
                  this.state.isLoadingMore && <div>
                    <IASpinner color={this.props.store.highlightColor} />
                  </div>
                }
                {
                  this.state.widgets?.length < this.state.widgetsCount && <div className={styles.IA_widgetsSelectorLoadMore}>
                    <IAButton
                      label='Load more'
                      darkMode={this.props.store.darkMode}
                      buttonColor={this.props.store.highlightColor}
                      onClick={() => this.loadArticleViewers()}
                    />
                  </div>
                }

              </div>
              }
            </div>
            <div className={styles.IA_previewArea}>
              <PreviewSettings
                displayLayout={this.state.displayLayout}
                showFooter={this.state.showFooter}
                showRssTicker={this.state.showRssTicker}
                onLayoutChange={(layout: IPlaySlideLayout) => this.setState({ displayLayout: layout }, () => this.forceUpdate())}
                onShowFooterChange={() => this.setState({ showFooter: !this.state.showFooter }, () => this.forceUpdate())}
                onShowRSSChange={() => this.setState({ showRssTicker: !this.state.showRssTicker }, () => this.forceUpdate())}
              />
              <div
                className={styles.IA_preview}
                style={{
                  width: previewWidth,
                  height: previewHeight,
                  paddingLeft: this.state.displayLayout === "portraitOneRow" || this.state.displayLayout === "portraitTwoRows" ? `calc(50% - ${previewWidth / 2}px)` : 40
                }}
              >
                <div
                  className={styles.IA_previewScreen}
                  style={{
                    height: previewHeight,
                    width: previewWidth
                  }}
                >
                  {this.state.showRssTicker &&
                    <RssTicker
                      rssTicker={{}}
                      editMode={true}
                      isActive={true}
                      sizeUnit={(previewHeight > previewWidth ? previewWidth : previewHeight) / 100}
                      playerWidth={previewWidth}
                      bottom={this.state.showFooter ? ((previewHeight > previewWidth ? previewWidth : previewHeight) / 100) * 8 : 0}

                    />
                  }
                  {this.state.showFooter &&
                    <Footer
                      footer={this.props.store.profile?.footer}
                      sizeUnit={(previewHeight > previewWidth ? previewWidth : previewHeight) / 100}
                      city={this.props.store.city}
                      currentWeather={this.props.store.currentWeather}
                      editMode={true}
                    />
                  }
                  <Slide
                    index={0}
                    slide={previewSlide}
                    isActive={true}
                    height={previewHeight}
                    width={previewWidth}
                    left={0}
                    footer={undefined}
                    city={this.props.store.city}
                    currentWeather={this.props.store.currentWeather}
                    environment={this.props.store.environment}
                    tenantId={this.props.store.tenantId}
                    profileId={this.props.store.profile.id}
                    editPreviewMode
                    showActivityLabel
                    standalone
                    forecast={this.props.store.weatherData}
                    nextSlide={() => { }}
                    rssTicker={this.state.showRssTicker ? { } : undefined}
                    isHardcodedWeatherData={this.props.store.isUsingHardCodedWeather}
                  />
                </div>
              </div>
            </div>
          </div>
          :
          <div
            className={styles.IA_loading}
            style={{
              backgroundColor: this.props.store.darkMode ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"
            }}
          >
            <IASpinner
              color={this.props.store.highlightColor}
            />
            <div className={styles.IA_weAreWorkingOnIt}>{this.localizationService.strings.Composer_WeAreWorkingOnIt}</div>
          </div>
        }
      </IAPanel>
    );
  }


}