exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_menuItem_2edq78gzAE-Fiq0v0-x9f2, .IA_menuItemDark_2W6p9X4CJ9uV-s6sQwMCbd {\n  position: relative;\n  float: left;\n  height: 50px;\n  line-height: 50px;\n  width: 100%;\n  cursor: pointer;\n  box-sizing: border-box;\n  overflow: hidden;\n}\n\n.IA_menuItem_2edq78gzAE-Fiq0v0-x9f2:hover {\n  background-color: #eeeeee;\n}\n\n.IA_menuItemDark_2W6p9X4CJ9uV-s6sQwMCbd:hover {\n  background-color: #414141;\n}\n\n.IA_menuItemIcon_3PDh9RkbPPFRHoVNKJ3RIk {\n  position: relative;\n  float: left;\n  height: 50px;\n  line-height: 50px;\n  width: 50px;\n  margin-left: -3px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.IA_menuItemText_MDFmrEsnoC8Q8WKuf4dCJ {\n  position: relative;\n  height: 50px;\n  line-height: 50px;\n  width: calc(100% - 50px);\n  box-sizing: border-box;\n}", ""]);

// Exports
exports.locals = {
	"IA_menuItem": "IA_menuItem_2edq78gzAE-Fiq0v0-x9f2",
	"IA_menuItemDark": "IA_menuItemDark_2W6p9X4CJ9uV-s6sQwMCbd",
	"IA_menuItemIcon": "IA_menuItemIcon_3PDh9RkbPPFRHoVNKJ3RIk",
	"IA_menuItemText": "IA_menuItemText_MDFmrEsnoC8Q8WKuf4dCJ"
};