import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './GroupEditPanel.css';
import { Store } from '../../../../stores';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import { IAPanel } from '../../../../../panels';
import { IAButton } from '../../../../../button/Button';
import { IATextField } from '../../../../../textField/TextField';
import { IPlayerGroup } from '../../../../../../interfaces/IPlay';
import PlayService from '../../../../../../services/PlayService';

export interface IProps {
  id: string;
  store?: Store;
  onClose: () => void;
}

export interface IState {
}

@inject('store')
@observer
export class GroupEditPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (this.props.id !== props.id && props.id != undefined) {
      if (this.props.store.groups?.length > 0) {
        this.props.store.groups.forEach((group: IPlayerGroup) => {
          if (group.id === props.id) {
            this.props.store.groupToEdit = group;
          }
        })
      }
    }
  }

  public render(): JSX.Element {
    return (
      <IAPanel
        open={this.props.store.groupToEdit != undefined}
        transition={"slide"}
        showNavigationBar={true}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        width={440}
        dataAutomationIdPrefix="group-edit-panel" 
        navigationsBarContent={
          <IAButton
            label={this.props.store.groupToEdit?.id ? this.localizationService.strings.PlayAdmin_Update?.toUpperCase() : this.localizationService.strings.PlayAdmin_Save?.toUpperCase()}
            buttonColor={this.props.store.highlightColor}
            disbaled={!(this.props.store.groupToEdit?.title)}
            darkMode={this.props.store.darkMode}
            onClick={() => {
              this.props.store.workingOnIt = true;
              if ( this.props.store.groupToEdit?.id) {
                PlayService.updateGroup(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, this.props.store.groupToEdit).then(() => {
                  this.props.store.groupToEdit = undefined;
                  this.props.store.workingOnIt = false;
                  this.props.onClose();
                });
              } else {
                PlayService.createGroup(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, this.props.store.groupToEdit).then(() => {
                  this.props.store.groupToEdit = undefined;
                  this.props.store.workingOnIt = false;
                  this.props.onClose();
                });
              }
            }}
            borderRadius={5}
            showSpinner={this.props.store.workingOnIt}
            style={{
              float: "right",
              position: "relative",
              marginTop: 7,
              marginRight: 10
            }}
          />
        }
        darkMode={this.props.store.darkMode}
        close={() => {
          this.props.store.groupToEdit = undefined;
          this.props.onClose();
        }}
      >
        {this.props.store.groupToEdit &&
          <div
            className={styles.IA_groupEditPanel}
            style={{
              height: window.innerHeight - 44,
              color: this.props.store.darkMode ? "#ffffff" : "#333333"
            }}
          >
            <div className={styles.IA_content}>
              {/* <h1>{this.localizationService.strings.PlayAdmin_AddGroup}</h1> */}
              <IATextField
                label={this.localizationService.strings.PlayAdmin_GroupTitle}
                text={this.props.store.groupToEdit.title}
                darkMode={this.props.store.darkMode}
                highlightColor={this.props.store.highlightColor}
                borderRadius={5}
                labelStyle={{
                  textTransform: "uppercase",
                  fontSize: 12
                }}
                onChange={(title: string) => {
                  this.props.store.groupToEdit.title = title;
                  this.forceUpdate();
                }}
              />
             
            </div>
          </div>
        }
      </IAPanel>
    );
  }

  
}