import * as React from 'react';
import Moment from 'react-moment';
// import ReactPlayer from 'react-player';
import { Helper } from '../../../../../Helper';
import { IPlayBoxStyle, IPlayWidgetAreaType } from '../../../../../interfaces/IPlay';
import DateTimeService from '../../../../../services/DateTimeService';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAIcon } from '../../../../icon/Icon';
import { PlayHelper } from '../../../../playAdmin/PlayHelper';
import * as styles from './RotatorItem.css';
import { Image } from '../../image/Image';
import { IEnvironment } from '../../../../../interfaces/IEnvironment';
import { IAReader } from '../../../../reader/Reader';
import { QRCode } from './QRCode/QRCode';
import { Video } from '../../video/Video';
import { Postcard } from './views/Postcard';

export interface IProps {
  title: string;
  text: string;
  date?: string;
  author?: string;
  image: string;
  video?: string; // Messages, Article
  gallery?: string[]; // Messages, Article
  link?: string; // Messages, Articles
  linkText?: string; // Articles
  startDate?: string; // Event
  endDate?: string; // Event
  isAllDayEvent?: boolean; // Event
  location?: string; // Event

  sizeUnit: number;
  playerWidth: number;
  playerHeight: number;
  active: boolean;
  standalone: boolean;
  width: number;
  height: number;
  duration: number;

  boxStyle: IPlayBoxStyle;
  boxStyleWidth?: number;
  boxStyleRoundCorner?: boolean;
  colorTheme: { textColor: string, backgroundColor: string };
  contentVerticalPosition?: "top" | "bottom";
  contentPosition?: "left" | "right" | "random";
  areaType: IPlayWidgetAreaType;
  showTimeBox: boolean;
  showTime: boolean;
  showProgressBar?: boolean;
  keepOriginalImage?: boolean;
  excludeImageFromImageAPI?: boolean;

  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  language?: string;
  articleEditMode?: boolean;
  widgetTitleIsShowing?: boolean;
  headerImage?: string;
  headerTitle?: string;
  headerSubtitle?: string;

  nextRotatorItem: () => void;
  willRestart: () => void;
}

export interface IState {
  progressBar: number;
  progressBarDuration: number;
  scrollTop: number;
  scrollTopToBeSet: number;
  scrollDuration: number;
  messageTextHeight?: number;
  padding: number;
  marginTop: number;
  duration?: number;
  ready: boolean;
  imageHasFailed?: boolean;
  isVertical?: boolean;
  contentPosition?: "left" | "right";
}

export class RotatorItem extends React.Component<IProps, IState> {

  private readonly dateTimeService: DateTimeService = new DateTimeService();
  private localizationService: LocalizationService;

  private _isMounted: boolean;
  private _hasStartedScroll: boolean;
  private bylineMarginTop: number;
  private timeMarginTop: number;
  private locationMarginTop: number;
  private index: string;

  constructor(props: IProps) {
    super(props);
    this.state = {
      progressBar: 0,
      progressBarDuration: undefined,
      scrollTop: 0,
      scrollTopToBeSet: undefined,
      scrollDuration: undefined,
      padding: props.sizeUnit * 5,
      marginTop: 0,
      ready: false
    };
    this.bylineMarginTop = props.sizeUnit * 1.4;
    this.timeMarginTop = props.sizeUnit * 2.5;
    this.locationMarginTop = props.sizeUnit;
    this.index = Helper.getRandomStringKey();
    this.localizationService = new LocalizationService(PlayHelper.getLocalizerLanguageFromPlayerLanguage(this.props.language));
  }

  public componentDidMount(): void {
    this._isMounted = true;
    this._hasStartedScroll = false;
    this.setFontSizes();
    if (this.props.areaType === "portraitFull" || this.props.areaType === "portraitHalf" || this.props.areaType === "vertical") {
      this.setState({ isVertical: true });
    }
  }

  private setFontSizes(): void {
    try {
      var css: any = document.createElement('style');
      css.type = 'text/css';
      css.appendChild(document.createTextNode(`#IA_ContentWrapper_${this.index} .ql-snow .ql-editor p { font-size: ${this.props.sizeUnit * 2.8}px !important; lineHeight: ${this.props.sizeUnit * 4}px !important}`));
      css.appendChild(document.createTextNode(`#IA_ContentWrapper_${this.index} .ql-snow .ql-editor li { font-size: ${this.props.sizeUnit * 2.8}px !important; lineHeight: ${this.props.sizeUnit * 4}px !important}`));
      css.appendChild(document.createTextNode(`#IA_ContentWrapper_${this.index} .ql-snow .ql-editor h1 { font-size: ${this.props.sizeUnit * 4.5}px !important}`));
      css.appendChild(document.createTextNode(`#IA_ContentWrapper_${this.index} .ql-snow .ql-editor h2 { font-size: ${this.props.sizeUnit * 3.5}px !important}`));
      document.getElementsByTagName("head")[0].appendChild(css);
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props !== this.props) {
      if (this.state.contentPosition == undefined || props.contentPosition !== "random") {
        this.setState({ contentPosition: props.contentPosition && this.props.image != undefined ? (props.contentPosition === "random" ? PlayHelper.getRandomPosition() : props.contentPosition) : "left" });
      }
      if (this.props.areaType === "portraitFull" || this.props.areaType === "portraitHalf" || this.props.areaType === "vertical") {
        this.setState({ isVertical: true });
      }
      if (props.active) {
        setTimeout(() => {
          this.startIfReady();
        }, 0);
      } else {
        this.resetMessage();
      }
    }
  }

  private nextRotatorItem() {
    try {
      if (this.props.active) {
        if (this.props.standalone) {
          this.resetMessage()
          this.props.willRestart();
        } else {
          this.props.nextRotatorItem();
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private startIfReady() {
    try {
      const contentBox = document.getElementById(`IA_ContentBox_${this.index}`);
      const messageDiv = document.getElementById(`IA_ContentWrapper_${this.index}`);
      const messageTextDiv = document.getElementById(`IA_MessageTextContent_${this.index}`);
      const messageTitleDiv = document.getElementById(`IA_Title_${this.index}`);
      const bylineDiv = document.getElementById(`IA_Byline_${this.index}`);
      const timeDiv = document.getElementById(`IA_Time_${this.index}`);
      const locationDiv = document.getElementById(`IA_Location_${this.index}`);
      const byLineHeight = bylineDiv ? bylineDiv.clientHeight + this.bylineMarginTop : 0;
      const timeHeight = timeDiv ? timeDiv.clientHeight + this.timeMarginTop : 0;
      const locationHeight = locationDiv ? locationDiv.clientHeight + (this.props.startDate ? this.locationMarginTop : this.timeMarginTop) : 0;
      const progressBarHeight = (this.props.sizeUnit * 2.5) + (this.props.sizeUnit * 2.5) + (this.props.sizeUnit * 0.5);
      if (!this.state.duration && !this.props.video) {
        const duration = this.props.boxStyle === "onlyMedia" ? 0 : this.getTextDuration();
        if (!this.props.video) {
          this.setState({ duration: Math.max(duration, this.props.duration) }, () => {
            setTimeout(() => {
              this.nextRotatorItem();
            }, this.state.duration * 1000);
          });
        } else {
          this.setState({ duration: Math.max(duration, this.props.duration) });
        }
      }
      if (messageTitleDiv) {
        let height = this.state.isVertical && (this.props.image || this.props.video || this.props.gallery) && (this.props.boxStyle !== "fullSolid" && this.props.boxStyle !== "fullTransparent") ? this.props.height / 2 : this.props.height;
        this.setState({ messageTextHeight: height - messageTitleDiv.clientHeight - this.state.padding - progressBarHeight });
      }
      if (messageDiv && messageTextDiv && this.state.messageTextHeight) {
        let combinedContentHeight = messageTextDiv.clientHeight + messageTitleDiv.clientHeight + progressBarHeight + byLineHeight + timeHeight + locationHeight;
        if (this.props.areaType === "vertical" && this.props.link) {
          combinedContentHeight = combinedContentHeight + (this.props.sizeUnit * 3.3) + (this.props.sizeUnit * 8.3);
        }
        const showQRCodeInline = this.props.link && (this.props.boxStyle === "fullTransparent" || this.props.boxStyle === "fullSolid" || (this.props.image == undefined && this.props.video == undefined && this.props.gallery == undefined));
        if (showQRCodeInline) {
          combinedContentHeight += this.props.sizeUnit * 13;
        }
        if (combinedContentHeight < (contentBox.clientHeight - (this.state.padding * 2)) && this.props.areaType !== "small") {
          this.setState({ marginTop: (contentBox.clientHeight - combinedContentHeight - (this.state.padding * 2)) / 2 });
        }
        const availableHeight = messageDiv.clientHeight;
        let contentHeight = messageTextDiv.scrollHeight;
        if (this.state.duration) {
          if (!this.state.scrollTopToBeSet && !this._hasStartedScroll) {
            this.setState({
              scrollTopToBeSet: -(contentHeight - availableHeight + (this.state.padding)),
              scrollDuration: this.state.duration
            }, () => {
              this.startAnimations();
            });
          } else {
            this.startAnimations();
          }
        } else {
          this.setState({ ready: true });
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private getTextDuration(): number {
    try {
      let duration = 0;
      if (this.props.title) {
        duration += PlayHelper.getReadingDurationOfText(this.props.title, 0);
      }
      if (this.props.text) {
        duration += PlayHelper.getReadingDurationOfText(Helper.stripHtml(this.props.text), 0);
      }
      return duration;
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private startAnimations(): void {
    try {
      if (!this._isMounted) {
        return;
      }
      this.setState({ progressBar: 0, ready: true });
      setTimeout(() => {
        this.setState({ progressBarDuration: this.state.duration - 1, progressBar: (this.props.width / 4) });
      }, 0);
      if (this.state.scrollTopToBeSet < 0 && !this.props.articleEditMode) {
        this.setState({ scrollTop: this.state.scrollTopToBeSet, scrollDuration: this.getTextDuration() });
      }
      this._hasStartedScroll = true;
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private resetMessage(): void {
    try {
      if (!this._isMounted) {
        return;
      }
      this.setState({ scrollDuration: 0.5, scrollTop: 0, progressBarDuration: 0, progressBar: 0, duration: undefined }, () => {
        if (this.props.standalone) {
          setTimeout(() => {
            this.startIfReady();
          }, 500);
        }
        this._hasStartedScroll = false;
      });
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private getGalleryStyleForIndex(index: number): { width: string, height: string } {
    try {
      const length = this.props.gallery.length >= 10 ? 9 : this.props.gallery.length;
      let style;
      switch (length) {
        case 1:
          style = {
            width: "100%",
            height: "100%",
          };
          break;
        case 2:
          style = {
            width: "100%",
            height: "50%",
          };
          break;
        case 3:
          if (index < 2) {
            style = {
              width: "50%",
              height: "50%",
            };
          } else {
            style = {
              width: "100%",
              height: "50%",
            };
          }
          break;
        case 4:
          style = {
            width: "50%",
            height: "50%",
          };
          break;
        case 5:
          if (index === 2) {
            style = {
              width: "100%",
              height: "33.33%",
            };
          } else {
            style = {
              width: "50%",
              height: "33.33%",
            };
          }
          break
        case 6:
          style = {
            width: "50%",
            height: "33.33%",
          };
          break;
        case 7:
          if (index > 1 && index < 5) {
            style = {
              width: "33.33%",
              height: "33.33%",
            };
          } else {
            style = {
              width: "50%",
              height: "33.33%",
            };
          }
          break;
        case 8:
          if (index > 2 && index < 5) {
            style = {
              width: "50%",
              height: "33.33%",
            };
          } else {
            style = {
              width: "33.33%",
              height: "33.33%",
            };
          }
          break;
        case 9:
          style = {
            width: "33.33%",
            height: "33.33%",
          };
          break;
        default:
          break;
      }
      return style;
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  private formatText(): void {
    try {
      const contentBox = document.getElementById(`IA_ContentBox_${this.index}`);
      if (contentBox) {
        const links = contentBox.getElementsByTagName("a");
        if (links?.length > 0) {
          for (let linkIndex = 0; linkIndex < links.length; linkIndex++) {
            links[linkIndex].style.color = this.props.colorTheme?.textColor;
            links[linkIndex].style.textDecoration = "none";
          }
        }
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  public render(): JSX.Element {
    let contentBoxWidth = (this.props.image || this.props.video || this.props.gallery) ? "50%" : "100%";
    let left: string | number = 0;
    let right: string | number = 0;
    let contentBoxLeftPadding: any = this.state.padding;
    let contentBoxRightPadding: any = this.state.padding;
    let background;
    let top: string | number = 0;
    let bottom: string | number = "auto";
    let imageWidth;
    let imageLeft;
    let imageHeight = "100%";
    let borderRadius: string | number = 0;
    let boxStyle = this.props.boxStyle;
    let contentBoxHeight;
    let isBoxStyle;
    const galleryImages = [];
    const showQRCodeInline = this.props.link && (this.props.boxStyle === "fullTransparent" || this.props.boxStyle === "fullSolid" || (!this.props.image && !this.props.video && !this.props.gallery) || this.props.areaType === "small");
    const showQRCodeInCorner = !showQRCodeInline && this.props.link && ((this.props.areaType === "full" || (this.state.isVertical && (this.props.image || this.props.video || this.props.gallery))) && (this.props.boxStyle !== "fullTransparent" && this.props.boxStyle !== "fullSolid") || (this.props.areaType === "small" && this.props.boxStyle === "onlyMedia"));
    try {
      switch (boxStyle) {
        case "solid":
          background = this.props.colorTheme?.backgroundColor;
          left = this.state.contentPosition === "right" && this.props.areaType === "full" ? "50%" : 0;
          break;
        case "transparent":
          background = this.props.colorTheme?.backgroundColor + "db";
          left = this.state.contentPosition === "right" && this.props.areaType === "full" ? "50%" : 0;
          break;
        case "hidden":
          background = "transparent";
          left = this.state.contentPosition === "right" && this.props.areaType === "full" ? "50%" : 0;
          break;
        case "gradient":
          contentBoxLeftPadding = this.state.padding;
          contentBoxRightPadding = (this.props.width / 10);
          left = this.state.contentPosition === "right" && this.props.areaType === "full" ? "50%" : 0;
          contentBoxWidth = "60%";
          background = `linear-gradient(${this.state.isVertical ? "180deg" : "-90deg"}, ${this.props.colorTheme?.backgroundColor}00 0%, ${this.props.colorTheme?.backgroundColor}ba 20%,${this.props.colorTheme?.backgroundColor} 100%)`;
          if (this.state.contentPosition === "right" && this.props.areaType === "full") {
            background = `linear-gradient( 90deg, ${this.props.colorTheme?.backgroundColor}00 0%, ${this.props.colorTheme?.backgroundColor}ba 18%,${this.props.colorTheme?.backgroundColor} 100%)`;
            contentBoxLeftPadding = `calc(10% + ${this.state.padding}px)`;
            contentBoxRightPadding = this.state.padding;
            left = "40%";
          }
          break;
        case "fullTransparent":
          contentBoxWidth = "100%";
          left = 0;
          if (this.props.gallery?.length > 0) {
            contentBoxLeftPadding = this.state.padding;
            contentBoxRightPadding = `calc(50% + ${this.state.padding}px)`;
          } else {
            // make text full when there is only text
            contentBoxLeftPadding = this.state.padding;
          }
          background = this.props.colorTheme?.backgroundColor + "db";
          break;
        case "fullSolid":
          contentBoxWidth = "100%";
          left = 0;
          background = this.props.colorTheme?.backgroundColor;
          if (this.props.gallery?.length > 0) {
            contentBoxLeftPadding = this.state.padding;
            contentBoxRightPadding = `calc(50% + ${this.state.padding}px)`;
            contentBoxWidth = "60%";
          } else {
            // make text full when there is only text
            contentBoxLeftPadding = this.state.padding;
          }
          break;
        case "box":
          contentBoxWidth = `${this.props.boxStyleWidth ?? 40}%`;
          left = this.state.contentPosition === "left" ? this.props.sizeUnit * 4 : "auto";
          right = this.state.contentPosition === "right" ? this.props.sizeUnit * 4 : "auto";
          contentBoxLeftPadding = this.state.padding;
          background = this.props.colorTheme?.backgroundColor;
          break;
        case "boxTransparent":
          contentBoxWidth = `${this.props.boxStyleWidth ?? 40}%`;
          left = this.state.contentPosition === "left" ? this.props.sizeUnit * 4 : "unset";
          right = this.state.contentPosition === "right" ? this.props.sizeUnit * 4 : "unset";
          contentBoxLeftPadding = this.state.padding;
          background = this.props.colorTheme?.backgroundColor + "db";
          break;
        case "boxHidden":
          contentBoxWidth = `${this.props.boxStyleWidth ?? 40}%`;
          left = this.state.contentPosition === "left" ? this.props.sizeUnit * 4 : "unset";
          right = this.state.contentPosition === "right" ? this.props.sizeUnit * 4 : "unset";
          contentBoxLeftPadding = this.state.padding;
          background = "transparent";
          break;
      }
      if (this.props.areaType === "small") {
        contentBoxWidth = "100%";
        left = "0px";
        contentBoxLeftPadding = this.state.padding;
        if (boxStyle === "gradient" || boxStyle === "box" || boxStyle === "boxTransparent") {
          boxStyle = "fullTransparent";
          background = this.props.colorTheme?.backgroundColor + "db";
        }
      }
      isBoxStyle = boxStyle === "box" || boxStyle === "boxTransparent" || boxStyle === "boxHidden";
      if (isBoxStyle) {
        if (this.props.contentVerticalPosition === "top") {
          top = this.props.sizeUnit * 4;
          bottom = "unset";
        } else {
          top = "unset";
          bottom = this.props.sizeUnit * 4;
        }
      }
      contentBoxHeight = isBoxStyle ? "auto" : "100%";

      // Gallery
      if (this.props.gallery?.length > 0) {
        this.props.gallery.forEach((image: string, index: number) => {
          const style = this.getGalleryStyleForIndex(index);
          if (style?.height && style?.width) {
            galleryImages.push(
              <div
                style={{
                  float: "left",
                  ...style
                }}
              >
                <Image
                  imageUrl={image}
                  height={style.height}
                  width={style.width}
                  environment={this.props.environment}
                  profileId={this.props.profileId}
                  tenantId={this.props.tenantId}
                  isActive={this.props.active}
                />
              </div>
            );
          }
        });
      }

      // Text
      this.formatText();
      const contentBox = document.getElementById(`IA_ContentBox_${this.index}`);
      if (contentBox) {
        const paragraphs: any = contentBox.getElementsByTagName('p');
        if (paragraphs) {
          for (let paragraph of paragraphs as HTMLParagraphElement[]) {
            if (paragraph.parentElement.classList.contains("ql-editor")) continue;
            paragraph.style.fontSize = `${this.props.sizeUnit * 2.9}px`;
            paragraph.style.color = this.props.colorTheme?.textColor;
          }
        }
      }
      // Image
      if (this.props.areaType === "full") {
        imageLeft = boxStyle === "solid" && this.state.contentPosition === "left" ? "50%" : 0;
      } else {
        imageLeft = 0;
      }
      if (isBoxStyle && this.props.boxStyleRoundCorner && document.getElementById(`IA_ContentBox_${this.index}`)) {
        const boxView = document.getElementById(`IA_ContentBox_${this.index}`);
        const width = boxView.clientWidth;
        const height = boxView.clientHeight;
        borderRadius = Math.max(width, height) * 0.03
      }
      imageWidth = boxStyle === "solid" ? "50%" : "100%";
      if (this.props.width < this.props.height) {
      }
      // Vertical view
      if (this.state.isVertical && (this.props.image || this.props.video || this.props.gallery) && !(boxStyle === "box" || boxStyle === "boxTransparent" || boxStyle === "fullTransparent" || boxStyle === "fullSolid")) {
        imageWidth = "100%";
        contentBoxWidth = "100%";
        contentBoxHeight = "50%";
        top = "50%";
        imageHeight = boxStyle === "solid" ? "50%" : "100%";
      }
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
    let marginLeft = 0;
    let marginTop = 0;
    let areaHeight = this.props.height;
    let areaWidth = this.props.width;
    const imageContainer = document.getElementById(`IA_image_${this.index}`);
    if (imageContainer) {
      areaHeight = imageContainer.clientHeight;
      areaWidth = imageContainer.clientWidth;
      if (this.props.active) {
        if (!this.props.keepOriginalImage) {
          const newAreaWidth = (areaHeight / 9) * 16;
          if (newAreaWidth < areaWidth) {
            const newAreaHeight = areaWidth / 16 * 9
            marginTop = (areaHeight - newAreaHeight) / 2;
            areaHeight = newAreaHeight;
          } else {
            marginLeft = (areaWidth - newAreaWidth) / 2;
            areaWidth = newAreaWidth;
          }
        }
      }
    } else {
      setTimeout(() => {
        this.forceUpdate();
      }, 500);
    }
    return this.state.contentPosition ? (
      (this.props.boxStyle === "postcard" ?
        <Postcard
          colorTheme={this.props.colorTheme}
          image={this.props.image}
          video={this.props.video}
          title={this.props.title}
          text={this.props.text}
          link={this.props.link}
          date={this.props.date}
          linkText={this.props.linkText}
          environment={this.props.environment}
          profileId={this.props.profileId}
          tenantId={this.props.tenantId}
          active={this.props.active}
          excludeImageFromImageAPI={this.props.excludeImageFromImageAPI}
          width={this.props.width}
          height={this.props.height}
          sizeUnit={this.props.width / 100}
          headerImage={this.props.headerImage}
          author={this.props.author}
          headerTitle={this.props.headerTitle}
          headerSubtitle={this.props.headerSubtitle}
          isVertical={this.state.isVertical}
        />
        :
        <div
          className={styles.IA_message}
        >
          <div
            className={styles.IA_image}
            id={`IA_image_${this.index}`}
            style={{
              width: imageWidth,
              height: imageHeight,
              left: imageLeft
            }}
          >
            {this.props.video == undefined && this.props.gallery == undefined &&
              <Image
                imageUrl={this.props.image}
                height="100%"
                width="100%"
                environment={this.props.environment}
                profileId={this.props.profileId}
                tenantId={this.props.tenantId}
                boxStyle={boxStyle}
                keepOriginal={this.props.keepOriginalImage}
                isActive={this.props.active}
                excludeFromImageAPI={this.props.excludeImageFromImageAPI}
                onError={() => {
                  console.log(`${this.props.image} failed loading`);
                  this.setState({ imageHasFailed: true });
                }}
                onLoad={() => {
                  this.setState({ imageHasFailed: false });
                }}
              />
            }
            {this.props.gallery && this.props.video == undefined &&
              galleryImages
            }
            {this.props.video && this.props.active && imageContainer &&
              <Video
                type={"video"}
                videoUrl={PlayHelper.getVideoFromIntranetApiIfVideoIsFromSharepoint(this.props.environment, this.props.video)}
                width={areaWidth}
                height={areaHeight}
                marginLeft={marginLeft}
                marginTop={marginTop}
                playerHeight={this.props.height}
                playerWidth={this.props.width}
                showTitle={false}
                title={undefined}
                environment={this.props.environment}
                profileId={this.props.profileId}
                tenantId={this.props.tenantId}
                willRestart={() => { }}
                onDuration={(duration: number) => {
                  const textDuration = this.getTextDuration();
                  if (duration > textDuration) {
                    this.setState({ duration });
                  } else {
                    this.setState({ duration: textDuration });
                  }
                  this.startIfReady();
                  setTimeout(() => {
                    this.nextRotatorItem();
                  }, this.state.duration * 1000);
                }}
              />
            }
          </div>
          <div
            className={styles.IA_contentBox}
            id={`IA_ContentBox_${this.index}`}
            style={{
              color: this.props.colorTheme?.textColor,
              background: background,
              width: contentBoxWidth,
              height: contentBoxHeight,
              top: top,
              bottom: bottom,
              left: left,
              right: right,
              padding: this.state.padding,
              paddingLeft: contentBoxLeftPadding,
              paddingRight: contentBoxRightPadding,
              zIndex: boxStyle === 'solid' ? 0 : 1,
              maxHeight: isBoxStyle ? "90%" : "100%",
              overflow: "hidden",
              borderRadius: borderRadius,
              opacity: this.props.boxStyle === "onlyMedia" ? 0 : 1, // remove text when onlyMedia is set
            }}
          >
            <div
              style={{
                opacity: this.state.ready ? 1 : 0,
              }}>
              <div
                id={`IA_Title_${this.index}`}
                className={styles.IA_title}
                style={{
                  fontSize: `${this.props.sizeUnit * 5}px`,
                  marginTop: isBoxStyle ? 0 : this.state.marginTop,
                  opacity: this.props.title === "" ? 0.3 : 1
                }}
              >
                {this.props.title !== "" ? this.props.title : this.localizationService.strings.PlayAdmin_MissionTitle}
              </div>
              {(this.props.author || this.props.date) &&
                <div
                  id={`IA_Byline_${this.index}`}
                  className={styles.IA_byline}
                  style={{
                    fontSize: `${this.props.sizeUnit * 1.7}px`,
                    marginTop: this.bylineMarginTop
                  }}
                >
                  {this.props.author &&
                    <span style={{ paddingRight: this.props.sizeUnit * 1.7 }}>{this.props.author}</span>
                  }
                  {this.props.date &&
                    <>
                      {this.props.author &&
                        <span style={{ paddingRight: this.props.sizeUnit * 1.7, opacity: 0.5 }}>|</span>
                      }
                      <Moment style={{ opacity: 0.5 }} fromNow locale={PlayHelper.getMomentJSLanguageCode(this.props.language)}>{this.props.date}</Moment>
                    </>
                  }
                </div>
              }
              {this.props.startDate && this.props.showTime &&
                <div
                  id={`IA_Time_${this.index}`}
                  className={styles.IA_byline}
                  style={{
                    fontSize: `${this.props.sizeUnit * 2.2}px`,
                    marginTop: this.timeMarginTop
                  }}
                >
                  <IAIcon
                    title="Calendar"
                    size={this.props.sizeUnit * 3.3}
                    color={this.props.colorTheme?.textColor}
                    style={{
                      float: "left"
                    }}
                  />
                  <div style={{
                    float: "left",
                    marginLeft: this.props.sizeUnit
                  }}>
                    {this.props.isAllDayEvent ? this.dateTimeService.ConvertToDDMMYYYY(this.props.startDate) : `${this.dateTimeService.ConvertToDDMMYYYYHHMM(this.props.startDate)} - ${this.dateTimeService.ConvertToDDMMYYYYHHMM(this.props.endDate)}`}
                  </div>
                </div>
              }
              {this.props.location &&
                <div
                  id={`IA_Location_${this.index}`}
                  className={styles.IA_byline}
                  style={{
                    fontSize: `${this.props.sizeUnit * 2.2}px`,
                    marginTop: this.props.startDate ? this.locationMarginTop : this.timeMarginTop
                  }}
                >
                  <IAIcon
                    title="Address"
                    size={this.props.sizeUnit * 3.3}
                    color={this.props.colorTheme?.textColor}
                    style={{
                      float: "left"
                    }}
                  />
                  <div style={{
                    float: "left",
                    marginLeft: this.props.sizeUnit
                  }}>
                    {this.props.location}
                  </div>
                </div>
              }
              {this.props.boxStyle !== "onlyMedia" && this.props.showProgressBar &&
                <div
                  className={styles.IA_progressBarBackground}
                  style={{
                    backgroundColor: this.props.colorTheme?.textColor + "5e",
                    width: this.props.width / 4,
                    height: this.props.sizeUnit * 0.5,
                    marginTop: this.props.sizeUnit * 2.5,
                    marginBottom: this.props.sizeUnit * 2.5,
                  }}
                >
                  {this.state.progressBar !== undefined && !this.props.articleEditMode &&
                    <div
                      className={styles.IA_progressBar}
                      style={{
                        width: this.state.progressBar,
                        transition: `width ${this.state.progressBarDuration}s linear`,
                        backgroundColor: this.props.colorTheme?.textColor,
                      }}
                    />
                  }
                </div>
              }
              <div
                className={styles.IA_contentWrapper}
                id={`IA_ContentWrapper_${this.index}`}
                style={{
                  marginTop: !this.props.showProgressBar ? this.props.sizeUnit * 2 : undefined,
                  height: isBoxStyle ? "auto" : this.state.messageTextHeight
                }}
              >
                <IAReader
                  useNewEditor
                  id={`IA_MessageTextContent_${this.index}`}
                  content={this.props.text ? this.props.text : ""}
                  style={{
                    position: "relative",
                    transition: `margin-top ${this.state.scrollDuration}s ease-in-out`,
                    marginTop: isBoxStyle ? 0 : this.state.scrollTop,
                    willChange: "marginTop",
                    fontSize: `${this.props.sizeUnit * 2.8}px`,
                    lineHeight: `${this.props.sizeUnit * 4}px`,
                    borderWidth: 1
                  }}
                />
                {showQRCodeInline &&
                  <QRCode
                    link={this.props.link}
                    linkText={this.props.linkText}
                    sizeUnit={this.props.sizeUnit}
                    language={this.props.language}
                    style={{
                      marginTop: this.props.sizeUnit * 2.5,
                      position: "relative",
                      float: "left"
                    }}
                  />
                }
              </div>
            </div>
          </div>
          {this.props.startDate && this.props.areaType === "full" && this.props.showTimeBox &&
            <div
              style={{
                position: "absolute",
                width: this.props.width / 6,
                height: this.props.width / 6,
                left: undefined,
                right: (this.props.width / 4) - this.props.width / 12,
                top: (this.props.height / 2) - (this.props.width / 12),
                zIndex: 999,
                backgroundColor: this.props.boxStyle === "onlyMedia" ? "#000000" : `${this.props.colorTheme?.backgroundColor}cc`,
                boxSizing: "border-box",
                border: this.props.image ? "1px solid transparent" : `1px solid ${this.props.colorTheme?.textColor}`
              }}
            >
              {/* One day event */}
              {this.dateTimeService.isStartAndEndDateTheSameDay(new Date(this.props.startDate), new Date(this.props.endDate)) ?
                /* All day event */
                this.props.isAllDayEvent ?
                  <div
                    className={styles.IA_now}
                    style={{
                      fontSize: `${this.props.sizeUnit * 5}px`,
                      color: this.props.colorTheme?.textColor,
                      lineHeight: `${this.props.width / 6}px`
                    }}
                  >
                    {this.localizationService.strings.Player_Now?.toUpperCase()}
                  </div>
                  :
                  this.dateTimeService.isStartAndEndDateTheSameDay(new Date(this.props.startDate), new Date()) ?
                    <>
                      <div
                        className={styles.IA_startDate}
                        style={{
                          fontSize: `${this.props.sizeUnit * 5}px`,
                          marginTop: this.props.sizeUnit * 7.1,
                          color: this.props.colorTheme?.textColor
                        }}
                      >
                        {this.dateTimeService.ConvertToHHMM(this.props.startDate)}
                      </div>
                      <div
                        className={styles.IA_startDateendDateDivider}
                        style={{
                          backgroundColor: this.props.colorTheme?.textColor
                        }}
                      />
                      <div
                        className={styles.IA_endDate}
                        style={{
                          fontSize: `${this.props.sizeUnit * 5}px`,
                          color: this.props.colorTheme?.textColor
                        }}
                      >
                        {this.dateTimeService.ConvertToHHMM(this.props.endDate)}
                      </div>
                    </>
                    :
                    <div
                      className={styles.IA_now}
                      style={{
                        fontSize: `${this.props.sizeUnit * 5}px`,
                        color: this.props.colorTheme?.textColor,
                        lineHeight: `${this.props.width / 6}px`
                      }}
                    >
                      {`${this.dateTimeService.getDayInMonth(this.props.startDate)}. ${this.dateTimeService.getNameOfMonth(this.props.startDate).substring(0, 3)?.toUpperCase()}`}
                    </div>
                :
                /* More than one day event */
                <>
                  <div
                    className={styles.IA_startDate}
                    style={{
                      fontSize: `${this.props.sizeUnit * 5}px`,
                      marginTop: this.props.sizeUnit * 7.1,
                      color: this.props.colorTheme?.textColor
                    }}
                  >
                    {!this.props.isAllDayEvent ?
                      `${this.dateTimeService.getDayInMonth(this.props.startDate)}. ${this.dateTimeService
                        .getNameOfMonth(this.props.startDate)
                        .substring(0, 3)
                        ?.toUpperCase()}`
                      :
                      `${this.dateTimeService.getDayInMonth(this.props.startDate)}. ${this.dateTimeService.getNameOfMonth(this.props.startDate).substring(0, 3)?.toUpperCase()}`
                    }
                  </div>
                  <div
                    className={styles.IA_startDateendDateDivider}
                    style={{
                      backgroundColor: this.props.colorTheme?.textColor
                    }}
                  />
                  <div
                    className={styles.IA_endDate}
                    style={{
                      fontSize: `${this.props.sizeUnit * 5}px`,
                      color: this.props.colorTheme?.textColor
                    }}
                  >
                    {`${this.dateTimeService.getDayInMonth(this.props.endDate)}. ${this.dateTimeService.getNameOfMonth(this.props.endDate).substring(0, 3)?.toUpperCase()}`}

                  </div>
                </>
              }
            </div>
          }
          {showQRCodeInCorner &&
            <QRCode
              link={this.props.link}
              linkText={this.props.linkText}
              sizeUnit={this.props.sizeUnit}
              language={this.props.language}
              style={{
                left: this.state.contentPosition === "left" ? "" : 20,
                right: this.state.contentPosition === "right" ? "" : 20,
                bottom: this.state.isVertical && boxStyle !== "onlyMedia" && boxStyle !== "box" ? "calc(50% + 20px)" : 20,
              }}
            />
          }
        </div >
      )
    ) : (<></>);
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }


}