import * as React from 'react';
import { Blurhash } from 'react-blurhash';
import { Helper } from '../../../../Helper';
import { IEnvironment } from '../../../../interfaces/IEnvironment';
import { IPlayBoxStyle } from '../../../../interfaces/IPlay';
import { PlayHelper } from '../../../playAdmin/PlayHelper';
import * as styles from './Image.css';
import { IndexDbFileService } from '../../../../services/IndexDbFileService';

export interface IProps {
  imageUrl: string;
  isActive: boolean;
  width?: number | string;
  height?: number | string;
  position?: "absolute" | "relative";
  float?: "left" | "right" | "none";
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  keepOriginal?: boolean;
  boxStyle?: IPlayBoxStyle;
  excludeFromImageAPI?: boolean;
  onError?: () => void;
  onLoad?: () => void;
}

export interface IState {
  isLoading: boolean;
  imageUrl: string;
  blurHash: string;
  randomId: string;
}

export class Image extends React.Component<IProps, IState> {
  private indexDbFileService: IndexDbFileService = new IndexDbFileService();
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: true,
      imageUrl: undefined,
      blurHash: undefined,
      randomId: Helper.getRandomStringKey()
    };
  }

  componentDidMount(): void {
    if (this.props.imageUrl && this.props.isActive) {
      this.setImage();
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.imageUrl !== this.props.imageUrl
      || !this.props.imageUrl
      || (prevProps.keepOriginal !== this.props.keepOriginal)
      || (prevProps.boxStyle !== this.props.boxStyle)
      || (this.props.isActive && prevProps.isActive !== this.props.isActive)) {
      this.setImage();
    }
  }

  private setImage() {
    try {
      let newImageUrl: string = this.props.imageUrl;
      let blurHash: string;
      const element = document.getElementById(`image_${this.props.imageUrl}_${this.state.randomId}`);
      if (element) {
        if (this.props.imageUrl?.indexOf("blurHash=") !== -1) {
          const index = this.props.imageUrl?.indexOf("blurHash=") + 9;
          blurHash = this.props.imageUrl?.substring(index, index + 36);
        }
        if (PlayHelper.excludeImageFromApi(this.props.environment, this.props.imageUrl) || this.props.excludeFromImageAPI) {
          // If the image is from Intranet, keep image as it is
          newImageUrl = this.props.imageUrl;
        } else {
          let format = PlayHelper.getStandardImageFormat(element.clientWidth, element.clientHeight);
          newImageUrl = PlayHelper.getImageFromApi(this.props.environment, this.props.tenantId, this.props.profileId, format, this.props.keepOriginal, this.props.imageUrl);
        }
      }
      this.indexDbFileService.getCachedFileUrl(newImageUrl).then((newUrl) => {
        if (newUrl !== this.state.imageUrl) {
          this.setState({
            imageUrl: newUrl,
            isLoading: true,
            blurHash: blurHash
          });
        }
      });
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  public render(): JSX.Element {
    return (<>
      {this.props.imageUrl &&
        <div
          id={`image_${this.props.imageUrl}_${this.state.randomId}`}
          style={{
            width: this.props.width ?? "100%",
            height: this.props.height ?? "100%",
            position: this.props.position ?? "absolute",
            float: this.props.float ?? "none"
          }}
        >
          {this.state.imageUrl &&
            <>
              <img
                className={styles.IA_imageImage}
                style={{
                  objectFit: this.props.keepOriginal ? "contain" : "cover",
                }}
                src={this.state.imageUrl}
                onLoad={() => this.setState({ isLoading: false }, () => this.props.onLoad && this.props.onLoad())}
                onError={() => this.props.onError && this.props.onError()}
              />
            </>
          }
          {this.state.blurHash &&
            <Blurhash
              hash={this.state.blurHash}
              width="100%"
              height="100%"
              style={{
                opacity: this.state.isLoading ? 1 : 0,
                transition: `opacity ${!this.state.isLoading ? ".5s" : "0s"}`
              }}
            />
          }
          {!this.state.blurHash && this.state.isLoading &&
            <div
              className={styles.IA_imageSkeleton}
            />
          }
        </div>
      }
    </>);
  }
}