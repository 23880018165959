import { Helper } from "./Helper";
import { Container } from "./Containers/Container";
import { FetchProxy } from "./Fetch/FetchProxy";

const TRUE: string = "true";

interface IPreviousFlagFormat {
  key: string;
  value: string;
}

export class TenantSettingsService<T> {

  public constructor(public readonly apiUrl: string, public readonly container?: Container) {}

  public async getSettings(tenantName: string, defaultSettings: T, token? :string): Promise<T> {
    const localStorage = Helper.getLocalStorage("settings");
    if (localStorage) {
      const formattedJsonObject = this.formatSettingsAsFlags(localStorage);
      return this.deepCopyFromDefault(formattedJsonObject, defaultSettings);
    } else {
      const apiUrl = this.apiUrl +`/${tenantName}`;
      
      if(!token) {
        token = Helper.getCookieValue("token");
      }
      
      let response: Response;
      if (this.container) {
        response = await new FetchProxy(apiUrl, { method: "GET", headers: {'Content-Type':'application/json', 'AuthToken': token} })
          .withContainer(this.container)
          .fetch();
      } else {
        response = await fetch(apiUrl, { method: "GET", headers: {'Content-Type':'application/json', 'AuthToken': token} })
      }

      if (!response.ok) {
        throw response;
      }

      const formattedJsonObject = this.formatSettingsAsFlags(await response.json());
      const jsonResponse = this.deepCopyFromDefault(formattedJsonObject, defaultSettings);
      Helper.setLocalStorage("settings", jsonResponse, 1);
      return jsonResponse;
    }
  }

  public formatSettingsAsFlags(obj: any): any {
    let tranformedObject = {};
    for(let prop in obj) {
      let objValue = obj[prop];
      let previousFlagFormat = objValue as IPreviousFlagFormat;
      if (previousFlagFormat.key) {
        tranformedObject[prop] = previousFlagFormat.value === TRUE;
      } else if (typeof(objValue) === "boolean") {
        tranformedObject[prop] = objValue;
      } else if (typeof(objValue) === "string") {
        tranformedObject[prop] = objValue === TRUE;
      } else {
        tranformedObject[prop] = this.formatSettingsAsFlags(objValue);
      }
    }
    return tranformedObject;
  }

  public deepCopyFromDefault(obj: T, defaultObj: T): T {
      for(let prop in defaultObj) {
        if (typeof(obj[prop]) === "object"){
          this.deepCopyFromDefaultWithAnyType(obj[prop], defaultObj[prop]);
        } else if (obj[prop] === null || obj[prop] === undefined) {
          obj[prop] = defaultObj[prop];
        }
      }
      return obj;
  }

  private deepCopyFromDefaultWithAnyType(obj: any, defaultObj: any): any {
    for(let prop in defaultObj) {
      if (typeof(obj[prop]) === "object"){
        this.deepCopyFromDefaultWithAnyType(obj[prop], defaultObj[prop]);
      } else if (obj[prop] === null || obj[prop] === undefined) {
        obj[prop] = defaultObj[prop];
      }
    }
    return obj;
}
}