import "./Global";

const SESSION_STORAGE_ITEM_ID = "IntraActive.Authentication.LiteTenantRequest";

export class TenantService {
  public constructor(
    private tenantName: string,
    private authenticationFunctionsUri: string) {
  }

  public isTenantLite(): Promise<boolean> {
    if (window.IntraActive.Authentication.LiteTenantRequestPromise){
      return window.IntraActive.Authentication.LiteTenantRequestPromise;
    }

    if (sessionStorage.getItem(SESSION_STORAGE_ITEM_ID)) {
      window.IntraActive.Authentication.LiteTenantRequestPromise = Promise.resolve(sessionStorage.getItem(SESSION_STORAGE_ITEM_ID) === "true");
      return window.IntraActive.Authentication.LiteTenantRequestPromise;
    }

    window.IntraActive.Authentication.LiteTenantRequestPromise = new Promise<boolean>((resolve, reject) => {
      fetch(`${this.authenticationFunctionsUri}/api/lite-check/${this.tenantName}`)
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          const normalizedText = text.toLowerCase();
          sessionStorage.setItem(SESSION_STORAGE_ITEM_ID, normalizedText);
          return resolve(normalizedText === "true");
        })
        .catch(() => resolve(false)); // In the worst case that the service fails, switch to non-lite
    });

    return window.IntraActive.Authentication.LiteTenantRequestPromise;
  }
}