import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import * as styles from './Permissions.css';
import PlayService from '../../../../../services/PlayService';
import { IListElement, IPlayUser } from '../../../../../interfaces/IPlay';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { Popup } from '../../../reusableComponents/popup/Popup';
import { IAButton } from '../../../../button/Button';
import { Helper } from '../../../../../Helper';
import { PermissionsEditPanel } from './permissionsEditPanel/PermissionsEditPanel';
import { IAListView } from '../../../../listView/ListView';

export interface IProps {
  showSkeleton: boolean;
  store?: Store;
}

export interface IState {
  idToRemove: string;
  isAddingUser: boolean;
}

@inject('store')
@observer
export class Permissions extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      idToRemove: undefined,
      isAddingUser: false
    }
  }

  public componentDidMount(): void {
    if (!this.props.showSkeleton) {
      this.getUsers();
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (!props.showSkeleton && props.showSkeleton !== this.props.showSkeleton) {
      this.getUsers();
    }
  }

  private getUsers() {
    this.props.store.workingOnIt = true;
    PlayService.getProfileUsers(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id).then((users: IPlayUser[]) => {
      this.props.store.profileUsers = users;
      this.props.store.workingOnIt = false;
    });
  }

  private onDelete(id: string) {
    this.props.store.workingOnIt = true;
    PlayService.removeUserFromProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, id).then(() => {
      this.props.store.workingOnIt = false;
      this.setState({ idToRemove: undefined });
      this.getUsers();
    });
  }

  private onAddUser(id: string) {
    PlayService.addUserToProfile(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile.id, id).then(() => {
      this.getUsers();
    })
  }

  public render(): JSX.Element {
    const listElements: IListElement[] = [];
    if (this.props.store.profileUsers?.length > 0) {
      this.props.store.profileUsers.forEach((profileUser: IPlayUser) => {
        listElements.push(
          {
            id: profileUser.userId,
            columns: [
              {
                header: this.localizationService.strings.PlayAdmin_DisplayName?.toUpperCase(),
                type: "text",
                text: profileUser.displayName,
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              },
              {
                header: this.localizationService.strings.PlayAdmin_UserPrincipalName?.toUpperCase(),
                type: "text",
                text: profileUser.userPrincipalName,
                backgroundColor: this.props.store.darkMode ? "#232323" : "#e6e6e2",
                color: this.props.store.darkMode ? "#ffffff" : "#333333",
              }
            ],
            buttons: [
              {
                action: "delete",
                icon: "Trash",
                color: this.props.store.highlightColor
              }
            ]
          }
        );
      });
    }

    return (
      <div className={styles.IA_permissions}>
        <PermissionsEditPanel
          isAddingUser={this.state.isAddingUser}
          onAddUser={(id) => this.onAddUser(id)}
          onClose={() => {
            this.setState({ isAddingUser: false })
            this.getUsers();
          }}
        />
        <IAButton
          label={this.localizationService.strings.PlayAdmin_Add?.toUpperCase()}
          buttonColor={this.props.store.highlightColor}
          darkMode={this.props.store.darkMode}
          onClick={() => {
            this.setState({ isAddingUser: true })
          }}
          borderRadius={5}
          showSpinner={this.props.store.workingOnIt}
          style={{
            float: "right",
            position: "relative",
            marginTop: 7
          }}
        />
        <IAListView
          id="ListViewOfPermissions"
          listElements={listElements}
          backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
          hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
          buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
          darkMode={this.props.store.darkMode}
          defaultAction={undefined}
          selectedAction={(action: string, id: string) => {
            switch (action) {
              case "delete":
                this.setState({ idToRemove: id });
                break;
              default:
                break;
            }
          }}
        />
        {this.state.idToRemove &&
          <Popup
            headline={this.localizationService.strings.PlayAdmin_RemoveUserFromProfile}
            close={() => this.setState({ idToRemove: undefined })}
            darkMode={this.props.store.darkMode}
            content={
              <>
                <IAButton
                  buttonColor={this.props.store.highlightColor}
                  label={this.localizationService.strings.DeletePopup_Yes}
                  darkMode={this.props.store.darkMode}
                  onClick={() => {
                    this.onDelete(this.state.idToRemove);
                  }}
                  borderRadius={5}
                  style={{
                    float: "left"
                  }}
                />
                <IAButton
                  textColor={this.props.store.highlightColor}
                  darkMode={this.props.store.darkMode}
                  label={this.localizationService.strings.DeletePopup_No}
                  onClick={() => this.setState({ idToRemove: undefined })}
                  style={{
                    float: "left",
                    marginLeft: 30
                  }}
                />
              </>
            }
          />
        }
      </div>
    );
  }

  
}