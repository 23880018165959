import * as msal from "@azure/msal-browser";
import { injectable } from "./Containers/Container";
import "./Global";

@injectable("MsalClientService")
export class MsalClientService {
  public constructor(
    public readonly msalInstance: msal.IPublicClientApplication,
    public readonly msalAccounts: msal.AccountInfo[],
    private readonly scope: string
    ) {}
    
  public getToken(): Promise<string>{
    return new Promise<string>((resolve, reject) => {
      const loginRequest = {
        scopes: [this.scope]
      }
      this.msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: this.msalAccounts[0]
      }).then((response) => {
        resolve(response.accessToken);
      }).catch(e => reject(e));
    });
  }
}