import * as React from 'react';
import { IAIcon } from '../../../icon/Icon';
import * as styles from './Popup.css';

export interface IIAPopupProps {
  headline?: string;
  content: JSX.Element;
  style?: React.CSSProperties;
  darkMode?: boolean;
  close?: () => void;
}

export class Popup extends React.Component<IIAPopupProps, {}> {

  constructor(props: IIAPopupProps) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <div className={this.props.darkMode ? styles.IA_popupDarkMode : styles.IA_popup}>
        <div
          className={styles.IA_popupBackground}
          onClick={() => {
            if (this.props.close) {
              this.props.close();
            }
          }}
        />
        <div
          className={styles.IA_popupContentBox}
          style={this.props.style}
        >
          {this.props.close != undefined &&
            <IAIcon
              title={"Close"}
              size={24}
              color={"#777777"}
              style={{
                float: "right",
                borderRadius: 24,
                width: 24,
                height: 24,
                lineHeight: "24px",
                marginBottom: 10
              }}
              onClick={() => this.props.close()}
            />
          }
          {this.props.headline &&
            <h1>{this.props.headline}</h1>
          }
          {this.props.content}
        </div>
      </div>
    );
  }
}