import * as React from 'react';
import { IAIcon } from '../../../../../icon/Icon';
import { IASpinner } from '../../../../../spinner/Spinner';
import * as styles from './SharePointSite.css';

interface IProps {
  siteName: string;
  siteId: string;
  highlightColor: string;
  disabled: boolean;
  onAdd: (id: string) => Promise<void>;
}

interface IState {
  executing: boolean;
}

export class SharePointSite extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      executing: false,
    }
  }
  
  public render(): JSX.Element {
    return <>
      <div
        className={styles.IA_sharePointSiteCard}
      >
        <div className={styles.IA_sharePointSiteWrapper}>
          <div>{this.props.siteName}</div>
          <div className={styles.IA_sharePointSiteId}>{this.props.siteId}</div>
        </div>
        <div>
          {this.state.executing ?
            <IASpinner
              style={{ margin: 0 }}
              color={this.props.highlightColor}
            />
            :
            <>
              {!this.props.disabled &&
                <IAIcon title='Add' color={this.props.highlightColor} size={20} style={{ display: 'pointer' }} onClick={async () => {
                  this.setState({ executing: true });
                  await this.props.onAdd(this.props.siteId);
                  this.setState({ executing: false });
                }} />
              }
            </>
          }
        </div>
      </div>
    </>
  }
}
