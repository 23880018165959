import * as React from 'react';
import { IPlayWeatherData, IPlayWidgetAreaType, IPlayWeatherDisplayMode, IPlayWeather } from '../../../../interfaces/IPlay';
import { LocalizationService } from '../../../../services/LocalizationService';
import { WeekHorizontalWeather } from './week/WeekHorizontalWeather';
import { WeekVerticalWeather } from './week/WeekVerticalWeather';
import { IColorTheme } from '../../../../interfaces/IColorTheme';
import * as styles from './Weather.css';
import { DayHorizontalWeather } from './day/DayHorizontalWeather';
import { DaySection } from './day/DaySection';
import { DayVerticalWeather } from './day/DayVerticalWeather';

export interface IProps {
  widgetId: string;
  forecast: IPlayWeatherData;
  width: number;
  height: number;
  sizeUnit: number;
  language?: string;
  areaType?: IPlayWidgetAreaType;
  colorTheme?: IColorTheme;
  isHardcodedWeatherData?: boolean;
  displayMode?: IPlayWeatherDisplayMode;
}

export interface IState {
}

export class Weather extends React.Component<IProps, IState> {
  private localizationService: LocalizationService;

  constructor(props: IProps) {
    super(props);
    this.state = {
    };
    this.localizationService = new LocalizationService(this.props.language);
  }

  private getDayString(i: number, time: string): string {
    if (i === 0) {
      return this.localizationService.strings.Player_Today;
    } else if (i === 1) {
      return this.localizationService.strings.Player_Tomorrow;
    } else {
      return this.getDayOfWeek(new Date(time).getDay());
    }
  }

  private getDayOfWeek(dayIndex: number): string {
    switch (dayIndex) {
      case 0:
        return this.localizationService.strings.Player_Sunday;
      case 1:
        return this.localizationService.strings.Player_Monday;
      case 2:
        return this.localizationService.strings.Player_Tuesday;
      case 3:
        return this.localizationService.strings.Player_Wednesday;
      case 4:
        return this.localizationService.strings.Player_Thursday;
      case 5:
        return this.localizationService.strings.Player_Friday;
      case 6:
        return this.localizationService.strings.Player_Saturday;
      default:
        return "";
    }
  }

  private getDaySections(): DaySection[] {
    const daySections: DaySection[] = [];
    this.props.forecast?.dayForecast && this.props.forecast.dayForecast.forEach((weather, i) => {
      const date = new Date(weather.time);
      const hour = date.getHours();
      const isNow = i === 0;
      if (isNow || hour % 6 === 0) {
        const weatherCopy = JSON.parse(JSON.stringify(weather)) as IPlayWeather; // deep copy
        daySections.push({ weather: weatherCopy, startDate: date.getDate(), ...this.getDaySectionsTitle(hour) });
      } else {
        daySections[daySections.length - 1].weather.precipitationAmount += weather.precipitationAmount;
      }
    });

    return daySections;
  }

  private getDaySectionsTitle(hour: number): { title: string, startTime: number } {
    if (6 <= hour && hour < 12) {
      return { title: this.localizationService.strings.Player_Morning, startTime: 6 };
    }
    if (12 <= hour && hour < 18) {
      return { title: this.localizationService.strings.Player_Afternoon, startTime: 12 };
    }
    if (18 <= hour && hour < 24) {
      return { title: this.localizationService.strings.Player_Evening, startTime: 18 };
    }
    return { title: this.localizationService.strings.Player_Night, startTime: 0 };
  }

  public render(): JSX.Element {
    const isHorizontal = this.props.areaType === "small" || this.props.areaType === "full";
    return (
      <>
        {this.props.forecast
          ?
          <>
            {this.props.isHardcodedWeatherData &&
              <div
                className={styles.IA_exampleWeatherLabel}
                style={{
                  height: this.props.height / 20,
                  width: this.props.height / 2,
                  left: -this.props.height / 7,
                  top: this.props.height / 14,
                  lineHeight: `${this.props.height / 20}px`,
                  fontSize: `${this.props.height / 45}px`,
                }}
              >
                {this.localizationService.strings.Player_ExampleWeather?.toUpperCase()}
              </div>
            }
            {isHorizontal
              ?
              <>
                {this.props.displayMode === "week" ?
                  <WeekHorizontalWeather
                    widgetId={this.props.widgetId}
                    forecast={this.props.forecast.forecast}
                    width={this.props.width}
                    height={this.props.height}
                    sizeUnit={this.props.sizeUnit}
                    isSmall={this.props.areaType === "small"}
                    colorTheme={this.props.colorTheme}
                    getDayString={(i, time) => this.getDayString(i, time)}
                  />
                  :
                  <DayHorizontalWeather
                    widgetId={this.props.widgetId}
                    forecast={this.props.forecast.dayForecast}
                    width={this.props.width}
                    height={this.props.height}
                    sizeUnit={this.props.sizeUnit}
                    isSmall={this.props.areaType === "small"}
                    colorTheme={this.props.colorTheme}
                    getDaySections={() => this.getDaySections()}
                  />
                }
              </>
              :
              <>
                {this.props.displayMode === "week" ?
                  <WeekVerticalWeather
                    widgetId={this.props.widgetId}
                    forecast={this.props.forecast.forecast}
                    width={this.props.width}
                    height={this.props.height}
                    sizeUnit={this.props.sizeUnit}
                    colorTheme={this.props.colorTheme}
                    getDayString={(i, time) => this.getDayString(i, time)}
                  />
                  :
                  <DayVerticalWeather
                    forecast={this.props.forecast.dayForecast}
                    width={this.props.width}
                    height={this.props.height}
                    sizeUnit={this.props.sizeUnit}
                    colorTheme={this.props.colorTheme}
                    getDaySections={() => this.getDaySections()}
                  />
                }
              </>
            }
          </>
          :
          <></>
        }
      </>
    );
  }
}