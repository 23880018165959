import * as React from "react";
import { Helper } from "../../../Helper";
import { IEventSettings } from "../../../interfaces/IEventSettings";
import DateTimeService from "../../../services/DateTimeService";
import { Environment } from "../../../services/Environment";
import { LocalizationService } from "../../../services/LocalizationService";
import { IAMessageVideo } from "../../messages/video/MessageVideo";
import * as styles from "./CardCover.css";

export interface ICardCoverProps {
  id?: string;
  imageURL?: string;
  videoURL?: string;
  html?: JSX.Element;
  eventSettings: IEventSettings;
  height: number;
  isMobile: boolean;
  cropSettings?: { x: number, y: number, width: number, height: number };
  modified?: Date;
  token?: string;
  tenant?: string;
  environment?: "Development" | "Test" | "Production";
  borderRadius?: number;
  onClickOpen: () => void;
  isPanelOpen?: boolean;
}

export interface ICardCoverState {
}

export class IACardCover extends React.Component<ICardCoverProps, ICardCoverState> {

  private readonly dateTimeService: DateTimeService = new DateTimeService();
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: ICardCoverProps) {
    super(props);
  }

  public render(): JSX.Element {
    const isEventToday = this.props.eventSettings && this.props.eventSettings.startDate ? this.dateTimeService.ConvertToDDMMYYYY(this.props.eventSettings.startDate) === this.dateTimeService.ConvertToDDMMYYYY(new Date()) : undefined;
    const isOneDayEvent = this.props.eventSettings && this.props.eventSettings.startDate && this.props.eventSettings.endDate ? this.dateTimeService.isStartAndEndDateTheSameDay(this.props.eventSettings.startDate, this.props.eventSettings.endDate) : undefined;
    const timespand = this.props.eventSettings && this.props.eventSettings.startDate && this.props.eventSettings.endDate ? this.dateTimeService.getEventString(this.props.eventSettings.startDate, this.props.eventSettings.endDate, this.props.eventSettings.isAllDayEvent) : undefined;
    let thumbnailImage = this.props.imageURL;
    if (this.props.imageURL) {
      thumbnailImage = thumbnailImage.replace(/StandardLarge|StandardSmall|Square|Wide|Full|DefaultRendition/, "StandardSmall").replace("version=&", `version=${this.props.modified ? (new Date(this.props.modified)).toISOString().replace(/[^\d]/g, '') : ""}&ignoreCache=false&`);
      thumbnailImage = Helper.getImageUrl(Environment.getEnvironmentForImageAPI(this.props.environment), this.props.tenant, this.props.token, thumbnailImage, this.props.modified ? (new Date(this.props.modified)).toISOString().replace(/[^\d]/g, '') : undefined, "StandardSmall", this.props.cropSettings, false);
    }
    if (this.props.videoURL && (this.props.videoURL && this.props.videoURL.indexOf("microsoftstream") != -1) || (this.props.videoURL && this.props.videoURL.indexOf("sharepoint") != -1 && location.href.indexOf("sharepoint.com") == -1) && this.props.imageURL == undefined && this.props.isMobile) {
      thumbnailImage = "https://amsglob0cdnstream13.azureedge.net/streamlogo/Light_640x360.jpg";
    }
    if (!this.props.imageURL && this.props.videoURL && Helper.isYouTubeUrl(this.props.videoURL)) {
      thumbnailImage = Helper.getYouTubeThumbnailFromURL(this.props.videoURL);
    }
    return (
      <div
        className={styles.IA_cardCover}
        style={{
          height: this.props.height,
          borderRadius: this.props.borderRadius ? `${this.props.borderRadius}px ${this.props.borderRadius}px 0 0` : undefined,
          backgroundImage: `url("${thumbnailImage}")`,
          cursor: this.props.onClickOpen ? "pointer" : "default",
        }}
        onClick={this.props.imageURL || this.props.eventSettings || (this.props.videoURL?.indexOf("sharepoint") != -1 && location.href.indexOf("sharepoint.com") == -1) ? (this.props.onClickOpen ? () => this.props.onClickOpen() : undefined) : undefined}
      >
        {this.props.eventSettings && this.props.eventSettings.startDate && !isOneDayEvent && !isEventToday && !this.props.html &&
          <div className={styles.IA_coverOverlay}>
            <div className={styles.IA_startDate}>
              {!this.props.eventSettings.isAllDayEvent
                ? `${this.dateTimeService.getDayInMonth(this.props.eventSettings.startDate)}. ${this.dateTimeService
                  .getNameOfMonth(this.props.eventSettings.startDate)
                  .substring(0, 3)
                  ?.toUpperCase()}`
                : `${timespand.substring(0, 2)}. ${this.dateTimeService
                  .getNameOfMonth(this.props.eventSettings.startDate)
                  .substring(0, 3)
                  ?.toUpperCase()}`}
            </div>
            <div className={styles.IA_startDateendDateDivider} />
            <div className={styles.IA_endDate}>
              {!this.props.eventSettings.isAllDayEvent
                ? `${this.dateTimeService.getDayInMonth(this.props.eventSettings.endDate)}. ${this.dateTimeService
                  .getNameOfMonth(this.props.eventSettings.endDate)
                  .substring(0, 3)
                  ?.toUpperCase()}`
                : `${timespand.substring(8, 10)}. ${this.dateTimeService
                  .getNameOfMonth(this.props.eventSettings.endDate)
                  .substring(0, 3)
                  ?.toUpperCase()}`}
            </div>
          </div>
        }
        {this.props.eventSettings && this.props.eventSettings.startDate && isOneDayEvent && !isEventToday && !this.props.html &&
          <div className={styles.IA_coverOverlay}>
            <div className={styles.IA_coverOverlayMonth}>
              {this.dateTimeService.getNameOfMonth(this.props.eventSettings.startDate).substring(0, 3)?.toUpperCase()}
            </div>
            <div className={styles.IA_coverOverlayDay}>
              {!this.props.eventSettings.isAllDayEvent
                ? this.dateTimeService.getDayInMonth(this.props.eventSettings.startDate)
                : timespand.substring(0, 2)}
            </div>
          </div>
        }
        {isEventToday && isOneDayEvent && !this.props.html &&
          <div className={styles.IA_coverOverlay}>
            <div className={styles.IA_coverOverlayToday}>{this.localizationService.strings.DateTimeService_Today?.toUpperCase()}</div>
          </div>
        }
        {isEventToday && !isOneDayEvent && !this.props.html &&
          <div className={styles.IA_coverOverlay}>
            <div className={styles.IA_coverOverlayMonth} style={{ marginTop: 0 }}>
              <div className={styles.IA_startDate}>{this.localizationService.strings.DateTimeService_Today?.toUpperCase()}</div>
              <div className={styles.IA_startDateendDateDivider} />
              <div className={styles.IA_endDate}>
                {!this.props.eventSettings.isAllDayEvent
                  ? `${this.dateTimeService.getDayInMonth(this.props.eventSettings.endDate)}. ${this.dateTimeService
                    .getNameOfMonth(this.props.eventSettings.endDate)
                    .substring(0, 3)
                    ?.toUpperCase()}`
                  : `${timespand.substring(8, 10)}. ${this.dateTimeService
                    .getNameOfMonth(this.props.eventSettings.endDate)
                    .substring(0, 3)
                    ?.toUpperCase()}`}
              </div>
            </div>
          </div>
        }
        {!this.props.eventSettings && !this.props.html && this.props.videoURL != undefined && this.props.imageURL == undefined &&
          <IAMessageVideo
            id={this.props.id}
            width={"100%"}
            height={this.props.height}
            videoUrl={this.props.videoURL}
            environment={this.props.environment}
            tenant={this.props.tenant}
            token={this.props.token}
            forceStop={this.props.isPanelOpen}
            fixSmallYTThumbnail
          />
        }
        {this.props.html &&
          this.props.html
        }
      </div>
    );
  }
}
