import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as styles from './LicensePopup.css';
import { Store } from '../../../stores';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { IAIcon } from '../../../../icon/Icon';
import { IAButton } from '../../../../button/Button';

export interface IProps {
  store?: Store;
  close: () => void;
}

export interface IState {
}

@inject('store')
@observer
export class LicensePopup extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  public render(): JSX.Element {
    return (
      <div className={styles.IA_licensePopup}>
        <div
          className={styles.IA_background}
          style={{
            backgroundColor: this.props.store.darkMode ? "#00000080" : "#ffffff80"
          }}
          onClick={() => {
            if (this.props.close) {
              this.props.close();
            }
          }}
        />
        <div className={styles.IA_contentBox}>
          <IAIcon
            title={"Close"}
            size={18}
            color={"#ffffff"}
            style={{
              float: "right",
              borderRadius: 5,
              width: 18,
              height: 18,
              lineHeight: "18px",
              padding: 2
            }}
            onClick={() => this.props.close()}
          />
          <h1>{this.localizationService.strings.PlayAdmin_NoAvailableLicensesHeadline}</h1>
          <div className={styles.IA_text}>{this.props.store.isAdmin ? (this.props.store.isMobile ? this.localizationService.strings.PlayAdmin_NoAvailableLicensesTextForAdminsOnMobile : this.localizationService.strings.PlayAdmin_NoAvailableLicensesTextForAdmins) : this.localizationService.strings.PlayAdmin_NoAvailableLicensesTextForEditors}</div>
          {this.props.store.isAdmin ?
            (!this.props.store.isMobile &&
              <IAButton
                label={this.localizationService.strings.PlayAdmin_GoToAccountSettings?.toUpperCase()}
                borderColor={"#ffffff"}
                buttonColor="transparent"
                textColor={"#ffffff"}
                darkMode={this.props.store.darkMode}
                onClick={() => {
                  this.props.close();
                  this.props.store.isShowingAccountSettings = true;
                }}
                borderRadius={5}
                style={{
                  float: "left",
                  position: "relative",
                  marginTop: 30,
                  marginLeft: "calc(50% - 100px)",
                  width: 200,
                }}
              />
            )
            :
            <IAButton
              label={this.localizationService.strings.PlayAdmin_ContactAdmin?.toUpperCase()}
              borderColor={"#ffffff"}
              buttonColor="transparent"
              textColor={"#ffffff"}
              darkMode={this.props.store.darkMode}
              onClick={() => {
                this.props.close();
                if (this.props.store.adminUsers?.length > 0) {
                  window.open(`MSTeams:/l/chat/0/0?users=${this.props.store.adminUsers.length[this.props.store.adminUsers.length - 1].userPrincipalName}`);
                }
              }}
              borderRadius={5}
              style={{
                float: "left",
                position: "relative",
                marginTop: 30,
                marginLeft: "calc(50% - 100px)",
                width: 200,
              }}
            />
          }
        </div>
      </div>
    );
  }

  
}