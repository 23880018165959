import { Fetch, onKnownHttpErrorMethodType } from './Fetch';
import { Container } from '../Containers/Container';

/**
 * Creates a multistate promise for fetch that can re-authenticate and wrap common errors
 * @example
 * new FetchProxy(url, {})
 * .whenReAuthenticates(() => {}) // Run code before reauthentication
 * .onKnownError((_statusCode: number, _statusText: string, response: Response) => {}) // When a known error has happened (then & catch do not run when this is activated.)
 * .fetch()
 * .then(() => {}) // Success only (statusCode < 400)
 * .catch(() => {}) // Fatal error only (non http error)
 */
export class FetchProxy {
  private container?: Container;
  private onReAuthenticationMethod?: ((value: Response) => void);
  private onKnownHttpErrorMethod?: onKnownHttpErrorMethodType;

  /**
   * Instantiates a proxy object - the request does not start until `fetch` is called
   * @param input First argument of `fetch`
   * @param init Second argument of `fetch`
   */
  public constructor(public readonly input: RequestInfo, public readonly init?: RequestInit) {
  }

  /**
   * Adds a DI container that the fetch can use to reauthenticate user, provided that
   * `IntraActiveTokenService` is registered
   * @param container 
   */
  public withContainer(container: Container): FetchProxy {
    this.container = container;
    return this;
  }

  /**
   * Adds JWT token as an Authorization header in request
   * @param token 
   */
  public withJWTAuthorization(token: string): FetchProxy {
    this.init.headers = { ...this.init.headers, Authorization: `Bearer ${token}` };
    return this
  }

    /**
   * Adds arbitrary headers to request
   * @param key
   * @param value
   */
  public withHeader(key:string, value:string):FetchProxy {
    this.init.headers = {...this.init.headers};
    this.init.headers[key] = value;
    return this;
  }

  /**
   * Registers a callback before the re-authentication for extra cleanup
   * @param value The callback
   */
  public whenReAuthenticates(value: ((value: Response) => void)): FetchProxy {
    this.onReAuthenticationMethod = value;
    return this;
  }

  /**
   * Registers a callback for known http errors (status-code >= 400).
   * _Note: When this callback is run then and catch are ignored_
   * @param value The callback to run
   */
  public onKnownHttpError(value: onKnownHttpErrorMethodType): FetchProxy {
    this.onKnownHttpErrorMethod = value;
    return this;
  }

  /**
   * Starts the fetch request to fetch the data
   */
  public fetch(): Fetch {
    return new Fetch(this.input, this.init, {
      container: this.container,
      isRequestRetried: false,
      onKnownError: this.onKnownHttpErrorMethod,
      onReAuthentication: this.onReAuthenticationMethod
    });
  }
}
