import * as React from 'react';
import { Provider } from 'mobx-react';
import { configure } from 'mobx';
import { Store } from './stores';
import { PlayAdmin } from './PlayAdmin';
import { IEnvironment } from '../../interfaces/IEnvironment';

export interface IProps {
  environment: IEnvironment;
  tenantId: string;
  token: string;
  isTeams: boolean;
  isLoading: boolean;
  displayConsentButton: boolean;
  consentIsGiven: boolean;
  darkMode?: boolean;
  authenticate?: () => void;
}

export class IAPlayAdmin extends React.Component<IProps> {

  private store: Store;

  constructor(props: IProps) {
    super(props);
    this.store = new Store();
    this.store.environment = props.environment;
    this.store.tenantId = props.tenantId;
    this.store.isTeams = props.isTeams;
    this.store.darkMode = props.darkMode;
    this.store.token = props.token;
    this.store.isLoading = props.isLoading;
    this.store.displayConsentButton = props.displayConsentButton;
    this.store.consentIsGiven = props.consentIsGiven;
    this.store.isMobile = window.innerWidth < 667;
    configure({
      isolateGlobalState: true,
      enforceActions: "never"
    });
  }

  componentDidMount(): void {
    window.addEventListener("resize", () => {
      if (!this.store.isMobile && window.innerWidth < 667) {
        this.store.isMobile = true;
      } else if (this.store.isMobile && window.innerWidth > 667){
        this.store.isMobile = false;
      }
    });
  }

  public componentWillReceiveProps(props: IProps) {
    if (this.store.token != props.token) {
      this.store.token = props.token;
    }
    if (this.store.tenantId != props.tenantId) {
      this.store.tenantId = props.tenantId;
    }
    if (this.store.isLoading !== props.isLoading) {
      this.store.isLoading = props.isLoading;
    }
    if (this.store.displayConsentButton !== props.displayConsentButton) {
      this.store.displayConsentButton = props.displayConsentButton;
    }
    if (this.store.consentIsGiven !== props.consentIsGiven) {
      this.store.consentIsGiven = props.consentIsGiven;
    }
    if (this.store.darkMode !== props.darkMode) {
      this.store.darkMode = props.darkMode;
    }
  }

  public render(): JSX.Element {
    return (
      <Provider store={this.store}>
        <PlayAdmin
          tenantId={this.props.tenantId}
          token={this.props.token}
          authenticate={() => this.props.authenticate()}
        />
      </Provider>
    );
  }
  
}