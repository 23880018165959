
/**
 * Wraps a dynamic resource load request using imports and makes the management easier
 * 
 * @example
 *  private readonly myResourcesDelayLoadResourceService = new DelayLoadResourceService(
 *  () => {
 *     return [
 *       import(
 *         /_* webpackChunkName: 'chunk-name' *_/ // You need this to make webpack create the async chunk (remove _)
 *         'a/package'
 *       ),
 *       import(
 *         /_* webpackChunkName: 'chunk-name' *_/
 *         'another/package'
 *       ),
 *       ...
 *      ]
 *     }
 *   );
 * 
 * // If you use react
 * async componentDidMount() {
 *    ...
 *    await this.myResourcesDelayLoadResourceService.load();
 *    this.setState({ loaded:true }); // Update
 *    // Or
 *    this.myResourcesDelayLoadResourceService.load().then(() => { this.setState({ loaded:true }); });
 * }
 * public render(): React.ReactNode {
 *    if (this.state.isLoaded) {
 *      const [{MyComponent, MyClass}, {AnotherComponent}] = this.adminDelayLoadResourceService.get();
 *      return (<MyComponent><AnotherComponent /></MyComponent>);
 *    }
 * }
 */
export class DelayLoadResourceService<T1, T2, T3, T4, T5, T6, T7, T8, T9> {
  private promiseListGenerator: 
    () => Promise<T1 | PromiseLike<T1> | T2 | PromiseLike<T2> | T3 | PromiseLike<T3> | T4 | PromiseLike<T4> | T5 | PromiseLike<T5> | T6 | PromiseLike<T6> | T7 | PromiseLike<T7> | T8 | PromiseLike<T8> | T9 | PromiseLike<T9>>[];
  private combinedPromise : Promise<(T1 | PromiseLike<T1> | T2 | PromiseLike<T2> | T3 | PromiseLike<T3> | T4 | PromiseLike<T4> | T5 | PromiseLike<T5> | T6 | PromiseLike<T6> | T7 | PromiseLike<T7> | T8 | PromiseLike<T8> | T9 | PromiseLike<T9>)[]>;
  private value: (T1 | PromiseLike<T1> | T2 | PromiseLike<T2> | T3 | PromiseLike<T3> | T4 | PromiseLike<T4> | T5 | PromiseLike<T5> | T6 | PromiseLike<T6> | T7 | PromiseLike<T7> | T8 | PromiseLike<T8> | T9 | PromiseLike<T9>)[];

  public constructor(promiseListGenerator: () => [Promise<T1>]);
  public constructor(promiseListGenerator: () => [Promise<T1>, Promise<T2>]);
  public constructor(promiseListGenerator: () => [Promise<T1>, Promise<T2>, Promise<T3>]);
  public constructor(promiseListGenerator: () => [Promise<T1>, Promise<T2>, Promise<T3>, Promise<T4>]);
  public constructor(promiseListGenerator: () => [Promise<T1>, Promise<T2>, Promise<T3>, Promise<T4>, Promise<T5>]);
  public constructor(promiseListGenerator: () => [Promise<T1>, Promise<T2>, Promise<T3>, Promise<T4>, Promise<T5>, Promise<T6>]);
  public constructor(promiseListGenerator: () => [Promise<T1>, Promise<T2>, Promise<T3>, Promise<T4>, Promise<T5>, Promise<T6>, Promise<T7>]);
  public constructor(promiseListGenerator: () => [Promise<T1>, Promise<T2>, Promise<T3>, Promise<T4>, Promise<T5>, Promise<T6>, Promise<T7>, Promise<T8>]);
  public constructor(promiseListGenerator: () => [Promise<T1>, Promise<T2>, Promise<T3>, Promise<T4>, Promise<T5>, Promise<T6>, Promise<T7>, Promise<T8>, Promise<T9>]);
  /**
   * Creates an object with a reference to that function. The function is not called until load is called.
   */
  public constructor(promiseListGenerator: () => 
    Promise<
      T1 | PromiseLike<T1> | T2 | PromiseLike<T2> | T3 | PromiseLike<T3> | T4 | PromiseLike<T4> | T5 | PromiseLike<T5> | T6 | PromiseLike<T6> | T7 | PromiseLike<T7> | T8 | PromiseLike<T8> | T9 | PromiseLike<T9>
    >[])
  {
    this.promiseListGenerator = promiseListGenerator;
  }

  /**
   * Invokes the imports (if hasn't before)
   */
  public async load() : Promise<(T1 | PromiseLike<T1> | T2 | PromiseLike<T2> | T3 | PromiseLike<T3> | T4 | PromiseLike<T4> | T5 | PromiseLike<T5> | T6 | PromiseLike<T6> | T7 | PromiseLike<T7> | T8 | PromiseLike<T8> | T9 | PromiseLike<T9>)[]> {
    if (this.combinedPromise) {
      return await this.combinedPromise;
    }

    this.combinedPromise = Promise.all(this.promiseListGenerator());
    this.value = await this.combinedPromise;
    return this.value;
  }

  /**
   * Gets the results of the promise, but only when it's already loaded
   */
  public get(): [T1, T2, T3, T4, T5, T6, T7, T8, T9] {
    return this.value as [T1, T2, T3, T4, T5, T6, T7, T8, T9];
  }
}