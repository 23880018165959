import * as React from 'react';
import { Store } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import { IATextField } from '../../../../../textField/TextField';
import { LocalizationService } from '../../../../../../services/LocalizationService';

export interface IProps {
  store?: Store;
  forceUpdate: () => void;
}

@inject('store')
@observer
export class EmbedWidgetSettings extends React.Component<IProps> {

  private readonly localizationService = new LocalizationService()

  render(): React.ReactNode {
    return (
      <>
        <IATextField
          label={this.localizationService.strings.PlayAdmin_EmbedUrl?.toUpperCase()}
          placeholder={this.localizationService.strings.PlayAdmin_EmbedUrl}
          text={this.props.store.widgetToEdit.embed.url}
          darkMode={this.props.store.darkMode}
          highlightColor={this.props.store.highlightColor}
          borderRadius={5}
          onChange={(embedUrl: string) => {
            this.props.store.widgetToEdit.embed.url = embedUrl;
            this.props.forceUpdate();
          }}
          labelStyle={{
            fontSize: "12px"
          }}
        />
      </>);
  }
}