import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Helper } from '../../../../../../Helper';
import { IListElement, IMediaFile, IMediaFileTag } from '../../../../../../interfaces/IPlay';
import { LocalizationService } from '../../../../../../services/LocalizationService';
import PlayService from '../../../../../../services/PlayService';
import { IAListView } from '../../../../../listView/ListView';
import { IAPanel } from '../../../../../panels';
import { IASpinner } from '../../../../../spinner/Spinner';
import { PlayHelper } from '../../../../PlayHelper';
import { Store } from '../../../../stores';
import * as styles from './MediaFileSelectorPanel.css';

export interface IProps {
  open: boolean;
  type: string;
  store?: Store;
  onSelect: (url: string) => void;
  onClose: () => void;
}

export interface IState {
  loading: boolean;
  isLoadingTags: boolean;
  selectedTags: Set<IMediaFileTag>;
}

@inject('store')
@observer
export class MediaFileSelectorPanel extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      isLoadingTags: false,
      selectedTags: new Set()
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.open) {
      this.getMediaFiles();
      this.getMediaFileTags();
    }
  }

  private getMediaFiles() {
    this.setState({ loading: true });
    const tags: string[] = [];
    this.state.selectedTags.forEach(tag => tags.push(tag.id));
    PlayService.getMediaFiles(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, this.props.type, { tags: tags }).then(mediaFiles => {
      this.props.store.mediaFiles = mediaFiles.value;
      this.props.store.mediaFilesCount = mediaFiles.count;
      this.setState({ loading: false });
    }).catch(() => {
      this.setState({ loading: false });
    });
  }
  
  private getMediaFileTags() {
    if (this.props.store.profile?.id) {
      this.setState({ isLoadingTags: true });
      PlayService.getMediaFileTags(this.props.store.environment, this.props.store.token, this.props.store.tenantId, this.props.store.profile?.id, {}).then(mediaFileTags => {
        this.props.store.mediaFileTags = mediaFileTags;
        this.setState({ isLoadingTags: false });
      }).catch(() => {
        this.setState({ isLoadingTags: false });
      });
    }
  }

  public render(): JSX.Element {
    const listElements: IListElement[] = [];
    if (this.props.store.mediaFiles && this.props.store.mediaFiles.length > 0) {
      this.props.store.mediaFiles.forEach((mediaFile: IMediaFile) => {
        let image: string;
        if (mediaFile.mimeType?.indexOf("image") !== -1) {
          image = PlayHelper.excludeImageFromApi(this.props.store.environment, mediaFile.url)
            ? mediaFile.url 
            : PlayHelper.getImageFromApi(this.props.store.environment, this.props.store.tenantId, this.props.store.profile.id, PlayHelper.getStandardImageFormat(500, 250), false, mediaFile.url);
        }

        listElements.push(
          {
            id: mediaFile.url,
            columns: [
              {
                header: "Preview"?.toUpperCase(),
                type: "preview",
                fileType: mediaFile.mimeType,
                src: image,
                color: this.props.store.darkMode ? '#fff' : '#000'
              },
              {
                header: this.localizationService.strings.PlayAdmin_Title?.toUpperCase(),
                type: "text",
                text: mediaFile.name,
                color: this.props.store.darkMode ? '#fff' : '#000'
              }
            ],
            buttons: [
              {
                action: "select",
                icon: "Add",
                color: this.props.store.highlightColor
              },
            ]
          }
        );
      })
    }
    return (
      <IAPanel
        open={this.props.open}
        transition={"slide"}
        showNavigationBar={true}
        spinnerColor={this.props.store.highlightColor}
        marginTop={0}
        isMobile={this.props.store.isMobile}
        loading={this.state.loading}
        width={600}
        dataAutomationIdPrefix="mediafiles-selector-panel"
        panelId="mediafiles-selector-panel"
        navigationsBarContent={undefined}
        darkMode={this.props.store.darkMode}
        close={() => {
          this.props.onClose();
        }}
      >
        <div className={styles.IA_mediaFileSelectorPanel}>
          {this.state.loading ?
            <IASpinner
              color={this.props.store.highlightColor}
              style={{
                float: "left",
                marginLeft: "calc(50% - 20px)"
              }}
            />
            :
            <div>
              {this.props.store.mediaFileTags && this.props.store.mediaFileTags.length > 0 &&
                <div style={{
                  marginBottom: 15,
                  clear: "both"
                }}>
                  <div
                    className={styles.IA_tagButton}
                    style={{
                      backgroundColor: this.state.selectedTags.size === 0 ? this.props.store.highlightColor : this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#eeeeee",
                      color: this.state.selectedTags.size === 0 ? "white" : this.props.store.darkMode ? "#ffffff" : "#333333"
                    }}
                    onClick={() => {
                      if (!this.state.isLoadingTags) {
                        this.setState({ selectedTags: new Set() }, () => {
                          this.getMediaFiles();
                        });
                      }
                    }}
                  >
                    {this.localizationService.strings.PlayAdmin_AllMediaFiles?.toUpperCase()}
                  </div>
                  {this.props.store.mediaFileTags.map((tag, i) => {
                    let backgroundColor = this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#eeeeee";
                    let color = this.props.store.darkMode ? "#ffffff" : "#333333";
                    const isSelected = this.state.selectedTags.has(tag);
                    if (isSelected) {
                      backgroundColor = this.props.store.highlightColor;
                      color = "white";
                    }
                    return <div
                      key={tag.id + i}
                      className={styles.IA_tagButton}
                      style={{
                        backgroundColor: backgroundColor,
                        color: color
                      }}
                      onClick={() => {
                        if (!this.state.isLoadingTags) {
                          this.state.selectedTags.has(tag) ? this.state.selectedTags.delete(tag) : this.state.selectedTags.add(tag);
                          this.setState({ selectedTags: this.state.selectedTags}, () => {
                            this.getMediaFiles();
                          });
                        }
                      }}
                    >
                      {tag.id?.toUpperCase()}
                    </div>
                  })}
                </div>
              }
              {listElements && listElements.length > 0 ?
                <IAListView
                  id="ListViewOfMediaFiles2"
                  listElements={listElements}
                  backgroundColor={this.props.store.darkMode ? Helper.darkModeCardBackgroundColor : "#f6f6f5"}
                  hoverBackgroundColor={this.props.store.darkMode ? "#393939" : "#e6e6e6"}
                  buttonAreaDividerColor={this.props.store.darkMode ? "#727272" : "#dcdcdc"}
                  darkMode={this.props.store.darkMode}
                  defaultAction={"select"}
                  selectedAction={(action: string, url: string) => {
                    switch (action) {
                      case "select":
                        this.props.onSelect(url);
                        break;
                      default:
                        break;
                    }
                  }}
                />
                :
                <div
                  style={{
                    textAlign: "center",
                    color: this.props.store.darkMode ? "#ffffff" : "#333333"
                  }}
                >
                  {this.localizationService.strings.PlayAdmin_MediaFilesNoContent}
                </div>
              }
            </div>
          }
        </div>
      </IAPanel>
    );
  }

  
}