exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_slides_3kuGyV_numJw4GZZ43J12j {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_2Omwet343rssPvqDuNif-n {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_1oA095nAxYRauP3h0Tkgj {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_BDlVETwXR9Q4-7DGmE-dt {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_slides": "IA_slides_3kuGyV_numJw4GZZ43J12j",
	"IA_filters": "IA_filters_2Omwet343rssPvqDuNif-n",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_1oA095nAxYRauP3h0Tkgj",
	"IA_paginationPageCount": "IA_paginationPageCount_BDlVETwXR9Q4-7DGmE-dt"
};