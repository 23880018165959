import * as React from 'react';

export interface IProps {
  id: string;
  prevId?: string;
  nextId?: string;
  onChange: (input: number) => void;
}

export class ConnectingNewPlayerInput extends React.Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  private isNumber(input: string): boolean {
    switch (parseInt(input)) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        return true;
      default:
        return false;
    }
  }

  public render(): JSX.Element {
    return (
      <input
        id={this.props.id}
        type="text"
        maxLength={1}
        inputMode="numeric"
        onChange={event => {
          const value = event.target.value;
          if (this.isNumber(value)) {
            this.props.onChange(parseInt(value));
            if (value !== "" && this.props.nextId) {
              document.getElementById(this.props.nextId).focus();
            }
          } else {
            (document.getElementById(this.props.id) as HTMLInputElement).value = "";
          }
        }}
        onKeyUp={event => {
          if (event.key === "Backspace" && this.props.prevId && (document.getElementById(this.props.id) as HTMLInputElement).value === "") {
            (document.getElementById(this.props.prevId) as HTMLInputElement).value = "";
            document.getElementById(this.props.prevId).focus();
          }
        }}
      />
    );
  }
}