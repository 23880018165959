import * as React from 'react';
import Moment from 'react-moment';
import { IPlayFooter, IPlayWeather } from '../../../interfaces/IPlay';
import { PlayHelper } from '../../playAdmin/PlayHelper';
import * as styles from './Footer.css';
import { FooterWeather } from './footerWeather/FooterWeather';

export interface IProps {
  footer: IPlayFooter;
  sizeUnit: number;
  city: string;
  currentWeather: IPlayWeather;
  editMode?: boolean;
  language?: string;
}

export interface IState {
  hours: string[];
  minutes: string[];
  seconds: string[];
}

export class Footer extends React.Component<IProps, IState> {
  private timerInterval;

  constructor(props: IProps) {
    super(props);
    this.state = {
      hours: undefined,
      minutes: undefined,
      seconds: undefined
    }
  }

  public componentDidMount(): void {
    this.getTime();
    this.timerInterval = setInterval(() => {
      this.getTime();
    }, 5000);
  }

  public componentWillUnmount(): void {
    clearInterval(this.timerInterval);
  }

  private checkTime(i: number): string {
    if (i < 10) { return "0" + i };  // add zero in front of numbers < 10
    return `${i}`;
  }

  private getTime(): void {
    const today = new Date();
    const hours = this.checkTime(today.getHours()).split("");
    const minutes = this.checkTime(today.getMinutes()).split("");
    const seconds = this.checkTime(today.getSeconds()).split("");
    this.setState({
      hours,
      minutes,
      seconds
    });
  }

  public render(): JSX.Element {
    return (
      <div
        className={styles.IA_footer}
        style={{
          backgroundColor: this.props.footer?.backgroundColor,
          backgroundImage: `linear-gradient(to right, ${this.props.footer?.backgroundColor}, ${this.props.footer?.backgroundColor2})`,
          color: this.props.footer.color,
          bottom: 0,
          height: this.props.sizeUnit * 8,
          width: this.props.editMode ? "100%" : window.innerWidth
        }}
      >
        {this.props.footer.showLogo &&
          <div
            className={styles.IA_logo}
            style={{
              backgroundImage: `url(${this.props.footer.logoUrl})`,
              marginLeft: `${this.props.sizeUnit * 3.3}px`,
              marginTop: `${this.props.sizeUnit * 1.6}px`,
              height: `calc(100% - ${this.props.sizeUnit * 3.3}px )`
            }}
          />
        }
        <div
          className={styles.IA_footerWeatherAndClockContainer}
          style={{ width: this.props.footer.showLogo ? `calc(50% - ${this.props.sizeUnit * 3.3}px)` : '100%' }}
        >
          {this.props.footer.showClock &&
            <div
              className={styles.IA_clock}
              style={{
                marginLeft: `${this.props.sizeUnit * 3.3}px`,
              }}
            >
              <div
                className={styles.IA_time}
                style={{
                  marginRight: `${this.props.sizeUnit * 3.3}px`,
                  fontSize: `${this.props.sizeUnit * 3.3}px`,
                  color: this.props.footer.color
                }}
              >
                <div>{this.state.hours ? this.state.hours[0] : ""}</div>
                <div>{this.state.hours ? this.state.hours[1] : ""}</div>
                <div className={styles.IA_timeDivider}>:</div>
                <div>{this.state.minutes ? this.state.minutes[0] : ""}</div>
                <div>{this.state.minutes ? this.state.minutes[1] : ""}</div>
              </div>
              <div
                className={styles.IA_date}
                style={{
                  fontSize: `${this.props.sizeUnit * 1.7}px`,
                  marginRight: `${this.props.sizeUnit * 3.3}px`,
                  color: this.props.footer.color
                }}
              >
                <Moment format="D MMMM" locale={PlayHelper.getMomentJSLanguageCode(this.props.language)}></Moment>
              </div>
            </div>
          }
          {this.props.footer?.showWeather && this.props.city && this.props.currentWeather &&
            <FooterWeather
              sizeUnit={this.props.sizeUnit}
              footer={this.props.footer}
              city={this.props.city}
              currentWeather={this.props.currentWeather}
            />
          }
        </div>
      </div>
    );
  }

  
}