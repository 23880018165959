export class IACardViewService {

  public getNumberOfCardsPerRowAndMaxCardWidthAvailable(containerWidth: number, cardPadding: number, minCardWidth: number, maxAmountOfColumns?: number): [number, number] {
    const fullWidth = containerWidth + cardPadding;
    let numberOfCardsPerRow = Math.floor(fullWidth / (minCardWidth + cardPadding));
    if (maxAmountOfColumns) {
      numberOfCardsPerRow = Math.floor(fullWidth / (minCardWidth + cardPadding)) > maxAmountOfColumns ? maxAmountOfColumns : Math.floor(fullWidth / (minCardWidth + cardPadding));
    }
    const maxCardWidthAvailable = Math.floor((fullWidth / numberOfCardsPerRow) - cardPadding);
    return [numberOfCardsPerRow, maxCardWidthAvailable ?? minCardWidth];
  }
}
