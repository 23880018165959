exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_profile_1_iSCVErKpI_JN3knpQ0DJ {\n  width: 100%;\n  margin-top: 20px;\n}\n\n.IA_divider_2JVPl3sgGOsgbCT0Hv0jv-, .IA_dividerDarkMode_AsV8nMms7GFXSC9i9YQdA {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_dividerDarkMode_AsV8nMms7GFXSC9i9YQdA {\n  background-color: #a8a8a8;\n}\n\n.IA_widgetType_23JZPPZmonJdqLZZ4zpeKl {\n  margin-bottom: 15px;\n  width: 75px;\n  cursor: pointer;\n}\n\n.IA_widgetTypeLabel_1bQODz7g7NAh8bOlgUU1dg {\n  font-size: 12px;\n  float: left;\n  clear: both;\n  width: 90%;\n  margin-top: 2px;\n}\n\n.IA_swatches_3JoK2NJzlLfN0seuk6JGOV {\n  width: 100%;\n  clear: both;\n  position: relative;\n  float: left;\n}", ""]);

// Exports
exports.locals = {
	"IA_profile": "IA_profile_1_iSCVErKpI_JN3knpQ0DJ",
	"IA_divider": "IA_divider_2JVPl3sgGOsgbCT0Hv0jv-",
	"IA_dividerDarkMode": "IA_dividerDarkMode_AsV8nMms7GFXSC9i9YQdA",
	"IA_widgetType": "IA_widgetType_23JZPPZmonJdqLZZ4zpeKl",
	"IA_widgetTypeLabel": "IA_widgetTypeLabel_1bQODz7g7NAh8bOlgUU1dg",
	"IA_swatches": "IA_swatches_3JoK2NJzlLfN0seuk6JGOV"
};