exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".likesAndComments_3YkrOJsYWc8jYll8DyM--f {\n  position: relative;\n  float: right;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.likesAndComments_3YkrOJsYWc8jYll8DyM--f .likes_eXxuWkxSoG0RlQw_wOiXA {\n  height: 20px;\n  float: right;\n  position: relative;\n}\n\n.likesAndComments_3YkrOJsYWc8jYll8DyM--f .spinner_17xYeCLj0qtZ1jJNHl_zFn {\n  height: 14px;\n  width: 12px;\n  float: right;\n  position: relative;\n  margin-left: 2px;\n  -webkit-animation: spin_WEV61tUdg8mIdANrRqw_z 700ms linear infinite;\n  -moz-animation: spin_WEV61tUdg8mIdANrRqw_z 700ms linear infinite;\n  animation: spin_WEV61tUdg8mIdANrRqw_z 700ms linear infinite;\n}\n\n.likesAndComments_3YkrOJsYWc8jYll8DyM--f .likesText_c5d1XoAAb3VQFje8D2AFv {\n  float: left;\n  margin-left: 4px;\n  margin-top: -1px;\n}\n\n.likesAndComments_3YkrOJsYWc8jYll8DyM--f .likesTextVertical_3Gop4xT7hIawGTA7e-evfx {\n  float: left;\n  clear: both;\n  margin-top: -7px;\n  text-align: center;\n  width: 100%;\n  box-sizing: border-box;\n  padding-left: 1px;\n}\n", ""]);

// Exports
exports.locals = {
	"likesAndComments": "likesAndComments_3YkrOJsYWc8jYll8DyM--f",
	"likes": "likes_eXxuWkxSoG0RlQw_wOiXA",
	"spinner": "spinner_17xYeCLj0qtZ1jJNHl_zFn",
	"spin": "spin_WEV61tUdg8mIdANrRqw_z",
	"likesText": "likesText_c5d1XoAAb3VQFje8D2AFv",
	"likesTextVertical": "likesTextVertical_3Gop4xT7hIawGTA7e-evfx"
};