
export class FetchPromise<T> {
  public constructor(private readonly promise: Promise<T>, private readonly wellKnownHttpErrorGenerator: () => (() => Promise<any>)) {
  }
  public then<TResult1 = T, TResult2 = never>(onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null): FetchPromise<TResult1 | TResult2> {
    const newPromise = this.promise.then(onfulfilled, onrejected);
    return new FetchPromise<TResult1 | TResult2>(newPromise, this.wellKnownHttpErrorGenerator);
  }
 
  public catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): FetchPromise<T | TResult | Promise<any>> {
    const newPromise = this.promise.catch((reason) => {
      const wellKnownHttpError = this.wellKnownHttpErrorGenerator();
      if (wellKnownHttpError) {
        return wellKnownHttpError();
      } else {
        return onrejected(reason);
      }
    });
    return new FetchPromise<T | TResult| Promise<any>>(newPromise, this.wellKnownHttpErrorGenerator);
  }
}
