
import { IUser } from "../interfaces/IUser";
import { ILike } from "../interfaces/ILike";
import { IAItem } from '../interfaces/IAItem';
import { IComment } from '../interfaces/IComment';
import { IReply } from '../interfaces/IReply';
import { Helper } from '../Helper';
import { Environment } from './Environment';
import { IHttpStatus } from '../interfaces/IHttpStatus';
import DateTimeService from './DateTimeService';
import { FetchProxy } from 'intraactive-sdk-helper';
import { sdkContainerInstance } from '../global/Container';

export default class SocialService {

  public static getMultipleLikesAndCommentCounts(environment: "Development" | "Test" | "Production", tenant: string, component: string, instance: string, token: string, itemIds: string[]): Promise<{ httpStatus: IHttpStatus, commentCounts?: number[], likes?: any[] }> {
    return new Promise((resolve) => {
      if (environment && tenant && component && instance && token && itemIds) {
        const apiUrl = Environment.getEnvironmentForSocialAPI(environment);
        const url = `${apiUrl}/${tenant}/${component}/${instance}/multiple`;
        return new FetchProxy(url, {
          method: "POST",
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            "AuthToken": token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify({
            "itemIds": itemIds
          })
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response);
            } else {
              return response.json();
            }
          }).then(response => {
            response.httpStatus = {
              statusCode: 200,
            },
              resolve(response);
          }).catch(error => {
            console.log(error);
            resolve({
              httpStatus: {
                statusCode: error.status,
                message: error.statusText
              }
            });
          });
      } else {
        resolve({
          httpStatus: {
            statusCode: 400,
            message: "Missing parameter"
          }
        });
      }
    });
  }

  // LIKES

  public static getLikes(environment: "Development" | "Test" | "Production", tenant: string, component: string, instance: string, token: string, itemId?: string, commentId?: string): Promise<ILike[]> {
    return new Promise((resolve) => {
      if (environment && tenant && component && instance && token) {
        const apiUrl = Environment.getEnvironmentForSocialAPI(environment);
        let url = `${apiUrl}/${tenant}/${component}/${instance}/${itemId}/likes`;
        url += commentId !== undefined ? `?commentId=${commentId}` : "";
        return new FetchProxy(url, {
          method: "GET",
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            "AuthToken": token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }),
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response.status);
            } else {
              return response.json();
            }
          }).then(response => {
            resolve(response);
          });
      } else {
        resolve(null);
      }
    });
  }

  public static getPublishingLikes(item: IAItem, environment: "Development" | "Test" | "Production", tenant: string, token: string): Promise<ILike[]> {
    const apiURL: string = Environment.getEnvironmentForMainAPI(environment) + '/api/' + tenant + '.sharepoint.com/' + item.site + '/news/likes?listId=' + item.listId + '&itemId=' + item.id;
    return new Promise((resolve) => {
      if (item && environment && tenant && token) {
        return new FetchProxy(apiURL, {
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            "AuthToken": token
          })
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              return response.status;
            } else if (response.status === 204) {
              return response.status;
            } else {
              return response.json();
            }
          }).then(response => {
            resolve(response);
          }).catch((error) => {
            console.log(error);
          });
      } else {
        resolve(null);
      }
    });
  }

  public static like(environment: "Development" | "Test" | "Production", tenant: string, component: string, instance: string, token: string, user: IUser, itemId?: string, source?: string, commentId?: string): Promise<ILike[]> {
    return new Promise((resolve) => {
      if (environment && tenant && component && instance && token && user) {
        const apiUrl = Environment.getEnvironmentForSocialAPI(environment);
        const url = `${apiUrl}/${tenant}/${component}/${instance}/${itemId}/likes`;
        return new FetchProxy(url, {
          method: "POST",
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            "AuthToken": token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify({
            "user": user,
            "source": source,
            "commentId": commentId
          })
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response.status);
            } else {
              return response.json();
            }
          }).then(() => {
            this.getLikes(environment, tenant, component, instance, token, itemId, commentId).then((likes: ILike[]) => {
              resolve(likes);
            });
          });
      } else {
        resolve(null);
      }
    });
  }

  public static unlike(environment: "Development" | "Test" | "Production", tenant: string, component: string, instance: string, token: string, itemId?: string, likeId?: string, commentId?: string): Promise<ILike[]> {
    return new Promise((resolve) => {
      if (environment && tenant && component && instance && token) {
        const apiUrl = Environment.getEnvironmentForSocialAPI(environment);
        const url = `${apiUrl}/${tenant}/${component}/${instance}/${itemId}/likes/${likeId}`;
        return new FetchProxy(url, {
          method: "DELETE",
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            "AuthToken": token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }),
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response.status);
            } else {
              return response;
            }
          }).then(() => {
            this.getLikes(environment, tenant, component, instance, token, itemId, commentId).then((likes: ILike[]) => {
              resolve(likes);
            });
          });
      } else {
        resolve(null);
      }
    });
  }

  public static publishingLike(item: IAItem, environment: "Development" | "Test" | "Production", tenant: string, token: string, like: boolean): Promise<ILike[]> {
    const apiURL: string = Environment.getEnvironmentForMainAPI(environment) + '/api/' + tenant + '.sharepoint.com/' + item.site + '/news/likes?listId=' + item.listId + '&itemId=' + item.id + '&like=' + like;
    return new Promise((resolve) => {
      if (item && environment && tenant && token) {
        return new FetchProxy(apiURL, {
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            "AuthToken": token
          }),
          method: 'POST'
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              return response.status;
            } else {
              return response.json();
            }
          }).then(() => {
            this.getPublishingLikes(item, environment, tenant, token).then((likes) => {
              resolve(likes);
            });
          }).catch((error) => {
            console.log(error);
          });
      } else {
        resolve(null);
      }
    });
  }

  // COMMENTS

  public static getCommentsCount(environment: "Development" | "Test" | "Production", tenant: string, component: string, instance: string, token: string, itemId?: string): Promise<number> {
    return new Promise((resolve) => {
      if (environment && tenant && token && component && instance) {
        const apiUrl = Environment.getEnvironmentForSocialAPI(environment);
        const url = `${apiUrl}/${tenant}/${component}/${instance}/${itemId}/comments/count`;
        return new FetchProxy(url, {
          method: "GET",
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            "AuthToken": token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }),
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response.status);
            } else {
              return response.json();
            }
          }).then(response => {
            resolve(response);
          });
      } else {
        resolve(null);
      }
    });
  }

  public static getLatestComments(environment: "Development" | "Test" | "Production", tenant: string, component: string, instance: string, token: string, itemId?: string): Promise<{ latestComments: IComment[], commentsCount: number }> {
    return new Promise((resolve) => {
      if (environment && tenant && token && component && instance) {
        const apiUrl = Environment.getEnvironmentForSocialAPI(environment);
        const url = `${apiUrl}/${tenant}/${component}/${instance}/${itemId}/comments`;
        return new FetchProxy(url, {
          method: "GET",
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            "AuthToken": token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }),
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response.status);
            } else {
              return response.json();
            }
          }).then(response => {
            const comments: IComment[] = [];
            response.forEach((comment: any) => {
              let reply: IReply;
              if (comment.commentId != null) {
                reply = {
                  user: comment.replyTo,
                  commentId: comment.commentId
                };
              }
              comments.push(
                {
                  content: comment.content,
                  created: comment.created,
                  user: comment.user,
                  id: comment.id,
                  itemId: comment.itemId,
                  likes: comment.likes,
                  mentions: comment.mentions,
                  source: comment.source,
                  reply: reply
                }
              );
            });
            if (comments.length >= 2) {
              resolve({ latestComments: [comments[1], comments[0]], commentsCount: comments.length });
            } else if (comments.length === 1) {
              resolve({ latestComments: [comments[0]], commentsCount: comments.length });
            } else {
              resolve({ latestComments: [], commentsCount: 0 });
            }
          });
      } else {
        resolve(null);
      }
    });
  }

  public static getComments(environment: "Development" | "Test" | "Production", tenant: string, component: string, instance: string, token: string, itemId?: string): Promise<IComment[]> {
    return new Promise((resolve) => {
      if (environment && tenant && token && component && instance) {
        const apiUrl = Environment.getEnvironmentForSocialAPI(environment);
        const url = `${apiUrl}/${tenant}/${component}/${instance}/${itemId}/comments`;
        return new FetchProxy(url, {
          method: "GET",
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            "AuthToken": token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }),
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response.status);
            } else {
              return response.json();
            }
          }).then(response => {
            const comments: IComment[] = [];
            response.forEach((comment: any) => {
              let reply: IReply;
              if (comment.commentId != null) {
                reply = {
                  user: comment.replyTo,
                  commentId: comment.commentId
                };
              }
              comments.push(
                {
                  content: comment.content,
                  created: comment.created,
                  user: comment.user,
                  id: comment.id,
                  itemId: comment.itemId,
                  likes: comment.likes,
                  mentions: comment.mentions,
                  source: comment.source,
                  reply: reply
                }
              );
            });
            resolve(comments.reverse());
          });
      } else {
        resolve(null);
      }
    });
  }

  public static getPublishingComments(item: IAItem, environment: "Development" | "Test" | "Production", tenant: string, token: string): Promise<IComment[]> {
    const apiURL: string = Environment.getEnvironmentForMainAPI(environment) + '/api/' + tenant + '.sharepoint.com/' + item.site + '/news/comments?listId=' + item.listId + '&itemId=' + item.id;
    return new Promise((resolve) => {
      if (item && environment && tenant && token) {
        return new FetchProxy(apiURL, {
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            "AuthToken": token
          })
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response.status);
            } else {
              return response.json();
            }
          }).then(response => {
            const comments = [];
            response.forEach((comment: any) => {
              comments.push({
                id: comment.id,
                content: comment.content,
                user: {
                  displayName: comment.author,
                  image: comment.authorImage,
                },
                created: new Date(comment.date)
              });
            });
            resolve(comments);
          }).catch((error) => {
            console.log(error);
          });
      } else {
        resolve(null);
      }
    });
  }

  public static addComment(environment: "Development" | "Test" | "Production", tenant: string, component: string, instance: string, token: string, user: IUser, content: string, mentions: IUser[], itemId?: string, source?: string, commentId?: string): Promise<string> {
    return new Promise((resolve) => {
      if (environment && tenant && token && user && content) {
        const apiUrl = Environment.getEnvironmentForSocialAPI(environment);
        const url = `${apiUrl}/${tenant}/${component}/${instance}/${itemId}/comments`;
        return new FetchProxy(url, {
          method: "POST",
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            "AuthToken": token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify({
            "commentId": commentId,
            "user": user,
            "content": content,
            "mentions": mentions,
            "source": source
          })
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response.status);
            } else {
              return response.json();
            }
          }).then(response => {
            resolve(response);
          });
      } else {
        resolve(null);
      }
    });
  }

  public static addPublishingComment(item: IAItem, environment: "Development" | "Test" | "Production", tenant: string, token: string, comment: string): Promise<string> {
    const apiURL: string = Environment.getEnvironmentForMainAPI(environment) + '/api/' + tenant + '.sharepoint.com/' + item.site + '/news/comments?listId=' + item.listId + '&itemId=' + item.id;
    return new Promise((resolve) => {
      if (item && environment && tenant && token && comment) {
        return new FetchProxy(apiURL, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "AuthToken": token
          },
          credentials: "same-origin",
          mode: "cors",
          body: JSON.stringify(comment),
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response.status);
            } else {
              return response.json();
            }
          }).then(response => {
            resolve(response.id);
          }).catch((error) => {
            console.log(error);
          });
      } else {
        resolve(null);
      }
    });
  }

  public static deleteComment(environment: "Development" | "Test" | "Production", tenant: string, component: string, instance: string, token: string, itemId?: string, commentId?: string): Promise<void> {
    return new Promise((resolve) => {
      if (environment && tenant && token && component && instance) {
        const apiUrl = Environment.getEnvironmentForSocialAPI(environment);
        const url = `${apiUrl}/${tenant}/${component}/${instance}/${itemId}/comments/${commentId}`;
        return new FetchProxy(url, {
          method: "DELETE",
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            "AuthToken": token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          })
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response.status);
            } else {
              return response.json();
            }
          }).then(response => {
            resolve(response);
          });
      } else {
        resolve(null);
      }
    });
  }

  public static deletePublishingComment(item: IAItem, environment: "Development" | "Test" | "Production", tenant: string, token: string, id: string): Promise<void> {
    const apiURL: string = Environment.getEnvironmentForMainAPI(environment) + '/api/' + tenant + '.sharepoint.com/' + item.site + '/news/comments/' + id;
    return new Promise((resolve) => {
      if (item && environment && tenant && token && id) {
        return new FetchProxy(apiURL, {
          method: 'DELETE',
          headers: {
            "AuthToken": token
          },
          credentials: "same-origin",
          mode: "cors"
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response.status);
            } else {
              return resolve();
            }
          }).then(response => {
            resolve(response);
          }).catch((error) => {
            console.log(error);
          });
      } else {
        resolve(null);
      }
    });
  }

  // USERS

  public static getUsersWithName(environment: "Development" | "Test" | "Production", tenant: string, token: string, userName: string): Promise<IUser[]> {
    return new Promise((resolve) => {
      if (environment && tenant && token && userName) {
        const apiUrl = Environment.getEnvironmentForMainAPI(environment);
        const url = `${apiUrl}/api/${tenant}.sharepoint.com/users/${userName}`;
        return new FetchProxy(url, {
          method: "GET",
          credentials: "same-origin",
          mode: "cors",
          headers: new Headers({
            "AuthToken": token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          })
        })
          .withContainer(sdkContainerInstance)
          .fetch()
          .then((response) => {
            if (!response.ok) {
              throw (response.status);
            } else {
              return response.json();
            }
          }).then(response => {
            resolve(response);
          });
      } else {
        resolve(null);
      }
    });
  }

  public static uploadSelecedImage(file: any, uploadUrl: string, token: string, imageHeight?: number): Promise<string> {
    return new Promise<string>((resolve) => {
      if (file && uploadUrl && token) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = (event: any) => {
          let imageType: string;
          if (event.target.result.indexOf("image/png") !== -1) {
            imageType = "png";
          } else if (event.target.result.indexOf("image/jpeg") !== -1) {
            imageType = "jpeg";
          } else if (event.target.result.indexOf("image/jpg") !== -1) {
            imageType = "jpg";
          } else if (event.target.result.indexOf("image/gif") !== -1) {
            imageType = "gif";
          }
          uploadUrl += `&name=intraactive-comment-${Helper.getRandomStringKey()}.${imageType}`;
          if (imageHeight !== undefined) {
            uploadUrl += `&height=${imageHeight}`;
          }
          new FetchProxy(uploadUrl, {
            method: "PUT",
            credentials: "same-origin",
            mode: "cors",
            headers: new Headers({
              "AuthToken": token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }),
            body: `"${event.target.result}"`
          })
            .withContainer(sdkContainerInstance)
            .fetch()
            .then((response) => {
              if (!response.ok) {
                throw (response.status);
              } else {
                return response.json();
              }
            }).then(response => {
              resolve(response);
            });
        };
        //fileReader.readAsDataURL(file.blob());
        fileReader.onerror = (error) => {
          console.log(error);
        };
      } else {
        resolve(null);
      }
    });
  }

  public static uploadSelecedFile(file: any, uploadUrl: string, token: string, imageHeight?: number): Promise<string> {
    return new Promise<string>((resolve) => {
      if (file && uploadUrl && token) {
        const fileReader = new FileReader();
        fileReader.onload = (event: any) => {
          let imageType: string;
          if (event.target.result.indexOf("image/png") !== -1) {
            imageType = "png";
          } else if (event.target.result.indexOf("image/jpeg") !== -1) {
            imageType = "jpeg";
          } else if (event.target.result.indexOf("image/jpg") !== -1) {
            imageType = "jpg";
          } else if (event.target.result.indexOf("image/gif") !== -1) {
            imageType = "gif";
          }
          uploadUrl += `&name=intraactive-comment-${Helper.getRandomStringKey()}.${imageType}`;
          if (imageHeight !== undefined) {
            uploadUrl += `&height=${imageHeight}`;
          }
          new FetchProxy(uploadUrl, {
            method: "PUT",
            credentials: "same-origin",
            mode: "cors",
            headers: new Headers({
              "AuthToken": token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }),
            body: `"${event.target.result}"`
          })
            .withContainer(sdkContainerInstance)
            .fetch()
            .then((response) => {
              if (!response.ok) {
                throw (response.status);
              } else {
                return response.json();
              }
            }).then(response => {
              resolve(response);
            });
        };
        fileReader.readAsDataURL(file.blob());
      } else {
        resolve(null);
      }
    });
  }

  public static areCommentsAndLikesEnabled(environment: "Development" | "Test" | "Production", tenant: string, token: string, instance: string, component: string): Promise<{ httpStatus: IHttpStatus, likesEnabled?: boolean, commentsEnabled?: boolean }> {
    return new Promise((resolve) => {
      if (environment && tenant && token && instance && component) {
        const areCommentsAndLikesEnabled = localStorage.getItem(component === "Messages" ? `IA_areCommentsAndLikesEnabledMessages_${instance}` : `IA_areCommentsAndLikesEnabledEvents_${instance}`);
        if (areCommentsAndLikesEnabled && new DateTimeService().getDatePlusOneHour(new Date(JSON.parse(areCommentsAndLikesEnabled).date)) > new Date()) {
          console.log("Using cached areCommentsAndLikesEnabled");
          resolve(JSON.parse(areCommentsAndLikesEnabled).value);
        } else {
          console.log("Fetching areCommentsAndLikesEnabled from server");
          const url = `${Environment.getEnvironmentForSocialAPI(environment)}/${tenant}/${component}/${instance}/enabled`;
          return new FetchProxy(url, {
            method: "GET",
            credentials: "same-origin",
            mode: "cors",
            headers: new Headers({
              "AuthToken": token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }),
          })
            .withContainer(sdkContainerInstance)
            .fetch()
            .then((response) => {
              if (!response.ok) {
                throw (response);
              } else {
                return response.json();
              }
            }).then(response => {
              response.httpStatus = {
                statusCode: 200
              };
              localStorage.setItem(component === "Messages" ? `IA_areCommentsAndLikesEnabledMessages_${instance}` : `IA_areCommentsAndLikesEnabledEvents_${instance}`, JSON.stringify({
                date: new Date(),
                value: response
              }));
              resolve(response);
            })
            .catch(error => {
              console.log(error);
              resolve({
                httpStatus: {
                  statusCode: error.status,
                  message: error.statusText
                }
              });
            });
        }
      }
    });
  }
}