import * as React from 'react';
import { IListElement, IListElementColumn } from '../../../interfaces/IPlay';
import * as styles from './ListElementHeader.css';

export interface IProps {
  listViewId: string;
  listElement: IListElement;
  darkMode: boolean;
}

export interface IState {
}

export class ListElementHeader extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  public render(): JSX.Element {
    const columns: JSX.Element[] = [];
    if (this.props.listElement && this.props.listElement.columns && this.props.listElement.columns.length > 0) {
      this.props.listElement.columns.forEach((column: IListElementColumn, index: number) => {
        switch (column.type) {
          case "preview":
            columns.push(
              <div
                className={styles.IA_previewColumn}
                style={{
                  color: this.props.darkMode ? "#ffffff" : "#333333"
                }}
                key={`column_${index}`}>
                {column.header}
              </div>
            );
            break;
          case "icon":
            columns.push(
              <div
                className={styles.IA_iconColumn}
                style={{
                  color: this.props.darkMode ? "#ffffff" : "#333333"
                }}
                key={`column_${index}`}>
                {column.header}
              </div>
            );
            break;
          default:
            columns.push(
              <div
                className={styles.IA_column}
                key={`column_${index}`}
                style={{
                  fontWeight: column.boldHeader ? 'bold' : 'normal',
                  color: this.props.darkMode ? "#ffffff" : "#333333",
                  ...column.style
                }}
              >
                {column.header}
              </div>
            );
            break;
        }
      });
      columns.push(
        <div
          key={`emptyColumn`}
          className={styles.IA_buttons}
          style={{
            width: document.getElementById(`IA_ListElementButtons_${this.props.listViewId}`) ? document.getElementById(`IA_ListElementButtons_${this.props.listViewId}`).clientWidth : 0
          }}
        >
          {" "}
        </div>
      );
    }
    return (
      <div
        key={`row_${this.props.listElement.id}`}
        className={styles.IA_listElementHeader}
      >
        {columns}
      </div>
    );
  }

  
}