exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_likesAndComments_1QykszOOKbWcglKk6CKyKu {\n  float: left;\n  width: 100%;\n  font-size: 12px;\n  line-height: 20px;\n  margin-top: auto;\n  padding-bottom: 10px;\n  background-color: #f6f6f6;\n  margin-bottom: 20px;\n  box-sizing: border-box;\n}\n\n.IA_commandBar_3jtVliyZW5PUl6LFqEnrjy {\n  width: 100%;\n  height: 30px;\n  float: left;\n  box-sizing: border-box;\n  line-height: 30px;\n}\n\n.IA_commandBarButton_2hGY-wUD8hZYKt-cWkZY37 {\n  min-width: 40px;\n  padding-right: 10px;\n  float: left;\n  cursor: pointer;\n}\n\n.IA_commandBarButtonIcon_3Vdf_sJyp16pOmQKXs0cbr {\n  margin-right: 5px;\n  margin-top: 1px;\n  float: left;\n}\n\n.IA_commandBarButtonText_DrhZN1pJ2vPIrfV01Gkia {\n  float: left;\n  width: auto;\n  font-size: 14px;\n}\n\n.IA_comments_2I7GtpZgbbdWxQ7N0Gt92f {\n  width: 100%;\n  height: auto;\n  box-sizing: border-box;\n  clear: both;\n}\n\n.IA_likes_2CL1eebAVB_7SENkm95Y7H {\n  width: 100%;\n  box-sizing: border-box;\n}\n\n.IA_spinner_3U-6eKUkQt5MDJAjCczg_w {\n  -webkit-animation: spin_2-Xwbyu_ExYhSxP1PqK9FU 700ms linear infinite;\n  -moz-animation: spin_2-Xwbyu_ExYhSxP1PqK9FU 700ms linear infinite;\n  animation: spin_2-Xwbyu_ExYhSxP1PqK9FU 700ms linear infinite;\n  height: 15px;\n  width: 15px;\n  background-position: center;\n  background-size: 15px;\n  position: relative;\n  float: left;\n  clear: both;\n  opacity: 0.5;\n}\n\n.IA_personWhoLiked_3-NgODurYfi00A9aCsqWhw {\n  padding-bottom: 5px;\n  padding-top: 5px;\n  border-bottom: 1px solid #eeeeee;\n  width: calc(100% - 15px);\n}\n\n@-moz-keyframes spin_2-Xwbyu_ExYhSxP1PqK9FU {\n  100% {\n    -moz-transform: rotate(360deg);\n  }\n}\n@-webkit-keyframes spin_2-Xwbyu_ExYhSxP1PqK9FU {\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin_2-Xwbyu_ExYhSxP1PqK9FU {\n  100% {\n    -webkit-transform: rotate(360deg);\n    transform: rotate(360deg);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"IA_likesAndComments": "IA_likesAndComments_1QykszOOKbWcglKk6CKyKu",
	"IA_commandBar": "IA_commandBar_3jtVliyZW5PUl6LFqEnrjy",
	"IA_commandBarButton": "IA_commandBarButton_2hGY-wUD8hZYKt-cWkZY37",
	"IA_commandBarButtonIcon": "IA_commandBarButtonIcon_3Vdf_sJyp16pOmQKXs0cbr",
	"IA_commandBarButtonText": "IA_commandBarButtonText_DrhZN1pJ2vPIrfV01Gkia",
	"IA_comments": "IA_comments_2I7GtpZgbbdWxQ7N0Gt92f",
	"IA_likes": "IA_likes_2CL1eebAVB_7SENkm95Y7H",
	"IA_spinner": "IA_spinner_3U-6eKUkQt5MDJAjCczg_w",
	"spin": "spin_2-Xwbyu_ExYhSxP1PqK9FU",
	"IA_personWhoLiked": "IA_personWhoLiked_3-NgODurYfi00A9aCsqWhw"
};