import * as React from 'react';
import * as styles from './FontColor.css';
import { inject, observer } from 'mobx-react';
import { Store } from '../stores';
import { IAButton } from '../../button/Button';
import { LocalizationService } from '../../../services/LocalizationService';
import { IAColorPicker } from '../../colorPicker/ColorPicker';

export interface IProps {
  store?: Store;
  onClose: () => void;
  onStyleChange: (color: string) => void;
}

export interface IState {
  color?: string;
}

@inject('store')
@observer
export class IAFontColor extends React.Component<IProps, IState> {

  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = { color: undefined }
  }

  public render(): JSX.Element {
    let toolTipContentElement = document.getElementById("IAFontColor");
    let cursorPositionTop = this.props.store.cursorPositionTop;
    let cursorPositionLeft = this.props.store.cursorPositionLeft;
    if (toolTipContentElement) {
      if (this.props.store.cursorPositionTop + toolTipContentElement.clientHeight > window.innerHeight) {
        cursorPositionTop -= toolTipContentElement.clientHeight + 20;
      }
      if (this.props.store.cursorPositionLeft + toolTipContentElement.clientWidth > window.innerWidth) {
        cursorPositionLeft = window.innerWidth - (toolTipContentElement.clientWidth + 20);
      }
    } else {
      this.forceUpdate();
    }
    return (
      <div
        style={{
          position: "fixed",
          zIndex: 3000000,
          height: "100%",
          width: "100%",
          top: 0,
          left: 0
        }}
        onClick={() => this.props.onClose()}
      >
        <div
          id="IAFontColor"
          className={styles.IA_box}
          style={{
            top: this.props.store.isMobile ? undefined : cursorPositionTop,
            bottom: this.props.store.isMobile ? 80 : undefined,
            left: this.props.store.isMobile ? "20px" : cursorPositionLeft,
            height: "auto",
            width: this.props.store.isMobile ? "calc(100% - 40px)" : 230,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <IAColorPicker
            color={this.state.color}
            onChange={(color: string) =>this.setState({ color })}
            showClearButton={false}
            styles={{
              marginLeft: -1,
              marginTop: 0
            }}
            displayInline
          />
          <IAButton
            label={this.localizationService.strings.Editor_Update}
            buttonColor={this.props.store.highlightColor}
            borderRadius={5}
            onClick={() => {
              this.props.onStyleChange(this.state.color ?? "REMOVE");
              this.props.onClose();
            }}
            style={{
              margin: "5px 0 10px 15px",
              clear: "both",
              float: "left",
            }}
          />
          <IAButton
            label={this.localizationService.strings.Editor_Remove}
            buttonColor={"grey"}
            borderRadius={5}
            onClick={() => {
              this.props.onStyleChange("REMOVE");
              this.props.onClose();
            }}
            style={{
              margin: "5px 0 10px 10px",
            }}
          />
        </div>
      </div>
    );
  }
}