
const MAX_CACHE_IN_MILLISECONDS: number = 60 * 60 * 1000;
const DB_VERSION: number = 1;
const URL = window.URL || window.webkitURL;
const GlobalUrlMemoryCache: { [key: string]: IMemoryCacheEntry } = {};

interface IFileBlobEntry {
  blob: Blob;
  timeCreated: number;
}

interface IMemoryCacheEntry {
  createdUrl: string;
  timeCreated: number;
}

export class IndexDbFileService {

  public getCachedFileUrl(url: string): Promise<string> {
    const dateNow = Date.now();
    return new Promise((resolve) => {
      if (GlobalUrlMemoryCache[url]) {
        if ((dateNow - GlobalUrlMemoryCache[url].timeCreated) > MAX_CACHE_IN_MILLISECONDS) {
          URL.revokeObjectURL(GlobalUrlMemoryCache[url].createdUrl);
          delete GlobalUrlMemoryCache[url];
        } else {
          resolve(GlobalUrlMemoryCache[url].createdUrl);
          return;
        }
      }

      const _this = this;
      const request = indexedDB.open("IntraaActiveCacheDb", DB_VERSION);
      request.onerror = function () {
        console.error("Error creating/accessing IndexedDB database");
        resolve(url);
      };

      request.onsuccess = function () {
        const db = request.result;

        db.onerror = function () {
          console.error("Error creating/accessing IndexedDB database");
          resolve(url);
        };

        const transaction = db.transaction(["files"], "readonly");
        const getFileRequest = transaction.objectStore("files").get(url);
        getFileRequest.onsuccess = () => {
          const result = getFileRequest.result as IFileBlobEntry;
          if (result) {
            if ((dateNow - result.timeCreated) > MAX_CACHE_IN_MILLISECONDS) {
              // Download, but still use the cache
              _this.downloadFileAsync(url, db, dateNow);
            }

            const newUrl = URL.createObjectURL(result.blob);
            GlobalUrlMemoryCache[url] = { createdUrl: newUrl, timeCreated: result.timeCreated };
            resolve(newUrl);
          } else {
            _this.downloadFileAsync(url, db, dateNow);
            resolve(url);
          }
        }
        getFileRequest.onerror = () => {
          _this.downloadFileAsync(url, db, dateNow);
          resolve(url);
        }
      }

      request.onupgradeneeded = function () {
        const db = request.result;
        db.createObjectStore('files');
      };
    });
  }

  private downloadFileAsync(url: string, db: IDBDatabase, dateNow: number): void {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.addEventListener("load", () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        this.saveFile(url, blob, db, dateNow);
      }
    }, false);
    xhr.send();
  }

  private saveFile(url: string, blob: Blob, db: IDBDatabase, dateNow: number): void {
    const transaction = db.transaction(["files"], "readwrite");
    transaction.objectStore("files").put({ blob: blob, timeCreated: dateNow }, url);
  }
}