import * as React from 'react';
import { REMOVE_FROM_TABORDER } from '../../global/Constants';
import "../../styles/BasicIA.css";
import { IIcon } from '../../interfaces/IIcon';
import IconService from '../../services/IconService';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { ColorTranslator } from 'colortranslator';

export interface IAIconProps {
  title?: string;
  url?: string;
  icon?: IIcon;
  color: string;
  size: number;
  disableTabKey?: boolean;
  onClick?: (itemUrl: string, event?) => void;
  name?: string;
  style?: React.CSSProperties;
  dataAutomationId?: string;
  tabIndex?: number;
  cursor?: "auto" | "pointer" | "copy";
  spin?: boolean;
  svgStyle?: React.CSSProperties;
}

export interface IAIconState {
  iconUrl?: string;
}

export class IAIcon extends React.Component<IAIconProps, IAIconState> {

  constructor(props: IAIconProps) {
    super(props);
    this.state = { };
  }

  public componentDidMount(): void {
    this.updateIcon();
  }

  public componentDidUpdate(prevProps: Readonly<IAIconProps>): void {
    if (prevProps.url !== this.props.url
      || prevProps.title !== this.props.title
      || prevProps.icon !== this.props.icon
      ) {
      this.updateIcon();
    }
  }

  private updateIcon() {
    let url;
    if (this.props.icon) {
      url = this.getUrlFromIcon(this.props.icon);
    }
    if (this.props.url) {
      url = this.props.url;
    }
    if (this.props.title?.includes(".svg")) {
      url = this.props.title;
    }
    this.setState({ iconUrl: url ?? this.getUrlfromTitle(this.props.title) });
  }

  private getUrlfromTitle(title: string): string {
    return this.getUrlFromIcon(IconService.mapOldIcon(title));
  }

  private getUrlFromIcon(icon: IIcon): string {
    let url;
    if (icon) {
      if (icon.type === "colored") {
        url = `https://intraactivestorage.blob.core.windows.net/cdn/colored%20icons/${icon.path}.svg`;
      } else if (icon.type === "extra") {
        url = `https://intraactivestorage.blob.core.windows.net/cdn/extra%20icons/${icon.path}.svg`;
      } else {
        url = `https://intraactivestorage.blob.core.windows.net/cdn/icons/${icon.title}/SVG/${icon.path}_${icon.type ? icon.type : "regular"}.svg`;
      }
    }
    return url;
  }

  public render(): JSX.Element {
    let tabIndex = REMOVE_FROM_TABORDER;
    if (!this.props.disableTabKey && this.props.onClick != undefined) {
      tabIndex = 0;
    }
    if (this.props.tabIndex) {
      tabIndex = this.props.tabIndex;
    }
    
    let color = "#000000";
    if (this.props.color) {
      color = new ColorTranslator(this.props.color.toLocaleLowerCase()).HEX;
    }
    const isColoredIcon = this.state.iconUrl?.includes("colored icons") || this.state.iconUrl?.includes("colored%20icons");  
    return (
      <div
        className={"IA_OnFocus"}
        tabIndex={tabIndex}
        onKeyPress={(event) => {
          if ((!this.props.disableTabKey && event.key === "Enter") && this.props.onClick != undefined) {
            this.props.onClick(this.state.iconUrl);
          }
        }}
        onClick={this.props.onClick != undefined ? (event) => {
          this.props.onClick(this.state.iconUrl, event)
        } : undefined}
        style={{
          ...this.props.style,
          cursor: this.props.onClick ? (this.props.cursor ? this.props.cursor : "pointer") : "",
          userSelect: "none"
        }}
        data-automation-id={this.props.dataAutomationId}
      >
        {this.state.iconUrl &&
          <img
            src={this.state.iconUrl}
            draggable={false}
            style={{
              ...this.props.svgStyle,
              height: this.props.size,
              width: this.props.size,
              filter: isColoredIcon ? "" : `brightness(0) saturate(100%) ${hexToCSSFilter(color).filter.replace(";", "")}`
            }}
          />
        }
      </div>
    );
  }
}