import * as React from 'react';
import * as styles from './Search.css';
import { IAIcon } from '../icon/Icon';
import { Helper } from '../../Helper';

export interface IProps {
  searchQuery: string;
  placeholder?: string;
  arialable?: string;
  dataAutomationId?: string,
  disabled?: boolean;
  style?: React.CSSProperties;
  darkMode?: boolean;
  fontSize?: number;
  borderRadius?: number;
  borderColor?: string;
  borderWidth?: number;
  backgroundColor?: string;
  onChange?: (option: string) => void;
}

export interface IState {
}

export class IASearch extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    }
  }

  public render(): JSX.Element {
    return (
      <div
        className={styles.IA_search}
        style={this.props.style}
      >
        <input
          style={{
            clear: "both",
            paddingLeft: "10px",
            width: "100%",
            boxSizing: "border-box",
            backgroundColor: this.props.darkMode ? Helper.darkModeInputFieldBackgroundColor : this.props.backgroundColor ?? "#f4f4f4",
            color:this.props.darkMode ? "#ffffff" : "#333333",
            borderRadius: this.props.borderRadius,
            borderStyle: this.props.borderColor && !this.props.darkMode ? "solid" : "none",
            borderColor: this.props.borderColor,
            borderWidth: this.props.borderWidth,
            fontSize: this.props.fontSize ?? "14px"
          }}
          disabled={this.props.disabled}
          type="text"
          value={this.props.searchQuery}
          placeholder={this.props.placeholder}
          onChange={(event: any) => {
            this.props.onChange(event.target.value);
          }}
        />
        {this.props.searchQuery &&
          <IAIcon
            title={"Close"}
            size={18}
            color={"#333333"}
            style={{
              float: "right",
              marginTop: -28,
              marginRight: 10
            }}
            onClick={() => {
              this.props.onChange("");
            }}
          />
        }
      </div>
    );
  }

  
}