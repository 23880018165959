import * as React from 'react';
import { Store } from '../../../stores';
import { inject, observer } from 'mobx-react';
import { LocalizationService } from '../../../../../services/LocalizationService';
import { Popup } from '../../../reusableComponents/popup/Popup';
import { IAButton } from '../../../../button/Button';
import PlayService from '../../../../../services/PlayService';
import { IASpinner } from '../../../../spinner/Spinner';

export interface IPlayAppRegistration {
  name: string;
  heading?: string;
  description?: string;
}

interface IPlayAppConsent {
  appRegistration: IPlayAppRegistration;
  consentUrl: string;
}

interface IProps {
  label?: string;
  content?: string;
  onConsent: (string) => void;
  onSuccess: () => void;
  appRegistrations?: IPlayAppRegistration[];
  store?: Store;
}

interface IState {
  loading: boolean;
  consents: IPlayAppConsent[];
  currentConsentIndex?: number;
  consentStage?: ConsentStage;
  displayConsentPopup: boolean;
}

enum ConsentStage {
  Reviewing,
  Consenting,
  Consented,
  Finished
}

@inject('store')
@observer
export class AppRegistrationConsentHelper extends React.Component<IProps, IState> {
  
  private interval?: number;
  private readonly localizationService: LocalizationService = new LocalizationService();

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      consents: [],
      currentConsentIndex: null,
      displayConsentPopup: false,
    }
  }

  async componentWillMount(): Promise<void> {
    const consents = [] as IPlayAppConsent[];

    if (this.props.appRegistrations?.length > 0) {
      for (const appRegistration in this.props.appRegistrations) {
        await PlayService.verifyAppRegistrationConsent(this.props.store?.environment, this.props.store?.token, this.props.store?.tenantId, this.props.appRegistrations[appRegistration].name).then(async (res) => {
          if (res.result === false) {
            consents.push({
              appRegistration: this.props.appRegistrations[appRegistration],
              consentUrl: res.hint
            });
          }
        });
      }
    }

    if (consents.length == 0) {
      this.props.onSuccess();
    }

    this.setState({
      consents: consents,
      currentConsentIndex: consents.length > 0 ? 0 : null,
      consentStage: consents.length > 0 ? ConsentStage.Reviewing : ConsentStage.Finished,
      loading: false,
    });
  }

  private getCurrectConsentRef() : IPlayAppConsent {
    return this.state.consents[this.state.currentConsentIndex];
  }

  private getNextButtonLabel() : string {
    switch (this.state.consentStage) {
      case ConsentStage.Reviewing:
        return this.localizationService.strings.PlayAdmin_AppRegistrationConsentHelperButtonReviewing;
      case ConsentStage.Finished:
        return this.localizationService.strings.PlayAdmin_AppRegistrationConsentHelperButtonFinished;
      default:
        return this.localizationService.strings.PlayAdmin_AppRegistrationConsentHelperButtonDefaultProceed;
    }
  }

  private getHeading() : string {
    switch (this.state.consentStage) {
      case ConsentStage.Consenting:
        return this.localizationService.strings.PlayAdmin_AppRegistrationConsentHelperHeadingConsenting;
      case ConsentStage.Finished:
        return this.localizationService.strings.PlayAdmin_AppRegistrationConsentHelperHeadingFinished;
      default:
        return this.getCurrectConsentRef()?.appRegistration.heading ?? "";
    }
  }

  private getDescription() : string {
    switch (this.state.consentStage) {
      case ConsentStage.Finished:
        return this.localizationService.strings.PlayAdmin_AppRegistrationConsentHelperDescriptionFinished;
      case ConsentStage.Consenting:
        return this.localizationService.strings.PlayAdmin_AppRegistrationConsentHelperDescriptionConsenting;
      case ConsentStage.Consented:
        return this.localizationService.strings.PlayAdmin_AppRegistrationConsentHelperDescriptionConsented;
      default:
        return this.getCurrectConsentRef()?.appRegistration.description ?? "";
    }
  }

  private checkForConsent(): void {
    let successfulResponses = 0;
    this.interval = window.setInterval(() => {
      PlayService.verifyAppRegistrationConsent(this.props.store?.environment, this.props.store?.token, this.props.store?.tenantId, this.getCurrectConsentRef().appRegistration.name).then(async (res) => {
        if (res.result === true) {
          ++successfulResponses;
          if (successfulResponses > 3) {
            this.next();
            clearInterval(this.interval);
          }
        }
      });
    }, 5000);
  }

  private requiresMoreConsentGrants(): boolean {
    return this.state.currentConsentIndex < (this.state.consents.length - 1);
  }

  private break(): void {
    alert("TODO");
  }

  async next(): Promise<void> {
    switch (this.state.consentStage) {
      case ConsentStage.Reviewing:
        // Get the consent URL for the app registration
        const consentUrl = this.state.consents[this.state.currentConsentIndex].consentUrl;
        // Check if consent URL exists
        if (consentUrl) {
          // Open the consent URL in a new window
          window.open(consentUrl);
          // Go to next stage
          this.setState({ consentStage: ConsentStage.Consenting });
          // Start checking for consent grant
          this.checkForConsent();
        } else {
          // Display error message
          this.break();
        }
        break;
      case ConsentStage.Consenting:
        // Go to next stage
        this.setState({ consentStage: ConsentStage.Consented });
        break;
      case ConsentStage.Consented:
        // Notify consent granted
        this.props.onConsent(this.getCurrectConsentRef().appRegistration.name);
        // Check if more consents are required
        if (this.requiresMoreConsentGrants()) {
          // Prepare for next app registration review
          this.setState({
            currentConsentIndex: this.state.currentConsentIndex + 1,
            consentStage: ConsentStage.Reviewing
          });
        } else {
          // Go to next stage
          this.setState({
            currentConsentIndex: null,
            consentStage: ConsentStage.Finished
          });
        }
        break;
      case ConsentStage.Finished:
        // Close the component
        this.close();
        break;
      default:
        // Should not get here
        break;
    }
  }

  close() : void {
    this.setState({ displayConsentPopup: false });
    if (this.state.consentStage == ConsentStage.Finished) {
      this.props.onSuccess();
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  public render(): JSX.Element {
    return (
      <>
        {this.state.loading ?
          <IASpinner
            color={this.props.store.highlightColor}
          />
          :
          <>
            {this.state.consentStage != null &&
              <>
                {this.state.displayConsentPopup &&
                  <Popup
                    headline={this.getHeading()}
                    close={() => this.close()}
                    darkMode={this.props.store.darkMode}
                    content={
                      <>
                        <p>{this.getDescription()}</p>
                        {this.state.consentStage === ConsentStage.Consenting ?
                          <IASpinner
                            color={this.props.store.highlightColor}
                          />
                          :
                          <IAButton
                            buttonColor={this.props.store.highlightColor}
                            darkMode={this.props.store.darkMode}
                            label={this.getNextButtonLabel()}
                            onClick={() => {
                              this.next();
                            }}
                            borderRadius={5}
                            style={{
                              float: "left"
                            }}
                          />
                        }
                      </>
                    }
                  />
                }
                <p>
                  {this.props.content &&
                    <>{this.props.content}</>
                  }
                  <IAButton
                    label={this.props.label ?? this.localizationService.strings?.PlayAdmin_Connect?.toUpperCase()}
                    buttonColor={this.props.store.highlightColor}
                    disbaled={false}
                    darkMode={this.props.store.darkMode}
                    onClick={async () => {
                      this.setState({
                        displayConsentPopup: true
                      });
                    }}
                    borderRadius={5}
                    showSpinner={this.props.store.workingOnIt}
                    style={{ clear: "both", float: "none", margin: "12px 0 0 0" }}
                  />
                </p>
              </>
            }
          </>
        }
      </>
    );
  }
}