import * as React from 'react';
import * as styles from './NumberField.css';

export interface INumberFieldProps {
  label?: string;
  number: number;
  max?: number;
  min?: number;
  styles?: React.CSSProperties;
  darkMode?: boolean;
  borderRadius?: number;
  disabled?: boolean;
  labelStyle?: any;
  inputProp?: React.InputHTMLAttributes<HTMLInputElement>;
  highlightColor?: string;
  onChange: (number: number) => void;
}

export interface INumberFieldState {
  number: number;
}

export class IANumberField extends React.Component<INumberFieldProps, INumberFieldState> {

  constructor(props: INumberFieldProps) {
    super(props);
    this.state = {
      number: props.number
    };
  }

  public componentWillReceiveProps(props: INumberFieldProps): void {
    if (props.number !== this.props.number) {
      this.setState({ number: props.number });
    }
  }

  private onChange(number: number): void {
    this.setState({ number });
    this.props.onChange(number);
  }

  public render(): JSX.Element {
    const { inputProp } = this.props
    const style = this.props.styles ?? {};
    if (!style["--highlight-color"]) {
      style["--highlight-color"] = this.props.highlightColor ?? "black";
    }
    return (
      <div
        className={this.props.darkMode ? styles.IA_numberFieldDarkMode : styles.IA_numberField}
        style={{
          ...this.props.styles,
        }}
      >
        {this.props.label &&
          <label style={{ ...this.props.labelStyle }}>{this.props.label}</label>
        }
        <input
          {...inputProp}
          type="number"
          value={this.state.number}
          max={this.props.max}
          min={this.props.min}
          style={{
            borderRadius: this.props.borderRadius,
            opacity: this.props.disabled ? 0.5 : 1
          }}
          onChange={(event: any) => {
            if (!this.props.disabled) {
              this.onChange(parseInt(event.target.value));
            }
          }}
          onBlur={() => {
            if (this.props.min && this.state.number <= this.props.min) {
              this.onChange(this.props.min);
            }
            if (this.props.max && this.props.max <= this.state.number) {
              this.onChange(this.props.max);
            }
          }}
        />
      </div>
    );
  }


}