exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_gallery_gBKgdfpj_WiEPIvAirIT0 {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n\n.IA_image_2A0Gltl0wCptlZWNkI_HPk {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_imageWithSlidingTransition_Kf4YoMDgQ1169pbKm4vy3 {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  float: left;\n  background-size: cover;\n  background-position: center;\n}\n\n.IA_thumbnails_B-w66LP8VmRdFuiLXT5Vm {\n  width: 100%;\n  position: absolute;\n  bottom: 0;\n  background-color: rgb(51,51,51);\n}\n\n.IA_thumbnailsContainer_28SCqvNVP6d9FnZV7JUPhN {\n  position: relative;\n  height: 100%;\n  width: max-content;\n  transition: margin-left 500ms ease-in-out;\n}\n\n.IA_thumbnail_30_69g6D_stMguVcIKI3mf {\n  width: 100px;\n  height: 100px;\n  float: left;\n  background-size: cover;\n  background-position: center;\n  transition: opacity 500ms ease-in-out;\n}", ""]);

// Exports
exports.locals = {
	"IA_gallery": "IA_gallery_gBKgdfpj_WiEPIvAirIT0",
	"IA_image": "IA_image_2A0Gltl0wCptlZWNkI_HPk",
	"IA_imageWithSlidingTransition": "IA_imageWithSlidingTransition_Kf4YoMDgQ1169pbKm4vy3",
	"IA_thumbnails": "IA_thumbnails_B-w66LP8VmRdFuiLXT5Vm",
	"IA_thumbnailsContainer": "IA_thumbnailsContainer_28SCqvNVP6d9FnZV7JUPhN",
	"IA_thumbnail": "IA_thumbnail_30_69g6D_stMguVcIKI3mf"
};