exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_personaCard_XBkB85a_-F3GOYD4mdC1E{\n  display:flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.IA_personaWrapper_2pIXkg-YV1hpXt54mSZEFf {\n  font-size: 14px;\n  float: left;\n  display: block;\n  width: 100%;\n  padding: 8px 0;\n}\n\n.IA_personaWrapper_2pIXkg-YV1hpXt54mSZEFf .IA_personaProfileImageWrapper_1hOPE_vT-MhgO1XqbjfmLd {\n  height: 30px;\n  width: 30px;\n  float: left;\n  border-radius: 30px;\n  margin-right: 7px;\n  margin-top: 1px;\n  font-size: 15px;\n  text-align: center;\n  line-height: 29px;\n  text-transform: uppercase;\n  font-weight: 200;\n}\n\n\n.IA_personaSmall_3ciq0VlkhELozvR6caURuf .IA_personaProfileImageText_3SVmsjSDP3Zez5XQAac8Mw {\n  height: 30px;\n  width: 30px;\n  border-radius: 30px;\n  left: 0px;\n  top: 0px;\n  position: relative;\n  float: left;\n}\n\n\n.IA_personaUserPrincipalName_1tzWdQ85CfHhUK3Syw_E7I {\n  font-size: 9px;\n  opacity: 0.8\n}", ""]);

// Exports
exports.locals = {
	"IA_personaCard": "IA_personaCard_XBkB85a_-F3GOYD4mdC1E",
	"IA_personaWrapper": "IA_personaWrapper_2pIXkg-YV1hpXt54mSZEFf",
	"IA_personaProfileImageWrapper": "IA_personaProfileImageWrapper_1hOPE_vT-MhgO1XqbjfmLd",
	"IA_personaSmall": "IA_personaSmall_3ciq0VlkhELozvR6caURuf",
	"IA_personaProfileImageText": "IA_personaProfileImageText_3SVmsjSDP3Zez5XQAac8Mw",
	"IA_personaUserPrincipalName": "IA_personaUserPrincipalName_1tzWdQ85CfHhUK3Syw_E7I"
};