exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_companyInformation_1WWVBoxY-DGA7zB19AIf33 {\n  width: 100%;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_divider_1YsetxTl5TV5iw1kEfd1Sj, .IA_dividerDarkMode_1jj9vTi_mfR7fZ9aTCz3Mt {\n  height: 1px;\n  width: 100%;\n  background-color: #eeeeee;\n  margin-top: 40px;\n  margin-bottom: 20px;\n  float: left;\n}\n\n.IA_billingInfo_1MO-SI3EY80ZBOn0AHao4T {\n  font-size: 14px;\n}", ""]);

// Exports
exports.locals = {
	"IA_companyInformation": "IA_companyInformation_1WWVBoxY-DGA7zB19AIf33",
	"IA_divider": "IA_divider_1YsetxTl5TV5iw1kEfd1Sj",
	"IA_dividerDarkMode": "IA_dividerDarkMode_1jj9vTi_mfR7fZ9aTCz3Mt",
	"IA_billingInfo": "IA_billingInfo_1MO-SI3EY80ZBOn0AHao4T"
};