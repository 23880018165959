import * as React from 'react';
import { IPlayFooter, IPlayWeather } from '../../../../interfaces/IPlay';
import { PlayHelper } from '../../../playAdmin/PlayHelper';
import * as styles from './FooterWeather.css';

export interface IProps {
  sizeUnit: number;
  footer: IPlayFooter
  city: string;
  currentWeather: IPlayWeather;
}

export interface IState {
  iconHasError: boolean;
}

export class FooterWeather extends React.Component<IProps, IState> {
  constructor(props: IProps){
    super(props);
    this.state = {
      iconHasError: false
    }
  }

  private getMarginForIcon(iconUrl: string): { marginTop: string, marginBottom: string } {
    if (iconUrl?.indexOf("partlycloudy") !== -1) {
      return { marginTop: "7%", marginBottom: "0" };
    } else if (iconUrl?.toLowerCase().match(/(fog|heavyrain.svg|heavyrainandthunder|heavysleet.svg|heavysleetandthunder|heavysnow.svg|heavysnowandthunder|lightrain.svg|lightrainandthunder|lightsleet.svg|lightsleetandthunder|lightsnow.svg|lightsnowandthunder|rain.svg|rainandthunder|sleet.svg|sleetandthunder|snow.svg|snowandthunder)$/)) {
      return { marginTop: "0", marginBottom: "7%" };
    } else {
      return { marginTop: "0", marginBottom: "0" };
    }
  }

  public render(): JSX.Element {
    const margin = this.getMarginForIcon(this.props.currentWeather?.iconUrl);
    return (
      <div
        className={styles.IA_weather}
        style={{
          marginRight: !this.props.footer.showClock ? 20 : 0
        }}
      >
        {this.props.currentWeather && this.props.currentWeather.iconUrl &&
          <img
            style={{
              marginRight: PlayHelper.getFontSize("small", this.props.sizeUnit * 100),
              marginTop: margin.marginTop,
              marginBottom: margin.marginBottom,
              display: this.state.iconHasError ? "none" : "block"
            }}
            className={styles.IA_weatherIcon}
            src={this.props.currentWeather.iconUrl}
            onError={() => this.setState({iconHasError: true})}
          />
        }
        <div>
          <div className={styles.IA_weatherTemperature}
            style={{
              fontSize: PlayHelper.getFontSize("extraLarge", this.props.sizeUnit * 100),
              color: this.props.footer.color
            }}
          >
            {this.props.currentWeather && this.props.currentWeather.airTemperature &&
              `${Math.round(this.props.currentWeather.airTemperature)}\u00b0C`
            }
          </div>
          <div
            className={styles.IA_weatherArea}
            style={{
              fontSize: PlayHelper.getFontSize("small",  this.props.sizeUnit * 100),
              color: this.props.footer.color
            }}
          >
            {this.props.city}
          </div>
        </div>
      </div>
    );
  }

  
}