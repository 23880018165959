import * as React from 'react';
import { Helper } from '../../../../Helper';
import { IColorTheme } from '../../../../interfaces/IColorTheme';
import { IEnvironment } from '../../../../interfaces/IEnvironment';
import { IPlayNewsItem, IPlayWidgetAreaType, IPlayWidgetExtended } from '../../../../interfaces/IPlay';
import { IRss, IRssItem } from '../../../../interfaces/IRss';
import { Environment } from '../../../../services/Environment';
import RssService from '../../../../services/RssService';
import { Rotator } from '../rotator/Rotator';

export interface IProps {
  widget: IPlayWidgetExtended;
  width: number;
  height: number;
  playerWidth: number;
  playerHeight: number;
  environment: IEnvironment;
  tenantId: string;
  profileId: string;
  editPreviewMode: boolean;
  areaType: IPlayWidgetAreaType;
  language?: string;
  previewColorTheme?: IColorTheme;
  willRestart: () => void;
}

export interface IState {
  marginLeft: number;
  progressBar: number;
  articles: IPlayNewsItem[];
  feedUrl: string;
  feedHasError: string;
  isFetchingFeed: boolean;
  contentPosition: "left" | "right" | "random";
}

export class Rss extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      marginLeft: 0,
      progressBar: 0,
      articles: [],
      feedUrl: "",
      feedHasError: undefined,
      isFetchingFeed: false,
      contentPosition: props.widget?.rss?.contentPosition
    };
  }

  public componentWillReceiveProps(): void {
    if (this.props.widget?.rss?.url !== this.state.feedUrl || this.props.widget?.rss?.contentPosition !== this.state.contentPosition) {
      this.setState({contentPosition: this.props.widget?.rss?.contentPosition});
      this.getRssFeed();
    }
  }

  private getRssFeed(): void {
    try {
      const feedUrl = this.props.widget?.rss?.url;
      if (this.state.isFetchingFeed || this.state.feedHasError && this.state.feedHasError === feedUrl) {
        return;
      }
      if (localStorage.getItem(`IA_rss_${feedUrl}`)) {
        let items = JSON.parse(localStorage.getItem(`IA_rss_${feedUrl}`)).item;
        const articles: IPlayNewsItem[] = [];
        items.forEach((item: IRssItem) => {
          if (item) {
            articles.push(
              {
                id: item.title,
                // type: this.props.widget.rss.type,
                author: item.outlet,
                title: item.title,
                content: item.description ? Helper.stripHtml(item.description ?? "") : "",
                date: item?.pubDate,
                image: item.image,
                link: item.link,
                linkText: this.props.widget?.rss?.linkText,
                contentPosition:  this.state.contentPosition
              }
            );
          }
        });
        this.setState({ articles, feedUrl });
      }
      this.setState({ isFetchingFeed: true });
      RssService.getDirectFeed(feedUrl, this.props.widget?.rss?.maxItems).then((rssResult: IRss) => {
        if (rssResult && rssResult.item) {
          localStorage.setItem(`IA_rss_${feedUrl}`, JSON.stringify(rssResult));
          const articles: IPlayNewsItem[] = [];
          rssResult.item.forEach((item: IRssItem) => {
            articles.push(
              {
                id: item.title,
                // type: this.props.widget.rss.type,
                // author: item.outlet,
                title: item.title,
                content: item.description ? Helper.stripHtml(item.description ?? "") : "",
                date: item?.pubDate,
                metaData: item.outlet,
                image: item.image ?? item.thumbnail,
                link: item.link,
                linkText: this.props.widget?.rss?.linkText,
                contentPosition: this.props.widget?.rss?.contentPosition
              }
            );
          });
          this.setState({ articles, feedUrl });
        }
      }).catch(() => {
        this.setState({ feedHasError: feedUrl });
        this.props.willRestart();
      }).finally(() => {
        this.setState({ isFetchingFeed: false });
      });
    } catch (error) {
      console.log(`%c${error}`, 'background: red; color: #ffffff')
    }
  }

  public render(): JSX.Element {
    const colorThemes = this.props.previewColorTheme ? [this.props.previewColorTheme] : (this.props.widget.rss?.colorThemes?.length > 0 ? this.props.widget.rss?.colorThemes : [])
    if (colorThemes.length === 0) {
      colorThemes.push(
        {
          textColor: "#333333",
          backgroundColor: "#ffffff"
        }
      );
    }
    return this.state.articles && this.state.articles.length > 0 ? (
      <Rotator
        widgetType="rss"
        newsItems={this.state.articles}
        widgetId={this.props.widget?.id}
        linkPortalURL={Environment.getEnvironmentForLinkPortal(this.props.environment)}
        width={this.props.width}
        height={this.props.height}
        playerHeight={this.props.playerHeight}
        playerWidth={this.props.playerWidth}
        environment={this.props.environment}
        tenantId={this.props.tenantId}
        profileId={this.props.profileId}
        transition={this.props.widget.rss?.transition}
        duration={this.props.widget.rss?.duration}
        boxStyle={this.props.widget.rss.boxStyle ? this.props.widget.rss.boxStyle : "solid"}
        editPreviewMode={this.props.editPreviewMode}
        areaType={this.props.areaType}
        colorThemes={colorThemes}
        showQRLink={this.props.widget.rss?.showQRLink != undefined ? this.props.widget.rss?.showQRLink : true}
        showTitle={this.props.widget.rss?.showTitle != undefined ? this.props.widget.rss?.showTitle : true}
        showText={this.props.widget.rss?.showText != undefined ? this.props.widget.rss?.showText : true}
        showProgressBar={this.props.widget?.showProgressBar != undefined ? this.props.widget?.showProgressBar : true}
        showAuthor={true}
        showDate={this.props.widget.rss?.showDate != undefined ? this.props.widget.rss?.showDate : true}
        showImage={this.props.widget.rss?.showImage != undefined ? this.props.widget.rss?.showImage : true}
        showVideo={false}
        headerTitle={this.props.widget.title}
        showGallery={false}
        language={this.props.language}
        widgetTitleIsShowing={this.props.widget?.showTitle}
        excludeImagesFromImageAPI={true}
        willRestart={() => this.props.willRestart()}
      />
    ) : <div></div>;
  }
}