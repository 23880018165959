import * as React from 'react';
import { IPlayWeatherForecast } from '../../../../../interfaces/IPlay';
import * as styles from '../Weather.css';

export interface IProps {
  widgetId: string;
  forecast: IPlayWeatherForecast[];
  sizeUnit: number;
  marginMultiplier: number;
  width: number;
  color: string;
}

export interface IState {
}

export class TemperatureGraph extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
    };
  }

  public componentDidMount(): void {
    if (this.props.forecast) {
      this.drawTemperatureGraph();
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.forecast && prevProps.forecast !== this.props.forecast 
      || this.props.widgetId !== prevProps.widgetId) {
      this.drawTemperatureGraph();
    }
  }

  private drawTemperatureGraph() {
    let svgPath = "";
    const container = document.getElementById(`graphContainer_${this.props.widgetId}`) as HTMLDivElement;

    const marginSize = this.props.sizeUnit;
    const drawHeight = (container.clientHeight * .65) - (this.props.sizeUnit * 2); // adds padding above and below graph

    const containerMargin = this.props.sizeUnit * this.props.marginMultiplier;
    const xStepSize = (container.clientWidth - (containerMargin * 2)) / 10;
    
    const lowestLow = this.getLowestLow(this.props.forecast);
    const highestHigh = this.getHighestHigh(this.props.forecast);
    const tempDifference = highestHigh - lowestLow;
    
    const drawScale = drawHeight / tempDifference;

    // ======================================================================
    // Canvas setup - has been replaced by SVG, but I leave here for future reference
    // const canvas = document.getElementById("tempGraph") as HTMLCanvasElement;
    // const ctx = canvas.getContext("2d");
    // canvas.width = container.clientWidth;
    // canvas.height = container.clientHeight * .65;
    // ctx.beginPath();
    // ctx.lineWidth = this.props.sizeUnit / 5;
    // ctx.strokeStyle = "hsl(0,0%,50%)";
    // ctx.shadowColor = "hsl(0,0%,50%)";
    // ctx.shadowBlur = this.props.sizeUnit / 5;;

    let steps = 0;
    let prevX: number;
    let prevY: number;
    this.props.forecast.forEach((weather, i) => {
      const lowNormalized = weather.airTemperatureLow - lowestLow;
      const highNormalized = weather.airTemperatureHigh - lowestLow;
      if (i === 0) {
        // First forecast - start of line
        const x1 = steps++;
        const y1 = (drawHeight - lowNormalized * drawScale) + marginSize;
        // ctx.moveTo(x1, y1);
        svgPath += `M ${x1}, ${y1}`;

        const x2 = steps++ * xStepSize + containerMargin;
        const y2 = (drawHeight - highNormalized * drawScale) + marginSize;
        const controlPoint1X = x2 * .33;
        const controlPoint2X = x2 * .67;
        // ctx.bezierCurveTo(controlPoint1X, y1, controlPoint2X, y2, x2, y2);
        svgPath += ` C ${controlPoint1X}, ${y1}, ${controlPoint2X}, ${y2}, ${x2}, ${y2}`;

        prevX = x2;
        prevY = y2;
      } else if (0 < i && i < 4) {
        // All forecasts between first and last
        const x1 = steps++ * xStepSize + containerMargin;
        const y1 = (drawHeight - lowNormalized * drawScale) + marginSize;
        const controlPoint1X1 = ((x1 - prevX) * .33) + prevX;
        const controlPoint2X1 = ((x1 - prevX) * .67) + prevX;
        // ctx.bezierCurveTo(controlPoint1X1, prevY, controlPoint2X1, y1, x1, y1);
        svgPath += ` C ${controlPoint1X1}, ${prevY}, ${controlPoint2X1}, ${y1}, ${x1}, ${y1}`;
        
        const x2 = steps++ * xStepSize + containerMargin;
        const y2 = (drawHeight - highNormalized * drawScale) + marginSize;
        const controlPoint1X2 = ((x2 - x1) * .33) + x1;
        const controlPoint2X2 = ((x2 - x1) * .67) + x1;
        // ctx.bezierCurveTo(controlPoint1X2, y1, controlPoint2X2, y2, x2, y2);
        svgPath += ` C ${controlPoint1X2}, ${y1}, ${controlPoint2X2}, ${y2}, ${x2}, ${y2}`;
        prevY = y2;
        prevX = x2;
      } else if (i === 4) {
        // Last forecast
        const x1 = steps++ * xStepSize + containerMargin;
        const y1 = (drawHeight - lowNormalized * drawScale) + marginSize;
        const controlPoint1X1 = ((x1 - prevX) * .33) + prevX;
        const controlPoint2X1 = ((x1 - prevX) * .67) + prevX;
        // ctx.bezierCurveTo(controlPoint1X1, prevY, controlPoint2X1, y1, x1, y1);
        svgPath += ` C ${controlPoint1X1}, ${prevY}, ${controlPoint2X1}, ${y1}, ${x1}, ${y1}`;

        const x2 = steps++ * xStepSize + containerMargin;
        const y2 = (drawHeight - highNormalized * drawScale) + marginSize;
        const controlPoint1X2 = ((x2 - x1) * .33) + x1;
        const controlPoint2X2 = ((x2 - x1) * .67) + x1;
        // ctx.bezierCurveTo(controlPoint1X2, y1, controlPoint2X2, y2, x2, y2);
        svgPath += ` C ${controlPoint1X2}, ${y1}, ${controlPoint2X2}, ${y2}, ${x2}, ${y2}`;

        const lowNormalizedNextDay = this.props.forecast[i + 1].airTemperatureLow - lowestLow;
        const x3 = container.clientWidth;
        const y3 = (drawHeight - lowNormalizedNextDay * drawScale) + marginSize;
        const controlPoint1X3 = ((x3 - x2) * .33) + x2;
        const controlPoint2X3 = ((x3 - x2) * .67) + x2;
        // ctx.bezierCurveTo(controlPoint1X3, y2, controlPoint2X3, y3, x3, y3);
        svgPath += ` C ${controlPoint1X3}, ${y2}, ${controlPoint2X3}, ${y3}, ${x3}, ${y3}`;
      }
    });
    // ctx.stroke();
    // ctx.closePath();

    // ======================================================================
    // SVG
    const path = document.getElementById(`tempGraphSVGPath_${this.props.widgetId}`);
    if (path) {
      path.setAttribute("d", svgPath);
    }
  }

  private getHighestHigh(forecast: IPlayWeatherForecast[]): number {
    const highs = forecast.map(weather => weather.airTemperatureHigh);
    let higestHigh = Number.MIN_SAFE_INTEGER;
    highs.forEach(i => {
      if (i > higestHigh) {
        higestHigh = i;
      }
    });
    return higestHigh;
  }

  private getLowestLow(forecast: IPlayWeatherForecast[]): number {
    const lows = forecast.map(weather => weather.airTemperatureLow);
    let lowestLow = Number.MAX_SAFE_INTEGER;
    lows.forEach(i => {
      if (i < lowestLow) {
        lowestLow = i;
      }
    });
    return lowestLow;
  }

  public render(): JSX.Element {
    return (
      <div
        id={`graphContainer_${this.props.widgetId}`}
        className={styles.IA_graphContainer}
      >
        {/* <canvas
          id="tempGraph"
        ></canvas> */}
        <svg
          id="tempGraphSVG"
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
          }}
        >
          <path
            id={`tempGraphSVGPath_${this.props.widgetId}`}
            fill="none"
            stroke={this.props.color}
            strokeWidth={this.props.sizeUnit / 4}
          />
        </svg>
      </div>
    );
  }
}