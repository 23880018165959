exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".IA_widgets_3QmWxdY2-4lavLqm6Hqvpf {\n  position: relative;\n  width: 100%;\n  height: auto;\n  float: left;\n}\n\n.IA_filters_3TTnVuxdxO4KbnVzSDgDlV {\n  width: 100%;\n  margin-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n}\n\n.IA_paginationButtonContainer_OFTa-iyjcDo9aECSdwvc7 {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-top: 20px;\n}\n\n.IA_paginationPageCount_2Gr6MXILjeU5807TG9-grl {\n  text-align: center;\n}", ""]);

// Exports
exports.locals = {
	"IA_widgets": "IA_widgets_3QmWxdY2-4lavLqm6Hqvpf",
	"IA_filters": "IA_filters_3TTnVuxdxO4KbnVzSDgDlV",
	"IA_paginationButtonContainer": "IA_paginationButtonContainer_OFTa-iyjcDo9aECSdwvc7",
	"IA_paginationPageCount": "IA_paginationPageCount_2Gr6MXILjeU5807TG9-grl"
};