import * as React from 'react';
import * as styles from './Spinner.css';

export interface IIASpinnerProps {
  style?: React.CSSProperties;
  color?: string;
}

export class IASpinner extends React.Component<IIASpinnerProps, {}> {

  constructor(props: IIASpinnerProps) {
    super(props);
  }

  public render(): JSX.Element {
    return (
      <div
        className={styles.loader}
        style={{...this.props.style, borderLeftColor: this.props.color ?? "#006DC7"}}>
      </div>
    );
  }
}
